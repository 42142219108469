import CatalogLayout from "../../library/BookCatalog/Catalog";
import useCourseCertificate from "./hooks/useCourseCertificate";

const CourseCertificate = () => {
  const { isLoading, data, searchHandler, returnBook } = useCourseCertificate();

  return (
    <CatalogLayout
      data={data?.data}
      searchPlaceholder="Search Certification"
      navLink="/courses"
      navText="Certification"
      isLoading={isLoading}
      searchFn={searchHandler}
      returnBook={returnBook}
    />
  );
};

export default CourseCertificate;
