// Module
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

// React icons
import {
  AddIcon,
  CameraIcon,
  DeleteIcon,
  PublishIcon,
  SaveIcon,
  ViewIcon,
  CloseIcon,
  UnPublishedIcon,
} from "../../../../assets/icons";

// Components
import Dropdown from "../../../../components/Dropdown";
import InputField from "../../../../components/InputField";
import TextArea from "../../../../components/TextArea";
import { FullpageModal } from "../../../../components/Modal";
import Tooltip from "../../../../components/Tooltip";
import { ThumbnailAction } from "./ThumbnailAction";
import { PageAction } from "./PageAction";
import Reader from "../../../../components/BookReader/Reader";
import ConfirmDialog from "../../../../components/ConfirmationDialogCD/ConfirmDialog";

// Template
import { TEMPLATE_LIST } from "../../../../templates/dictionary";

// Style
import style from "./EditBook.module.css";

// Hooks
import useBookForm from "../hooks/useBookForm";
import { defaultImage } from "../../../../assets/image";
import { toast } from "react-toastify";
import adminService from "../../../../service/admin.service";
import useCdReactQuery from "../../../../utils/hooks/useCdReactQuery";
import { upperCase } from "lodash";

const EditBook = () => {
  const navigate = useNavigate();
  const { bookId } = useParams();
  const { queryEndpoint } = useCdReactQuery();
  const {
    saveBook,
    state,
    updateFormState,
    updateFormStateDataFromInputField,
    toggleModal,
    modalOpen,
    previewClicked,
    setPreviewClicked,
    openPageForm,
    previewPage,
    pageUpdateHandler,
    readerModal,
    toggleReaderModal,
    classes,
    updateClassSubjectList,
    classSubjects,
    subjectTopics,
    updateTopicList,
    updateImage,
    publishBook,
    unpublishBook,
    deleteImageFolder,
    deleteBookHandler: deleteBook,
  } = useBookForm();

  useEffect(() => {
    (async () => {
      const bookDetails = await queryEndpoint({
        endpointUrl: adminService.getBook(bookId),
        httpMethod: "GET",
      });

      const {
        _id,
        book_cover_url,
        book_type,
        pages,
        language,
        is_published,
        title,
        description,
      } = bookDetails.book_details;

      updateFormState({
        book_id: _id,
        bookType: book_type,
        language,
        subject: bookDetails?.book_details?.subject?.name || "",
        subject_id: bookDetails?.book_details?.subject?.id || "",
        class: bookDetails?.book_details?.sub_category?.name || "",
        class_id: bookDetails?.book_details?.sub_category?.id || "",
        topic: bookDetails?.book_details?.topic?.name || "",
        topic_id: bookDetails?.book_details?.topic?.id || "",
        is_published,
        title,
        bookSummary: description,
        book_cover_url,
        pages,
        content_type: bookDetails?.book_details?.content_type || "",
        price: bookDetails?.book_details?.price || 0,
      });
    })();
    // eslint-disable-next-line
  }, []);

  const defaultDialog = {
    modal: false,
    msg: "",
    action: async () => {},
  };

  const totalBookPage = state?.pages.length;

  const [dialogModal, setDialogModal] = useState(defaultDialog);

  const deleteBookHandler = () => {
    setDialogModal((prev) => ({
      modal: !prev.modal,
      msg: "Click continue to DELETE book",
      action: async () => {
        deleteBook(state.book_id);
        navigate("/admin/library");
      },
    }));
  };

  const publishBookHandler = (action) => {
    setDialogModal((prev) => ({
      modal: !prev.modal,
      msg: `Click continue to ${upperCase(action)} book`,
      action: async () => {
        // setDialogModal(defaultDialog);
        action === "publish" ? publishBook() : unpublishBook();
      },
    }));
  };

  const updateBookCoverImageHandler = async (event) => {
    const cover_url = await updateImage(
      event,
      state.book_id,
      "cover",
      state.book_cover_url
    );
    updateFormState({ book_cover_url: cover_url });
  };

  const saveBookHandler = async () => {
    await saveBook();
    toast.success("Book saved");
  };

  const deletePage = (pageId) => {
    const removeTemplate = () => {
      const newPages = state.pages.filter((page) => {
        deleteImageFolder(`bid_${state.book_id}/pgid_${pageId}`);
        return page.pageId !== pageId;
      });
      updateFormState({ pages: newPages });
    };
    setDialogModal((prev) => ({
      modal: !prev.modal,
      msg: "Click continue to DELETE page",
      action: async () => {
        removeTemplate();
      },
    }));
  };

  return (
    <>
      <div className={style.bookWrapper}>
        <div className={style.actions}>
          <Tooltip data="Close">
            <span onClick={() => navigate("/admin/library")}>
              <CloseIcon />
            </span>
          </Tooltip>
          <Tooltip data="Save">
            <span onClick={saveBookHandler}>
              <SaveIcon />
            </span>
          </Tooltip>
          <Tooltip data="Delete">
            <span onClick={deleteBookHandler}>
              <DeleteIcon />
            </span>
          </Tooltip>
          <Tooltip data="View">
            <span onClick={toggleReaderModal}>
              <ViewIcon />
            </span>
          </Tooltip>
          <Tooltip data="Publish">
            <span onClick={() => publishBookHandler("publish")}>
              <PublishIcon />
            </span>
          </Tooltip>

          <Tooltip data="UnPublish">
            <span onClick={() => publishBookHandler("unpublish")}>
              <UnPublishedIcon />
            </span>
          </Tooltip>
        </div>
        <hr />
        <div className={style.scrollWrapper}>
          <div className={style.fluid}>
            <div className={style.formGroup}>
              <div className={style.label}>Book Type</div>
              <Dropdown
                options={[
                  { id: 1, name: "TEXT" },
                  { id: 2, name: "VIDEO" },
                  { id: 3, name: "AUDIO" },
                ]}
                value={state.bookType.toUpperCase() || "Select"}
                onChange={(e) => updateFormState({ bookType: e.name })}
                className={style.bookType}
              />
            </div>
            <div className={style.formGroup}>
              <div className={style.label}>Language</div>
              <Dropdown
                options={[
                  { id: 1, name: "English" },
                  { id: 2, name: "Yoruba" },
                  { id: 3, name: "Igbo" },
                  { id: 3, name: "Hausa" },
                  { id: 3, name: "French" },
                ]}
                value={state.language || "Select"}
                className={style.language}
                onChange={(e) => updateFormState({ language: e.name })}
              />
            </div>
            <div className={style.formGroup}>
              <div className={style.label}>Class</div>
              <Dropdown
                options={classes}
                value={state.class || "Select"}
                className={style.class}
                onChange={(e) => {
                  updateFormState({
                    class_id: e.id,
                    class: e.name,
                    subject_id: "",
                    subject: "",
                    topic_id: "",
                    topic: "",
                  });
                  updateClassSubjectList(e.name, e.id);
                }}
              />
            </div>
            <div className={style.formGroup}>
              <div className={style.label}>Subject</div>
              <Dropdown
                options={classSubjects.subjects}
                value={state.subject || "Select"}
                className={style.subject}
                onChange={(e) => {
                  updateFormState({
                    subject_id: e.id,
                    subject: e.name,
                    topic_id: "",
                    topic: "",
                  });
                  updateTopicList(e.name);
                }}
              />
            </div>
            <div className={style.formGroup}>
              <div className={style.label}>Topic</div>
              <Dropdown
                options={subjectTopics}
                value={state.topic || "Select"}
                className={style.topic}
                onChange={(e) =>
                  updateFormState({ topic_id: e.id, topic: e.name })
                }
              />
            </div>
            <div className={style.formGroup}>
              <div className={style.label}>Book Title</div>
              <InputField
                onChange={(e) => updateFormStateDataFromInputField(e, "title")}
                className={style.title}
                value={state?.title || ""}
              />
            </div>
          </div>
          <div className={style.fluid}>
            <div className={style.formGroup}>
              <div className={style.label}>Content Type</div>
              <Dropdown
                options={[
                  { id: "library", name: "library" },
                  { id: "certificate", name: "certificate" },
                ]}
                value={state?.content_type || "Library"}
                onChange={(e) =>
                  updateFormState({
                    content_type: e.id,
                    price:
                      state?.content_type.toLowerCase() === "certificate"
                        ? state?.price
                        : 0,
                  })
                }
                className={style.bookType}
              />
            </div>
            {state?.content_type.toLowerCase() === "certificate" && (
              <div className={style.formGroup}>
                <div className={style.label}>Price</div>
                <InputField
                  onChange={(e) =>
                    updateFormStateDataFromInputField(e, "price")
                  }
                  className={style.title}
                  value={state?.price || 0}
                  type={`number`}
                />
              </div>
            )}
          </div>
          <div className={style.fluid}>
            <div className={style.formGroup}>
              <div className={style.label}>Book Summary</div>
              <TextArea
                onChange={(e) =>
                  updateFormStateDataFromInputField(e, "bookSummary")
                }
                rows={4}
                name="summary"
                className={style.textarea}
                value={state?.bookSummary || ""}
              />
            </div>
            <div className={style.formGroup}>
              <div className={style.label}>Add Page</div>
              <div className={style.pages}>
                <button className={style.btnBig} onClick={toggleModal}>
                  <i>
                    <AddIcon />
                  </i>
                </button>
              </div>
            </div>
          </div>
          {/* Selected templates */}
          <div className={style.fluid}>
            <div className={style.thumnailPreview}>
              <div className={style.image}>
                <p className={style.coverLbl}>Book Cover</p>
                <img
                  src={state.book_cover_url || defaultImage}
                  alt=""
                  className={style.img}
                />
                {/* Select image icon */}
                <div className={style.imageUpload}>
                  <label>
                    <CameraIcon />
                    <input
                      type="file"
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={updateBookCoverImageHandler}
                      name="file"
                    />
                  </label>
                </div>
              </div>
              {/* Page view*/}
              {state.pages.map((item, index) => (
                <div className={style.thumbnail} key={index}>
                  {
                    <PageAction
                      templateForm={openPageForm}
                      index={item.index}
                      pageId={item.pageId}
                      data={item}
                      position={index}
                      totalPages={totalBookPage}
                      deletePage={deletePage}
                    />
                  }
                  {TEMPLATE_LIST[item.index]?.viewComponent(item, "thumbnail")}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {/* Template page preview */}
      {modalOpen && (
        <FullpageModal toggleModal={toggleModal}>
          <div className={style.thumnailPreview}>
            {TEMPLATE_LIST.map((item, index) => (
              <div className={style.thumbnail} key={index}>
                {/* actions */}
                <ThumbnailAction
                  previewFn={previewPage}
                  index={index}
                  name={item.name}
                />
                {/* render page component. {} is used for data so that the component can use the dummy data */}
                {item.viewComponent({}, "thumbnail")}
              </div>
            ))}
          </div>
        </FullpageModal>
      )}
      {/* Page full view */}
      {previewClicked.modal && (
        <FullpageModal
          toggleModal={() =>
            setPreviewClicked((prev) => ({ ...prev, modal: !prev.modal }))
          }
          className={style.previewSize}
        >
          <div className={style.scrollWrapper}>
            {previewClicked.type === "preview" ? (
              <div className={style.fullView}>
                {/* Full page view */}
                {TEMPLATE_LIST[previewClicked.index].viewComponent(
                  state.pages[previewClicked.index],
                  "pageView"
                )}
              </div>
            ) : (
              <div className={style.fullView}>
                {/* Form view */}
                {TEMPLATE_LIST[previewClicked.index].formComponent(
                  previewClicked?.data || {},
                  pageUpdateHandler,
                  updateImage
                )}
              </div>
            )}
          </div>
        </FullpageModal>
      )}
      {/* Reader View */}
      {readerModal && (
        <FullpageModal toggleModal={toggleReaderModal}>
          <Reader pages={state.pages} bookTitle={state.title} />
        </FullpageModal>
      )}
      {/* Dialog view */}
      {dialogModal.modal && (
        <ConfirmDialog
          confirm={async () => {
            await dialogModal.action();
            setDialogModal((prev) => ({ ...prev, modal: !prev.modal }));
          }}
          cancel={() =>
            setDialogModal((prev) => ({ ...prev, modal: !prev.modal }))
          }
          message={dialogModal.msg}
        />
      )}
    </>
  );
};
export default EditBook;
