import { BsFillPatchCheckFill } from "react-icons/bs";
import classes from "./PraticeQuestionCard.module.css";
import { AiOutlineDelete } from "react-icons/ai";

function PraticeQuestionCard({
  onClick,
  title,
  coverImage,
  description,
  questionClass = "--",
  subject,
  level,
  onDelete,
  isPraticeQuestion,
}) {
  return (
    <div onClick={onClick} className={classes.cardRoot}>
      <div className={classes.courseCover}>
        {coverImage ? (
          <img className={classes.bookCoverImage} src={coverImage} alt="" />
        ) : (
          <div className={classes.imgPlaceHolder}>{title?.charAt(0)}</div>
        )}
      </div>
      <div className={classes.cardRight}>
        <div>
          <div className={classes.cardTopWraper}>
            <div style={{ flex: 1 }}>
              <h3 className={classes.courseTitle}>{title}</h3>
              <div className={classes.subjectWrapper}>
                <b>{subject}</b>
                <span>{`(${questionClass})`}</span>
              </div>
            </div>
            {!!onDelete && (
              <div>
                <button onClick={onDelete} className={classes.deleteBtn}>
                  <AiOutlineDelete />
                </button>
              </div>
            )}
          </div>
          {!!description && (
            <div className={classes.courseDescription}>{description}</div>
          )}
        </div>
        <div className={classes.badgesWrapper}>
          {!!level && (
            <button className={classes.praticeQuestion}>
              <BsFillPatchCheckFill />
              <span>{level}</span>
            </button>
          )}
          {!!isPraticeQuestion && (
            <button className={classes.praticeQuestion}>
              <BsFillPatchCheckFill />
              <span>Pratice Question</span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default PraticeQuestionCard;
