import { useEffect, useState } from "react";
import { CloseIcon } from "../../../assets/icons";
import BookCard2 from "../../../components/BookCard2/BookCard2";
import { TERMS } from "../../../utils/constants";
import style from "./CurriculumnTopicDetails.module.css";
import { Loader } from "../../../components/Modal";
import useCurriculum from "../../Admin/Curriculum/hooks/useCurriculum";
import { useNavigate } from "react-router-dom";
import Dropdown from "../../../components/Dropdown";

const CurriculumnTopicDetails = ({ onClose, curriculum, termId }) => {
  const [topics, setTopics] = useState([]);
  const { curriculumTopic, curriculumSemesterTopics, isLoading } =
    useCurriculum();
  const [currentTopic, setCurrentTopic] = useState(null);
  const navigate = useNavigate();

  const getTopicDetail = async (topic_id) => {
    const detailParam = {
      term: termId,
      curriculum_id: curriculum.id,
      topic_id,
    };
    const topicData = await curriculumTopic(detailParam);

    setCurrentTopic(topicData);
  };

  useEffect(() => {
    const fetch = async () => {
      const params = { term: termId, curriculum_id: curriculum.id };
      const res = await curriculumSemesterTopics(params);

      if (res?.length) {
        await getTopicDetail(res[0]?.id);
      }
      setTopics(res);
    };
    fetch();

    // eslint-disable-next-line
  }, []);

  const getInitials = (name) => {
    // Split the name into words
    const words = name.split(" ");
    // Extract the first letter of each word and concatenate
    const initials = words.map((word) => word.charAt(0)).join("");

    return initials;
  };

  return (
    <div className={style.rootPage}>
      <div className={style.header}>
        <div>
          <h4 className={style.title}>{TERMS[termId].toUpperCase()} TERM</h4>
          <p className={style.mtN10}>{curriculum?.subject?.name}</p>
        </div>
        <div className={style.close}>
          <CloseIcon
            size={35}
            onClick={() => {
              setCurrentTopic(null);
              onClose();
            }}
          />
        </div>
      </div>
      <div className={style.body}>
        <div className={style.menu}>
          <p className={style.title}>Topics ({topics?.length})</p>
          <ol>
            {(topics || []).map((topic) => (
              <li key={topic?.id} onClick={() => getTopicDetail(topic?.id)}>
                {topic?.name}
              </li>
            ))}
          </ol>
        </div>
        <div className={style.content}>
          <div className={`${style.topicDropdown}`}>
            <Dropdown
              options={topics}
              value={currentTopic?.topicDetails?.topic?.name || ""}
              onChange={(e) => {
                getTopicDetail(e?.id);
              }}
              // className={`${style.topicDropdown}`}
            />
          </div>
          {currentTopic ? (
            <>
              <div className={style.contentHeader}>
                <h4 className={style.title}>
                  {currentTopic?.topicDetails?.topic?.name}
                </h4>
                <div>Week {currentTopic?.topicDetails?.week}</div>
              </div>
              <div className={style.row}>
                <div className={style.initials}>
                  {getInitials(currentTopic?.topicDetails?.author)}
                </div>
                <div>
                  <p>{currentTopic?.topicDetails?.author}</p>
                  <p className={style.mtN20}>
                    {currentTopic?.topicDetails?.author_position}
                  </p>
                </div>
              </div>
              <div>
                <h5 className={style.title}>Course Description</h5>
                <pre>{currentTopic?.topicDetails?.course_description}</pre>
              </div>
              <div>
                <h5 className={style.title}>Learning Objectives</h5>
                <pre className={style.learningObj}>
                  {currentTopic?.topicDetails?.learning_objectives}
                </pre>
              </div>
              {currentTopic?.resources?.length > 0 && (
                <div>
                  <h5 className={style.title}>Resources</h5>
                  <div className={style.row}>
                    {currentTopic?.resources.map((item) => (
                      <BookCard2
                        img={item?.book_cover_url}
                        book_type={item?.book_type}
                        title={item?.title}
                        rating={item?.rating}
                        handleClick={() =>
                          navigate(`/library/course/${item?.id}`)
                        }
                      />
                    ))}
                  </div>
                </div>
              )}
            </>
          ) : (
            <p>No content available</p>
          )}
        </div>
      </div>
      {isLoading && <Loader />}
    </div>
  );
};
export default CurriculumnTopicDetails;
