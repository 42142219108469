import { useEffect, useState } from "react";
import notificationService from "../../../service/notification.service";
import useCdReactQuery from "../../../utils/hooks/useCdReactQuery";

function useNotificationPage() {
  const { queryEndpoint, isLoading, error } = useCdReactQuery();
  const [data, setData] = useState([])

  useEffect(() => {
    (async() =>{
      await inboxMessages()
    })()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getNotification = async(id) => {

    const res = await queryEndpoint({
      endpointUrl: `${notificationService.notificationByID}`,
      params : { notification_id : id }
    });

    return res
  }

  const sentMessages = async () => {
    const res = await queryEndpoint({
      endpointUrl: notificationService.sentMessages,
    });
    setData(res?.data)
  }

  const inboxMessages = async () => {
    const res = await queryEndpoint({
      endpointUrl: notificationService.fetchNotifications,
    });
    setData(res?.data)
  }

  const markAsReadUnRead = async (ids, read=true) => {
    const res = await queryEndpoint({
      endpointUrl: read ? notificationService.markAsRead : notificationService.markAsUnRead,
      httpMethod : "PUT",
      body : { notification_id : ids }
    });
    return res
  }

  const deleteNotifications = async(ids=[]) => {
    const res = await queryEndpoint({
      endpointUrl: notificationService.deleteNotifications,
      httpMethod : "DELETE",
      body : { notification_id : ids }
    });
    return res
  }

  const sendMessage = async (message) => {
    const  res = await queryEndpoint({
      endpointUrl: notificationService.sendMessage,
      httpMethod : "POST",
      body : message
    })
    return res
  }

  return {
    deleteNotifications,
    notifications : data,
    getNotification,
    inboxMessages,
    markAsReadUnRead,
    sentMessages,
    sendMessage,
    isLoading,
    error,
  };
}

export default useNotificationPage;
