import React from 'react'
import style from "./EditBio.module.css";
import Button from '../../components/Button/Button';
import useEditBio from './hooks/useEditBio';
import InputField from '../../components/InputField';
import Dropdown from '../../components/Dropdown'

const EditBio = ({onSuccess}) => {

  const {
    state,
    isLoading,
    dispatch,
    onSubmit,
  } = useEditBio(onSuccess)

    const {
    firstName,
    lastName,
    phoneNumber,
    gender
  } = state

const userGender = [
  {name: 'Male', id: 'Male'},
  {name: 'Female', id: 'Female'}
]

  return (
    <div className={style.editUserWrapper}>
      <div className={style.header}>
        <h3 className={style.editBio}>Edit Bio</h3>
        <hr />
        <p className={style.indicate}>Indicate required*</p>
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <div className={style.inputWrapper}>
          <InputField
            type="text"
            value={firstName}
            placeholder="First Name"
            className={style.inputField}
            onChange={({ target }) =>
              dispatch({firstName: target.value })
            }
            required
          />
        </div>
        <div className={style.inputWrapper}>
          <InputField
            type="text"
            value={lastName}
            placeholder="Last Name"
            className={style.inputField}
            onChange={({ target }) =>
              dispatch({lastName: target.value})
            }
            required
          />
        </div>

        <div className={style.inputWrapper}>
          <InputField
            type="text"
            value={phoneNumber}
            placeholder="Phone Number"
            className={style.inputField}
            onChange={({ target }) =>
              dispatch({phoneNumber: target.value})
            }

          />
        </div>
        <div className={style.inputWrapper} style={{marginBottom: 25}}>
            <Dropdown
            value={gender}
             options={userGender}
            onChange={( event ) => {
              dispatch({ gender: event?.name })
            }}
               />
        </div>

        <div >
          <Button className={style.save}
            type="submit"
            isLoading={isLoading}
            onClick={onSubmit}
          >Save</Button>
        </div>
      </form>

    </div>
  )
}

export default EditBio
