import SkeletonLoader from "../../../components/SkeletonLoader";
import TopCard from "../../../components/TopCard";
import classes from "./TutorUploadedCourses.module.css";
import useManageCourses from "./hooks/useManageCourses";
import { useNavigate } from "react-router-dom";

function TutorUploadedCourses() {
  const { isFetching, bookToDelete, courseUploaded, deletUploadedBook } =
    useManageCourses();
  const navigate = useNavigate();

  return (
    <div className={classes.pageBody}>
      <div className={classes.mainBody}>
        <div>
          {isFetching ? (
            <div>
              {[...new Array(4).values()].map((_, index) => (
                <SkeletonLoader className={classes.skeletonLogLoader} />
              ))}
            </div>
          ) : (
            <div>
              {!courseUploaded.length ? (
                <div className={classes.emptyHistory}>
                  <h3>No Course Uploaded.</h3>
                </div>
              ) : (
                courseUploaded.map((book) => (
                  <TopCard
                    onClick={() =>
                      navigate(`/library/manage-course/edit/${book?._id?.$oid}`)
                    }
                    coverImage={book?.book_cover_url}
                    bookTitle={book?.topic}
                    subcategory={book?.sub_category_name}
                    status={book?.template_name ? 100 : 50}
                    isDeleting={bookToDelete[book?._id?.$oid]}
                    onDelete={() => {
                      deletUploadedBook(book);
                    }}
                  />
                ))
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default TutorUploadedCourses;
