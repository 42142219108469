import { HiSpeakerWave } from "react-icons/hi2";
import { BsFillCaretRightSquareFill } from "react-icons/bs";
import { BiSolidBook } from "react-icons/bi";

export const getBooktype = (type) => {
  const booktype = {
    audio: "Play",
    video: "Watch",
    text: "Read",
  };
  return type ? booktype[type.toLowerCase()] : "";
};

export const getBooktypeIcon = (type) => {
  const icons = {
    audio: <HiSpeakerWave style={{ width: 20, height: 20 }} />,
    video: <BsFillCaretRightSquareFill style={{ width: 20, height: 20 }} />,
    text: <BiSolidBook style={{ width: 20, height: 20 }} />,
  };
  return type ? icons[type.toLowerCase()] : "";
};
