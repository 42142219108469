import notificationService from "../../../service/notification.service";
// import { useSelector } from "react-redux";
// import notifcationData from "../data.json"

import { useEffect, useState } from "react";
import useCdReactQuery from "../../../utils/hooks/useCdReactQuery";

function useNotification() {
  // const { user } = useSelector(({ currentUser }) => currentUser);
  const { queryEndpoint, isLoading, error } = useCdReactQuery();
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    (async () => {
      const res = await queryEndpoint({
        endpointUrl: `${notificationService.fetchNotifications}`,
      });
      setNotifications(res.data);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getNotifications = async (id) => {
    setNotifications(
      notifications.map((item) => {
        if (id === item._id) {
          return { ...item, is_read: true };
        }
        return item;
      })
    );
  };

  return {
    notifications,
    getNotifications,
    // fetchingNotification,
    isLoading,
    error,
    unReadNotifications: notifications.reduce(
      (acc, notification) => acc + !notification?.is_read,
      0
    ),
  };
}

export default useNotification;
