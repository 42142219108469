import React, { useEffect, useRef, useState } from "react";
import style from "./ProfileCard.module.css";
import { useLocation } from "react-router-dom";
import { accountNavPths } from "./constants";
import { NavLink } from "react-router-dom";
import UserTag from "../UserTag";
import useLogout from "../../utils/hooks/useLogout";
import useProfilePage from "../../pages/ProfilePage/hooks/useProfilePage";
import { useSelector } from "react-redux";

const ProfileCard = ({ onClick }) => {
  const { displayProfileColor } = useProfilePage();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const { user } = useSelector(({ currentUser }) => currentUser);
  const ref = useRef();
  const logout = useLogout();

  useEffect(() => {
    /**
     * close when clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
  return (
    <div ref={ref} style={{ position: "relative" }}>
      <div className={style.userInfoWrapper} onClick={() => setIsOpen(!isOpen)}>
        <UserTag
          intials={`${user?.firstName?.charAt(0) || ""}${
            user?.lastName?.charAt(0) || ""
          }`}
          bgColor={displayProfileColor()}
          className={style.userImg}
        />
        <div className={style.userWelcome}>
          <span>Hi,</span> <b>{user?.firstName}</b>
        </div>
      </div>
      <div className={style.dropDownWrapper}>
        <div
          className={`${style.profileCard} ${
            isOpen ? style.onOpen : undefined
          }`}
        >
          <div className={style.profileContainer}>
            <div className={style.name}>
              {user?.firstName} <span>{user?.lastName?.charAt(0)}.</span>
            </div>
            <div className={`${style.studentId} ${style.email}`}>
              {user?.email || user?.phoneNumber}
            </div>
          </div>

          {accountNavPths.map((navItem, i) => (
            <div className={style.profileContent} key={i}>
              <hr />
              {navItem.map(({ path, name, icon, onclick, regex }, index) =>
                regex.test("logout") ? (
                  <button
                    key={index}
                    onClick={() => {
                      setIsOpen(false);
                      logout();
                    }}
                    className={style.logoutContainer}
                  >
                    <span>{name}</span>
                  </button>
                ) : (
                  <NavLink
                    key={index}
                    to={path}
                    onClick={() => setIsOpen(false)}
                    className={`${style.navPath} ${
                      regex?.test(location.pathname)
                        ? style.activeLink
                        : undefined
                    }`}
                  >
                    <span>{name}</span>
                  </NavLink>
                )
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;
