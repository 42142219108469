import { useEffect, useReducer } from "react";
import libraryService from "../../../../service/library.service";
import { QUERY_KEY } from "../../../../utils/constants";
import useAppQuery from "../../../../utils/hooks/useAppQuery";

function useCourseIntendedLearners() {
  const initialState = {
    selectedCategory: {},
  };

  const [state, dispatch] = useReducer(
    (stateVal, value) => ({ ...stateVal, ...value }),
    initialState
  );

  const {
    data: { data: categories = [] } = {},
    isLoading: fetchingCategories,
  } = useAppQuery(QUERY_KEY.getCategories, libraryService.getCategories, {});

  useEffect(() => {
    dispatch({ selectedCategory: categories[0] });
  }, [categories]);

  return { state, categories, fetchingCategories, dispatch };
}

export default useCourseIntendedLearners;
