import { useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { setCurrentUser } from "../../../redux/feature/authSlice";
import userProfile from "../../../service/userProfile.service";
import { QUERY_KEY } from "../../../utils/constants";
import useAppMutation from "../../../utils/hooks/useAppMutation";

function useEditBio(onSuccess) {
  const { user } = useSelector(({ currentUser }) => currentUser);
  const reduxDispatch = useDispatch();

  const initialState = {
    firstName: user.firstName,
    lastName: user.lastName,
    phoneNumber: user.phoneNumber,
    gender: user?.gender,
    email: user.email,
    city: user?.city,
    country: user?.country,
    country_code: user?.country_code,
    state: user?.state,
  };
  const [state, dispatch] = useReducer(
    (stateValue, value) => ({ ...stateValue, ...value }),
    initialState
  );
  const { firstName, lastName, phoneNumber, gender } = state;
  const { isLoading, mutateAsync: asyncEditbio } = useAppMutation(
    QUERY_KEY.editProfile,
    userProfile.editProfile
  );
  function onSubmit() {
    if (!firstName || !lastName) {
      toast.error("first name or last name is required");
      return;
    }
    asyncEditbio({
      firstName,
      lastName,
      phoneNumber,
      gender,
      email: user.email,
      city: user?.city,
      country: user?.country,
      country_code: user?.country_code,
      state: user?.state,
    })
      .then(({ data: userData }) => {
        toast.success("updated successfully");
        reduxDispatch(setCurrentUser(userData));
        onSuccess?.();
      })
      .catch(({ msg }) => {
        toast.error(msg);
      })
      .finally(() => {});
  }
  return {
    state,
    isLoading,
    onSubmit,
    dispatch,
  };
}

export default useEditBio;
