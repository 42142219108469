import { useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import useCdReactQuery from "../../../../utils/hooks/useCdReactQuery";
import adminService from "../../../../service/admin.service";
import Loader from "../../../../components/Modal/Loader";
import {
  BackIcon,
  DeleteIcon,
  DurationIcon,
  PublishIcon,
  SaveIcon,
  SearchIcon,
  UnPublishedIcon,
  ViewIcon,
} from "../../../../assets/icons";
import Tooltip from "../../../../components/Tooltip";
import style from "./EditBucket.module.css";
import Dropdown from "../../../../components/Dropdown";
import InputField from "../../../../components/InputField";
import { cdDecryption, initCaps } from "../../../../utils/helpers";
import { useNavigate, useParams } from "react-router-dom";
import { Controller } from "../../../ExamPrep/ExamPrepBuckets/Controller";
import ExamPrepStorage from "../../../ExamPrep/storage/ExamPrepStorage";
import ConfirmDialog from "../../../../components/ConfirmationDialogCD/ConfirmDialog";
import { FullpageModal } from "../../../../components/Modal";
import { toast } from "react-toastify";
// import { defaultImage } from "../../../../assets/image";

const BucketQuestionCard = ({
  question,
  selected,
  viewQuestion,
  handleSelect,
  handleRemove,
}) => {
  const decryptedQuestions = cdDecryption(question.questions);
  return (
    <div className={style.questionCard}>
      <div className={style.questions_card_header}>
        <p>
          #{question?._id} | {initCaps(question?.level)}
        </p>
        <div className={style.card_actions}>
          {selected ? (
            <Tooltip data="Delete">
              <i
                onClick={() => {
                  handleRemove(question._id);
                }}
              >
                <DeleteIcon className={style.card_icon} />
              </i>
            </Tooltip>
          ) : (
            <Tooltip data="select question">
              <input
                type="checkbox"
                className={style.checkbox}
                onClick={(e) => handleSelect(e, question)}
              />
            </Tooltip>
          )}
          <Tooltip data="View">
            <i onClick={() => viewQuestion(question)}>
              <ViewIcon className={style.card_icon} />
            </i>
          </Tooltip>
        </div>
      </div>
      <h5 className={style.topic}>{question?.topic?.name}</h5>

      <div className={style.question}>
        {decryptedQuestions?.paragraph.trim() !== ""
          ? decryptedQuestions?.paragraph
          : decryptedQuestions?.questionGroup?.[0]?.question}
      </div>
    </div>
  );
};

const QuestionCard = ({ data }) => {
  const { level, questions, subcategory, topic } = data;
  const decryptedQuestions = cdDecryption(questions);
  return (
    <div className={style.question_card_container}>
      <h1 className={style.question_header}>{topic?.name}</h1>
      <div className={`${style.row} ${style.question_desc}`}>
        <span className={style.badge}>{subcategory?.name}</span>
        <div className={style.no}>
          <DurationIcon /> {initCaps(level)}
        </div>
      </div>
      <div className={style.question_section}>
        {decryptedQuestions.paragraph && <p>{decryptedQuestions.paragraph}</p>}
        <h4>Questions</h4>
        {(decryptedQuestions.questionGroup || []).map((item, index) => (
          <div key={index} className={style.card}>
            <span className={style.questionText}>{item.question}</span>
            {item.options.map((option, key) => (
              <div key={key} className={style.card}>
                <span
                  className={`${style.label} ${
                    option.isChecked ? style.check : ""
                  }`}
                >
                  {option.label}
                </span>

                {option.inputValue}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

const EditBucket = () => {
  const [formState, updateFormState] = useState({});
  const [dialogModal, setDialogModal] = useState({ modal: false, message: "" });
  const { userClass, subject } = useSelector((store) => store.questionBank);
  const [loader, setLoader] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [filteredQuestion, setFilteredQuestion] = useState([]);
  const { queryEndpoint, error, isLoading } = useCdReactQuery();
  const { updateStorage, assessmentModalToggle } = useContext(ExamPrepStorage);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [viewQuestionData, setViewQuestionData] = useState(null);
  const searchRef = useRef(null);
  const { bucketId } = useParams();
  const navigate = useNavigate();

  const idExist = (id) => {
    return bucketId !== "0";
  };

  const validID = idExist(bucketId);

  const fetch = async (url, params) => {
    setLoader(true);
    const response = await queryEndpoint({
      endpointUrl: url,
      params: params,
    });
    // setFilteredQuestion(questions)
    setLoader(false);
    return response;
  };

  const previewBucket = () => {
    updateStorage({
      ...formState,
      questions: selectedQuestions,
      assessmentModalToggle: !assessmentModalToggle,
      modalPage: "AssessmentDetails",
      preview_data: selectedQuestions,
      subject: subject,
      isPreview: true,
    });
  };

  useEffect(() => {
    (async () => {
      const params = {
        subcategory_id: userClass.id,
        subject_id: subject.id,
        limit: 100,
        level: "easy",
      };
      const questions = await fetch(adminService.getFilteredQuestion, params);
      setFilteredQuestion(questions);
    })();

    updateFormState({
      title: "",
      name: "",
      difficulty_level: "Easy",
      duration: "10",
      class_id: userClass.id,
      subject_id: subject.id,
      is_published: false,
    });
    // eslint-disable-next-line
  }, []);

  // Call your notification here
  useEffect(() => {
    if (error) {
      const error_data = error.response.data;
      toast.error(`
        ${error_data.error || error_data.msg || error_data.detail}
      `);
      setLoader(false);
    }
  }, [error]);

  // Fetch question info for editing everytime the url params changes
  useEffect(() => {
    if (validID) {
      const fetch = async () => {
        const response = await queryEndpoint({
          endpointUrl: adminService.getBucketByID,
          params: {
            bucket_id: bucketId,
          },
        });

        const bucket = response?.bucket_data;
        updateFormState({
          ...formState,
          title: bucket.title || "",
          name: bucket.title || "",
          difficulty_level: initCaps(bucket.difficulty_level) || "Easy",
          duration: bucket.duration || 10,
          is_published: bucket.is_published || false,
        });

        filterByQuestionLevel(bucket.difficulty_level, true);
        setSelectedQuestions(response.questions);
      };
      fetch();
    }
    // eslint-disable-next-line
  }, []);

  const selectQuestion = (e, question) => {
    const { checked } = e.target;
    if (checked) {
      setSelectedQuestions([...selectedQuestions, question]);
    } else {
      removeQuestion(question._id);
    }
  };

  const removeQuestion = (question_id) => {
    const questions = selectedQuestions.filter(
      (question) => question._id !== question_id
    );
    setSelectedQuestions(questions);
  };

  const viewQuestion = (question) => {
    setViewQuestionData(question);
    setModalOpen(!modalOpen);
  };

  const saveBucket = async () => {
    const payload = {
      ...formState,
      questions_ids: selectedQuestions.map((questions) => questions._id),
      duration: parseInt(formState.duration),
      difficulty_level: formState.difficulty_level.toLocaleLowerCase(),
    };
    if (!payload.title) {
      toast.error("Bucket must have a title");
      return;
    }
    if (!payload.questions_ids.length) {
      toast.error("Add questions to bucket. Bucket cannot be empty");
      return;
    } else {
      if (validID) {
        payload["bucket_id"] = bucketId;
      }

      const requestData = {
        endpointUrl: validID
          ? adminService.updateBucket
          : adminService.createBucket,
        httpMethod: validID ? "PUT" : "POST",
        body: payload,
      };

      const res = await queryEndpoint(requestData);

      if (res.msg) {
        toast.success(res.msg);
        navigate("/admin/examprep");
      }
    }
  };

  const publishBucket = async () => {
    const payload = {
      bucket_id: bucketId,
      is_published: !formState.is_published,
    };
    if (!formState.title) {
      toast.error("Bucket must have a title");
      return;
    }
    if (!selectedQuestions.length) {
      toast.error("Add questions to bucket. Bucket cannot be empty");
      return;
    } else {
      if (validID) {
        const res = await queryEndpoint({
          endpointUrl: adminService.updateBucket,
          httpMethod: "PUT",
          body: payload,
        });

        if (res.msg) {
          toast.success(res.msg);
          navigate("/admin/examprep");
        }
      } else {
        toast.error("You cannot publish a bucket without first saving it");
      }
    }
  };

  const filterByQuestionLevel = async (difficulty_level, edit = false) => {
    const params = {
      subcategory_id: userClass.id,
      subject_id: subject.id,
      limit: 100,
      level: difficulty_level.toLocaleLowerCase(),
    };
    const questions = await fetch(adminService.getFilteredQuestion, params);
    setFilteredQuestion(questions || []);
    if (!edit) {
      setSelectedQuestions([]);
    }
    setLoader(false);
  };

  const isNumber = (value) => {
    return !isNaN(value);
    // return typeof value === 'number' && isFinite(value) && !isNaN(value);
  };

  const searchQuestion = async () => {
    const { value } = searchRef.current;
    const params = {
      subcategory_id: userClass.id,
      subject_id: subject.id,
      limit: 100,
      level: formState.difficulty_level.toLowerCase(),
    };
    let questions = null;
    if (value) {
      if (isNumber(value)) {
        questions = await fetch(adminService.getQuestionById(value));
        if (
          questions?.question?.level ===
          formState.difficulty_level.toLowerCase()
        ) {
          setFilteredQuestion({ filtered_questions: [questions.question] });
        } else {
          setFilteredQuestion({ filtered_questions: [] });
        }
      } else {
        const urlParams = { ...params, topic_name: value };
        questions = await fetch(adminService.getFilteredQuestion, urlParams);
        setFilteredQuestion(questions);
      }
    } else {
      const questions = await fetch(adminService.getFilteredQuestion, params);
      setFilteredQuestion(questions);
    }
  };

  const deleteBucket = async () => {
    const res = await queryEndpoint({
      endpointUrl: adminService.getBucketByID,
      httpMethod: "DELETE",
      params: {
        bucket_id: bucketId,
      },
    });

    if (res.msg) {
      toast.success(res.msg);
      navigate("/admin/examprep");
    }
  };

  return (
    <div className={style.root}>
      <div className={style.root_header}>
        <div className={style.root_header_title}>
          <Tooltip data="Back" callbackFn={() => navigate("/admin/examprep")}>
            <BackIcon />
          </Tooltip>
          <h5>{validID ? "Edit" : "Add"} Bucket</h5>
        </div>
        <div className={style.actions}>
          <Tooltip data="Save" callbackFn={saveBucket}>
            <SaveIcon />
          </Tooltip>
          <Tooltip data="Preview" callbackFn={previewBucket}>
            <ViewIcon />
          </Tooltip>
          {validID && (
            <>
              <Tooltip
                data={formState.is_published ? "Unpublish" : "Publish"}
                callbackFn={publishBucket}
              >
                {formState.is_published ? <UnPublishedIcon /> : <PublishIcon />}
              </Tooltip>
              <Tooltip
                data="Delete"
                callbackFn={() => {
                  setDialogModal({
                    ...dialogModal,
                    questionId: null,
                    modal: !dialogModal.modal,
                    message: "Are you sure you want to delete bucket?",
                  });
                }}
              >
                <DeleteIcon />
              </Tooltip>
            </>
          )}
        </div>
      </div>
      <hr />
      <div className={`${style.row} ${style.page_layout}`}>
        <div className={style.formContainer}>
          <div className={style.form}>
            <div className={style.fluid}>
              <div className={style.formGroup}>
                <div className={style.label}>Title</div>
                <InputField
                  placeholder={"Bucket title"}
                  value={formState.title}
                  onKeyUp={(e) =>
                    updateFormState({
                      ...formState,
                      title: e.target.value,
                      name: e.target.value,
                    })
                  }
                  // onChange={(e) =>
                  //   updateFormState({
                  //     ...formState,
                  //     title: e.target.value,
                  //     name: e.target.value,
                  //   })
                  // }
                />
              </div>
            </div>
            <div className={style.fluid}>
              <div className={style.formGroup}>
                <div className={style.label}>Difficulty Level</div>
                <Dropdown
                  options={[
                    { id: 1, name: "Easy" },
                    { id: 2, name: "Medium" },
                    { id: 3, name: "Hard" },
                  ]}
                  value={formState.difficulty_level}
                  onChange={(e) => {
                    filterByQuestionLevel(e.name);
                    updateFormState({
                      ...formState,
                      difficulty_level: e.name,
                    });
                  }}
                  className={style.difficulty}
                />
              </div>
              <div className={style.formGroup}>
                <div className={style.label}>Duration (Minutes)</div>
                <Dropdown
                  options={[
                    { id: 1, name: 10 },
                    { id: 2, name: 20 },
                    { id: 3, name: 30 },
                    { id: 3, name: 120 },
                  ]}
                  value={formState.duration}
                  onChange={(e) =>
                    updateFormState({ ...formState, duration: e.name })
                  }
                  className={style.difficulty}
                />
              </div>
            </div>
          </div>
          <div className={style.bookWrapper}>
            <div className={style.scrollWrapper}>
              {(selectedQuestions || []).map((item, index) => (
                <BucketQuestionCard
                  question={item}
                  key={index}
                  selected={true}
                  viewQuestion={viewQuestion}
                  handleSelect={selectQuestion}
                  handleRemove={(id) => {
                    setDialogModal({
                      ...dialogModal,
                      questionId: id,
                      modal: !dialogModal.modal,
                      message: "Are you sure you want to remove question?",
                    });
                  }}
                />
              ))}
              {!selectedQuestions.length &&
                "No question added! Add questions to bucket by selecting from the question panel"}
            </div>
          </div>
        </div>
        <div className={style.questions_section}>
          <div className={style.search_container}>
            <input
              placeholder={"Search by [ ID | Topic | Difficulty ]"}
              ref={searchRef}
              className={style.search_input}
              onKeyUp={(e) => {
                if (e.key === "Enter") searchQuestion(e.target.value);
              }}
            />
            <button onClick={searchQuestion}>
              <SearchIcon />
            </button>
          </div>
          <div className={style.question_list_container}>
            {(filteredQuestion?.filtered_questions || []).map((item, index) => (
              <BucketQuestionCard
                question={item}
                key={index}
                selected={false}
                viewQuestion={viewQuestion}
                handleSelect={selectQuestion}
                handleRemove={(id) => {
                  setDialogModal({
                    ...dialogModal,
                    questionId: id,
                    modal: !dialogModal.modal,
                    message: "Are you sure you want to remove question?",
                  });
                }}
              />
            ))}
          </div>
        </div>
      </div>
      {assessmentModalToggle && <Controller />}
      {(loader || isLoading) && <Loader />}
      {modalOpen && (
        <FullpageModal toggleModal={() => setModalOpen(!modalOpen)}>
          <QuestionCard data={viewQuestionData} />
        </FullpageModal>
      )}
      {dialogModal.modal && (
        <ConfirmDialog
          confirm={() => {
            if (dialogModal.questionId) {
              removeQuestion(dialogModal.questionId);
            } else {
              deleteBucket();
            }
            setDialogModal((prev) => ({ questionId: "", modal: false }));
          }}
          cancel={() =>
            setDialogModal((prev) => ({ ...prev, modal: !prev.modal }))
          }
          message={dialogModal.message}
        />
      )}
    </div>
  );
};

export default EditBucket;
