// Component
import BookList from "./table/BookList";

// React icons
import { GridAddIcon, SearchIcon } from "../../../assets/icons";

// style
import style from "./Library.module.css";
import useBookForm from "./hooks/useBookForm";

const Library = () => {
  const { createBookHandler, books } = useBookForm();

  return (
    <div className={style.root}>
      <div className={style.header}>
        <span>Contents Created : {books ? books?.length : 0}</span>
        <div className={style.right}>
          <i className={style.searchIcon}>
            <SearchIcon />
          </i>
          <i onClick={createBookHandler}>
            <GridAddIcon />
          </i>
        </div>
      </div>

      <BookList />
    </div>
  );
};
export default Library;
