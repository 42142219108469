import { defaultImage } from "../../assets/image";

export const DUMMY_DATA = {
  // book_id
  // index:0,
  // name
  // pageId:1, the index, name and pageId is managed from the code that renders the template
  title: "Question",
  questions: [
    {
      question: {
        text: "Question page layout",
        imageUrl: defaultImage,
        videoUrl: "",
      },
      options: [
        { label: "A", value: "2" },
        { label: "B", value: "8" },
        { label: "C", value: "24" },
        { label: "D", value: "12" },
        { label: "E", value: "8" },
        { label: "F", value: "20" },
      ],
      optionType: "single",
      answer: "C",
    },
  ],
  page_type: "question",
  pass_mark: 50, // in percentage
};
