import React, { useEffect, useRef, useState } from "react";
import style from "./QuickReview.module.css";
import { useContext } from "react";
import ApplicationFooter from "../../../components/ApplicationFooter/ApplicationFooter";
import { BsFillCaretRightFill } from "react-icons/bs";
import ExamPrepStorage from "../storage/ExamPrepStorage";
import { Controller } from "./Controller";
import { RowLayout } from "../../Dashboard/layout/PageLayout";
import adminService from "../../../service/admin.service";
import useCdReactQuery from "../../../utils/hooks/useCdReactQuery";
import { Loader } from "../../../components/Modal";
import SkeletonLoader from "../../../components/SkeletonLoader";
import { SearchUI } from "../../../components/searchUI";

export const ReviewCard = ({ data }) => {
  const { updateStorage, assessmentModalToggle } = useContext(ExamPrepStorage);
  const { subject, topic, question_counts } = data;
  const { queryEndpoint, isLoading } = useCdReactQuery();

  async function reviewCourse() {
    try {
      const topicReview = await queryEndpoint({
        endpointUrl: adminService.getReviewQuestion,
        params: { review_id: data?._id },
      });

      if (topicReview?.questions) {
        updateStorage({
          questions: topicReview?.questions,
          assessmentModalToggle: !assessmentModalToggle,
          modalPage: "Review",
        });
      }
    } catch (error) {}
  }

  return (
    <>
      <div className={style.card}>
        <div className={style.cardTop}>Q&A {`[${question_counts || 0}]`}</div>
        <div className={style.cardContent}>
          <p>{subject?.name}</p>
          <h4>{topic?.name}</h4>
          {question_counts > 0 && (
            <button onClick={reviewCourse}>
              START REVIEW <BsFillCaretRightFill />
            </button>
          )}
        </div>
      </div>
      {isLoading && <Loader />}
    </>
  );
};

function QuickReview() {
  const { assessmentModalToggle } = useContext(ExamPrepStorage);
  const [selectedSubject, setSelectedSubject] = useState("ALL SUBJECT");
  const { data = {}, isLoading, queryEndpoint } = useCdReactQuery();
  const { data: subjectResponse = {}, queryEndpoint: fetchSubjects } =
    useCdReactQuery();

  const searchRef = useRef("");

  const subjects = subjectResponse?.data || [];

  const { course_reviews = [] } = data || {};

  const fetch = async ({ subjectName = "", topicName = "" }) => {
    await queryEndpoint({
      endpointUrl: adminService.courseReview,
      params: {
        limit: 1000,
        subject_name: subjectName !== "ALL SUBJECT" ? subjectName : "",
        topic_name: topicName,
      },
    });
  };

  useEffect(() => {
    fetch({ subjectId: selectedSubject?.name });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchSubjects({
      endpointUrl: adminService.getSubjects,
      httpMethod: "GET",
    });
  }, []); // eslint-disable-line

  const dropDownValue = (selectValue) => {
    setSelectedSubject(selectValue);
    fetch({ subjectName: selectValue, topicName: searchRef?.current?.value });
  };

  const searchHandler = async (searchItem) => {
    fetch({ topicName: searchItem });
  };

  return (
    <>
      <div className={style.root}>
        <div className={style.page}>
          <SearchUI
            navText="Review"
            navLink="/examprep"
            filterBySubject={dropDownValue}
            searchHandler={searchHandler}
            subjects={[...subjects.map((item) => item.name)]}
            MaxSubjectView={2}
            placeholder="Search Review"
            hasClearnBtn={false}
          />
          {isLoading ? (
            <div className={style.loaderRow}>
              {[...new Array(4).values()].map((_, index) => (
                <SkeletonLoader
                  className={style.skeletonLogLoader}
                  key={index}
                />
              ))}
            </div>
          ) : (
            <div className={style.bucket_container}>
              {course_reviews.map((data, index) => (
                <ReviewCard data={data} key={index} />
              ))}
            </div>
          )}
        </div>
        <RowLayout>
          <ApplicationFooter />
        </RowLayout>
      </div>
      {assessmentModalToggle && <Controller />}
    </>
  );
}

export default QuickReview;
