import { useNavigate, useParams } from "react-router-dom";
import libraryService from "../../../../service/library.service";
import { QUERY_KEY } from "../../../../utils/constants";
import useAppQuery from "../../../../utils/hooks/useAppQuery";
import { useReducer } from "react";
import useAppMutation from "../../../../utils/hooks/useAppMutation";
import coursesService from "../../../../service/courses.service";

function useManageCourse() {
  const { subcategoryId } = useParams();
  const navigate = useNavigate();

  const initialState = {
    title: "",
    selectedSubject: {},
    topicRow: [{ topic: null, description: "" }],
    selectedTopic: {},
    bookRow: [],
  };

  const [state, dispatch] = useReducer(
    (stateVal, value) => ({ ...stateVal, ...value }),
    initialState
  );

  const { selectedSubject, title, topicRow, bookRow } = state;

  const {
    data: { subcategory: { subject = [] } = {} } = {},
    isLoading: fetchSubject,
  } = useAppQuery(
    [QUERY_KEY.getAllSubjects],
    libraryService.getAllSubjectsBySubId,
    { subcategoryId: subcategoryId }
  );

  const { data: { data: topics = [] } = {} } = useAppQuery(
    [QUERY_KEY.getTopicBySubjectId],
    libraryService.getTopicBySubjectId,
    { subjectId: selectedSubject?._id?.$oid },
    { enabled: !!selectedSubject?._id?.$oid }
  );
  console.log(topicRow);
  const {
    data: { data: categories = [] } = {},
    isLoading: fetchingCategories,
  } = useAppQuery(QUERY_KEY.getCategories, libraryService.getCategories, {});

  function addNewLine() {
    dispatch({ topicRow: [...topicRow, { topic: null, description: "" }] });
  }

  function removeLine(index) {
    const oldTopicRow = [...topicRow];
    delete oldTopicRow[index];
    dispatch({ topicRow: oldTopicRow.filter((item) => item) });
  }

  function onChangeInput(value, name, index) {
    const oldTopicRow = [...topicRow];
    oldTopicRow[index][name] = value;
    dispatch({ topicRow: oldTopicRow });
  }

  const { isLoading: uploadingData, mutateAsync: asycCreateCourse } =
    useAppMutation([QUERY_KEY.createCourse], coursesService.createCourse);

  function onSave() {
    const saving = topicRow.filter(({ topic }) => topic?.name);
    asycCreateCourse({
      completion_time: "2hrs",
      materials: { data: saving },
      prerequisites: [""],
      subcategory_id: subcategoryId,
      subject_id: selectedSubject?._id?.$oid,
      title,
    })
      .then(() => {
        navigate("/course/uploaded");
      })
      .catch(() => {});
  }
  return {
    categories,
    subject,
    state,
    topics,
    uploadingData,
    dispatch,
    addNewLine,
    removeLine,
    onChangeInput,
    onSave,
    fetchSubject,
    fetchingCategories,
    bookRow,
  };
}

export default useManageCourse;
