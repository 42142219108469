import { HiMiniArrowLongLeft } from "react-icons/hi2";
import CurrentPlanInfoCard from "../../components/CurrentPlanInfoCard";
import Table from "../../components/Table";
import classes from "./Subscription.module.css";
import NextPlanPayment from "../../components/NextPlanPayment";
import useSubscription from "./hooks/useSubscription";
import SkeletonLoader from "../../components/SkeletonLoader";
import CashViewer from "../../components/CashViewer";
import { FullpageModal, Loader } from "../../components/Modal";
import PlanSubscription from "../../components/PlanSubscription";
import { useState } from "react";
import ConfirmDialog from "../../components/ConfirmationDialogCD/ConfirmDialog";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { LuRefreshCcw } from "react-icons/lu";

function Subscription() {
  const [isUnitTopupOpen, setIsUnitTopupOpen] = useState(false);
  const navigate = useNavigate();
  const [isPlanModalOpen, setIsPlanModalOpen] = useState({
    managePlan: false,
    upgradePlan: false,
  });
  const {
    fetchingSubLog,
    currentPlan,
    validatingPayment,
    isLoading,
    handleUnitTopup,
    subHistory,
    // onCancelSub,
    fetchCurrentPlan,
    revalidatePayment,
  } = useSubscription();
  return (
    <div className={classes.pageRoot}>
      <div>
        <button onClick={() => navigate(-1)} className={classes.goBackButton}>
          <HiMiniArrowLongLeft className={classes.arrowLeft} />
          <h4 className={classes.pageHeader}>Plans and billing</h4>
        </button>
      </div>
      <div className={classes.billingInfo}>
        <CurrentPlanInfoCard
          currentPlan={currentPlan}
          onClickTopup={() => {
            if (/free/.test(currentPlan?.current_plan)) {
              toast.error("Not available for free plan");
              return;
            }
            if (currentPlan?.units_left > 10) {
              toast.error("Units still available");
              return;
            }
            setIsUnitTopupOpen(true);
          }}
        />
        <NextPlanPayment
          currentPlan={currentPlan}
          onManagePlan={() => setIsPlanModalOpen({ managePlan: true })}
        />
      </div>
      <div className={classes.manageUnitSection}>
        <h4 className={classes.unitLeftText}>{`${
          currentPlan?.units_left || "--"
        } Units left`}</h4>
        <div className={classes.managePlanButtonWrapper}>
          {/* This will be needed when we are offering auto renewals
          <button onClick={onCancelSub} className={classes.cancelPlanButton}>
            Cancel Plan
          </button> 
          */}
          <button
            onClick={() => setIsPlanModalOpen({ upgradePlan: true })}
            className={classes.upgradeButton}
          >
            Upgrade Plan
          </button>
        </div>
      </div>

      <div className={classes.sectionWrapper}>
        <div>
          <h3 className={classes.sectionTitle}>Billing History</h3>

          <div className={classes.tableWrapper}>
            {fetchingSubLog ? (
              <div>
                {[...new Array(4).values()].map((_, index) => (
                  <SkeletonLoader className={classes.paymentLogLoader} />
                ))}
              </div>
            ) : (
              <div>
                {!subHistory.length ? (
                  <div className={classes.emptyHistory}>
                    <h3>No Payment Recorded.</h3>
                  </div>
                ) : (
                  <Table
                    headerClassName={classes.tableHeader}
                    th={[
                      <span className={classes.tHeader}>plan</span>,
                      <span className={classes.tHeader}>DETAILS</span>,
                      <span className={classes.tHeader}>STATUS</span>,
                      <span className={classes.tHeader}>AMOUNT</span>,
                      <span className={classes.dateTableHead}>DATE</span>,
                      <span
                        className={classes.tHeader}
                        style={{ width: 30 }}
                      />,
                    ]}
                    rows={subHistory.map((subscription) => [
                      <span className={classes.tableCell}>
                        {subscription?.plan?.name}
                      </span>,
                      <span className={classes.tableCell}>
                        {subscription?.payment_purpose?.replace(/_/, " ")}
                      </span>,
                      <span className={classes.tableCell}>
                        <button
                          className={`${classes.paymentStatus} ${
                            classes[subscription?.payment_status]
                          }`}
                        >
                          {subscription?.payment_status}
                        </button>
                      </span>,
                      <span className={classes.tableCell}>
                        <CashViewer amount={subscription?.amount_paid} />
                      </span>,
                      <span
                        className={classes.tableCell}
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {moment(
                          subscription?.payment_date,
                          "DD-MM-YYYY HH:mm:ss"
                        ).format("MMMM Do YYYY")}
                      </span>,
                      <span style={{ width: 30 }}>
                        {/pending/.test(subscription?.payment_status) && (
                          <LuRefreshCcw
                            onClick={() =>
                              revalidatePayment({
                                plan_id: subscription?.plan?.id,
                                reference_number:
                                  subscription?.reference_number,
                                revalidate: true,
                              })
                            }
                          />
                        )}
                      </span>,
                    ])}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {(isPlanModalOpen?.managePlan || isPlanModalOpen?.upgradePlan) && (
        <FullpageModal
          toggleModal={() => {
            setIsPlanModalOpen({ managePlan: false, upgradePlan: false });
          }}
          className={classes.paymentModal}
        >
          <PlanSubscription
            onSuccess={() => {
              setIsPlanModalOpen({ managePlan: false, upgradePlan: false });
              fetchCurrentPlan?.();
            }}
            onClose={() =>
              setIsPlanModalOpen({ managePlan: false, upgradePlan: false })
            }
            currentPlan={currentPlan}
            type={isPlanModalOpen?.upgradePlan ? "upgrade" : "manage"}
          />
        </FullpageModal>
      )}
      {isUnitTopupOpen && (
        <ConfirmDialog
          confirm={async () => {
            handleUnitTopup();
            setIsUnitTopupOpen(false);
          }}
          cancel={() => setIsUnitTopupOpen(false)}
          message={"Click continue to TOPUP current plan unit"}
        />
      )}
      {(isLoading || validatingPayment) && <Loader />}
    </div>
  );
}

export default Subscription;
