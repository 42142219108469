import { request } from "./api";
import env from "../env";

const API = env.API_URL;

const URL = {
  deleteAccount: (email) => `${API}/auth/delete_account?email=${email}`,
  editProfile: `${API}/auth/user`,
};
function deleteAccount({ email }) {
  return request(URL.deleteAccount(email), {}, "DELETE", {});
}
function editProfile(params) {
  return request(URL.editProfile, params, "PUT", {});
}


const userProfile = {
  deleteAccount,
  editProfile,
  edit_profile_url : URL.editProfile,
};
export default userProfile;
