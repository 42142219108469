import SkeletonLoader from "../../../../components/SkeletonLoader";

function ReviewLoader() {
  return (
    <div>
      <div style={{ display: "flex", gap: 20, marginTop: 30 }}>
        <SkeletonLoader style={{ height: 50 }} />
        <SkeletonLoader style={{ height: 50 }} />
        <SkeletonLoader style={{ height: 50 }} />
      </div>

      <div style={{ display: "flex", gap: 20, marginTop: 30 }}>
        <SkeletonLoader style={{ height: 200 }} />
        <SkeletonLoader style={{ height: 200 }} />
      </div>
      <div style={{ display: "flex", gap: 20, marginTop: 30 }}>
        <SkeletonLoader style={{ height: 200 }} />
        <SkeletonLoader style={{ height: 200 }} />
      </div>
    </div>
  );
}

export default ReviewLoader;
