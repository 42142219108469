import React from "react";
import style from "./QuestionNavigator.module.css";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { FaRegCircleDot } from "react-icons/fa6";

export const QuestionBtn = ({ question, returnValue }) => {
  return (
    <button
      className={`${style.btn} ${style[question.status]}`}
      onClick={() => returnValue(question.number - 1)}
    >
      {question.number}
    </button>
  );
};

// TODO:: Allow users to drag UI
const QuestionNavigator = ({
  data,
  toggleQuestionNavigation,
  callbackFn = () => {},
}) => {
  const clickHandler = () => {
    toggleQuestionNavigation();
  };
  return (
    <div className={style.root}>
      <div className={style.top}>
        <h4>Questions</h4>
        <IoIosCloseCircleOutline
          className={style.icon}
          onClick={clickHandler}
        />
      </div>
      <div className={style.wrapper}>
        <ul className={style.legend}>
          <li>
            <span className={style.answered}>
              <FaRegCircleDot />
            </span>{" "}
            Answered
          </li>
          <li>
            <span className={style.skipped}>
              <FaRegCircleDot />
            </span>{" "}
            Skipped
          </li>
          <li>
            <span className={style.pending}>
              <FaRegCircleDot />
            </span>{" "}
            Pending
          </li>
          <li>
            <span className={style.review}>
              <FaRegCircleDot />
            </span>{" "}
            Marked for Review
          </li>
        </ul>
        <div className={style.content}>
          {data.map((question, index) => (
            <QuestionBtn
              question={question}
              key={index}
              returnValue={callbackFn}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
export default QuestionNavigator;
