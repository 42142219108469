import { MdOutlineKeyboardBackspace } from "react-icons/md";
import CourseSyllabus from "../../../components/CourseSyllabus";
import StarRating from "../../../components/StarRating/StarRating";
import { cashFormater } from "../../../utils/helpers";
import classes from "./CourseDetails.module.css";
import { useNavigate } from "react-router-dom";
import { BsBarChartLine } from "react-icons/bs";
import { IoTimeOutline } from "react-icons/io5";
import { GrCertificate } from "react-icons/gr";
import { RiTodoLine } from "react-icons/ri";
import useCourseDetails from "./hooks/useCourseDetails";
import CourseDetailsLoader from "../CourseDetailsLoader";

function CourseDetails() {
  const navigate = useNavigate();
  const { curriculum, fetchingDetals } = useCourseDetails();

  if (fetchingDetals) {
    return <CourseDetailsLoader />;
  }
  return (
    <div>
      <hr />
      <div className={classes.pageRootWrap}>
        <div className={classes.detailsBannerWrap}>
          <div className={classes.bannerDetailsItems}>
            <div className={classes.bannerTopWrapper}>
              <button
                onClick={() => navigate(-1)}
                className={classes.courseTypeText}
              >
                <MdOutlineKeyboardBackspace className={classes.backIcon} />
                <span>Curriculum Based</span>
              </button>
              <div className={classes.bannerTopText}>Course</div>
            </div>
            <h2 className={classes.courseTitleBannerText}>
              {curriculum?.title}
            </h2>
            <div className={classes.courseDetailsBannerText}>
              Learn about ChatGPT, one of the most advanced AI systems available
              today, and dive into the world of Generative AI.
            </div>
            <div className={classes.courseRatingWraper}>
              <h3 className={classes.ratingsValue}>4.5</h3>
              <StarRating />
              <div className={classes.ratingsCount}>{`${cashFormater(
                20000
              )} ratings`}</div>
            </div>
          </div>
        </div>
        <div className={classes.pageBody}>
          <div className={classes.courseStudyInfo}>
            <div>
              <BsBarChartLine className={classes.infoIcon} />
              <div>
                <div className={classes.infoTextTitle}>Level</div>
                <div className={classes.infoTextBody}>Primary 1</div>
              </div>
            </div>
            <div>
              <IoTimeOutline className={classes.infoIcon} />
              <div>
                <div className={classes.infoTextTitle}>Time to complete</div>
                <div
                  className={classes.infoTextBody}
                >{`Approx. ${curriculum?.completion_time}`}</div>
              </div>
            </div>
            <div>
              <GrCertificate className={classes.infoIcon} />
              <div>
                <div className={classes.infoTextTitle}>
                  Certificate of completion
                </div>
                <div className={classes.infoTextBody}>None</div>
              </div>
            </div>
            <div>
              <RiTodoLine className={classes.infoIcon} />
              <div>
                <div className={classes.infoTextTitle}>Prerequisites</div>
                <div className={classes.infoTextBody}>None</div>
              </div>
            </div>
          </div>
          <div className={classes.sylabusWrapper}>
            <div>
              {(curriculum?.materials?.data || []).map(
                ({ topic, description }, index) => (
                  <div>
                    <CourseSyllabus
                      onClickTopic={() =>
                        navigate(
                          `/course/topic/${topic?._id?.$oid}?topicName=${topic?.name}`
                        )
                      }
                      topic={topic?.name}
                      description={description}
                    />
                    {index !== 4 && <hr />}
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CourseDetails;
