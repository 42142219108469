import { AiOutlineSearch } from "react-icons/ai";
import style from "./SearchField.module.css";

function SearchField({
  onClick,
  placeholder,
  value,
  onChange,
  className,
  classNameIcon,
  ...res
}) {
  return (
    <div className={style.searchBox}>
      <input
        type="text"
        value={value}
        onChange={onChange}
        className={`${style.inputSearch} ${className}`}
        placeholder={placeholder}
        {...res}
      />
      <AiOutlineSearch
        className={`${style.searchIcon} ${classNameIcon}`}
        onClick={onClick}
      />
    </div>
  );
}

export default SearchField;
