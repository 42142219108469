import { useEffect, useState } from "react";
import useCdReactQuery from "../../../utils/hooks/useCdReactQuery";
import {
  qNext,
  qPrevious,
  qResultModal,
  qStatus,
} from "../../../redux/feature/bookQuestionsSlice";
import { useDispatch, useSelector } from "react-redux";
import { cdDecryption, roundToDecimalPlaces } from "../../../utils/helpers";
import adminService from "../../../service/admin.service";

const useReader = (data) => {
  const { queryEndpoint } = useCdReactQuery();
  const dispatch = useDispatch();

  const { hasQuestion, position, totalQuestions } = useSelector(
    (state) => state.bookQuestions
  );

  const [pageIndex, setPageIndex] = useState(0);
  const [pageData, setPageData] = useState({});
  const [isMenu, setIsMenu] = useState(false);
  const [lastVisitedPageIndex, setLastVisitedPageIndex] = useState(pageIndex);

  // Fetch user details for book
  useEffect(() => {
    (async () => {
      const bookProgressResponse = await queryEndpoint({
        endpointUrl: adminService.getReadProgress(data[0].book_id),
      });

      const initLastPageVisited =
        bookProgressResponse?.data?.lastPageVisitedIndex || 0;
      setPageIndex((prev) => initLastPageVisited);
      setLastVisitedPageIndex((prev) => initLastPageVisited);
    })();
    // eslint-disable-next-line
  }, []);

  // This always ensures MathJax is loaded when a new page is generated or when index position in question changes
  useEffect(() => {
    if (typeof window?.MathJax !== "undefined") {
      window.MathJax.typesetClear();
      window.MathJax.typeset();
    }
  });

  useEffect(() => {
    // all question page must have page_type property.
    if (pageData?.page_type === "question") {
      // decrypt the question part
      const decryptedQuestions = cdDecryption(pageData.questions);
      dispatch(
        qStatus({
          hasQuestion: true,
          totalQuestions: decryptedQuestions.length,
          pageId: pageData.pageId,
        })
      );
    } else {
      dispatch(
        qStatus({
          hasQuestion: false,
          totalQuestions: 0,
        })
      );
    }
    return () => {
      dispatch(qResultModal(false));
    };
    // eslint-disable-next-line
  }, [pageData]);

  useEffect(() => {
    const _pageData = data[pageIndex];
    const book_id = data[0].book_id;
    setPageData(_pageData);

    // update last book page index and percentage completed
    if (pageIndex > 0 && pageIndex > lastVisitedPageIndex) {
      const bookSize = data.length;
      const progress = roundToDecimalPlaces(pageIndex / bookSize, 1) * 100;
      // const pageVisited = pageIndex === bookSize ? pageIndex - 1 : pageIndex; // count from 0 to bookSize -1

      // console.log(
      //   "run put req",
      //   "pageIndex ",
      //   pageIndex,
      //   "lastVisit ",
      //   lastVisitedPageIndex,
      //   "progress ",
      //   progress
      // );
      // Save read progress and page index number
      const putRequest = async () =>
        await queryEndpoint({
          endpointUrl: adminService.updateBookReadProgress,
          httpMethod: "PUT",
          body: {
            book_id: book_id,
            page_info: {
              lastPageVisitedIndex: pageIndex,
            },
            read_progress: progress,
          },
        });
      putRequest();

      setLastVisitedPageIndex((prev) => pageIndex);
    }

    // eslint-disable-next-line
  }, [pageIndex]);

  // If question position is 0 and hasQuestion is true, move to the next page
  const previous = () => {
    if (hasQuestion) {
      position === 0
        ? setPageIndex((prev) => (prev > 0 ? prev - 1 : 0))
        : dispatch(qPrevious({ position }));
    } else setPageIndex((prev) => (prev > 0 ? prev - 1 : 0));
  };
  const next = () => {
    if (hasQuestion && position < totalQuestions) {
      dispatch(qNext());
    } else
      setPageIndex((prev) =>
        prev <= data.length - 1 ? prev + 1 : data.length - 1
      );
  };
  const skip = () => {
    setPageIndex((prev) =>
      prev <= data.length - 1 ? prev + 1 : data.length - 1
    );
  };
  const pageSelectionHandler = (index) => {
    setPageIndex(index);
    setIsMenu(false);
  };

  return {
    totalPages: data.length,
    pageIndex,
    templateIndex: pageData?.index,
    pageData,
    previous,
    next,
    skip,
    isMenu,
    setIsMenu,
    pageSelectionHandler,
    hasQuestion,
    position,
    totalQuestions,
    lastVisitedPageIndex,
  };
};

export default useReader;
