import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authSlice from "./feature/authSlice";
import confrimationPopupSlice from "./feature/confrimationPopupSlice";
import popupSlice from "./feature/popupSlice";
import progressLoaderSlice from "./feature/progressLoaderSlice";
import splashLoaderSlice from "./feature/splashLoaderSlice";
import doubleShapePopupSlice from "./feature/doubleShapePopupSlice";
import popup2Slice from "./feature/popup2Slice";
import courseDataSlice from "./feature/courseDataSlice"; //Not in use. Verify and remove
import bookQuestions from "./feature/bookQuestionsSlice";
import questionBank from "./feature/questionBankSlice";
import examprepSlice from "./feature/examprepSlice";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["bookQuestions", "questionBank", "authSlice"],
};

const rootReducer = combineReducers({
  currentUser: authSlice,
  popup: popupSlice,
  popup2: popup2Slice,
  doubleShape: doubleShapePopupSlice,
  splashLoader: splashLoaderSlice,
  confirmationPopup: confrimationPopupSlice,
  progressload: progressLoaderSlice,
  courseData: courseDataSlice,
  bookQuestions,
  questionBank,
  examprep: examprepSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);
