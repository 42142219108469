import { useEffect, useState } from "react";
import { MdOutlineCloudDownload } from "react-icons/md";
import useCdReactQuery from "../../../../utils/hooks/useCdReactQuery";
import adminService from "../../../../service/admin.service";
import classes from "./Tables.module.css";
import { formatDate } from "../../../../utils/helpers";
import Pagination from "../../../../components/Pagination/Pagination";
import Table from "../../../../components/Table";
import CashViewer from "../../../../components/CashViewer";
import SkeletonLoader from "../../../../components/SkeletonLoader";
import Tooltip from "../../../../components/Tooltip";
import { downloadExcel } from "../../../../utils/downloadExcel";
import { RotatingLines } from "react-loader-spinner";
import { PAGE_SIZE } from "../../../../utils/constants";

export const TotalStudentsTable = () => {
  const { data: response, isLoading, queryEndpoint } = useCdReactQuery();
  const { isLoading: isDownloading, queryEndpoint: queryDownload } =
    useCdReactQuery();
  async function handleDownload() {
    const response = await queryDownload({
      endpointUrl: adminService.downloadStudentList,
    });
    downloadExcel(response, "student-list");
  }

  async function fetch(pageNumber = 1) {
    await queryEndpoint({
      endpointUrl: `${adminService.dashboardStudentList}?page_size=${PAGE_SIZE}&page=${pageNumber}`,
    });
  }
  useEffect(() => {
    fetch();
  }, []); // eslint-disable-line

  return (
    <div className={classes.modalBodyWrapper}>
      <button className={classes.downnloadButton}>
        {isDownloading ? (
          <RotatingLines
            visible={true}
            strokeColor="#000249"
            width="96"
            strokeWidth="5"
            animationDuration="0.75"
            ariaLabel="rotating-lines-loading"
          />
        ) : (
          <Tooltip callbackFn={handleDownload} data="Download">
            <MdOutlineCloudDownload style={{ marginTop: -5 }} />
          </Tooltip>
        )}
      </button>
      <div className={classes.tableWrapper}>
        {isLoading ? (
          <div>
            {[...new Array(4).values()].map((_, index) => (
              <SkeletonLoader className={classes.paymentLogLoader} />
            ))}
          </div>
        ) : (
          <Table
            headerClassName={classes.tableHeader}
            th={[
              <span className={classes.tHeader}>Name</span>,
              <span className={classes.tHeader}>Phone Number</span>,
              <span className={classes.tHeader}>Gender</span>,
              <span className={classes.tHeader}>Date Added</span>,
              <span className={classes.dateTableHead}>Class</span>,
            ]}
            rows={(response?.students || []).map((item) => [
              <span className={classes.tableCell}>{item?.Name}</span>,
              <span className={classes.tableCell}>{item?.Phone}</span>,
              <span className={classes.tableCell}>{item?.Gender}</span>,
              <span className={classes.tableCell}>
                {formatDate(item["Date Added"])}
              </span>,
              <span className={classes.tableCell}>{item?.Class}</span>,
            ])}
          />
        )}
      </div>

      <Pagination
        currentPage={response?.current_page}
        pages={response?.pages}
        onChange={fetch}
      />
    </div>
  );
};

export const TotalRevenueTable = () => {
  const { data: response, isLoading, queryEndpoint } = useCdReactQuery();
  const { isLoading: isDownloading, queryEndpoint: queryDownload } =
    useCdReactQuery();
  async function handleDownload() {
    const response = await queryDownload({
      endpointUrl: adminService.downloadRevenue,
    });
    downloadExcel(response, "revenue-list");
  }

  async function fetch(pageNumber = 1) {
    await queryEndpoint({
      endpointUrl: `${adminService.dashboardRevenueList}?page_size=${PAGE_SIZE}&page=${pageNumber}`,
    });
  }

  useEffect(() => {
    fetch();
  }, []); // eslint-disable-line

  return (
    <div className={classes.modalBodyWrapper}>
      <button className={classes.downnloadButton}>
        {isDownloading ? (
          <RotatingLines
            visible={true}
            strokeColor="#000249"
            width="96"
            strokeWidth="5"
            animationDuration="0.75"
            ariaLabel="rotating-lines-loading"
          />
        ) : (
          <Tooltip callbackFn={handleDownload} data="Download">
            <MdOutlineCloudDownload style={{ marginTop: -5 }} />
          </Tooltip>
        )}
      </button>
      <div className={classes.tableWrapper}>
        {isLoading ? (
          <div>
            {[...new Array(4).values()].map((_, index) => (
              <SkeletonLoader className={classes.paymentLogLoader} />
            ))}
          </div>
        ) : (
          <Table
            headerClassName={classes.tableHeader}
            th={[
              <span className={classes.tHeader}>Name</span>,
              <span className={classes.tHeader}>Email</span>,
              <span className={classes.tHeader}>Amount</span>,
              <span className={classes.tHeader}>Date Added</span>,
              <span className={classes.dateTableHead}>Status</span>,
            ]}
            rows={(response?.data || []).map((item) => [
              <span className={classes.tableCell}>{item?.Name}</span>,
              <span className={classes.tableCell}>{item?.Email}</span>,
              <span className={classes.tableCell}>
                <CashViewer amount={item?.Amount_Paid} />
              </span>,
              <span className={classes.tableCell}>
                {formatDate(item["Date_Added"])}
              </span>,
              <span className={classes.tableCell}>
                {item?.Payment_Purpose}
              </span>,
            ])}
          />
        )}
      </div>

      <Pagination
        currentPage={response?.current_page}
        pages={response?.total_pages}
        onChange={fetch}
      />
    </div>
  );
};

export const BookRead = () => {
  const { data: response, isLoading, queryEndpoint } = useCdReactQuery();

  const { isLoading: isDownloading, queryEndpoint: queryDownload } =
    useCdReactQuery();
  async function handleDownload() {
    const response = await queryDownload({
      endpointUrl: adminService.downloadBookRead,
    });
    downloadExcel(response, "Book-read");
  }

  async function fetch(pageNumber = 1) {
    await queryEndpoint({
      endpointUrl: `${adminService.bookReadList}?page_size=${PAGE_SIZE}&page=${pageNumber}`,
    });
  }

  useEffect(() => {
    fetch();
  }, []); // eslint-disable-line

  return (
    <div className={classes.modalBodyWrapper}>
      <button className={classes.downnloadButton}>
        {isDownloading ? (
          <RotatingLines
            visible={true}
            strokeColor="#000249"
            width="96"
            strokeWidth="5"
            animationDuration="0.75"
            ariaLabel="rotating-lines-loading"
          />
        ) : (
          <Tooltip callbackFn={handleDownload} data="Download">
            <MdOutlineCloudDownload style={{ marginTop: -5 }} />
          </Tooltip>
        )}
      </button>
      <div className={classes.tableWrapper}>
        {isLoading ? (
          <div>
            {[...new Array(4).values()].map((_, index) => (
              <SkeletonLoader
                className={classes.paymentLogLoader}
                key={index}
              />
            ))}
          </div>
        ) : (
          <Table
            headerClassName={classes.tableHeader}
            th={[
              <span className={classes.tHeader}>Title</span>,
              <span className={classes.tHeader}>Class</span>,
              <span className={classes.tHeader}>Name</span>,
              <span className={classes.tHeader}>Date</span>,
            ]}
            rows={(response?.data || []).map((item) => [
              <span className={classes.tableCell}>{item?.title}</span>,
              <span className={classes.tableCell}>{item?.class}</span>,
              <span className={classes.tableCell}>{item?.name}</span>,
              <span className={classes.tableCell}>
                {formatDate(item["date_created"])}
              </span>,
            ])}
          />
        )}
      </div>

      <Pagination
        currentPage={response?.current_page}
        pages={response?.total_pages}
        onChange={fetch}
      />
    </div>
  );
};

export const BookSold = () => {
  const { data: response, isLoading, queryEndpoint } = useCdReactQuery();

  const { isLoading: isDownloading, queryEndpoint: queryDownload } =
    useCdReactQuery();
  async function handleDownload() {
    const response = await queryDownload({
      endpointUrl: adminService.downloadBookSold,
    });
    downloadExcel(response, "Book-read");
  }

  async function fetch(pageNumber = 1) {
    await queryEndpoint({
      endpointUrl: `${adminService.bookSoldList}?page_size=${PAGE_SIZE}&page=${pageNumber}`,
    });
  }

  useEffect(() => {
    fetch();
  }, []); // eslint-disable-line

  return (
    <div className={classes.modalBodyWrapper}>
      <button className={classes.downnloadButton}>
        {isDownloading ? (
          <RotatingLines
            visible={true}
            strokeColor="#000249"
            width="96"
            strokeWidth="5"
            animationDuration="0.75"
            ariaLabel="rotating-lines-loading"
          />
        ) : (
          <Tooltip callbackFn={handleDownload} data="Download">
            <MdOutlineCloudDownload style={{ marginTop: -5 }} />
          </Tooltip>
        )}
      </button>
      <div className={classes.tableWrapper}>
        {isLoading ? (
          <div>
            {[...new Array(4).values()].map((_, index) => (
              <SkeletonLoader className={classes.paymentLogLoader} />
            ))}
          </div>
        ) : (
          <Table
            headerClassName={classes.tableHeader}
            th={[
              <span className={classes.tHeader}>Title</span>,
              <span className={classes.tHeader}>Class</span>,
              <span className={classes.tHeader}>Name</span>,
              <span className={classes.tHeader}>Date</span>,
            ]}
            rows={(response?.data || []).map((item) => [
              <span className={classes.tableCell}>{item?.title}</span>,
              <span className={classes.tableCell}>{item?.class}</span>,
              <span className={classes.tableCell}>{item?.name}</span>,
              <span className={classes.tableCell}>
                {formatDate(item["date_created"])}
              </span>,
            ])}
          />
        )}
      </div>

      <Pagination
        currentPage={response?.current_page}
        pages={response?.total_pages}
        onChange={fetch}
      />
    </div>
  );
};

export const BookStatsList = () => {
  const [stat, setStat] = useState("SOLD");
  const childComponents = {
    SOLD: <BookSold />,
    READ: <BookRead />,
  };
  return (
    <div>
      <div className={classes.bookStatTab}>
        <button
          className={stat === "SOLD" && classes?.activeBookStatTab}
          onClick={() => setStat("SOLD")}
        >
          Sold
        </button>
        <button
          className={stat === "READ" && classes?.activeBookStatTab}
          onClick={() => setStat("READ")}
        >
          Read
        </button>
      </div>
      <div>{childComponents[stat]}</div>
    </div>
  );
};

export const MostViewedBooksList = () => {
  const { data: response, isLoading, queryEndpoint } = useCdReactQuery();

  const { isLoading: isDownloading, queryEndpoint: queryDownload } =
    useCdReactQuery();
  async function handleDownload() {
    const response = await queryDownload({
      endpointUrl: adminService.downloadMostViewedBook,
    });
    downloadExcel(response, "most-viewed-book");
  }

  async function fetch(pageNumber = 1) {
    await queryEndpoint({
      endpointUrl: `${adminService.dashboardMostViewedBookList}?page_size=${PAGE_SIZE}&page=${pageNumber}`,
    });
  }
  useEffect(() => {
    fetch();
  }, []); // eslint-disable-line

  return (
    <div className={classes.modalBodyWrapper}>
      <button className={classes.downnloadButton}>
        {isDownloading ? (
          <RotatingLines
            visible={true}
            strokeColor="#000249"
            width="96"
            strokeWidth="5"
            animationDuration="0.75"
            ariaLabel="rotating-lines-loading"
          />
        ) : (
          <Tooltip callbackFn={handleDownload} data="Download">
            <MdOutlineCloudDownload style={{ marginTop: -5 }} />
          </Tooltip>
        )}
      </button>
      <div className={classes.tableWrapper}>
        {isLoading ? (
          <div>
            {[...new Array(4).values()].map((_, index) => (
              <SkeletonLoader className={classes.paymentLogLoader} />
            ))}
          </div>
        ) : (
          <Table
            headerClassName={classes.tableHeader}
            th={[
              <span className={classes.tHeader}>Title</span>,
              <span className={classes.tHeader}>Topic</span>,
              <span className={classes.tHeader}>Subject</span>,
              <span className={classes.tHeader}>Rating</span>,
            ]}
            rows={(response?.data || []).map((item) => [
              <span className={classes.tableCell}>{item?.title}</span>,
              <span className={classes.tableCell}>{item?.topic}</span>,
              <span className={classes.tableCell}>{item?.subject}</span>,
              <span className={classes.tableCell}>{item?.rating}</span>,
            ])}
          />
        )}
      </div>

      <Pagination
        currentPage={response?.current_page}
        pages={response?.total_pages}
        onChange={fetch}
      />
    </div>
  );
};

export const RenewalListTable = () => {
  const { data: response, isLoading, queryEndpoint } = useCdReactQuery();

  const { isLoading: isDownloading, queryEndpoint: queryDownload } =
    useCdReactQuery();
  async function handleDownload() {
    const response = await queryDownload({
      endpointUrl: adminService.downloadRenewalList,
    });
    downloadExcel(response, "most-viewed-book");
  }

  async function fetch(pageNumber = 1) {
    await queryEndpoint({
      endpointUrl: `${adminService.dashboardRenewalList}?page_size=${PAGE_SIZE}&page=${pageNumber}`,
    });
  }

  useEffect(() => {
    fetch();
  }, []); // eslint-disable-line

  return (
    <div className={classes.modalBodyWrapper}>
      <button className={classes.downnloadButton}>
        {isDownloading ? (
          <RotatingLines
            visible={true}
            strokeColor="#000249"
            width="96"
            strokeWidth="5"
            animationDuration="0.75"
            ariaLabel="rotating-lines-loading"
          />
        ) : (
          <Tooltip callbackFn={handleDownload} data="Download">
            <MdOutlineCloudDownload style={{ marginTop: -5 }} />
          </Tooltip>
        )}
      </button>
      <div className={classes.tableWrapper}>
        {isLoading ? (
          <div>
            {[...new Array(4).values()].map((_, index) => (
              <SkeletonLoader className={classes.paymentLogLoader} />
            ))}
          </div>
        ) : (
          <Table
            headerClassName={classes.tableHeader}
            th={[
              <span className={classes.tHeader}>Name</span>,
              <span className={classes.tHeader}>Phone Number</span>,
              <span className={classes.tHeader}>Date</span>,
              <span className={classes.tHeader}>Amount</span>,
            ]}
            rows={(response?.data || []).map((item) => [
              <span className={classes.tableCell}>{item?.name}</span>,
              <span className={classes.tableCell}>{item?.phone_number}</span>,
              <span className={classes.tableCell}>
                {formatDate(item?.expiry_date)}
              </span>,
              <span className={classes.tableCell}>
                <CashViewer amount={item?.amount} />
              </span>,
            ])}
          />
        )}
      </div>

      <Pagination
        currentPage={response?.current_page}
        pages={response?.total_pages}
        onChange={fetch}
      />
    </div>
  );
};
