const enviroment = {
  development: {
    API_URL: "https://project-mecury-api.herokuapp.com",
    // API_URL: "http://127.0.0.1:8000",
    APP_URL: "http://localhost:3000",
  },
  production: {
    API_URL: "https://project-mecury-api.herokuapp.com",
    APP_URL: "https://ctrlearn.com",
  },
};
// eslint-disable-next-line
export default {
  ...enviroment.production,
  // ...enviroment.development,
};
