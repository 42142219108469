import React from "react";
import { BsArrowLeft } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import classes from "./NewPasswordForm.module.css";
import Button from "../../components/Button";
import InputField from "../../components/InputField";

const NewPasswordForm = ({
  onClick,
  onResend,
  state,
  email,
  dispatch,
  isLoading,
  resetToken,
}) => {
  const history = useNavigate();
  const { password, cPassword } = state;
  return (
    <div className={classes.compRoot}>
      <div className={classes.mainBody}>
        <h2 className={classes.formTitle}>Update Password</h2>
        <p className={classes.formText}>
          Enter your new password. If token has expired, click on request a new
          reset token.
        </p>
        <div className={classes.otpInputContainer}>
          <div className={classes.inputRow}>
            <InputField
              type="token"
              disabled
              placeholder="token"
              value={resetToken}
              onChange={({ target }) => dispatch({ token: target.value })}
              className={classes.inputField}
              required
            />
          </div>
          <div className={classes.inputRow}>
            <InputField
              type="password"
              placeholder="New Password"
              value={password}
              onChange={({ target }) => dispatch({ password: target.value })}
              className={classes.inputField}
              required
            />
          </div>
          <div className={classes.inputRow}>
            <InputField
              type="password"
              placeholder="Confirm Password"
              value={cPassword}
              onChange={({ target }) => dispatch({ cPassword: target.value })}
              className={classes.inputField}
              required
            />
          </div>
        </div>
        <div className={classes.actionBtnWrapper}>
          <button
            onClick={() => history("/login")}
            className={classes.backTologin}
          >
            <BsArrowLeft className={classes.arrowBack} />
            <span>Back To Login</span>
          </button>
          <Button
            onClick={onClick}
            isLoading={isLoading}
            className={classes.verifyBtn}
          >
            Verify
          </Button>
        </div>
        <p className={classes.infoText}>
          Didn’t get the code?{" "}
          <b onClick={onResend} className={classes.requestNewCode}>
            {" "}
            Request a new reset token{" "}
          </b>{" "}
        </p>
      </div>
    </div>
  );
};

export default NewPasswordForm;
