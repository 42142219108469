import { request } from "./api";
import env from "../env";

const API = env.API_URL;

const URL = {
  getQuestionByFilter: (filterMethod, methodId) =>
    `${API}/questions/${filterMethod}/${methodId}`,
};

function getQuestionByFilter({ filterMethod, methodId }) {
  return request(
    URL.getQuestionByFilter(filterMethod, methodId),
    {},
    "GET",
    {}
  );
}

const assessmentService = {
  bucketSubmission : `${API}/buckets_submissions`,
  buckets : `${API}/buckets`,
  bucketById : (id) => `${API}/buckets/bucket?bucket_id=${id}`,
  bucketBySubjectId : (id) => `${API}/buckets?subject_id=${id}`,
  performanceCourseData : `${API}/course-performance-data`,
  getQuestionByFilter,
};

export default assessmentService;
