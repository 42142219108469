import { useEffect, useRef, useState } from "react";
import style from "./QuestionBank.module.css";
import ClassModal from "./modal/ClassModal";
import { defaultImage } from "../../../assets/image";
import {
  DeleteIcon,
  EditIcon,
  FilterIcon,
  GridAddIcon,
} from "../../../assets/icons";
import Tooltip from "../../../components/Tooltip/Tooltip";
import ConfirmDialog from "../../../components/ConfirmationDialogCD/ConfirmDialog";
import { useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";
import useCdReactQuery from "../../../utils/hooks/useCdReactQuery";
import adminService from "../../../service/admin.service";
import { cdDecryption } from "../../../utils/helpers";
import SkeletonLoader from "../../../components/SkeletonLoader";

export const QuestionCard = ({ data: _data, updateParent }) => {
  const navigate = useNavigate();
  const { queryEndpoint } = useCdReactQuery();

  const { questions, topic, _id } = _data;
  const decryptedQuestions = cdDecryption(questions);

  const [isDelete, setIsDelete] = useState({
    status: false,
    action: () => {},
  });
  const deleteHandler = () => {
    const deleteQuestion = async () => {
      await queryEndpoint({
        endpointUrl: adminService.deleteQuestion(_id),
        httpMethod: "DELETE",
      });
      updateParent();
    };
    setIsDelete({ status: true, action: deleteQuestion });
  };

  return (
    <>
      <div className={style.questionCard}>
        <div className={style.actions}>
          {/* TODO: Update url with question id */}
          <span onClick={() => navigate(`/admin/question-bank/${_id}`)}>
            <Tooltip data="edit" classes={style.tooltip}>
              <EditIcon />
            </Tooltip>
          </span>
          <span onClick={deleteHandler}>
            <Tooltip data="delete" classes={style.tooltip}>
              <DeleteIcon />
            </Tooltip>
          </span>
        </div>
        <h5 className={style.topic}>{topic?.name}</h5>
        <p className={style.number}>#{_id}</p>

        <div
          className={`${style.question} ${
            decryptedQuestions?.paragraph.trim() !== ""
              ? style["paragraph"]
              : ""
          }`}
        >
          {decryptedQuestions?.paragraph.trim() !== ""
            ? decryptedQuestions?.paragraph
            : decryptedQuestions?.questionGroup?.[0]?.question}
        </div>
        {decryptedQuestions?.questionGroup?.[0]?.imageUrl && (
          <div className={style.image}>
            <img src={defaultImage} alt="Question" />
          </div>
        )}
      </div>
      {isDelete.status && (
        <ConfirmDialog
          confirm={async () => {
            // call delete action here
            isDelete.action();
            setIsDelete({ status: false, action: () => {} });
          }}
          cancel={() => setIsDelete({ status: false, action: () => {} })}
          message={"Click continue to DELETE question"}
        />
      )}
    </>
  );
};

const QuestionBank = () => {
  const { userClass, subject } = useSelector((store) => store.questionBank);
  const [modal, setModal] = useState(false);
  const [reload, setReload] = useState(false);
  // const [loader, setLoader] = useState(false);
  const { queryEndpoint, data, isLoading } = useCdReactQuery();

  const navigate = useNavigate();

  useEffect(() => {
    if (typeof window?.MathJax !== "undefined") {
      window.MathJax.typesetClear();
      window.MathJax.typeset();
    }
  });

  useEffect(() => {
    // show subject and class modal if not set
    if (userClass.id === "" && subject.id === "") setModal(true);

    // eslint-disable-next-line
  }, []);

  // Get questions

  useEffect(() => {
    const fetch = async () => {
      await queryEndpoint({
        endpointUrl: adminService.getFilteredQuestion,
        params: {
          subcategory_id: userClass.id,
          subject_id: subject.id,
          limit: 100,
        },
      });
    };
    fetch();
    // eslint-disable-next-line
  }, [userClass.id, subject.id, reload]);

  const searchRef = useRef("");

  const searchHandler = (searchItem) => {
    console.log(searchItem);
  };

  return (
    <div className={style.root}>
      {/* <h5>
        Question Bank / {userClass.name} / {subject.name}
      </h5> */}
      {/* Search, Filter and Add UI */}
      {/* <div className={`${style.row} ${style.searchGroup}`}>
        <div className={style.search}>
          <SearchField
            onClick={searchHandler}
            placeholder="Search Question Bank"
            onChange={(e) => getSearchData(e)}
            classNameIcon={style.searchComponent}
          />
        </div>
        <div className={style.searchActions} onClick={() => setModal(true)}>
          <FilterIcon />
        </div>
        <div
          className={style.searchActions}
          onClick={() => navigate("/admin/question-bank/0")}
        >
          <GridAddIcon />
        </div>
      </div> */}
      <div className={`${style.row} ${style.searchActions}`}>
        <div className={style.searchContainer}>
          <input
            type="search"
            className={style.search}
            placeholder="Search Question Bank"
            onKeyUp={(e) => {
              if (e.key === "Enter") searchHandler(e.target.value);
            }}
            ref={searchRef}
          />
          <button
            className={style.searchBtn}
            onClick={() => searchHandler(searchRef?.current?.value)}
          >
            GO
          </button>
        </div>

        <Tooltip
          data="Filter"
          classes={style.tooltip}
          callbackFn={() => setModal(true)}
        >
          <FilterIcon size={22} />
        </Tooltip>

        <Tooltip
          data="Add"
          classes={style.tooltip}
          callbackFn={() => {
            navigate("/admin/question-bank/0");
          }}
        >
          <GridAddIcon size={25} />
        </Tooltip>
      </div>
      <div className={`${style.row}`}>
        {isLoading ? (
          <div className={style.row}>
            {[...new Array(4).values()].map((_, index) => (
              <SkeletonLoader className={style.skeletonLogLoader} key={index} />
            ))}
          </div>
        ) : (
          (data?.filtered_questions || []).map((item, index) => (
            <QuestionCard
              data={item}
              key={index}
              updateParent={() => setReload((prev) => !prev)}
            />
          ))
        )}
      </div>
      {modal && <ClassModal closeModal={() => setModal(false)} />}
      {/* {loader && <Loader />} */}
    </div>
  );
};
export default QuestionBank;
