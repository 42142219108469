import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import libraryService from "../../../../service/library.service";
import { QUERY_KEY } from "../../../../utils/constants";
import useAppMutation from "../../../../utils/hooks/useAppMutation";
import useAppQuery from "../../../../utils/hooks/useAppQuery";
import useBookmark from "../../Bookmark/hooks/useBookmark";
import useMyShelf from "../../MyShelf/hooks/useMyShelf";
function useCourseDetails() {
  const { courseId } = useParams();
  const { allBookmark, error } = useBookmark();
  const bookmarkValue = !error ? allBookmark?.bookmarks_data || [] : [];
  const { coursesInShelf, refetch } = useMyShelf();

  const borrowedBook = (coursesInShelf || []).find(
    (book) => book?.book?._id?.$oid === courseId
  );
  const isBookBorrowed = (id) => {
    const getBorrowedBook = (coursesInShelf || []).find(
      (book) => book?.book?._id?.$oid === id
    );
    return getBorrowedBook;
  };
  const isBookmarked = bookmarkValue.find(
    (book) => book?.detail_field?._id.$oid === courseId
  );
  const { data: courseDetails, isLoading: isFetching } = useAppQuery(
    QUERY_KEY.getBookDetails,
    libraryService.getBookDetails,
    {
      bookId: courseId,
    }
  );
  

  const { isLoading: isReturning, mutateAsync: asycReturnBook } =
    useAppMutation(
      QUERY_KEY.returnBorrowedBook,
      libraryService.returnBorrowedBook
    );

  function onReturnBook() {
    asycReturnBook({ bookId: courseId })
      .then(() => {
        toast.success("Book Returned successfully");
        refetch();
      })
      .catch(({ msg }) => {
        toast.error(msg);
      })
      .finally(() => {});
  }

  const { isLoading: isBorroWing, mutateAsync: asycBorrowBook } =
    useAppMutation(QUERY_KEY.borrowBook, libraryService.borrowBook);

  function onBorrowBook() {
    asycBorrowBook({ bookId: courseId })
      .then(() => {
        toast.success("Borrwed successfully");
        refetch();
      })
      .catch(({ msg }) => {
        toast.error(msg);
      })
      .finally(() => {});
  }

  const { isLoading: isInitiatingPayment, mutateAsync: asycMakePayment } =
    useAppMutation(QUERY_KEY.payForBook, libraryService.payForBook);

  function buyBook() {
    asycMakePayment({ book_id: courseId })
      .then(({ session }) => {
        window.open(session?.redirect_url);
      })
      .catch(({ msg }) => {
        toast.error(msg);
      })
      .finally(() => {});
  }

  return {
    courseDetails,
    isReturning,
    borrowedBook,
    isFetching,
    isInitiatingPayment,
    onReturnBook,
    onBorrowBook,
    buyBook,
    isBookmarked,
    isBorroWing,
    isBookBorrowed,
  };
}

export default useCourseDetails;
