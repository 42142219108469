import RequestHelp from "../../RequestHelp/RequestHelp";
import { useDispatch } from "react-redux";
import { setPopupChild, showPopup } from "../../../../redux/feature/popupSlice";
import useAppQuery from "../../../../utils/hooks/useAppQuery";
import { QUERY_KEY } from "../../../../utils/constants";
import libraryService from "../../../../service/library.service";
import { useNavigate, useParams } from "react-router-dom";
import { useReducer, useState } from "react";
import { toast } from "react-toastify";
import useAppMutation from "../../../../utils/hooks/useAppMutation";
import useMyShelf from "../../MyShelf/hooks/useMyShelf";
function useStudyLayout() {
  const reduxDispatch = useDispatch();
  const { courseId } = useParams();
  const navigate = useNavigate();
  const [noteText, setNoteText] = useState("");
  const { coursesInShelf } = useMyShelf();

  const borrowedBook = (coursesInShelf || []).find(
    (book) => book?.book?._id?.$oid === courseId
  );
  const initialState = {
    noteTitle: "",
  };
  const [state, dispatch] = useReducer(
    (stateVal, value) => ({ ...stateVal, ...value }),
    initialState
  );

  const { data: { book_details: courseDetails = {} } = {} } = useAppQuery(
    QUERY_KEY.getBookDetails,
    libraryService.getBookDetails,
    {
      bookId: courseId,
    }
  );

  // useEffect(() => {
  //   dispatch({ noteTitle: courseDetails?.title });
  // }, [courseDetails]);

  const { isLoading: isSaveing, mutateAsync: asycSaveNote } = useAppMutation(
    QUERY_KEY.saveNote,
    libraryService.saveNote
  );

  function saveNote() {
    if (!noteText) {
      toast.error("Note cannot be empty");
      return;
    }
    if (!state?.noteTitle) {
      toast.error("Note Title cannot be empty");
      return;
    }
    asycSaveNote({
      filename: state?.noteTitle,
      content: noteText,
    })
      .then(() => {
        toast.success("Note saved successfully");
        setNoteText("");
      })
      .catch(() => {});
  }

  const { isLoading, mutateAsync: asycReturnBook } = useAppMutation(
    QUERY_KEY.returnBorrowedBook,
    libraryService.returnBorrowedBook
  );

  function onReturnBook() {
    asycReturnBook({ courseId })
      .then(() => {
        toast.success("Book Returned successfully");
        navigate("/library/myshelf");
      })
      .catch(({ msg }) => {
        toast.error(msg);
      })
      .finally(() => {});
  }

  function handleRequestHelp() {
    reduxDispatch(
      setPopupChild(
        <RequestHelp
          courseDetails={courseDetails}
          onSuccess={() => {
            reduxDispatch(
              showPopup({
                open: false,
              })
            );
          }}
        />
      )
    );
    reduxDispatch(
      showPopup({
        open: true,
      })
    );
  }

  return {
    state,
    noteText,
    courseDetails,
    isLoading,
    isSaveing,
    borrowedBook,
    dispatch,
    saveNote,
    setNoteText,
    handleRequestHelp,
    onReturnBook,
  };
}
export default useStudyLayout;
