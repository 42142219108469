import style from "./ConfirmDialog.module.css";

const ConfirmDialog = ({ confirm, cancel, message }) => {
  return (
    <div className={style.dialog}>
      <p className={style.message}>{message}</p>
      <div className={style.actions}>
        <button
          className={style.btnContinue}
          onClick={() => {
            confirm();
          }}
        >
          CONTINUE
        </button>
        <button
          className={style.btnCancel}
          onClick={() => {
            cancel();
          }}
        >
          CANCEL
        </button>
      </div>
    </div>
  );
};
export default ConfirmDialog;
