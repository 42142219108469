import { useContext, useEffect, useState } from "react";
import useCdReactQuery from "../../../../utils/hooks/useCdReactQuery";
import ExamPrepStorage from "../../storage/ExamPrepStorage";
import assessmentService from "../../../../service/assessment.service";

export const useBucketDetail = (bucket_id) => {
  const { data, queryEndpoint, isLoading } = useCdReactQuery();
  const { updateStorage } = useContext(ExamPrepStorage);
  const [bucket, setBucket] = useState([]);

  useEffect(() => {
    (async () => {
      await queryEndpoint({
        endpointUrl: assessmentService.bucketById(bucket_id),
      });
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data) {
      setBucket(data);
      updateStorage({
        ...data,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return {
    isLoading,
    bucket,
  };
};
