import axios from "axios";
import { emitter } from "../utils/constants";

export const defaultHeader = {
  Accept: "application/json",
  "Cache-Control": "no-cache",
  "Content-Type": "application/json",
};

async function request(url, payload, method, header, options = {}) {
  delete defaultHeader.Authorization;
  if (options?.isFormData) delete defaultHeader["Content-Type"];

  const requestMethod = method.toLowerCase();
  const persistedToken = localStorage.getItem("accessToken");

  if (persistedToken) {
    defaultHeader.Authorization = `Bearer ${persistedToken}`;
  }
  try {
    const response = await axios({
      method: requestMethod,
      url,
      [requestMethod === "get" ? "params" : "data"]: payload,
      headers: { ...defaultHeader, ...header },
    });
    return response.data;
  } catch ({ response }) {
    if (response?.status === 490) {
      emitter.sessionTimeOutEventEmitter.emit("unauthorized");
    } else if (response?.status === 491) {
      emitter.sessionTimeOutEventEmitter.emit("planExpired");
    }
    return new Promise((_, reject) => {
      reject(response.data);
    });
  }
}

export { request };
