import React from "react";
import style from "./StudyTrend.module.css";
import {
  AreaChart,
  Area,
  XAxis,
  // YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { useWindowSize } from "./hooks/useWindowSize";

// interface DataProps {
//   month: string;
//   total: number;
// }

// interface StudyTrendProps {
//   data: DataProps[];
// }

export const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className={style.customTooltip}>
        <div>{`Total: ${payload[0].value}`}</div>
        <div>{`Month: ${label}`}</div>
      </div>
    );
  }

  return null;
};

const StudyTrend = ({ data }) => {
  const screenWidth = useWindowSize();
  const chartHeight = screenWidth[0] < 768 ? 220 : 320;
  return (
    // <div>
    <ResponsiveContainer width="100%" height={chartHeight}>
      <AreaChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="month" stroke="#fff" interval="preserveStartEnd" />
        {/* <YAxis /> */}
        <Tooltip content={<CustomTooltip />} />
        <Area
          type="monotone"
          dataKey="total"
          stroke="#fff"
          fill="#fff"
          strokeWidth={3}
        />
      </AreaChart>
    </ResponsiveContainer>
    // </div>
  );
};

export default StudyTrend;
