import { useEffect, useReducer, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import libraryService from "../../../../service/library.service";
import { QUERY_KEY } from "../../../../utils/constants";
import useAppMutation from "../../../../utils/hooks/useAppMutation";
import useAppQuery from "../../../../utils/hooks/useAppQuery";

function useManageNote() {
  const [noteText, setNoteText] = useState("");
  const { noteId } = useParams();
  const initialState = {
    note: {},
    noteContent: "",
    noteTitle: "",
    readNote: true,
  };

  const [state, dispatch] = useReducer(
    (stateVal, value) => ({ ...stateVal, ...value }),
    initialState
  );
  const { note, noteContent, noteTitle } = state;

  const { data: savedNote = [], isLoading: isFetchingNote } = useAppQuery(
    QUERY_KEY.getNoteById,
    libraryService.getNoteById,
    {
      noteId,
    },
    {
      enabled: !!noteId,
    }
  );

  useEffect(() => {
    dispatch({
      noteContent: savedNote?.content,
      noteTitle: savedNote?.filename,
      note: savedNote,
    });
  }, [savedNote]);
  const { isLoading: isSaveing, mutateAsync: asycSaveNote } = useAppMutation(
    QUERY_KEY.updateNote,
    libraryService.updateNote
  );

  function saveNote() {
    asycSaveNote({
      content: noteContent,
      filename: noteTitle,
      noteId: note?._id?.$oid,
    })
      .then(() => {
        toast.success("Note saved successfully");
        dispatch({ readNote: true });
      })
      .catch(() => {});
  }

  return {
    state,
    noteText,
    isFetchingNote,
    isSaveing,
    setNoteText,
    dispatch,
    saveNote,
  };
}

export default useManageNote;
