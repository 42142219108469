import useAppQuery from "../../../../utils/hooks/useAppQuery";
import { QUERY_KEY } from "../../../../utils/constants";
import libraryService from "../../../../service/library.service";

function useDiscover() {
  const { data: allRecentUploads = [], isLoading: fetchingRecentUploads } =
    useAppQuery(
      QUERY_KEY.getRecentUploads,
      libraryService.getRecentUploads,
      {}
    );
  const { data: allTopRated = [], isLoading: fetchingTopRated } = useAppQuery(
    QUERY_KEY.getTopRated,
    libraryService.getTopRated,
    {}
  );
  return {
    allRecentUploads,
    fetchingRecentUploads,
    allTopRated,
    fetchingTopRated,
  };
}
export default useDiscover;
