import React, { useContext } from "react";
import ExamPrepStorage from "../../storage/ExamPrepStorage";
import { AiOutlineCloseCircle } from "react-icons/ai";
import style from "./AssessmentResult.module.css";

export const AssessmentResult = () => {
  const { updateStorage, assessmentModalToggle, title, questions, submission } = useContext(ExamPrepStorage);
  
  return (
    <>
      <div className={style.close}>
        <button
          onClick={() =>
            updateStorage({
              assessmentModalToggle: !assessmentModalToggle,
              modalPage: "AssessmentDetails",
            })
          }
        >
          <AiOutlineCloseCircle />
        </button>
      </div>
      <div className={style.content}>
        <div className={style.left}>
          <h3>Result Summary</h3>
          <p>{title}</p>
          <div className={style.topicsContainer}>
            <h5>Performance by topics</h5>
            <hr />
            <div className={style.topicsInnerContainer}>
              <ul className={style.topics}>
                {submission.performance_by_topics.map(topic => (
                  <li key={topic.topic_id}>
                    {topic.topic_name} <span>{topic.score}/{topic.total_questions}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className={style.right}>
          <div className={style.percentageScore}>
            <h1>
              {parseFloat(((submission.total_score/questions.length)*100).toFixed(1))}<span>%</span>
            </h1>
            <p>Passed</p>
          </div>
          <div className={style.totalPerformance}>
            <p>
              Total Questions : <span>{questions.length}</span>
            </p>
            <p className={style.correct}>
              Questions Answered Correctly : <span>{submission.total_correctly_answered}</span>
            </p>

            <p className={style.wrong}>
              Questions Answered Wrongly : <span>{submission.total_wrongly_answered}</span>
            </p>
            <p className={style.skipped}>
              Questions Skipped : <span>{submission.total_skipped_questions}</span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
