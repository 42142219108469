import React from "react";
import classes from "./ExamPrepDashboard.module.css";
import ApplicationFooter from "../../../components/ApplicationFooter";
import { useNavigate } from "react-router-dom";
import CourseTypeCard from "../../../components/CourseTypeCard";

const ExamPrepDashboard = () => {
  const navigate = useNavigate();
  return (
    <div>
      {" "}
      <div className={classes.pageRoot}>
        <section className={classes.courseBanner}>
          <article>
            <h2>Exam Preparation</h2>
            <p>Your path to mastering the material and acing your exams.</p>
          </article>
        </section>

        <div className={classes.cardWrapper}>
          <CourseTypeCard
            onClick={() => navigate("buckets")}
            title="Buckets"
            description={`Our buckets help students assess their readiness for upcoming examination by taking practice test.`}
          />
          <CourseTypeCard
            onClick={() => navigate("quick-review")}
            title="Quick Review"
            description={`Master every key aspect of a topic within 20mins using our flip-card revision pack.`}
          />
          {/* <CourseTypeCard
            onClick={() => navigate("performance-tracker")}
            title="Performance Tracker"
            description={`View your performance and areas of improvements as suggested by our algorithm.`}
          /> */}
        </div>

        <ApplicationFooter />
      </div>
    </div>
  );
};

export default ExamPrepDashboard;
