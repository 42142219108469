import { useParams, useNavigate } from "react-router-dom";
import style from "./EditQuestionBank.module.css";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Dropdown from "../../../../components/Dropdown";
import TextArea from "../../../../components/TextArea";
import QuestionUI from "./QuestionUI";
// import { booleanOption, questionLevel } from "../../../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  updateState,
  updateForm,
  addQuestion,
  removeQuestion,
  updateQuestion,
} from "../../../../redux/feature/questionBankSlice";
import { FullpageModal } from "../../../../components/Modal";
import {
  cdDecryption,
  cdEncryption,
  initCaps,
} from "../../../../utils/helpers";
import { DeleteIcon, EditIcon } from "../../../../assets/icons";
import ConfirmDialog from "../../../../components/ConfirmationDialogCD/ConfirmDialog";
import useCdReactQuery from "../../../../utils/hooks/useCdReactQuery";
import adminService from "../../../../service/admin.service";
import useAdminLibrary from "../../Library/hooks/useAdminLibrary";

const EditQuestionBank = () => {
  const { questionId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [questionModal, setQuestionModal] = useState({
    status: false,
    index: null,
  });
  const [dialog, setDialog] = useState({ status: false, index: null });

  const { queryEndpoint, error } = useCdReactQuery(null);
  const { getTopicsBySubject } = useAdminLibrary();

  const title = questionId === "0" ? "Add Question" : "Edit Question";

  const {
    topic,
    topics = [],
    difficulty,
    questionType,
    hasParagraph,
    userClass,
    subject,
    form,
    forceUpdate,
  } = useSelector((store) => store.questionBank);

  useEffect(() => {
    const fetchSubjects = async () => {
      const list = await getTopicsBySubject(subject.id);
      // TODO:: Cleanup needed from backend to return only name and id. But first check where it will affect
      const updatedList = list.map((item) => ({
        name: item.name,
        id: item._id.$oid,
      }));
      dispatch(updateState({ topics: updatedList }));
    };

    fetchSubjects();
    // eslint-disable-next-line
  }, []);

  // Fetch question info for editing everytime the url params changes
  useEffect(() => {
    if (questionId !== "0") {
      const fetch = async () => {
        const response = await queryEndpoint({
          endpointUrl: adminService.getQuestionById(questionId),
        });

        const qst = response?.question;

        const decryptedQuestions = cdDecryption(qst.questions);

        const obj = {
          topic: qst.topic,
          subject: qst.subject,
          difficulty: { id: qst.level, name: initCaps(qst.level.toString()) },
          questionType: {
            id: qst.is_practice,
            name: initCaps(qst.is_practice.toString()),
          },
          hasParagraph:
            decryptedQuestions.questionGroup.lenght > 1
              ? { id: true, name: "True" }
              : { id: false, name: "False" },
          form: {
            paragraph: decryptedQuestions.paragraph,
            questions: decryptedQuestions.questionGroup,
          },
          forceUpdate: !forceUpdate,
        };
        dispatch(updateState(obj));
      };

      fetch();
    }
    // eslint-disable-next-line
  }, [questionId]);

  useEffect(() => {
    // console.log("ForcedUpdate changed ", forceUpdate);
  }, [forceUpdate]);

  const submit = (data) => {
    const _question = {
      paragraph: form.paragraph,
      questionGroup: data,
    };
    const payload = {
      class_id: userClass.id,
      topic_id: topic.id,
      subject_id: subject.id,
      is_practice: questionType.id,
      level: difficulty.id,

      questions: cdEncryption(_question),
    };

    if (questionId !== "0") {
      payload["question_id"] = parseInt(questionId);
    }
    const addQuestion = async () => {
      await queryEndpoint({
        endpointUrl: adminService.addQuestion,
        httpMethod: questionId === "0" ? "POST" : "PATCH",
        body: payload,
      });

      if (error?.response || false) {
        toast.error(`${error.response.data.detail}`);
      } else {
        toast.success(`Question ${questionId === "0" ? "Added" : "Updated"}`);
        // update state.
        dispatch(
          updateState({
            forceUpdate: !forceUpdate,
            hasParagraph: { id: false, name: "False" },
            form: { paragraph: "", questions: [] },
          })
        );
        navigate("/admin/question-bank");
      }

      // getBooks();
    };
    addQuestion();
  };

  const saveQuestionDetails = (data) => {
    if (questionModal?.index !== null) {
      // Update record
      dispatch(updateQuestion({ index: questionModal.index, data: data }));
      setQuestionModal({ status: false, index: null });
    } else {
      // Save record
      dispatch(addQuestion(data));
    }
  };

  const deleteQuestionHandler = (index) => {
    setDialog({ status: true, index });
  };
  const editQuestionHandler = (index) => {
    setQuestionModal({ status: true, index });
  };

  return (
    <>
      <div className={style.root} key={forceUpdate}>
        <div className={style.frame}>
          <h4 className={style.title}>{title}</h4>
          <div className={style.form}>
            <div className={style.fluid}>
              <div className={style.formGroup}>
                <div className={style.label}>Difficulty</div>
                <Dropdown
                  options={[
                    {
                      name: "Easy",
                      id: "easy",
                    },
                    {
                      name: "Medium",
                      id: "medium",
                    },
                    {
                      name: "Hard",
                      id: "hard",
                    },
                  ]}
                  value={difficulty.name || "Select"}
                  onChange={(e) =>
                    dispatch(
                      updateState({ difficulty: { id: e.id, name: e.name } })
                    )
                  }
                  className={style.difficulty}
                />
              </div>
              <div className={style.formGroup}>
                <div className={style.label}>Practice Question</div>
                <Dropdown
                  options={[
                    { name: "False", id: false },
                    { name: "True", id: true },
                  ]}
                  value={questionType.name || "True"}
                  className={style.questionType}
                  onChange={(e) =>
                    dispatch(
                      updateState({ questionType: { id: e.id, name: e.name } })
                    )
                  }
                />
              </div>
              <div className={style.formGroup}>
                <div className={style.label}>Topic</div>
                <Dropdown
                  options={topics}
                  value={topic.name || "Select"}
                  className={style.topic}
                  onChange={(e) =>
                    dispatch(updateState({ topic: { id: e.id, name: e.name } }))
                  }
                />
              </div>
              <div className={style.formGroup}>
                <div className={style.label}>Has Paragraph</div>
                <Dropdown
                  options={[
                    { name: "False", id: false },
                    { name: "True", id: true },
                  ]}
                  value={hasParagraph.name || "Select"}
                  className={style.hasParagraph}
                  onChange={(e) =>
                    dispatch(
                      updateState({ hasParagraph: { id: e.id, name: e.name } })
                    )
                  }
                />
              </div>
            </div>
            {hasParagraph.name === "True" ? (
              <>
                <div className={style.fluid}>
                  <div className={style.label}>Paragraph</div>
                  <TextArea
                    onChange={(e) =>
                      dispatch(updateForm({ paragraph: e.target.value }))
                    }
                    rows={6}
                    name="paragraph"
                    value={""}
                    className={style.paragraph}
                  />
                </div>
                <div className={`${style.fluid} ${style.actionGroup}`}>
                  <div
                    className={style.btn}
                    onClick={() =>
                      setQuestionModal({ status: true, index: null })
                    }
                  >
                    ADD QUESTION
                  </div>
                </div>
                {/* table */}
                <div>
                  <h4>Questions</h4>
                  {(form?.questions || []).map((item, index) => (
                    <div key={index} className={style.card}>
                      <div className={style.actions}>
                        <span>
                          <EditIcon
                            size={20}
                            onClick={() => editQuestionHandler(index)}
                          />
                        </span>
                        <span>
                          <DeleteIcon
                            size={20}
                            onClick={() => deleteQuestionHandler(index)}
                          />
                        </span>
                      </div>
                      <span className={style.questionText}>
                        {item.question}
                      </span>
                      {item.options.map((option, key) => (
                        <div key={key} className={style.card}>
                          <span
                            className={`${style.label} ${
                              option.isChecked ? style.check : ""
                            }`}
                          >
                            {option.label}
                          </span>

                          {option.inputValue}
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
                <div className={`${style.fluid} ${style.actionGroup}`}>
                  <div
                    className={style.btn}
                    onClick={() => submit(form.questions)}
                  >
                    SAVE QUESTION
                  </div>
                </div>
              </>
            ) : (
              <QuestionUI
                classes={style.fullpage}
                submitForm={submit}
                questionDetails={form.questions[0]}
              />
            )}
          </div>
        </div>
      </div>
      {questionModal.status && (
        <FullpageModal
          toggleModal={() => setQuestionModal({ status: false, index: null })}
        >
          <QuestionUI
            hasParagraph={!!hasParagraph.name}
            returnFormData={saveQuestionDetails}
            questionDetails={form?.questions?.[questionModal.index] || {}}
          />
        </FullpageModal>
      )}
      {dialog.status && (
        <ConfirmDialog
          confirm={() => {
            dispatch(removeQuestion(dialog.index));
            setDialog({ status: false, index: null });
          }}
          cancel={() => setDialog({ status: false, index: null })}
          message="Click continue to DELETE question"
        />
      )}
    </>
  );
};
export default EditQuestionBank;
