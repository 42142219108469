import React, { useRef, useState, useEffect } from "react";
import StudyLayout from "../StudyLayout/StudyLayout";
import classes from "./AudioBook.module.css";
import Img from "../../../components/Img/Img";
import { AiFillForward, AiFillBackward } from "react-icons/ai";
import { BsPlayFill, BsPauseFill } from "react-icons/bs";
// import { WaveSurfer } from 'react-wavesurfer';
import WaveSurfer from "wavesurfer.js";
function AudioBook({ img }) {
  // const { courseDetails, isFetching } = useAudioBook();
  // const API = env.API_URL;
  const [playing, setPlaying] = useState(false);
  const [waver, setWaver] = useState(null);
  const audioEl = useRef();
  const el = useRef();
  const url =
    "https://api.twilio.com//2010-04-01/Accounts/AC25aa00521bfac6d667f13fec086072df/Recordings/RE6d44bc34911342ce03d6ad290b66580c.mp3";
  useEffect(() => {
    if (el.current) {
      let wavesurfer = WaveSurfer.create({
        barWidth: 3,
        barRadius: 3,
        barGap: 2,
        barMinHeight: 1,
        cursorWidth: 1,
        container: el.current,
        backend: "WebAudio",
        height: 80,
        progressColor: "#00FF00",
        responsive: true,
        waveColor: "#FF0000",
        cursorColor: "transparent",
      });

      wavesurfer.load(audioEl.current);
      setWaver(wavesurfer);
      return () => wavesurfer.destroy();
    }
  }, []);
  const handlePlay = () => {
    setPlaying(!playing);
    waver?.playPause();
  };
  return (
    <StudyLayout mediaType="audio">
      <div className={classes.audioBook}>
        {/* {isFetching ? (
          <SkeletonLoader className={classes.loader} />
        ) : ( */}
        <>
          <div className={classes.imgContainer}>
            <div className={classes.header}>
              <Img className={classes.imgBook} src={img} alt="book" />
              <p className={classes.title}>Title</p>
              <p>Author</p>
            </div>
          </div>

          <div className={classes.waveFormContainer}>
            <div className={classes.wave} ref={el}></div>
            <audio ref={audioEl} src={url} />
            <div>0.52</div>
          </div>
          <div className={classes.audioKeys}>
            <AiFillBackward className={classes.controlBtn} />
            <div className={classes.playButton} onClick={() => handlePlay()}>
              {!playing ? (
                <BsPlayFill className={classes.controlBtn} />
              ) : (
                <BsPauseFill className={classes.controlBtn} />
              )}
            </div>
            <AiFillForward className={classes.controlBtn} />
          </div>
        </>
        {/* )} */}
      </div>
    </StudyLayout>
  );
}

export default AudioBook;
