import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
};

export const progressLoaderSlice = createSlice({
  name: "splash-loader",
  initialState,
  reducers: {
    setIsloading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setIsloading } = progressLoaderSlice.actions;

export default progressLoaderSlice.reducer;
