import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  popupChild: null,
  open: false,
  // onClose: () => {},
  popupChildStyle: "",
};

export const doubleShapePopupSlice = createSlice({
  name: "doubleShape",
  initialState,
  reducers: {
    setPopupChild: (state, action) => {
      console.log(action, "is action");
      state.popupChild = action.payload;
    },
    showPopup: (state, action) => {
      return {
        ...state,
        open: action.payload.open,
        // onClose: action.payload.onClose || (() => {}),
      };
    },
    setChildStyle: (state, action) => {
      state.popupChildStyle = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setPopupChild, showPopup, setChildStyle } =
  doubleShapePopupSlice.actions;

export default doubleShapePopupSlice.reducer;
