import { useEffect, useState } from "react";
import libraryService from "../../../../service/library.service";
import useCdReactQuery from "../../../../utils/hooks/useCdReactQuery";

const useBookCatalog = () => {
  const { queryEndpoint, isLoading, error } = useCdReactQuery();
  const [books, setBooks] = useState([]);

  const params = {
    content_type: "library",
    limit: "100",
  };

  const fetchAllBooks = async () => {
    const res = await queryEndpoint({
      endpointUrl: libraryService.getBooks,
      params: params,
    });
    setBooks(res);
  };

  useEffect(() => {
    (async () => {
      await fetchAllBooks();
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchBooks = async (search_word) => {
    if (search_word) {
      const res = await queryEndpoint({
        endpointUrl: libraryService.searchBook,
        params: { q: search_word },
      });
      setBooks(res?.items);
    } else {
      await fetchAllBooks();
    }
  };

  return {
    isLoading,
    data: books,
    error,
    searchBooks,
  };
};

export default useBookCatalog;
