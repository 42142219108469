import classes from "./Table.module.css";

function Table({ headerClassName, th = [], rows = [] }) {
  return (
    <table className={classes.tableRoot}>
      <thead className={`${(classes.headerRow, headerClassName)}`}>
        <tr>
          {th.map((tableHead, index) => (
            <th key={index}>{tableHead}</th>
          ))}
        </tr>
      </thead>
      <tbody className={classes.tableBody}>
        {rows.map((row) => (
          <>
            <tr>
              {row.map((item, index) => (
                <td key={index}>{item}</td>
              ))}
            </tr>
            <tr className={classes.lineDivider} />
          </>
        ))}
      </tbody>
    </table>
  );
}

export default Table;
