import React from "react";
import { cashFormater } from "../../utils/helpers";

function CashViewer({ amount, isLoading }) {
  if (!amount && amount !== 0) return <span>-- --</span>;

  return (
    <span style={{ whiteSpace: "nowrap" }}>
      {isLoading ? "-- / --" : `₦ ${cashFormater(amount)}`}
    </span>
  );
}

export default CashViewer;
