import SkeletonLoader from "../../../components/SkeletonLoader";
import classes from "./CourseDetailsLoader.module.css";

function CourseDetailsLoader() {
  return (
    <div className={classes.pageRoot}>
      <SkeletonLoader className={classes.bannerLoader} />
      <SkeletonLoader className={classes.infoLoader} />

      <div>
        {[...new Array(3).values()].map((_, index) => (
          <SkeletonLoader className={classes.topicsLoader} />
        ))}
      </div>
    </div>
  );
}

export default CourseDetailsLoader;
