import classes from "./TermsAndConditions.module.css";

function TermsAndConditions() {
  return (
    <div className={classes.pageRoot}>
      <h3 className={classes.title}>Terms</h3>
      <div className={classes.termsBody}>
        <p>
          This Privacy Policy is meant to help you understand what information
          we collect, why we collect it, and how you can update, manage, export,
          and delete your information.
          <br />
          We build a range of services that help millions of people daily to
          explore and interact with the world in new ways. Our services include:
          <ul>
            <li>
              Google apps, sites, and devices, like Search, YouTube, and Google
              Home
            </li>
            <li>
              Platforms like the Chrome browser and Android operating system
            </li>
            <li>
              Products that are integrated into third-party apps and sites, like
              ads, analytics, and embedded Google Maps
            </li>
          </ul>
        </p>
        <p>
          You can use our services in a variety of ways to manage your privacy.
          For example, you can sign up for a Google Account if you want to
          create and manage content like emails and photos, or see more relevant
          search results. And you can use many Google services when you’re
          signed out or without creating an account at all, like searching on
          Google or watching YouTube videos. You can also choose to browse the
          web in a private mode, like Chrome Incognito mode. And across our
          services, you can adjust your privacy settings to control what we
          collect and how your information is used.
        </p>
        <p>
          To help explain things as clearly as possible, we’ve added examples,
          explanatory videos, and definitions for key terms. And if you have any
          questions about this Privacy Policy, you can
        </p>
        <h3 className={classes.title}>
          We want you to understand the types of information we collect as you
          use our services
        </h3>
        <p>
          We collect information to provide better services to all our users —
          from figuring out basic stuff like which language you speak, to more
          complex things like which ads you’ll find most useful, the people who
          matter most to you online, or which YouTube videos you might like. The
          information Google collects, and how that information is used, depends
          on how you use our services and how you manage your privacy controls.
        </p>
        <p>
          When you’re not signed in to a Google Account, we store the
          information we collect with unique identifiers tied to the browser,
          application, or device you’re using. This allows us to do things like
          maintain your preferences across browsing sessions, such as your
          preferred language or whether to show you more relevant search results
          or ads based on your activity.
        </p>
        <p>
          When you’re signed in, we also collect information that we store with
          your Google Account, which we treat as personal information.
        </p>
      </div>
    </div>
  );
}

export default TermsAndConditions;
