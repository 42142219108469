import React, { useContext } from "react";
import style from "./Controller.module.css";
import ExamPrepStorage from "../storage/ExamPrepStorage";
import { Review } from "./Review/Review";

export const Controller = () => {
  const { modalPage } = useContext(ExamPrepStorage);

  const UI = {
    Review: <Review />,
  };
  return (
    <div className={style.rootPage}>
      <div className={style.modal}>{UI[modalPage]}</div>
    </div>
  );
};
