import { useParams } from "react-router-dom";
import libraryService from "../../../../service/library.service";
import { QUERY_KEY } from "../../../../utils/constants";
import useAppQuery from "../../../../utils/hooks/useAppQuery";

function useCourseTopics() {
  const { topicId } = useParams();
  const { data: { data: books = [] } = {}, isLoading: loadingBooks } =
    useAppQuery(
      QUERY_KEY.getBookBySubCategory,
      libraryService.getBookBySubCategory,
      {
        topic_id: topicId,
      },
      {
        enabled: !!topicId,
      }
    );

  return {
    books,
    topicId,
    loadingBooks,
  };
}

export default useCourseTopics;
