import { useEffect, useState } from "react";
import Button from '../../components/Button/Button';
import style from "./EditLocation.module.css";
import CountryCode from "../../components/CountryStateData/CountryCode.json"
import useEditLocation from "./hooks/useEditLocation";
const COUNTRY_API = "https://pkgstore.datahub.io/core/world-cities/world-cities_json/data/5b3dd46ad10990bca47b04b4739a02ba/world-cities_json.json";
const EditLocation = ({onSuccess}) => {
  const {
    store,
    isLoading,
    dispatch,
    onSubmit,
  } = useEditLocation(onSuccess)

  const {
    country,
    country_code,
    state,
    city,
  } = store
  const [data, setData] = useState([]);
  const [countryCode, setCountryCode] = useState("");
  const [getState, setState] = useState([]);
  const [cities, setCities] = useState([]);
  useEffect(() => {
    fetch(
      `${COUNTRY_API}`
    )
      .then((response) => response.json())
      .then((data) => setData(data));
  }, []);
  const country_id = [...new Set(data.map((item) => item.country))];
  country_id.sort();
  const handleCountry = (e) => {
    let states = data.filter((state) => state.country === e.target.value);
    states = [...new Set(states.map((item) => item.subcountry))];
    states.sort();
    setState(states);
    let sortcode = CountryCode.find((item) => item.country === e.target.value);
    setCountryCode(sortcode.code)
    dispatch({ country: e.target.value, country_code: sortcode?.code})
  };

  const handleState = (e) => {
    const cities = data.filter((city) => city.subcountry === e.target.value);
    setCities(cities);
    dispatch({state: e.target.value })
  };

  const handleCity = (e) => {
    dispatch({city:e.target.value})
  };


  return (
    <div >
      <div className={style.header}>
        <h3 className={style.editBio}>Edit Location</h3>
        <hr />
        <p className={style.indicate}>Indicate required*</p>
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <div className={style.inputWrapper}>
          <select className={style.location} onChange={(e) => handleCountry(e)}>
            <option value={country} >Select Country</option>
            {country_id.map((items) => (
              <option key={items} value={items}>
                {items}
              </option>
            ))}
          </select>
        </div>
        <div className={style.inputWrapper}>
          <select className={style.location} onChange={(e) => handleState(e)}>
            <option value={state}>Select State</option>
            {getState.map((items) => (
              <option key={items} value={items}>
                {items}
              </option>
            ))}
          </select>
        </div>
        <div className={style.inputWrapper}>
          <select className={style.location} onChange={(e) => handleCity(e)}>
            <option value={city}>Select City</option>
            {cities.map((items) => (
              <option key={items.name}>{items.name}</option>
            ))}
          </select>
        </div>
        <div className={style.inputWrapper}>
          <div value={country_code} className={`${style.location} ${style.countryCode}`}>
            {countryCode || "Country Code"}

          </div>
        </div>
        <div className={style.saveWrapper}>
          <Button className={style.save}
            children="Save"
            type="submit"
            isLoading={isLoading}
            onClick={onSubmit}
          />
        </div>
      </form>

    </div>
  )
}

export default EditLocation
