import { useEffect, useRef, useState } from "react";
import style from "../Examprep/Examprep.module.css";
import classes from "./Curriculum.module.css";
import {
  DeleteIcon,
  EditIcon,
  FilterIcon,
  GridAddIcon,
  SubjectIcon,
} from "../../../assets/icons";
import Tooltip from "../../../components/Tooltip";
import useCdReactQuery from "../../../utils/hooks/useCdReactQuery";
import SkeletonLoader from "../../../components/SkeletonLoader";
import { FullpageModal, Loader } from "../../../components/Modal";
import { useSelector } from "react-redux";
import ClassSingleModal from "../QuestionBank/modal/ClassSingleModal";
import adminService from "../../../service/admin.service";
import { toast } from "react-toastify";
import { initCaps } from "../../../utils/helpers";
import AddCurriculum from "./forms/AddCurriculum";
import { TERMS } from "../../../utils/constants";
import Term from "./Term";
import AddTopic from "./forms/AddTopic";
import useCurriculum from "./hooks/useCurriculum";
import ConfirmDialog from "../../../components/ConfirmationDialogCD/ConfirmDialog";

const ACTIONS = {
  add_curriculum: "add-curriculum",
  show_term: "show-term",
  add_topic: "add-topic",
};

const Curriculum = () => {
  const [modal, setModal] = useState(false);
  const [curriculums, setCurriculums] = useState([]);
  const [contentModal, setContentModal] = useState({
    modal: false,
    action: ACTIONS.add_curriculum,
  });
  const [isDelete, setIsDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const [editData, setEditData] = useState(null);
  const searchRef = useRef("");
  const { error, queryEndpoint, isLoading } = useCdReactQuery();
  const { userClass } = useSelector((store) => store.questionBank);
  const { deleteCurriculum, saveCurriculum, saveTopicToCurriculumTerm } =
    useCurriculum();

  const searchHandler = async (searchItem) => {
    const res = await queryEndpoint({
      endpointUrl: adminService.curriculums(userClass?.id),
      params: { query: searchItem },
    });
    setCurriculums(res);
  };

  const deleteHandler = (curriculumId) => {
    setDeleteId(curriculumId);
    setIsDelete(true);
  };

  useEffect(() => {
    // show filter modal if not subject and user class is not set
    if (userClass.id === "") setModal(true);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (error) {
      const error_data = error?.response?.data;
      toast.error(`
        ${error_data?.error || error_data?.msg || error_data?.detail}
      `);
    }
  }, [error]);

  const fetch = async () => {
    const res = await queryEndpoint({
      endpointUrl: adminService.curriculums(userClass.id),
    });
    setCurriculums(res);
  };

  useEffect(() => {
    setCurriculums([]);
    fetch();
    // eslint-disable-next-line
  }, [userClass.id]);

  const renderToModal = () => {
    if (contentModal.action === ACTIONS.add_curriculum) {
      return <AddCurriculum handleSave={handleSave} editData={editData} />;
    } else if (contentModal.action === ACTIONS.show_term) {
      return (
        <Term
          curriculum={contentModal.curriculum_id}
          term={contentModal.term}
          addtopic={displayTopicForm}
        />
      );
    } else if (contentModal.action === ACTIONS.add_topic) {
      return (
        <AddTopic
          curriculum={contentModal.curriculum_id}
          term={contentModal.term}
          subject_id={contentModal.subject_id}
          addTopic={addTopic}
          topic={contentModal.topic}
        />
      );
    }
  };

  const displayTopicForm = (obj = null) => {
    setContentModal({ ...contentModal, action: ACTIONS.add_topic, topic: obj });
  };

  const addTopic = async (topic) => {
    const res = await saveTopicToCurriculumTerm(topic);
    if (res) {
      toast.success(`
        ${res}
      `);
      setContentModal({ ...contentModal, modal: false });
    }
  };

  const handleSave = async (curriculum, isEdit = false) => {
    const res = await saveCurriculum(curriculum, isEdit);
    const responseData = res?.curriculum || res?.data;
    if (responseData) {
      if (isEdit) {
        setCurriculums(
          curriculums.map((curr) => {
            if (curr.id === responseData?.id) {
              return responseData;
            }
            return curr;
          })
        );
      } else {
        setCurriculums([...curriculums, responseData]);
      }
      setContentModal({ ...contentModal, modal: false });
      toast.success(`
        ${res?.msg}
      `);
    }
  };

  const handleDelete = async (curriculum_id) => {
    const res = await deleteCurriculum(curriculum_id);
    if (res) {
      setCurriculums(curriculums.filter((_) => _.id !== curriculum_id));
      toast.success(`
        ${res}
      `);
    }
  };

  return (
    <div className={style.root}>
      <div className={`${style.row} ${style.actions}`}>
        <div className={style.searchContainer}>
          <input
            type="search"
            className={style.search}
            placeholder={`Search Curriculum`}
            onKeyUp={(e) => {
              if (e.key === "Enter") searchHandler(e.target.value);
            }}
            ref={searchRef}
          />
          <button
            className={style.searchBtn}
            onClick={() => searchHandler(searchRef?.current?.value)}
          >
            GO
          </button>
        </div>

        <Tooltip
          data="Filter"
          classes={style.tooltip}
          callbackFn={() => setModal(true)}
        >
          <FilterIcon size={22} />
        </Tooltip>

        <Tooltip
          data="Add"
          classes={style.tooltip}
          callbackFn={() =>
            setContentModal((prev) => ({
              ...prev,
              modal: !prev.modal,
              action: ACTIONS.add_curriculum,
            }))
          }
        >
          <GridAddIcon size={25} />
        </Tooltip>
      </div>
      <div className={style.row}>
        {isLoading ? (
          <div className={style.row}>
            {[...new Array(4).values()].map((_, index) => (
              <SkeletonLoader className={style.skeletonLogLoader} key={index} />
            ))}
          </div>
        ) : curriculums.length ? (
          curriculums.map((item, index) => (
            <div className={classes.questionCard} key={index}>
              <div className={``}>
                <div
                  className={`${classes.bucketcard_header_container} ${classes.row}`}
                >
                  <h5 className={classes.topic}>{item?.subject?.name}</h5>
                  <div className={``}>
                    <Tooltip
                      data="Edit Curriculum"
                      classes={style.tooltip}
                      callbackFn={() => {
                        setEditData(item);
                        setContentModal((prev) => ({
                          ...prev,
                          modal: !prev.modal,
                          action: ACTIONS.add_curriculum,
                        }));
                      }}
                    >
                      <EditIcon size={22} />
                    </Tooltip>
                    <Tooltip
                      data="Delete Curriculum"
                      classes={style.tooltip}
                      callbackFn={() => {
                        deleteHandler(item?.id);
                      }}
                    >
                      <DeleteIcon size={22} />
                    </Tooltip>
                  </div>
                </div>
                <div className={classes.sub_category}>
                  <SubjectIcon /> <span>{item?.sub_category?.name}</span>
                </div>
              </div>

              <div className={`${classes.bucket_info}`}>
                <div className={`${classes.row} ${classes.bucket_content}`}>
                  {item?.terms.map(
                    (term) =>
                      TERMS[term] && (
                        <span
                          className={`${classes.term} ${classes[TERMS[term]]}`}
                          onClick={() => {
                            setContentModal((prev) => ({
                              ...prev,
                              modal: !prev.modal,
                              action: ACTIONS.show_term,
                              term: term,
                              curriculum_id: item.id,
                              subject_id: item?.subject?.id,
                            }));
                          }}
                        >
                          {initCaps(TERMS[term])}
                        </span>
                      )
                  )}
                  {/* <DurationIcon /> {item?.duration}mins */}
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>No Curriculum associated to this class!</p>
        )}
      </div>
      {isLoading && <Loader />}
      {modal && <ClassSingleModal closeModal={() => setModal(false)} />}
      {contentModal.modal && (
        <FullpageModal
          toggleModal={() => {
            setEditData(null);
            setContentModal((prev) => ({ ...prev, modal: !prev.modal }));
          }}
          className={classes.previewSize}
        >
          <div className={classes.scrollWrapper}>{renderToModal()}</div>
        </FullpageModal>
      )}

      {isDelete && (
        <ConfirmDialog
          confirm={async () => {
            handleDelete(deleteId);
            // call delete action here
            setIsDelete(false);
          }}
          cancel={() => setIsDelete(false)}
          message={"Click continue to DELETE this curriculum"}
        />
      )}
    </div>
  );
};

export default Curriculum;
