import classes from "./Popup.module.css";
import { MdOutlineCancel } from "react-icons/md";

const Popup = ({
  ispopupOpen,
  popupChild,
  showPopup,
  onClose,
  childComponentStyles,
  noCloseIcon,
}) => {
  function closePopup() {
    showPopup({ open: false });
    onClose?.();
  }
  return (
    <div
      onClick={closePopup}
      className={`${classes.popupRoot} ${
        !ispopupOpen ? classes.popupOpen : undefined
      }`}
    >
      <div
        onClick={(event) => event.stopPropagation()}
        className={`${classes.childComponent} ${childComponentStyles}`}
      >
        {!noCloseIcon && (
          <div className={classes.cancelBtnWrapper}>
            <button onClick={closePopup} className={classes.cancelButton}>
              <MdOutlineCancel className={classes.cancelIcon} />
            </button>
          </div>
        )}
        <div className={classes.container}>{popupChild}</div>
      </div>
    </div>
  );
};

export default Popup;
