import React from "react";
import ReactQuill from "react-quill";

function BlockEditor({ className, value, onChange, ...props }) {
  return (
    <div className={className}>
      <ReactQuill
        style={{ height: "80%" }}
        theme="snow"
        value={value}
        onChange={onChange}
        {...props}
      />
    </div>
  );
}

export default BlockEditor;
