import { useNavigate } from "react-router-dom";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import Button from "../../../components/Button/Button";
import SkeletonLoader from "../../../components/SkeletonLoader/SkeletonLoader";
import TopCard from "../../../components/TopCard/TopCard";
import classes from "./MyCourse.module.css";
import useMyCourse from "./hooks/useMyCourse";

function MyCourse() {
  const navigate = useNavigate();
  const {
    curriculumUploaded,
    curriculumToDelete,
    isFetching,
    deletUploadedCurriculum,
  } = useMyCourse();
  return (
    <div className={classes.pageBody}>
      <div className={classes.pageTopWrapper}>
        <div className={classes.gobackWrapper}>
          <button
            onClick={() => navigate("/courses")}
            className={classes.backButton}
          >
            <MdOutlineKeyboardBackspace className={classes.backIcon} />
          </button>
          <h3 className={classes.pageTitle}>My Courses</h3>
        </div>
        <Button
          onClick={() => navigate("/course/create")}
          className={classes.newCourseButton}
        >
          New Course
        </Button>
      </div>
      <div className={classes.mainBody}>
        <div>
          {isFetching ? (
            <div>
              {[...new Array(4).values()].map((_, index) => (
                <SkeletonLoader className={classes.skeletonLogLoader} />
              ))}
            </div>
          ) : (
            <div>
              {!curriculumUploaded?.length ? (
                <div className={classes.emptyHistory}>
                  <h3>No Course Uploaded.</h3>
                </div>
              ) : (
                curriculumUploaded.map((book) => (
                  <TopCard
                    coverImage={book?.book_cover_url}
                    bookTitle={book?.title}
                    subcategory={book?.sub_category}
                    status={book?.materials?.data?.length ? 100 : 50}
                    isDeleting={curriculumToDelete[book?._id?.$oid]}
                    onDelete={() => {
                      deletUploadedCurriculum(book);
                    }}
                  />
                ))
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default MyCourse;
