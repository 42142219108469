import { useQuery } from "@tanstack/react-query";

function useAppQuery(queryKeys = [], queryFn, params = {}, options) {
  const normalizedParams = Object.keys(params).reduce((result, key) => {
    if (!!params[key]) result[key] = params[key];
    return result;
  }, {});

  return useQuery({
    queryKey: [options, normalizedParams, ...queryKeys],
    queryFn: async () => queryFn(normalizedParams),
    options,
  });
}

export default useAppQuery;
