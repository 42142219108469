import React, { useContext, useEffect, useState } from "react";
import style from "./Assessment.module.css";
import ExamPrepStorage from "../../storage/ExamPrepStorage";
import Timer from "../../../../components/TImer/Timer";
import { FaLongArrowAltLeft, FaLongArrowAltRight } from "react-icons/fa";
import { BsQuestion } from "react-icons/bs";
import { CgMenuGridR } from "react-icons/cg";
import ImageView from "./QuestionImageView/ImageView";
// eslint-disable-next-line
import ParagraphView from "./QuestionParagraphView/ParagraphView";
import TextView from "./QuestionTextView/TextView";
import QuestionNavigator from "../../../../components/QuestionNavigator/QuestionNavigator";
import useAssessment from "../hooks/useAssessment";
import { cdDecryption, fastStringSorting } from "../../../../utils/helpers";

export const OptionView = ({
  label,
  value,
  selected,
  selectFn = () => {},
  showLabel = true,
  isShortAnswer = false,
  isSingleOption = true,
}) => {
  // check if label was selected prior to loading
  const isSelected = selected?.includes(label);

  const handleSelection = () => {
    let tempSelection = selected || "";
    if (isSingleOption) {
      selectFn(label);
      return;
    } else {
      if (isSelected) {
        tempSelection = tempSelection.replace(label, "");
      } else {
        tempSelection += label;
      }
    }

    selectFn(fastStringSorting(tempSelection));
  };

  return (
    <div
      className={`${style.option} ${isSelected ? style.selected : ""}`}
      onClick={handleSelection}
    >
      {showLabel && <div className={style.optionLabel}>{label}</div>}
      <div className={style.optionValue}>{value}</div>
    </div>
  );
};

export const Assessment = () => {
  const {
    updateStorage,
    duration,
    title,
    subject,
    questions = [],
    preview_data = [],
    _id,
    isPreview = false,
  } = useContext(ExamPrepStorage);

  const { submitAssessment } = useAssessment();

  const [questionNavigatorOpen, setQuestionNavigatorOpen] = useState(false);

  const [questionList, setQuestionList] = useState([]);
  const [answers, setAnswers] = useState({});
  const [userAnswers, setUserAnswers] = useState({});
  const [navigationStatus, setNavigationStatus] = useState([]);
  const [questionNumber, setQuestionNumber] = useState(0);
  // use navigatorClicked to re-render navigationStatus -- It uses question number to know when changes occured
  const [navigatorClicked, setNavigatorClicked] = useState({
    previous: 0,
    current: 0,
  });

  // Init setup - Extract questions, answers and navigation state
  useEffect(() => {
    let counter = 0;
    const answers = {}; //TODO:: In v2, allow each question to have thier separate marks
    const navigationState = [];

    const qst = questions.flatMap((item, index) => {
      const { topic, questions } = item;

      const { questionGroup, paragraph } = cdDecryption(questions);

      if (questionGroup.length === 1) {
        counter += 1;
        const item = questionGroup[0];
        answers[counter] = { answer: item.answer, topic };
        navigationState.push({ number: counter, status: "pending" });
        return {
          questionNumber: counter,
          paragraph: "",
          ...item,
        };
      }
      // extract sub questions
      else {
        return questionGroup.map((item, i) => {
          counter += 1;
          answers[counter] = { answer: item.answer, topic };
          navigationState.push({ number: counter, status: "pending" });
          return {
            questionNumber: counter,
            paragraph: paragraph,
            ...item,
          };
        });
      }
    });

    setQuestionList(qst);
    setAnswers(answers);
    setNavigationStatus(navigationState);
    // eslint-disable-next-line
  }, []);

  // update navigation when question number changes from navigation

  useEffect(() => {
    if (
      navigatorClicked.previous !== navigatorClicked.current &&
      !!userAnswers[navigatorClicked.previous]
    ) {
      markAnsweredOrSkipped(navigatorClicked.previous);
    }
    // eslint-disable-next-line
  }, [navigatorClicked]);

  // Toggle question navigator
  const questionNavigatorToggle = () => {
    setQuestionNavigatorOpen((prev) => !prev);
  };

  // Load question
  const renderQuestion = () => {
    const {
      imageUrl = "",
      question = "",
      paragraph = "",
    } = questionList[questionNumber] || {};
    if (!!imageUrl) {
      return <ImageView imageUrl={imageUrl} question={question} />;
    } else {
      return !!paragraph ? (
        <ParagraphView paragraph={paragraph} question={question} />
      ) : (
        <TextView question={question} />
      );
    }
  };

  // Load options
  const renderOptions = () => {
    // TODO:: Plan for questions without options to select from but just having input or text area field.

    const { options, answer } = questionList[questionNumber] || [];

    const answerSelected = (selectedOption) => {
      setUserAnswers((prev) => ({
        ...prev,
        [questionNumber]: selectedOption,
      }));
    };

    return (options || []).map((option, i) => (
      <OptionView
        key={i}
        label={option?.label}
        showLabel={false}
        value={option?.inputValue}
        selected={userAnswers[questionNumber]}
        selectFn={answerSelected}
        isSingleOption={answer?.length === 1}
      />
    ));
  };

  // Navigator indication
  const markAnsweredOrSkipped = (forcedQuestionNumber = undefined) => {
    const qstNumber = !!forcedQuestionNumber
      ? forcedQuestionNumber
      : questionNumber;
    setNavigationStatus((prev) =>
      prev.map((item) => {
        const questionIndex = item.number - 1;
        if (item.status !== "review" && questionIndex === qstNumber) {
          if (!!userAnswers[qstNumber]) {
            return { ...item, status: "answered" };
          } else return { ...item, status: "skipped" };
        }
        return item;
      })
    );
  };

  // Question navigator
  const questionNavHandler = (action) => {
    if (action === "next") {
      setQuestionNumber((prev) => {
        if (prev < questionList.length - 1) return prev + 1;
      });

      markAnsweredOrSkipped();
    } else if (action === "review") {
      setNavigationStatus((prev) =>
        prev.map((item) => {
          if (
            item.number - 1 === questionNumber &&
            userAnswers[questionNumber]
          ) {
            // if the question has been marked as reviewed before, update status to answered
            if (item.status === "review")
              return { ...item, status: "answered" };
            return { ...item, status: "review" };
          }
          return item;
        })
      );
    } else {
      setQuestionNumber((prev) => {
        if (prev > 0) return prev - 1;
      });
      markAnsweredOrSkipped();
    }
  };

  const handleSubmit = () => {
    let totalCorrectAns = 0;
    let totalWrongAns = 0;
    let totalScore = 0;
    // use object because of its read speed of O(1) time complexity
    const performance = {}; //topicID:{score,topic_id,topic_name,total_questions}

    for (let i = 0; i < questionList.length; i++) {
      const performanceKey = answers[i + 1].topic.id;
      let _score = 0;

      if (!!userAnswers[i]) {
        if (answers[i + 1].answer === userAnswers[i]) {
          totalScore += 1;
          _score = 1;
          totalCorrectAns += 1;
        } else {
          totalWrongAns += 1;
        }
      }
      if (!performance[performanceKey]) {
        // Initialize if it doesn't exist
        performance[performanceKey] = {
          score: 0,
          topic_id: answers[i + 1].topic.id,
          topic_name: answers[i + 1].topic.name,
          total_questions: 0,
        };
      }

      performance[performanceKey].score += _score;
      performance[performanceKey].total_questions += 1;
    }

    const totalSkippedAns =
      questionList.length - (totalCorrectAns + totalWrongAns);

    // convert object to array
    const performanceArray = Object.keys(performance).map((key) => ({
      ...performance[key],
    }));

    const submission = {
      bucket_id: _id,
      performance_by_topics: [...performanceArray],
      total_correctly_answered: totalCorrectAns,
      total_score: totalScore,
      total_skipped_questions: totalSkippedAns,
      total_wrongly_answered: totalWrongAns,
    };

    submitAssessment(submission);

    updateStorage({
      modalPage: "AssessmentResult",
      submission,
    });
  };

  const handleClose = () => {
    updateStorage({
      assessmentModalToggle: false,
    });
  };

  return (
    <div className={style.assessmentPage}>
      <div className={style.assessmentQuestions}>
      <div className={`${style.optionHeader} ${style.mobile_options}`}>
          <span onClick={questionNavigatorToggle}>
            <CgMenuGridR />
          </span>
          <Timer
            time={duration}
            callbackFn={() =>
              updateStorage({
                modalPage: "AssessmentResult",
              })
            }
          />
        </div>
        <div className={style.bucket}>
          <div>
            <p>{title}</p>
            <p className={style.label}>{subject.name}</p>
          </div>
          <span className={style.questionCount}>
            Question {questionNumber + 1}/{questionList.length}
          </span>
        </div>
        <div className={style.question_container}>{renderQuestion()}</div>
      </div>
      <div className={style.assessmentOptions}>
        <div className={style.optionHeader}>
          <span onClick={questionNavigatorToggle}>
            <CgMenuGridR />
          </span>
          <Timer
            time={duration}
            callbackFn={() =>
              updateStorage({
                modalPage: "AssessmentResult",
              })
            }
          />
        </div>
        <div className={style.optionView}>{renderOptions()}</div>
        {questionNavigatorOpen && (
          <QuestionNavigator
            data={navigationStatus}
            toggleQuestionNavigation={questionNavigatorToggle}
            callbackFn={(n) => {
              setQuestionNumber(n);
              setNavigatorClicked((prev) => ({
                previous: prev.current,
                current: n,
              }));
            }}
          />
        )}

        <div className={style.footer}>
          <button onClick={() => questionNavHandler("review")}>
            <BsQuestion />
          </button>
          {questionNumber > 0 && (
            <button onClick={() => questionNavHandler("previous")}>
              <FaLongArrowAltLeft />
            </button>
          )}
          {questionNumber < questionList.length - 1 ? (
            <button onClick={() => questionNavHandler("next")}>
              <FaLongArrowAltRight />
            </button>
          ) : (
            !preview_data.length && (
              <button onClick={handleSubmit} className={style.submit}>
                Submit
              </button>
            )
          )}
          {isPreview && (
            <button onClick={handleClose} className={style.submit}>
              Close
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
