// import React, { useEffect, useState } from "react";
// import style from "./FlipCard.module.css";
// import { FaLongArrowAltLeft, FaLongArrowAltRight } from "react-icons/fa";

// function FlipCard({ answer, toggleState = false, callback = () => {} }) {
//   const [isFlipped, setIsFlipped] = useState(false);

//   useEffect(() => {
//     setIsFlipped(false);
//   }, [toggleState]);

//   const handleFlip = () => {
//     setIsFlipped(!isFlipped);
//     callback();
//   };

//   console.log("toggleState in FlipCard:", toggleState);

//   return (
//     <div className={`${style.flipCard} ${isFlipped ? style.flipped : ""}`}>
//       <div className={style.flipCardInner}>
//         <div className={style.flipCardFront}>
//           <button onClick={handleFlip}>
//             SHOW ANSWER <FaLongArrowAltRight />
//           </button>
//         </div>
//         {isFlipped && (
//           <div className={style.flipCardBack}>
//             <div className={style.answer}>{answer}</div>
//             <button onClick={handleFlip}>
//               <FaLongArrowAltLeft /> HIDE ANSWER
//             </button>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// }

// export default FlipCard;

import React, { useEffect, useState } from "react";
import style from "./FlipCard.module.css";
import { FaLongArrowAltLeft, FaLongArrowAltRight } from "react-icons/fa";

function FlipCard({
  answer,
  toggleState = false,
  toggleStateMobile = false,
  callback = () => {},
}) {
  const [isFlipped, setIsFlipped] = useState(false);

  useEffect(() => {
    toggleStateMobile ? setIsFlipped(true) : setIsFlipped(false);
    // eslint-disable-next-line
  }, [toggleState]);

  // This always ensures MathJax is loaded when a new page is generated
  useEffect(() => {
    if (typeof window?.MathJax !== "undefined") {
      window.MathJax.typesetClear();
      window.MathJax.typeset();
    }
  });

  const handleFlip = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <div className={`${style.flipCard} ${isFlipped ? style.flipped : ""}`}>
      <div className={style.flipCardInner}>
        <div className={style.flipCardFront}>
          <button onClick={handleFlip}>
            SHOW ANSWER <FaLongArrowAltRight />
          </button>
        </div>
        {isFlipped && (
          <div className={style.flipCardBack}>
            <div className={style.answer}>{answer}</div>
            <button
              onClick={() => {
                handleFlip();
                callback();
              }}
            >
              <FaLongArrowAltLeft /> HIDE ANSWER
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default FlipCard;
