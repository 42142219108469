import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import style from "./SubjectsList.module.css";
import {
  BackIcon,
  DeleteIcon,
  EditIcon,
  GridAddIcon,
  SearchIcon,
  ViewIcon,
} from "../../../../assets/icons";
import { toast } from "react-toastify";
import useAdminSubject from "../hooks/useAdminSubject";
import useAdminLibrary from "../../Library/hooks/useAdminLibrary";
import { FullpageModal } from "../../../../components/Modal";
import InputField from "../../../../components/InputField";
import Dropdown from "../../../../components/Dropdown";

const INITIAL_DATA = { name: "", subcategory_id: "", subcategory_name: "" };

const SubjectList = () => {
  const { createSubject, deleteSubject, editSubject } = useAdminSubject();
  const { getClasses, getSubjects, isLoading } = useAdminLibrary();
  const [subject, setSubject] = useState(INITIAL_DATA);
  const [subjects, setSubjects] = useState([]);
  const [classes, setClasses] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    (async () => {
      setSubjects(await getSubjects());
      setClasses(await getClasses());
    })();
    // eslint-disable-next-line
  }, []);

  const getClassName = (id) => {
    return [...classes].find((subcategory) => subcategory.id === id);
  };

  const updateSubject = async () => {
    const postData = { name: subject.name, subject_id: subject.id };
    const resp = await editSubject(postData);
    if (resp.subject) {
      setSubjects((curr) =>
        curr.map((data) => {
          if (data.id === resp.subject.id) {
            return { ...data, name: resp.subject.name };
          }
          return data;
        })
      );
      toggleModal();
      toast.success(`Subject renamed to ${resp.subject.name} successfully`);
    } else {
      toast.error("Error updating subject");
    }
  };

  const handleDelete = async (id) => {
    const resp = await deleteSubject(id);
    if (resp.subject) {
      toast.success(resp.subject);
      setSubjects((curr) => curr.filter((subject) => subject.id !== id));
    } else {
      toast.error(resp.error);
    }
  };

  const saveSubject = async () => {
    const postData = {
      name: subject.name,
      subcategory_id: subject.subcategory_id,
    };
    const resp = await createSubject(postData);
    console.log(resp);
    if (resp.subject) {
      setSubjects([...subjects, resp.subject]);
      toast.success("Subject created successfully");
      setSubject(INITIAL_DATA);
      setTimeout(() => {
        toggleModal();
      }, 1000);
    } else {
      toast.success("Error creating subject");
    }
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <div className={style.root}>
      <div className={style.bookWrapper}>
        <div className={style.header}>
          <span>
            <Link to="/admin/settings/">
              <BackIcon />
              &nbsp; Subject
            </Link>
          </span>
          <div className={style.right}>
            <i className={style.searchIcon}>
              <SearchIcon />
            </i>
            <i>
              <GridAddIcon
                title="Add subject"
                onClick={() => {
                  setIsEdit(false);
                  setSubject(INITIAL_DATA);
                  toggleModal();
                }}
              />
            </i>
          </div>
        </div>
        <div className={style.scrollWrapper}>
          <table className={style.bookList}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Class</th>
                <th>Topic count</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody className={style.items}>
              {isLoading ? (
                "Loading all subjects"
              ) : subjects && subjects.length > 0 ? (
                subjects?.map((item, index) => (
                  <tr key={index}>
                    <td>{item.name}</td>
                    <td>{getClassName(item.subcategory_id)?.name}</td>
                    <td>
                      <Link
                        to={`/admin/subjects/${item.id}`}
                        style={{ color: "#3B43D0" }}
                      >
                        {item.topics.length} topic
                        {item.topics.length ? "s" : ""}
                      </Link>
                    </td>
                    <td className={style.actions}>
                      <i>
                        <ViewIcon />
                      </i>{" "}
                      <i
                        onClick={() => {
                          setSubject(item);
                          setIsEdit(true);
                          toggleModal();
                        }}
                      >
                        <EditIcon />
                      </i>{" "}
                      <i>
                        <DeleteIcon onClick={() => handleDelete(item.id)} />
                      </i>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colspan="6">No record found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {modalOpen && (
        <FullpageModal toggleModal={toggleModal} className={style.previewSize}>
          <div className={style.scrollWrapper}>
            <p className={style.title}>Subject Form</p>
            <div className={style.fluid}>
              <div className={style.formGroup}>
                <div className={style.label}>Name</div>
                <InputField
                  className={style.title}
                  value={subject.name}
                  onChange={(e) =>
                    setSubject({ ...subject, name: e.target.value })
                  }
                />
              </div>
              {!isEdit && (
                <div className={style.formGroup}>
                  <div className={style.label}>Class</div>
                  <Dropdown
                    options={classes}
                    value={subject.subcategory_name || "Select"}
                    className={style.bookType}
                    onChange={(e) =>
                      setSubject({
                        ...subject,
                        subcategory_id: e.id,
                        subcategory_name: e.name,
                      })
                    }
                  />
                </div>
              )}
              <div>
                {isEdit ? (
                  <button className={style.btn} onClick={updateSubject}>
                    Edit
                  </button>
                ) : (
                  <button className={style.btn} onClick={saveSubject}>
                    Save
                  </button>
                )}
              </div>
            </div>
          </div>
        </FullpageModal>
      )}
    </div>
  );
};

export default SubjectList;
