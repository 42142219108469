import env from "../env";

const API = env.API_URL;

const adminService = {
  createBook: `${API}/books/create`,
  getBooks: `${API}/books/user_books`,
  getBook: (bookId) => `${API}/books/${bookId}`, // Use same path for delete
  getClasses: `${API}/categories/subcategories`,
  getSubjects: `${API}/subjects`,
  // updateBookReadProgress: `${API}/books/read-progress`,
  updateBookReadProgress: `${API}/books/read-progress`,
  getReadProgress: (bookId) => `${API}/books/read-progress/${bookId}`,
  addQuestion: `${API}/questions`,
  getQuestionById: (questionId) => `${API}/questions/${questionId}`,
  getFilteredQuestion: `${API}/questions/filter`,
  deleteQuestion: (questionId) => `${API}/questions/${questionId}/delete`,
  getBuckets: `${API}/buckets`,
  getBucketByID: `${API}/buckets/bucket`,
  createBucket: `${API}/buckets/create_bucket`,
  updateBucket: `${API}/buckets/update_bucket`,
  courseReview: `${API}/course-reviews`,
  getTopicsBySubjectId: (subjectId) => `${API}/subject/${subjectId}/topics`,
  getReviewById: (reviewId) =>
    `${API}/course-reviews/review?review_id=${reviewId}`,
  reviewAddQuestion: `${API}/course-reviews`,
  deleteReview: (review_id) =>
    `${API}/course-reviews/review?review_id=${review_id}`,

  getReviewQuestion: `${API}/course-reviews/questions`,
  publishReview: `${API}/course-reviews/publish`,
  curriculums: (id) => `${API}/curriculum/${id}`,
  topicsBySubjectID: (id) => `${API}/subject/${id}/topics`,
  curriculum: `${API}/curriculum`,
  curriculumTopic: `${API}/curriculum/topic`,
  curriculumSemesterTopics: `${API}/curriculum/topics`,
  deleteCurriculumTopic: (id) => `${API}/curriculum/topic/${id}`,
  getMostViewBooks: `${API}/dashboard/most_viewed_books`,
  getRenewalList: `${API}/dashboard/renewal_list`,
  getStudentStats: `${API}/dashboard/student_stat`,
  getRevenueStats: `${API}/dashboard/revenue_stat`,
  getBookReadSoldStats: `${API}/dashboard/books_read_sold_stat`,
  dashboardStudentList: `${API}/dashboard/students`,
  dashboardRevenueList: `${API}/dashboard/revenue`,
  dashbardBookStatsList: `${API}/dashboard/books_sold`,
  dashboardMostViewedBookList: `${API}/dashboard/most_viewed_books`,
  dashboardRenewalList: `${API}/dashboard/renewal_list`,
  downloadStudentList: `${API}/dashboard/download_students`,
  downloadRevenue: `${API}/dashboard/download_revenue`,
  downloadMostViewedBook: `${API}/dashboard/most_viewed_books_download`,
  downloadRenewalList: `${API}/dashboard/renewal_list_download`,
  downloadBookRead: `${API}/dashboard/books_read_download`,
  bookSoldList: `${API}/dashboard/books_sold`,
  bookReadList: `${API}/dashboard/books_read`,
  downloadBookSold: `${API}/dashboard/books_sold_download`,
};

export default adminService;
