import React from "react";
import { BsCheckCircle } from "react-icons/bs";
import classes from "./ProductFeatureCard.module.css";

const ProductFeatureCard = ({ title, body }) => {
  return (
    <div className={classes.cardWrapper}>
      <div className={classes.icon}>
        <BsCheckCircle className={classes.featureIcon} />
      </div>
      <div>
        <h4 className={classes.cardTitle}>{title}</h4>
        <p className={classes.cardBody}>{body}</p>
      </div>
    </div>
  );
};

export default ProductFeatureCard;
