import { useEffect, useMemo, useState } from "react";
import classes from "./InputField.module.css";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";

const InputField = ({
  className,
  onChange,
  value,
  placeholder,
  endIcon,
  type,
  password,
  name,
  startIcon,
  required,
  ...props
}) => {
  const [data, setData] = useState("");

  useEffect(() => {
    setData(value);
  }, [value]);

  const [showPassword, setShowPasssword] = useState(false);
  const passwordToggle = useMemo(
    () => (
      <span onClick={() => setShowPasssword(!showPassword)}>
        {showPassword ? (
          <AiOutlineEye className={classes.passIcon} />
        ) : (
          <AiOutlineEyeInvisible className={classes.passIcon} />
        )}
      </span>
    ),
    [showPassword]
  );
  const onChangeHandler = (e) => {
    setData(e.target.value);
    onChange(e);
  };
  return (
    <div className={classes.inputWrapper}>
      {!!startIcon && <span className={classes.startIcon}>{startIcon}</span>}
      <input
        type={showPassword ? "text" : type}
        className={`${classes.inputField} ${className}`}
        value={data}
        name={name}
        onChange={onChangeHandler}
        required={required}
        placeholder={placeholder}
        {...props}
        style={{
          paddingLeft: startIcon ? "27px" : "5px",
          paddingRight: endIcon || type === "password" ? "27px" : "5px",
        }}
      />
      {(type === "password" || endIcon) && (
        <span className={classes.passwordEndIcon}>
          {endIcon || passwordToggle}
        </span>
      )}
    </div>
  );
};

export default InputField;
