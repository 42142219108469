import { useState } from "react";
import { BsCheck2Circle } from "react-icons/bs";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import classes from "./BookCatalogCard.module.css";

function BookCatalogCard({
  subcategory,
  name,
  colorCode = "",
  bgColorCode = "",
  onSelectSubcategory,
}) {
  const [flipped, setFlipped] = useState(false);
  function handleClick() {
    setFlipped(!flipped);
  }

  return (
    <div
      className={classes.cardRoot}
      style={{
        borderColor: colorCode,
      }}
    >
      <div
        className={`${classes.card} ${flipped ? classes.flipped : ""}`}
        style={{ backgroundColor: flipped ? "#303C47" : bgColorCode }}
      >
        {/* CARD FRONT */}
        <div className={classes.cardFront}>
          <h3 style={{ fontWeight: 700, color: colorCode }}>{name}</h3>
          <p className={classes.categoryDescription}>
            Comparing numbers, names of shapes, consonant and vowel sounds,
            sight words and more.
          </p>
          <div className={classes.lineDivider} />
          <p>
            {(subcategory || []).slice(0, 2).map((item, index) => (
              <div className={classes.subCategoryRow}>
                <BsCheck2Circle />
                <div
                  onClick={() => onSelectSubcategory?.(item)}
                  className={classes.subCategoryName}
                >
                  {item?.name}
                </div>
              </div>
            ))}
          </p>
          {subcategory?.length > 2 && (
            <div className={classes.frontCardBottom}>
              <button
                onClick={handleClick}
                className={classes.frontFlipBtn}
                style={{ color: colorCode }}
              >
                <span>[MORE ++]</span>
              </button>
            </div>
          )}
        </div>
        {/* CARD BACK */}
        <div className={classes.cardBack}>
          <div className={classes.cardBackItems}>
            {(subcategory || []).map((item, index) => (
              <div
                onClick={() => onSelectSubcategory?.(item)}
                className={classes.subCategoryWrap}
              >
                <BsCheck2Circle />
                <span>{item?.name}</span>
              </div>
            ))}
          </div>
          <div className={classes.backFlipWrapper}>
            <button className={classes.backFlip} onClick={handleClick}>
              <MdOutlineKeyboardBackspace />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BookCatalogCard;
