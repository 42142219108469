import { useState, useEffect } from "react";
import useCdReactQuery from "../../../../utils/hooks/useCdReactQuery";
import adminService from "../../../../service/admin.service";
import { toast } from "react-toastify";

const useCurriculumBasedCategories = () => {
  const { error, queryEndpoint, isLoading } = useCdReactQuery();
  const [curriculums, setCurriculums] = useState([]);
  const subcategory = JSON.parse(localStorage.getItem("subcategory")) || null;
  const [contentModal, setContentModal] = useState({
    modal: false,
    subjectId: "",
    classId: "",
  });
  const fetch = async () => {
    const res = await queryEndpoint({
      endpointUrl: adminService.curriculums(subcategory?.id),
    });
    setCurriculums(res);
  };

  useEffect(() => {
    if (subcategory?.id) {
      fetch();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (error) {
      const error_data = error?.response?.data;
      toast.error(`
        ${error_data.error || error_data.msg || error_data.detail}
      `);
    }
  }, [error]);

  const viewCurriculum = (curriculum, termId) => {
    setContentModal((prev) => ({
      ...prev,
      modal: !prev.modal,
      curriculum: curriculum,
      termId: termId,
    }));
  };

  const searchHandler = async (searchItem) => {
    const res = await queryEndpoint({
      endpointUrl: adminService.curriculums(subcategory?.id),
      params: { query: searchItem },
    });
    setCurriculums(res);
  };

  return {
    searchHandler,
    viewCurriculum,
    contentModal,
    curriculums,
    isLoading,
    setContentModal,
  };
};

export default useCurriculumBasedCategories;
