import React from "react";
import classes from "./BriefAbout.module.css";
// svg from https://uxwing.com/books-icon/
import { ReactComponent as TrainingIcon } from "../../assets/Svgs/trainingicon.svg";
import { ReactComponent as LibraryIcon } from "../../assets/Svgs/libraryicon.svg";
import { ReactComponent as ExamIcon } from "../../assets/Svgs/examicon.svg";
import { ReactComponent as ShopIcon } from "../../assets/Svgs/shopicon.svg";

const BriefAbout = ({ forwardedRef }) => {
  return (
    <div ref={forwardedRef} className={classes.rootComp}>
      <div className={`${classes.item} ${classes.item1}`}>
        <h2>About Us</h2>
        <p>
          Our online learning platform helps students prepare for exams, request
          help on subject topics, participate in quizzes and competitions, and
          offers certification training services to businesses, schools, and
          government agencies. We also, offer stationary market place for
          students to buy learning items at an affordable rate. Our mission is
          to make learning fun and accessible to everyone.
        </p>
        <p>
          Are you a student looking to ace your exams, or an organization
          seeking to train your employees, we have got you covered.
        </p>
      </div>
      <div className={`${classes.item} ${classes.item2}`}>
        <h2>
          Training <br /> Programmes
        </h2>
        <p>
          We offer a structured approach to developing knowledge, skills, and
          abilities of individuals or teams through learning objectives,
          training materials, and evaluation methods.
        </p>
        <TrainingIcon width="60px" height="60px" fill="#999" />
      </div>
      <div className={`${classes.item} ${classes.item3}`}>
        <h2>
          Library
          <br /> Content
        </h2>
        <p>
          Our platform contains a collection of digital resources, including
          books, novels, literatures, articles, and multimedia materials, that
          provide users with access to a wide range of information on various
          topics.
        </p>
        <LibraryIcon width="40px" height="40px" fill="#999" />
      </div>

      <div className={`${classes.item} ${classes.item4}`}>
        <h2>
          Exam <br />
          Prep{" "}
        </h2>
        <p>
          We have a wide range of study materials and resources designed to help
          individuals prepare for an exam by reviewing and practicing the
          relevant content skills, and tracking progress by topics with the help
          of our computer based testing application.
        </p>
        <ExamIcon width="40px" height="40px" fill="#999" />
      </div>
      <div className={`${classes.item} ${classes.item5}`}>
        <h2>
          eStationary <br />
          Shops
        </h2>
        <p>
          We provide online market place where variety of products, including
          stationary items, school wears and digital learning equipments for use
          in educational and professional settings can be gotten at a discounted
          rate. <strong>Coming soon!!</strong>
        </p>
        <ShopIcon width="40px" height="40px" fill="#999" />
      </div>
    </div>
  );
};

export default BriefAbout;
