import StudyLayout from "../StudyLayout";
import classes from "./VideoCourse.module.css";
import VideoPlayer from "../../../components/VideoPlayer";
import useStudyLayout from "../StudyLayout/hooks/useStudyLayout";

function VideoCourse() {
  const { courseDetails } = useStudyLayout();
  console.log(courseDetails);
  return (
    <StudyLayout mediaType="video">
      <div className={classes.viewUploadWrapper}>
        <VideoPlayer src={courseDetails?.video_url} type="video/mp4" />
      </div>
    </StudyLayout>
  );
}

export default VideoCourse;
