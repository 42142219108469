import classes from "./ReadNote.module.css";
import useManageNote from "./hooks/useReadNote";
import BlockEditor from "../../../components/BlockEditor";
import Button from "../../../components/Button";
import { AiOutlineSave } from "react-icons/ai";
import { FaRegEdit } from "react-icons/fa";
import SkeletonLoader from "../../../components/SkeletonLoader";
import { MdOutlineCancel } from "react-icons/md";
import InputField from "../../../components/InputField";
import { RxArrowLeft } from "react-icons/rx";
import { useNavigate } from "react-router-dom";

function ReadNote() {
  const navigate = useNavigate();
  const { state, isFetchingNote, isSaveing, saveNote, dispatch } =
    useManageNote();
  const { noteContent, readNote, noteTitle } = state;

  return (
    <div className={classes.manageNoteRoot}>
      <div>
        <button onClick={() => navigate(-1)} className={classes.backBtn}>
          <RxArrowLeft className={classes.backIcon} />
          <span>Back</span>
        </button>
      </div>
      {isFetchingNote ? (
        <SkeletonLoader className={classes.loader} />
      ) : (
        <div className={classes.editorWrapper}>
          <div className={classes.actionbuttonWrapper}>
            {!readNote && (
              <Button
                isLoading={isSaveing}
                onClick={saveNote}
                className={classes.saveNoteButton}
              >
                <AiOutlineSave />
              </Button>
            )}
            <button
              onClick={() => dispatch({ readNote: !readNote })}
              className={classes.actionBtn}
            >
              {readNote ? (
                <FaRegEdit />
              ) : (
                <MdOutlineCancel style={{ color: "#FC0000" }} />
              )}
            </button>
          </div>
          {readNote ? (
            <div>
              <h3>{noteTitle}</h3>
              <div dangerouslySetInnerHTML={{ __html: noteContent }} />
            </div>
          ) : (
            <div style={{ height: "100%", marginTop: 10 }}>
              <InputField
                value={noteTitle}
                onChange={({ target }) => dispatch({ noteTitle: target.value })}
                className={classes.noteTitleInput}
              />
              <BlockEditor
                value={noteContent}
                onChange={(e) => dispatch({ noteContent: e })}
                className={classes.blockTextEditor}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default ReadNote;
