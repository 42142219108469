import NewPasswordForm from "../../layouts/NewPasswordForm";
import useNewPassword from "./hooks/useNewPassword";
import classes from "./NewPassword.module.css";

function NewPassword() {
  const {
    state,
    isLoading,
    email,
    resetToken,
    dispatch,
    resetPassword,
    handlePasswordResend,
  } = useNewPassword();

  return (
    <div className={classes.pageRoot}>
      <NewPasswordForm
        email={email}
        resetToken={resetToken}
        state={state}
        dispatch={dispatch}
        onResend={handlePasswordResend}
        onClick={resetPassword}
        isLoading={isLoading}
      />
    </div>
  );
}

export default NewPassword;
