import "./Dropdown.css";
import React, { useState, useEffect, useRef } from "react";
import { MDBSpinner } from "mdb-react-ui-kit";

const Dropdown = ({
  options = [],
  value = "Select",
  onChange,
  isLoading,
  className,
}) => {
  const [defaultSelectText, setDefaultSelectText] = useState(value);
  const [showOptionList, setShowOptionList] = useState(false);
  const selectRef = useRef();

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (
        !selectRef.current.contains(e.target) ||
        e.target.classList.contains("custom-select-option") ||
        e.target.classList.contains("selected-text")
      ) {
        // setShowOptionList(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectRef]);

  useEffect(() => {
    setDefaultSelectText(value);
    // eslint-disable-next-line
  }, [options]);

  const handleListDisplay = () => {
    setShowOptionList((prevState) => !prevState);
  };

  const handleOptionClick = (e) => {
    setDefaultSelectText(e.target.getAttribute("data-name"));
    setShowOptionList(false);
  };

  useEffect(() => {
    /**
     * close when clicked on outside of element
     */
    function handleClickOutside(event) {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setShowOptionList(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectRef]);

  return (
    <div className={`custom-select-container ${className}`} ref={selectRef}>
      <div
        className={`selected-text ${
          showOptionList ? "active" : ""
        } ${className}`}
        onClick={handleListDisplay}
      >
        {defaultSelectText || value}
      </div>

      {showOptionList && (
        <ul onClick={(e) => e.stopPropagation()} className="select-options">
          {options.map((option, index) => (
            <li
              className="custom-select-option"
              data-name={option.name}
              key={index}
              onClick={(e) => {
                onChange?.(option);
                handleOptionClick(e);
              }}
            >
              {option.name}
            </li>
          ))}
          {isLoading && (
            <div className="loader-wrapper">
              <MDBSpinner className="spinner" role="status" />
            </div>
          )}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
