// Module
import { useState } from "react";

// Asset
import { defaultImage } from "../../../../assets/image";

// Icons
import { EditIcon, ViewIcon, DeleteIcon } from "../../../../assets/icons";

// Components
import { FullpageModal } from "../../../../components/Modal";
import Reader from "../../../../components/BookReader/Reader";
import Tooltip from "../../../../components/Tooltip";
import ConfirmDialog from "../../../../components/ConfirmationDialogCD/ConfirmDialog";

// Hooks
import useBookForm from "../hooks/useBookForm";

// Style
import style from "./BookList.module.css";
import SkeletonLoader from "../../../../components/SkeletonLoader";
import { useNavigate } from "react-router-dom";

const BookList = () => {
  const navigate = useNavigate();
  const {
    // editBookHandler,
    deleteBookHandler: deleteBook, // rename the deleteBookHandler to deleteBook for clarity when used in code
    books,
    isLoading,
  } = useBookForm();

  const [toggleModal, setToggleModal] = useState(false);
  const [dialogModal, setDialogModal] = useState({ bookId: "", modal: false });
  const [bookDetails, setBookDetails] = useState({});

  const viewBookHandler = (pages, title) => {
    setBookDetails((prev) => ({
      pages,
      title,
    }));
    setToggleModal((prev) => !prev);
  };

  const deleteBookHandler = (book_id) => {
    setDialogModal((prev) => ({ bookId: book_id, modal: !prev.modal }));
  };

  // console.log(books);

  return (
    <>
      <div className={style.bookWrapper}>
        <div className={style.scrollWrapper}>
          <table className={style.bookList}>
            <thead>
              <tr>
                <th style={{ width: "80px" }}>Cover</th>
                <th>Title</th>
                <th>Pages</th>
                <th>Class</th>
                <th>Subject</th>
                <th>Published</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody className={style.items}>
              {isLoading ? (
                <div className={style.loaderWrapper}>
                  {[...new Array(4).values()].map((_, index) => (
                    <SkeletonLoader
                      className={style.skeletonLogLoader}
                      key={index}
                    />
                  ))}
                </div>
              ) : books && books.length > 0 ? (
                books.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <img
                        src={item?.book_cover_url || defaultImage}
                        alt=""
                        className={style.bookCover}
                      />
                    </td>
                    <td>{item?.title || "---"}</td>
                    <td>{item.pages.length}</td>
                    <td>{item?.sub_category?.name || "---"}</td>
                    <td>{item?.subject?.name || "---"}</td>
                    <td>{item.is_published ? "Yes" : "No"}</td>
                    <td className={style.actions}>
                      <Tooltip data="View">
                        <i
                          onClick={() =>
                            viewBookHandler(item.pages, item.title)
                          }
                        >
                          <ViewIcon />
                        </i>
                      </Tooltip>{" "}
                      <Tooltip data="Edit">
                        <i
                          onClick={
                            () => navigate("/admin/edit-book/" + item._id)
                            // editBookHandler(item)
                          }
                        >
                          <EditIcon />
                        </i>
                      </Tooltip>{" "}
                      <Tooltip data="Delete">
                        <i onClick={() => deleteBookHandler(item._id)}>
                          <DeleteIcon />
                        </i>
                      </Tooltip>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6">No record found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {toggleModal && (
        <FullpageModal toggleModal={setToggleModal}>
          <Reader pages={bookDetails.pages} bookTitle={bookDetails.title} />
        </FullpageModal>
      )}
      {dialogModal.modal && (
        <ConfirmDialog
          confirm={() => {
            deleteBook(dialogModal.bookId);
            setDialogModal((prev) => ({ bookId: "", modal: false }));
          }}
          cancel={() =>
            setDialogModal((prev) => ({ ...prev, modal: !prev.modal }))
          }
          message="Click continue to DELETE book"
        />
      )}
    </>
  );
};
export default BookList;
