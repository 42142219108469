import React, { useContext, useState } from "react";
import style from "./PerformanceTracker.module.css";
import ApplicationFooter from "../../../components/ApplicationFooter/ApplicationFooter";

import {
  LineChart,
  Line,
  XAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import PerformanceBySubject from "./subject_performance";
import { Link } from "react-router-dom";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { PerformanceTrackerByTopics } from "./subject_performance";
import { RowLayout } from "../../Dashboard/layout/PageLayout";
import StrengthIndicator from "../../Dashboard/component/StrengthIndicator";
import Tags from "../../../components/Tags/Tags";
import usePerformanceTracker from "./hooks/usePerformanceTracker";
import ExamPrepStorage from "../storage/ExamPrepStorage";

function PerformanceTracker() {
  const { updateStorage } = useContext(ExamPrepStorage);
  const [toggleModal, setToggle] = useState(false);
  const {
    subjectStrength,
    subjectWeakness,
    performance_by_subject,
    performance_trends,
    recommended_materials,
  } = usePerformanceTracker();

  // TODO:: Always get the 6 months data. Not working yet but should be done
  const lineChartData = performance_trends
    ? performance_trends.map((item) => {
        const months = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        const monthIndex = parseInt(item.date.split("-")[1]);
        return { total: item.score, month: months[monthIndex - 1] };
      })
    : [
        {
          month: "Jan",
          total: 0,
        },
        {
          month: "Feb",
          total: 0,
        },
        {
          month: "Mar",
          total: 0,
        },
        {
          month: "Apr",
          total: 0,
        },
        {
          month: "May",
          total: 0,
        },
        {
          month: "Jun",
          total: 0,
        },
      ];

  const handleToggle = (item) => {
    updateStorage({
      ...item,
    });
    setToggle((prev) => !prev);
  };

  return (
    <>
      <div className={style.root}>
        <Link to="/examprep" className={style.navIcon}>
          <FaLongArrowAltLeft /> Performance Tracker
        </Link>
        <div className={style.page}>
          <div className={style.left}>
            <p className={style.sub_heading}>Strength</p>

            <div className={style.strengthContainer}>
              <StrengthIndicator
                data={subjectStrength}
                color={{
                  fontColor: "#000",
                  stroke: "#4f3bc4",
                  fill: "#4f3bc4",
                  gridColor: "#666666",
                }}
              />
            </div>
            <p className={style.sub_heading}>Weakness</p>
            <div className={style.strengthContainer}>
              <StrengthIndicator
                data={subjectWeakness}
                color={{
                  fontColor: "#000",
                  fontSize: "12px",
                  stroke: "#d72829",
                  fill: "#d72829",
                  gridColor: "#666666",
                }}
              />
            </div>
          </div>
          <div className={style.right}>
            <div className={style.recommendations}>
              <p className={style.sub_heading}>Recommended Materials</p>
              <div className={style.tags}>
                {recommended_materials ? (
                  recommended_materials.map((item, key) => (
                    <Tags
                      key={key}
                      name={item.subject}
                      callbackFn={() => handleToggle(item)}
                    />
                  ))
                ) : (
                  <Tags name="You have no recommendation." successText={true} />
                )}
              </div>
            </div>
            <div className={style.performanceWrapper}>
              {/* It should should be area graph i.e two line graph intertwined showing performance and books read*/}
              <p className={style.sub_heading}>Progress Trend</p>
              <div className={style.lineChart}>
                <ResponsiveContainer>
                  <LineChart data={lineChartData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      dataKey="month"
                      axisLine={false}
                      tickLine={false}
                      tick={{ fontSize: 12 }}
                      interval="preserveStartEnd"
                      stroke="#bebaff"
                    />
                    <Tooltip />
                    {/* <Line
                      type="monotone"
                      dataKey="total"
                      stroke="#bebaff"
                      dot={{
                        fill: "#ffffff",
                        stroke: "#fff",
                        strokeWidth: 2,
                      }}
                      fill="#000"
                      strokeWidth={3}
                    /> */}
                    <Line
                      type="monotone"
                      dataKey="total"
                      stroke="#bebaff"
                      dot={{
                        fill: "#ffffff",
                        stroke: "#fff",
                        strokeWidth: 2,
                      }}
                      fill="#000"
                      strokeWidth={3}
                    >
                      <LabelList dataKey="total" position="top" />
                    </Line>
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>
            <div className={style.performance_subject}>
              <PerformanceBySubject performance={performance_by_subject} />
            </div>
          </div>
        </div>

        <RowLayout>
          <ApplicationFooter />
        </RowLayout>
      </div>
      {toggleModal && (
        <div className={style.rootPageModal}>
          <div className={style.modal}>
            <PerformanceTrackerByTopics toggleModal={handleToggle} />
          </div>
        </div>
      )}
    </>
  );
}

export default PerformanceTracker;
