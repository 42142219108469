export const bookImg = require("./sample.png");
export const defaultImage = require("./png/default-placeholder.png");
export const studyImg = require("./jpeg/pexels-pixabay-301920.jpg");
export const textImg = require("./jpeg/pexels-ivan-bertolazzi-2681319.jpg");
export const phoneImg = require("./phone.png");
export const sampleLogo = require("./png/sampleImg.png");
export const googleImage = require("./png/googleImage.png");
export const arrowLeft = require("./png/arrowLeft.png");
export const submitImage = require("./png/submit.png");
export const unlockImage = require("./png/unLock.png");
export const facebookIcon = require("./png/facebook_icon.png");
export const googleIcon = require("./png/google_icon.png");
export const contactImg = require("./jpeg/pexels-liza-summer-6347901.jpg");
export const avatarImage = require("./png/avatar-image.png");
export const mobileBackground = require("./jpeg/mobile-background.jpg");
export const circleOrange = require("./png/circle_orange.jpeg");
export const masterCard = require("./png/master_card.png");
export const visaCard = require("./png/visa_card.jpeg");
export const defaultProfileImg = require("./png/default_profile_img.png");
export const projectManage = require("./How-To-Build-Your-Digital-Project-Manager-Resume-featured-image-800x800.webp");
export const tutorImg = require("./pexels-photo-220453.webp");
export const boyLearning = require("./jpeg/boy-learning.jpg");
export const readerIlustration = require("./readerIllustration.png");
export const bookCover = require("./books-cover.jpg");
export const thumbnailPlaceholder = require("./jpeg/placeholder.jpeg");
export const Magnify = require("./jpeg/pencil_magnifier.jpeg");
export const stripeLogo = require("./Svgs/stripeLogo.png");
export const BookmarkImg = require("./png/Bookmarks-amico.png");
export const Myshelf = require("./png/my-shelf.png");
export const studyIllustration = require("./jpeg/studyIllustration.jpeg");
export const studyIllustration2 = require("./jpeg/studyIllustration2.webp");
export const checkImage = require("./jpeg/check-imagejpg.jpg");
export const questionMark = require("./jpeg/questionMark.jpeg");
export const whiteBg = require("./jpeg/whiteBg.jpeg");
export const boyStudy = require("./png/boyReading.png");
export const cloud = require("./png/onlineCloud.png");
export const rightPointHand = require("./png/younger-man-pointing.png");
export const questionIllustration = require("./png/questionimage.png");
export const questionAndAnswer = require("./png/questionandanswer.png");
export const femaleHsndPoint = require("./png/female-hand-pointwd.png");
export const tranpezuim = require("./png/tranpezuim.png");
export const rightTranpezuim = require("./png/rightTranpezuim.png");
export const leftTranpezuim = require("./png/leftTranpezuim.png");
export const iconImage = require("./jpeg/icon-image.jpg");
export const womanTutor = require("./jpeg/woman-tutor.jpg");
export const manTutor = require("./png/man-tutor.png");
export const woman2Tutor = require("./png/woman2-tutor.png");
export const womanDemo = require("./jpeg/woman-demo.jpg");
export const monitorImage = require("./jpeg/monitor-image.jpg");
export const robotImage = require("./jpeg/robot2.webp");
export const robotPick = require("./jpeg/robot-pick.jpeg");
export const championCup = require("./png/champion-cup.png");
export const examprepBanner = require("./jpeg/exam-prep-banner.jpg");
export const darkTranspezuium = require("./webp/catalog_dark_banner.webp");
export const mockExamBanner = require("./jpeg/mock-section-banner.jpg");
export const OnlineExam = require("./png/online-exam.png");
export const loginBg = require("./png/login.png");
export const hourGlassImage = require("./png/hourglass.png")
export const topUpImage = require("./png/topup.png")
