import { useReducer } from "react";
import { toast } from "react-toastify";
import subscriptionService, {
  URL,
} from "../../../service/subscription.service";
import { QUERY_KEY } from "../../../utils/constants";
import useAppQuery from "../../../utils/hooks/useAppQuery";
import { useSelector } from "react-redux";
import env from "../../../env";
import useCdReactQuery from "../../../utils/hooks/useCdReactQuery";

function usePlanSubscription({ currentPlan, onSuccess, type }) {
  const initialState = {
    selectedPlan: {},
  };
  const [state, dispatch] = useReducer(
    (stateValue, value) => ({ ...stateValue, ...value }),
    initialState
  );
  const { selectedPlan } = state;

  const { user } = useSelector(({ currentUser }) => currentUser);

  const { data: { data: plans = [] } = {}, isLoading: fetchingPlans } =
    useAppQuery(QUERY_KEY.getAllPlans, subscriptionService.getAllPlans, {});

  const { queryEndpoint, isLoading: isMakingPayment } = useCdReactQuery();

  async function handsub() {
    try {
      const response = await queryEndpoint({
        endpointUrl: type === "upgrade" ? URL.upgradePlan : URL.changePlan,
        httpMethod: "PUT",
        body: {
          ...(type === "upgrade"
            ? {
                callback_url: `${env.APP_URL}/subscription?plan_id=${selectedPlan?.id}`,
              }
            : {}),
          currency: "NGN",
          email: user?.email,
          plan_id:
            type === "manage" ? currentPlan?.current_plan_id : selectedPlan?.id,
          ...(type === "manage" ? { updated_plan_id: selectedPlan?.id } : {}),
        },
      });

      if (response?.auth_url && type === "upgrade") {
        window.location.href = response?.auth_url;
        return;
      }
      onSuccess?.();
      toast.success("Updated successfully");
    } catch ({ msg, auth_url }) {
      if (auth_url && type === "upgrade") {
        window.location.href = auth_url;
        return;
      }
      toast.error(msg);
    }
    onSuccess?.();
  }
  return {
    state,
    fetchingPlans,
    isMakingPayment,
    plans,
    dispatch,
    handsub,
  };
}

export default usePlanSubscription;
