import style from "./MessageComposer.module.css";
import InputField from "../../components/InputField";
import TextArea from "../../components/TextArea";
import Dropdown from "../../components/Dropdown/Dropdown";
import { CloseIcon, SendIcon } from "../../assets/icons";
import { useEffect, useState } from "react";
import useCdReactQuery from "../../utils/hooks/useCdReactQuery";
import authApplication from "../../service/auth";
import { Loader } from "../../components/Modal";
import { getClassByID } from "../../utils/helpers";
import useAdminLibrary from "../Admin/Library/hooks/useAdminLibrary";
import Select from 'react-select'

const MessageComposer = ({ close = () => {}, composeMessage }) => {
  const [initForm, setInitForm] = useState({
    title : "",
    description : "",
    user_password : "",
    // recipients_id : [],
    // recipient_names : [],
    receivers : null,
  })
  const [showPassword, setShowPassword] = useState(false)
  const { queryEndpoint, isLoading } = useCdReactQuery();
  const { getClasses } = useAdminLibrary();
  const [classes, setClasses] = useState([])
  const [recipients, setRecipients] = useState([])
  const [currentClass, setCurrentClass] = useState("")
  const [sendToAll, setSendToAll] = useState(false)

  const subcategory = JSON.parse(localStorage.getItem("subcategory"));

  useEffect(() => {
    const fetch = async() => {
        const res = await getClasses()
        setClasses(res?.map(_ => _))
    }
    fetch()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    (async() => {
      let params = {
        account_type : "admin"
      }
      if(!subcategory?.id){
        params = {
          ...params, 
          account_type : "student", subcategory_id : currentClass?.id
        }
      }
      
      const res = await queryEndpoint({
          endpointUrl: authApplication.users,
          params : params
      });
      setRecipients(res?.data ? res.data.map(item => ({...item, value : item.name, label : item.name})) : [] )
    })()
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClass, subcategory.id])

  useEffect(() => {
    (async() => {

    })()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmit = () => {
    const data = initForm
    delete data.receivers
    composeMessage(data)
  }


  return (
    <div className={style.modal}>
      <div onClick={close} className={style.close}>
        <CloseIcon size={30} />
      </div>
      <h5 className={style.title}>Send Message</h5>
      <hr />

      <div className={style.scrollWrapper}>

        {!subcategory?.id && (
        <div className={style.row}>
          Class
          <Dropdown
            options={classes}
            value={getClassByID(classes, currentClass.id) || "Select Class"}
            onChange={e => setCurrentClass(e)}
          />
        </div>
        )}
        <div className={style.row}>
          <div className={`${style.d_flex} ${style.row}`} style={{justifyContent : "space-between"}}>
            Recipients
            {recipients.length > 0 && (
            <div className={style.d_flex}>
              <input className={style.checkbox} type="checkbox" checked={sendToAll} onChange={e => {
                setSendToAll(e.target.checked)
                setInitForm(
                  {
                    ...initForm, 
                    recipients_id : e.target.checked ? recipients.map(item => item.id) : null,
                    recipient_names : e.target.checked ? recipients.map(item => item.name) : null,
                    receivers : null
                  })
                }}/>
              <span>Send to all</span>
            </div>
            )}
          </div>
          <Select 
          isDisabled={sendToAll}
          options={recipients} isMulti={true}
          isSearchable={true}
          // isClearable={true}
          className={style.multiselect}
          value={initForm.receivers}
          onChange={e => {
            setInitForm(
            {
              ...initForm, 
              receivers : e.map(item => item),
              recipients_id : e.map(item => item.id),
              recipient_names : e.map(item => item.name),
            })}}
          />
        </div>
        <div className={style.row}>
          Subject
          <InputField 
            onChange={e => setInitForm({...initForm, title : e.target.value})}
            value={initForm.title}
          />
        </div>
        <div className={style.row}>
          Message
          <TextArea 
            onChange={e => setInitForm({...initForm, description : e.target.value})}
            value={initForm.description}
          />
        </div>
        {showPassword && (
        <div className={style.row}>
          <span style={{color : "red"}}>Password (Please enter password)</span>
          <InputField 
            onChange={e => setInitForm({...initForm, user_password : e.target.value})}
            value={initForm.user_password}
            type = "password"
            autocomplete= "off"
          />
        </div>
        )}
      </div>
      
      <div className={style.action}>
        <button 
          className={`${style.btn}`} 
          onClick={showPassword ? handleSubmit : () => setShowPassword(true)}
          disabled={(!showPassword || initForm.user_password.length) ? false : true}
        >
          Send Message <SendIcon />
        </button>
      </div>
      {isLoading && <Loader />}
    </div>
  );
};
export default MessageComposer;
