import { useNavigate } from "react-router-dom";
import style from "./SearchUI.module.css";
import { FaFilter, FaLongArrowAltLeft } from "react-icons/fa";
import Tags from "../Tags/Tags";
import { IoCloseSharp, IoSearchOutline, IoSend } from "react-icons/io5";
import { useRef, useState } from "react";

const SearchInput = ({
  closeSearch = () => {},
  searchHandler,
  classes = "",
  placeholder,
}) => {
  const searchRef = useRef("");
  return (
    <div className={style.searchContainer}>
      <input
        type="text"
        placeholder={placeholder}
        className={`${classes} ${style.search}`}
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            searchHandler(e.target.value);
            closeSearch(false);
          }
        }}
        ref={searchRef}
      />
      <IoSend
        className={`${classes} ${style.send}`}
        onClick={() => {
          searchHandler(searchRef?.current?.value);
          closeSearch(false);
        }}
      />
      <IoCloseSharp
        className={`${classes} ${style.close}`}
        onClick={closeSearch}
      />
    </div>
  );
};
const FilterUI = ({
  subjects,
  MaxSubjectView,
  filterBySubject,
  setToggleModal = () => {},
  classes = "",
  closeFilter = () => {},
  isMobile = false,
  hasClearnBtn = true,
}) => {
  const limit = isMobile ? subjects.length : MaxSubjectView;

  return (
    <div className={classes}>
      <IoCloseSharp
        className={`${style.closeModal}`}
        onClick={() => closeFilter(false)}
      />

      {limit > 0 &&
        subjects.slice(0, limit).map((item, index) => (
          <Tags
            name={item}
            key={index}
            callbackFn={(value) => {
              filterBySubject(value);
              closeFilter(false);
            }}
          />
        ))}
      {hasClearnBtn && (
        <Tags
          name={"Clear"}
          key={-1}
          callbackFn={(value) => {
            filterBySubject("");
            closeFilter(false);
          }}
        />
      )}
      {!isMobile && subjects.length > MaxSubjectView && (
        <Tags
          name="More"
          callbackFn={(name) => {
            closeFilter(true);
          }}
          isButton={true}
        />
      )}
    </div>
  );
};
const SearchUI = ({
  MaxSubjectView = 1,
  placeholder = "Search",
  navText = "",
  navLink = "",
  subjects = [],
  filterBySubject = () => {},
  searchHandler = () => {},
  hasFilter = true,
  hasClearnBtn = true,
}) => {
  const navigate = useNavigate();
  const [openSearch, setOpenSearch] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);

  return (
    <section className={style.root}>
      {navText && (
        <button onClick={() => navigate(navLink)} className={style.nav}>
          <FaLongArrowAltLeft /> {navText}
        </button>
      )}
      <div className={style.row}>
        <SearchInput
          closeSearch={() => setOpenSearch(false)}
          classes={!openSearch && style.desktop}
          placeholder={placeholder}
          searchHandler={searchHandler}
        />
        {hasFilter && (
          <FilterUI
            closeFilter={setOpenFilter}
            classes={openFilter ? style.filterModal : style.desktop}
            isMobile={openFilter ? true : false}
            subjects={subjects}
            MaxSubjectView={MaxSubjectView}
            filterBySubject={filterBySubject}
            hasClearnBtn={hasClearnBtn}
          />
        )}
        <div className={`${style.row} ${style.mobile}`}>
          <IoSearchOutline
            size={30}
            className={style.icon}
            onClick={() => setOpenSearch(true)}
          />

          {hasFilter && (
            <FaFilter
              size={20}
              className={style.icon}
              onClick={() => setOpenFilter(true)}
            />
          )}
        </div>
      </div>
    </section>
  );
};
export default SearchUI;
