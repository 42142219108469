import PraticeQuestionCard from "../../../components/PraticeQuestionCard";
import SkeletonLoader from "../../../components/SkeletonLoader/SkeletonLoader";
import useTutorUploadedQuestions from "./hooks/useTutorUploadedQuestions";
import classes from "./TutorUploadedQuestions.module.css";

function TutorUploadedQuestions() {
  const { userQuestions, isFetching, deletUploadedQuestion } =
    useTutorUploadedQuestions();
  if (isFetching) {
    return (
      <div className={classes.mainBody}>
        {[...new Array(4).values()].map((_, index) => (
          <SkeletonLoader className={classes.skeletonLogLoader} />
        ))}
      </div>
    );
  }
  return (
    <div className={classes.mainBody}>
      {userQuestions.map((item, index) => (
        <PraticeQuestionCard
          title={item.topic}
          description={item.description}
          coverImage={item.image}
          key={index}
          questionClass={item?.subcategory}
          subject={item?.subject}
          level={item?.level}
          isPraticeQuestion={item?.is_practice}
          onDelete={() => deletUploadedQuestion(item)}
        />
      ))}
    </div>
  );
}

export default TutorUploadedQuestions;
