import Dashboard from "../pages/Dashboard";
import Subscription from "../pages/Subscription";
import ProfilePage from "../pages/ProfilePage";
import NotificationPage from "../pages/NotificationPage";
import LeaderBoard from "../pages/library/LeaderBoard";
import CoursesExplore from "../pages/Courses/CoursesExplore/CoursesExplore";
import CurriculumBasedCategories from "../pages/Courses/CurriculumBasedCategories";
import CourseDetails from "../pages/Courses/CourseDetails";
import CourseTopics from "../pages/Courses/CourseTopics";
import ManageCourse from "../pages/Courses/ManageCourse";
import MyCourse from "../pages/Courses/MyCourse";
import CourseCertificate from "../pages/Courses/CourseCertificate";
export const dashboardRoutes = [
  {
    path: "dashboard",
    element: <Dashboard />,
  },
  {
    path: "subscription",
    element: <Subscription />,
  },
  {
    path: "/profile",
    element: <ProfilePage />,
  },
  {
    path: "notification",
    element: <NotificationPage />,
  },
  {
    path: "notification/:notificationId",
    element: <NotificationPage />,
  },
  { path: "leaderboard", element: <LeaderBoard /> },

  {
    path: "courses",
    element: <CoursesExplore />,
  },
  {
    path: "courses/certificates",
    element: <CourseCertificate />,
  },
  {
    path: "courses/curriculum",
    element: <CurriculumBasedCategories />,
  },
  {
    path: "course/create",
    element: <CurriculumBasedCategories type="create" />,
  },
  {
    path: "courses/:courseId",
    element: <CourseDetails />,
  },
  {
    path: "course/topic/:topicId",
    element: <CourseTopics />,
  },

  {
    path: "course/create/:subcategoryId",
    element: <ManageCourse />,
  },
  {
    path: "course/uploaded",
    element: <MyCourse />,
  },
];
