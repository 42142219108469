import { useEffect } from "react";
import useCdReactQuery from "../../../../utils/hooks/useCdReactQuery";
import coursesService from "../../../../service/courses.service";
import { toast } from "react-toastify";
import libraryService from "../../../../service/library.service";

function useCourseCertificate() {
  const { data, isLoading, queryEndpoint, error } = useCdReactQuery();

  useEffect(() => {
    (async () => {
      await queryEndpoint({
        endpointUrl: coursesService.booksFilter,
        params: {
          content_type: "certificate",
          limit: "100",
        },
      });
    })();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (error) {
      const error_data = error.response.data;
      toast.error(`
            ${error_data.error || error_data.msg || error_data.detail}
          `);
    }
  }, [error]);

  //   const filterBySubject = async (value) => {
  //     await queryEndpoint({
  //       endpointUrl: coursesService.booksFilter,
  //       params: {
  //         content_type: "certificate",
  //         subject_name: value,
  //         limit: 100,
  //       },
  //     });
  //   };
  const returnBook = async (bookId) => {
    await queryEndpoint({
      endpointUrl: libraryService.returnBook(bookId),
    });
  };
  const searchHandler = async (searchItem) => {
    let params = {
      content_type: "certificate",
      limit: 100,
    };
    if (searchItem) {
      params = { ...params, title: searchItem };
    }
    await queryEndpoint({
      endpointUrl: coursesService.booksFilter,
      params: params,
    });
  };

  return {
    isLoading,
    data,
    error,
    searchHandler,
    returnBook,
  };
}

export default useCourseCertificate;
