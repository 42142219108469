import { request } from "./api";
import env from "../env";

const API = env.API_URL;

export const URL = {
  getAllPlans: `${API}/subscription/plan`,
  subscribePlan: `${API}/subscriptions/create_user_sub`,
  getUserCurrentPlan: `${API}/billing`,
  cancelSubscription: `${API}/subscriptions/subscription/delete`,
  subscriptionPayment: `${API}/subscribe`,
  verifySubscription: `${API}/verify_subscription`,
  getSubscriptionHistory: `${API}/billing_history`,
  upgradePlan: `${API}/upgrade_plan`,
  topupUnit: `${API}/topup_units`,
  changePlan: `${API}/manage_plan`,
};

function getAllPlans(params) {
  return request(URL.getAllPlans, params, "GET", {});
}
function subscribePlan(params) {
  return request(URL.subscribePlan, params, "POST", {});
}
function getUserSubscription({ email }) {
  return request(URL.getUserSubscription, {}, "GET", {});
}
function cancelSubscription(params) {
  return request(URL.cancelSubscription, params, "DELETE", {});
}
function subscriptionPayment(params) {
  return request(URL.subscriptionPayment, params, "POST", {});
}

const subscriptionService = {
  getAllPlans,
  subscribePlan,
  cancelSubscription,
  getUserSubscription,
  subscriptionPayment,
};
export default subscriptionService;
