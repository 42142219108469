import classes from "./ContactRow.module.css";

function ContactRow({ title, text }) {
  return (
    <div className={classes.compWrapper}>
      <div className={classes.title}>{title}</div>
      <div className={classes.text}>{text}</div>
    </div>
  );
}

export default ContactRow;
