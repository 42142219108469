import classes from "./CurriculumBasedCategories.module.css";
import SkeletonLoader from "../../../components/SkeletonLoader";
import CurriculumnTopicDetails from "./CurriculumnTopicDetails";
import { SearchUI } from "../../../components/searchUI";
import useCurriculumBasedCategories from "./hooks/useCurriculumBasedCategories";

function CurriculumBasedCategories(props) {
  const {
    searchHandler,
    viewCurriculum,
    contentModal,
    curriculums,
    isLoading,
    setContentModal,
  } = useCurriculumBasedCategories();

  return (
    <>
      <div className={classes.pageRoot}>
        <div className={classes.searchNav}>
          <SearchUI
            navText="Curriculum"
            navLink="/courses"
            searchHandler={searchHandler}
            placeholder="Search Curriculum"
            hasFilter={false}
          />
        </div>

        <div className={classes.pageBody}>
          {isLoading ? (
            <div className={classes.catalogItems}>
              {[...new Array(6).values()].map((_, index) => (
                <SkeletonLoader className={classes.skeletonLogLoader} />
              ))}
            </div>
          ) : (
            <div className={classes.catalogItems}>
              {curriculums.length ? (
                curriculums.map((curriculum, index) => (
                  <div className={classes.item} key={curriculum?.id}>
                    <p className={classes.subjectTitle}>
                      {curriculum?.subject?.name}
                    </p>
                    <p className={classes.termsLabel}>Terms:</p>
                    <div className={classes.row}>
                      <div
                        className={classes.term}
                        onClick={() => viewCurriculum(curriculum, 1)}
                      >
                        1<sup>st</sup>
                      </div>
                      <div
                        className={classes.secondTerm}
                        onClick={() => viewCurriculum(curriculum, 2)}
                      >
                        2<sup>nd</sup>
                      </div>
                      <div
                        className={classes.thirdTerm}
                        onClick={() => viewCurriculum(curriculum, 3)}
                      >
                        3<sup>rd</sup>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p>No available curriculum</p>
              )}
            </div>
          )}
        </div>
        {/* <hr /> */}
      </div>
      {contentModal.modal && (
        <CurriculumnTopicDetails
          curriculum={contentModal.curriculum}
          termId={contentModal.termId}
          onClose={() => setContentModal({ ...contentModal, modal: false })}
        />
      )}
    </>
  );
}

export default CurriculumBasedCategories;
