import { useEffect, useState } from "react";
import libraryService from "../../../../service/library.service";
import useCdReactQuery from "../../../../utils/hooks/useCdReactQuery";

function useMyShelf() {
  const { queryEndpoint, isLoading, error } = useCdReactQuery();
  const [books, setBooks] = useState([])

  useEffect(() => {
    (async () => {
      const res = await queryEndpoint({
        endpointUrl: libraryService.getBorrowedBooks,
      });
      setBooks(res?.borrowed_books)
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const returnBook = async (bookId) => {
    await queryEndpoint({
      endpointUrl: libraryService.returnBook(bookId),
    });
  };

  const searchShelf = async (params) => {
    const res = await queryEndpoint({
      endpointUrl: libraryService.getBorrowedBooks,
    });
    setBooks(
      res?.borrowed_books.filter(
        book => book?.title.toLowerCase().includes(params.toLowerCase()) || book?.subject?.name.toLowerCase().includes(params.toLowerCase())
      )
    )

  }

  return {
    isLoading,
    data : books,
    error,
    returnBook,
    searchShelf,
  };
}

export default useMyShelf;
