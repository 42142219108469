import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import TermsAndConditions from "../../pages/TermsAndConditions";
import { setPopupChild, showPopup } from "../../redux/feature/popupSlice";
import { navPaths } from "../../utils/constants";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
} from "react-icons/fa";
import classes from "./Footer.module.css";
import InputField from "../../components/InputField";
import Button from "../../components/Button";

export default function Footer() {
  const history = useNavigate();
  const reduxDispatch = useDispatch();
  function handleTermsAndCondition() {
    reduxDispatch(setPopupChild(<TermsAndConditions />));
    reduxDispatch(
      showPopup({
        open: true,
      })
    );
  }
  return (
    <>
      <footer>
        <div className={classes.newsLetterWrapper}>
          <h3>Subscribe to our news letter</h3>
          <div className={classes.newsContent}>
            <div className={classes.row}>
              <InputField
                type="text"
                placeholder="you@company.com"
                className={classes.inputField}
              />
              <Button className={classes.submitBtn}>SUBSCRIBE</Button>
            </div>
          </div>
        </div>
        <div className={classes.footerMenu}>
          <div className={classes.footerItem}>
            <h5 className={classes.footerHeadings}>Quick link</h5>
            <ul className={classes.list}>
              {navPaths.map((nav, index) => (
                <li key={index}>
                  <button
                    className={classes[(nav.classN, "navlink")]}
                    onClick={() => {
                      if (nav.path === "home") {
                        window.scrollTo({ top: 0, behavior: "smooth" });
                        return;
                      }
                      history("/", { state: nav.path });
                    }}
                  >
                    {nav.name}
                  </button>
                </li>
              ))}
              <li>
                <button
                  className={classes.navlink}
                  onClick={handleTermsAndCondition}
                >
                  Terms of Service
                </button>
              </li>
            </ul>
          </div>
          <div className={classes.footerItem}>
            <h5 className={classes.footerHeadings}>Services</h5>
            <ul className={classes.list}>
              <li>eLibrary Services</li>
              <li>Cooporate Training</li>
              <li>Exam Preparation</li>
              {/* <li>Career Mentorship</li> */}
              <li>EduLeague & Charity Cup Games </li>
              {/* <li>On-demand assistance on difficult topics </li> */}
              <li>eStationary Shop</li>
            </ul>
          </div>
          <div className={`${classes.footerItem} ${classes.lastItem}`}>
            <p>
              <span>ctrLearn:</span> Empowering educational institutions through
              technology and inspiring minds, one book at a time. Stay connected
              with us through our social media channels for the latest updates,
              events, and educational content.
            </p>
            <div className={classes.socialPlatFormIcon}>
              <div className={classes.eachIcon}>
                <div className={classes.icon}>
                  <FaFacebookF className={classes.socialIcon} />
                </div>
                <span>Facebook</span>
              </div>
              <div className={classes.eachIcon}>
                <div className={classes.icon}>
                  <FaTwitter className={classes.socialIcon} />
                </div>
                <span>Twitter</span>
              </div>
              <div className={classes.eachIcon}>
                <div className={classes.icon}>
                  <FaLinkedinIn className={classes.socialIcon} />
                </div>
                <span>LinkedIn</span>
              </div>
              <div className={classes.eachIcon}>
                <div className={classes.icon}>
                  <FaInstagram className={classes.socialIcon} />
                </div>
                <span>Instagram</span>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
