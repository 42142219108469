import Button from "../../../components/Button/Button";
import Dropdown from "../../../components/Dropdown/Dropdown";
import InputField from "../../../components/InputField/InputField";
import ManageCourseWrapper from "../../../layouts/ManageCourseWrapper";
import classes from "./CourseUploadSetup.module.css";
import useCourseUploadSetup from "./hooks/useCourseUploadSetup";
import { languages } from "./constants";
import { MdKeyboardBackspace } from "react-icons/md";
import { IoMdClose } from "react-icons/io";
import { GrAdd } from "react-icons/gr";
import UploadSetupLoader from "./components/UploadSetupLoader";

function CourseUploadSetup({ isEdit }) {
  const {
    subjects,
    fetchingSubjects,
    fetchingCourseDetails,
    state,
    topics,
    fetchingTopics,
    dispatch,
    upLoadImage,
    handleSaveBook,
    navigate,
    handleUpdateBook,
  } = useCourseUploadSetup(isEdit);
  const {
    publisher,
    yearPublished,
    bookCover,
    savingBookData,
    price,
  } = state;
  return (
    <ManageCourseWrapper title="Course Curriculum & structure">
      {fetchingCourseDetails && isEdit ? (
        <UploadSetupLoader />
      ) : (
        <div className={classes.pageRoot}>
          <div style={{ flex: 1 }}>
            <div className={classes.imageSectionWrapper}>
              <div className={classes.coverImage}>
                {bookCover?.imageUrl ? (
                  <div className={classes.uploadedBookCover}>
                    <img src={bookCover?.imageUrl} alt="" />
                    <button
                      onClick={() => dispatch({ bookCover: {} })}
                      className={classes.removeImageBtn}
                    >
                      <IoMdClose />
                    </button>
                  </div>
                ) : (
                  <div className={classes.coverImageUploader}>
                    <label
                      htmlFor="contained-button-file"
                      className={classes.addImage}
                    >
                      <div className={classes.uploadImageAction}>
                        <GrAdd className={classes.addIcon} />
                        <div>Cover Image</div>
                      </div>
                    </label>
                    <input
                      accept="image/*"
                      className={classes.imageInput}
                      id="contained-button-file"
                      multiple
                      type="file"
                      onChange={upLoadImage}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className={classes.inputRow}>
              <div className={classes.inputColumn}>
                <div className={classes.inputLabel}>Subject*</div>
                <Dropdown
                  isLoading={fetchingSubjects}
                  className={classes.inputField}
                  options={subjects.subject}
                  value={""}
                  onChange={(value) => {
                    dispatch({ selectedSubject: value, selectedTopic: {} });
                  }}
                />
              </div>
              <div className={classes.inputColumn}>
                <div className={classes.inputLabel}>Topic*</div>
                <Dropdown
                  className={classes.inputField}
                  value={""}
                  options={topics}
                  isLoading={fetchingTopics}
                  onChange={(value) => {
                    dispatch({ selectedTopic: value });
                  }}
                />
              </div>
              <div className={classes.inputColumn}>
                <div className={classes.inputLabel}>Language</div>
                <Dropdown
                  className={classes.inputField}
                  options={languages}
                  value=""
                  onChange={(value) => {
                    dispatch({ selectedLanguage: value });
                  }}
                />
              </div>
            </div>
            <div className={classes.inputRow}>
              <div className={classes.inputColumn}>
                <div className={classes.inputLabel}>Price*</div>
                <InputField
                  className={classes.inputField}
                  value={price}
                  onChange={({ target }) => {
                    dispatch({ price: target.value });
                  }}
                />
              </div>
              <div className={classes.inputColumn}>
                <div className={classes.inputLabel}>Publisher*</div>
                <InputField
                  className={classes.inputField}
                  value={publisher}
                  onChange={({ target }) => {
                    dispatch({ publisher: target.value });
                  }}
                />
              </div>
              <div className={classes.inputColumn}>
                <div className={classes.inputLabel}>Year Publisher*</div>
                <InputField
                  className={classes.inputField}
                  value={yearPublished}
                  onChange={({ target }) => {
                    dispatch({ yearPublished: target.value });
                  }}
                />
              </div>
            </div>
          </div>
          <div className={classes.saveButtonWrapper}>
            <button onClick={() => navigate(-1)} className={classes.backButton}>
              <MdKeyboardBackspace className={classes.backIcon} />
              <span>Back</span>
            </button>
            <Button
              onClick={isEdit ? handleUpdateBook : handleSaveBook}
              isLoading={savingBookData}
              className={classes.saveBtn}
            >
              Save And Continue
            </Button>
          </div>
        </div>
      )}
    </ManageCourseWrapper>
  );
}

export default CourseUploadSetup;
