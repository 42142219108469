import classes from "./QandACardRow.module.css";
import SwitchButton from "../SwitchButton/SwitchButton";
import { MdOutlineClear } from "react-icons/md";
import { IoMdAdd } from "react-icons/io";

function QandACardRow({
  questionNumber,
  onRemove,
  onChangeQuestion,
  onChangeAnswerType,
  onSelectCorrectAnswer,
  exercise = {},
  updateAnswersOption,
  courseTemplateQuestion,
  handlePraticeQuestion,
  isPraticeQuestion,
}) {
  // eslint-disable-next-line
  const { options, answerType, correctAnswer, question } = exercise;

  function handleRemoveOption(index) {
    const answerOptions = options;
    if (answerOptions.length === 1) return;
    delete answerOptions[index];
    updateAnswersOption?.(answerOptions.filter((item) => item));
  }

  function handleAddOptionLine(index) {
    updateAnswersOption?.([
      ...options,
      { image: "", option: "", text: "", id: index + 1 },
    ]);
  }
  function handleOnchangeOptionValues(e, index) {
    const { name, value } = e?.target;
    const answerOptions = options;
    answerOptions[index][name] = value;
    updateAnswersOption?.(answerOptions);
  }

  function checkCorrectAnswer(value) {
    correctAnswer?.reduce(
      (acc, cumu) => (cumu?.id === value?.id ? true : acc),
      false
    );
  }

  return (
    <div className={classes.cardRoot}>
      <div className={classes.cardTop}>
        <div className={classes.questionNumber}>{questionNumber}</div>
        <div className={classes.rightTop}>
          {!courseTemplateQuestion && (
            <div className={classes.makePraticequestion}>
              <span>Make Pratice Question</span>
              <input
                type="checkbox"
                checked={isPraticeQuestion}
                onChange={handlePraticeQuestion}
              />
            </div>
          )}
        </div>
      </div>
      <div className={classes.lineDivider} />
      <div className={classes.cardBody}>
        <div className={classes.questionColumn}>
          <textarea
            name="text"
            placeholder="Pratice Question"
            className={classes.textInput}
            onChange={onChangeQuestion}
            value={question?.text}
          />
        </div>
        <div className={classes.answerColumn}>
          <div className={classes.answersColumnTop}>
            <div className={classes.switchWrapper}>
              <span className={classes.switchBtnText}>Multi Answer</span>
              <SwitchButton
                checked={answerType === "singleAnswer"}
                onChange={({ target }) =>
                  onChangeAnswerType?.(
                    !target.checked ? "multiAnswer" : "singleAnswer"
                  )
                }
                className={classes.switchBtn}
              />
              <span className={classes.switchBtnText}>Single Answer</span>
            </div>
          </div>
          <div>
            {options?.map((answer, index) => (
              <div className={classes.answerRow}>
                <div style={{ flex: 1 }}>
                  <textarea
                    name="text"
                    placeholder="Option"
                    className={classes.textInput}
                    onChange={(e) => {
                      handleOnchangeOptionValues(e, index);
                    }}
                    value={answer?.text}
                  />
                </div>
                <div>
                  {answerType === "multiAnswer" ? (
                    <input
                      name="checkbox"
                      checked={checkCorrectAnswer(answer)}
                      onChange={(e) => {
                        onSelectCorrectAnswer?.(e, answer);
                      }}
                      type="checkbox"
                    />
                  ) : (
                    <input
                      name="radio"
                      checked={checkCorrectAnswer(answer)}
                      onChange={(e) => {
                        onSelectCorrectAnswer?.(e, answer);
                      }}
                      type="radio"
                    />
                  )}
                </div>
                <div className={classes.answerRowAction}>
                  {options?.length - 1 === index && (
                    <button
                      onClick={() => handleAddOptionLine(index)}
                      className={classes.addRowButtton}
                    >
                      <IoMdAdd />
                    </button>
                  )}
                  {options?.length !== 1 && (
                    <button
                      onClick={() => handleRemoveOption(index)}
                      className={classes.removeRow}
                    >
                      <MdOutlineClear />
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default QandACardRow;
