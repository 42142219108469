import React from "react";
import style from "./Tags.module.css";
import { RxDotFilled } from "react-icons/rx";
import { FaLongArrowAltRight } from "react-icons/fa";
const Tags = ({
  name,
  callbackFn = () => {},
  isButton,
  successText = false,
}) => {
  const handleCallBack = () => {
    callbackFn(name);
  };

  // console.log(name, callbackFn, isButton);

  if (isButton) {
    return (
      <div className={style.button} onClick={handleCallBack}>
        {name} <FaLongArrowAltRight />
      </div>
    );
  } else if (name === "Clear") {
    return (
      <div className={`${style.tag} ${style.clear}`} onClick={handleCallBack}>
        {name}
      </div>
    );
  } else {
    return (
      <div
        className={`${style.tag} ${
          successText ? style.success : style[name.slice(0, 1).toLowerCase()]
        }`}
        onClick={handleCallBack}
      >
        {!successText && <RxDotFilled className={style.icon} />} {name}
      </div>
    );
  }
};

export default Tags;
