import { useEffect, useState } from "react";
import useCdReactQuery from "../../../../utils/hooks/useCdReactQuery";
import assessmentService from "../../../../service/assessment.service";

export const usePerformanceTracker = () => {
  const { queryEndpoint } = useCdReactQuery();

  const initialPerformanceData = {
    subjectStrength: [],
    subjectWeakness: [],
    performance_by_subject: [],
    performance_trends: [],
    recommended_materials: [],
  };

  const [performanceData, setPerformanceData] = useState(
    initialPerformanceData
  );

  const populateDummyData = (num) => {
    // Without unique names, the radarchart wont render its color properly
    const dummySubjectPlaceholders = [
      { subject: ".", score: 0 },
      { subject: "..", score: 0 },
      { subject: "...", score: 0 },
      { subject: "-", score: 0 },
      { subject: "...", score: 0 },
    ];

    return dummySubjectPlaceholders.filter((item, index) => index < num);
  };

  const extractData = (data, dummySize = 5) => {
    const size = data?.length || 0;
    const numDummyItems = Math.max(dummySize - size, 0); // Ensure non-negative
    return [...(data || []), ...populateDummyData(numDummyItems)];
  };

  useEffect(() => {
    const fetchPerformanceData = async () => {
      try {
        // TODO:: CHIDI HIGH ALERT -- Recommended materials should not return the entire book content. This will increase the response data.

        const data = await queryEndpoint({
          endpointUrl: assessmentService.performanceCourseData,
        });

        const processedData = {
          subjectStrength: extractData(data?.strengths),
          subjectWeakness: extractData(data?.weakness),
          performance_by_subject: data?.performance_by_subject || [],
          performance_trends: data?.performance_trends || [],
          recommended_materials: data?.recommended_materials || [],
        };

        setPerformanceData((prev) => ({
          ...prev,
          ...processedData,
        }));
      } catch (err) {
        setPerformanceData((prev) => ({
          ...prev,
          subjectStrength: populateDummyData(5),
          subjectWeakness: populateDummyData(5),
        }));
      }
    };

    fetchPerformanceData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return performanceData;
};

export default usePerformanceTracker;
