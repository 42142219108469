import React from "react";
import classes from "./ConfirmationPopup.module.css";
import Button from "../Button";

function ConfirmationPopup({
  isConfirmOpen,
  showConfirmationPopup,
  onModalConfirmDismissed,
  headerText,
  bodyText,
  onConfirm,
  buttonText,
}) {
  function closePopup() {
    showConfirmationPopup({ open: false });
    onModalConfirmDismissed?.();
  }
  return (
    <div
      onClick={closePopup}
      className={`${classes.modal} ${
        isConfirmOpen ? classes.showModal : undefined
      }`}
    >
      <div
        className={classes.popup}
        onClick={(event) => event.stopPropagation()}
      >
        <p className={classes.confirmHeaderText}>
          <i
            style={{ color: "#FF6900", marginRight: 10 }}
            className="fas fa-exclamation-triangle"
          />
          <span>{!headerText ? "Confirm !!!" : headerText}</span>
        </p>
        <div className={classes.confirmBody}>
          <span>{bodyText || "You are about to take a critical action !"}</span>
        </div>
        <div className={classes.btnBox}>
          <Button
            className={classes.buttonConfirm}
            style={{
              maxWidth: 100,
              height: 35,
              backgroundColor: "#FFFFFF",
              border: "1px solid #000",
              color: "#000",
            }}
            variant="outline"
            onClick={() => {
              showConfirmationPopup({ isOpen: false });
            }}
          >
            <span className={classes.cancelText}>Cancel</span>
          </Button>
          <Button
            style={{ maxWidth: 100, height: 35 }}
            className={classes.buttonConfirm}
            onClick={onConfirm}
          >
            <span>{buttonText || "Confirm"}</span>
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ConfirmationPopup;
