import {
  // ColumnLayout,
  ColumnLayout2,
  RowLayout,
  RowLayout2,
} from "./layout/PageLayout";
import style from "./Dashboard.module.css";
// import Greetings from "./component/Greetings";
// import CoursesAndCertificates from "./component/CoursesAndCertificates";
// import StrengthIndicator from "./component/StrengthIndicator";
import CardDeck from "./component/CardDeck";
import BookCard2 from "../../components/BookCard2/BookCard2";
import ApplicationFooter from "../../components/ApplicationFooter";
import useFeaturedContent from "../library/LibraryHome/hooks/useFeaturedContent";
// import usePerformanceTracker from "../ExamPrep/ExamPrepPerformanceTracker/hooks/usePerformanceTracker";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useCdReactQuery from "../../utils/hooks/useCdReactQuery";
import libraryService from "../../service/library.service";
import classNames from "classnames";
// import useResponsiveness from "../../utils/hooks/useResponsiveness";
// import { SCREEN_SIZE } from "../../utils/constants";
import useDiscover from "../library/LibraryHome/hooks/useDiscover";
import SkeletonLoader from "../../components/SkeletonLoader";
import StudyTrend from "./component/study_trend/StudyTrend";

const Dashboard = () => {
  const { featuredContent, isLoading } = useFeaturedContent();
  // const { subjectStrength } = usePerformanceTracker();
  const [uncompletedBooks, setUncompletedBooks] = useState([]);
  const [booksRead, setBooksRead] = useState({});
  const navigate = useNavigate();
  // const { responsive } = useResponsiveness(SCREEN_SIZE.sm);
  const { queryEndpoint } = useCdReactQuery();
  const {
    allRecentUploads,
    fetchingRecentUploads,
    allTopRated,
    fetchingTopRated,
  } = useDiscover();

  useEffect(() => {
    (async () => {
      const response = await queryEndpoint({
        endpointUrl: libraryService.uncompletedReading,
      });

      const books_read_res = await queryEndpoint({
        endpointUrl: libraryService.monthlyRead,
      });

      setBooksRead(books_read_res);
      setUncompletedBooks(response?.data);
    })();

    // eslint-disable-next-line
  }, []);

  return (
    <div className={style.root}>
      <RowLayout bg="#4541be">
        <h2 className={`${style.title} ${style.studyTrend}`}>
          {`Books Read (${booksRead?.totalBooksRead})`}
        </h2>
      </RowLayout>
      <RowLayout bg="#4541be" classes={classNames(style.top)}>
        <StudyTrend data={booksRead?.data} />
      </RowLayout>
      {/* <RowLayout bg="#4541be" classes={classNames(style.top)}>
        <ColumnLayout className={style.greetingsColumn}>
          <Greetings />
          <CoursesAndCertificates booksRead={booksRead} />
        </ColumnLayout>
        <ColumnLayout _positionRight={!responsive ? true : false}>
          <div className={style.signalWrapper}>
            <p className={style.indicator}>Subject Strength</p>
            <div className={style.indicatorChart}>
              <StrengthIndicator
                data={subjectStrength}
                color={{
                  fontColor: "#fff",
                  stroke: "#8a7ae8",
                  fill: "#8a7ae8",
                }}
              />
            </div>
          </div>
        </ColumnLayout>
      </RowLayout> */}

      <ColumnLayout2>
        <h2 className={`${style.title} `}>Continue your studies</h2>
        <hr />
        {uncompletedBooks?.length ? (
          <CardDeck books={uncompletedBooks} />
        ) : (
          <div className={style.emptyRecord}>
            <p style={{ textAlign: "left" }}> No record available </p>
          </div>
        )}
      </ColumnLayout2>

      <ColumnLayout2>
        <h2 className={`${style.title} ${style.mtN3}`}>
          Recently added contents
        </h2>
        <hr />
        {fetchingRecentUploads ? (
          <div className={style.loaderWrapper}>
            {[...new Array(3).values()].map((_, index) => (
              <SkeletonLoader className={style.skeletonLogLoader} key={index} />
            ))}
          </div>
        ) : !allRecentUploads?.length ? (
          <div className={style.emptyHistory}>
            <p>No record available.</p>
          </div>
        ) : (
          <RowLayout2>
            {allRecentUploads.map((item, index) => (
              <BookCard2
                key={index}
                img={item?.book_cover_url}
                title={item?.title}
                rating={item?.rating}
                subject={item?.subject?.name}
                price={item?.price}
                handleClick={() => navigate(`/library/course/${item?._id}`)}
              />
            ))}
          </RowLayout2>
        )}
      </ColumnLayout2>

      <ColumnLayout2>
        <h2 className={`${style.title} ${style.mtN3}`}>Most viewed contents</h2>
        <hr />
        {isLoading ? (
          <div className={style.loaderWrapper}>
            {[...new Array(3).values()].map((_, index) => (
              <SkeletonLoader className={style.skeletonLogLoader} key={index} />
            ))}
          </div>
        ) : !featuredContent?.length ? (
          <div className={style.emptyHistory}>
            <p>No record available.</p>
          </div>
        ) : (
          <RowLayout2>
            {featuredContent.map((item, index) => (
              <BookCard2
                key={index}
                img={item?.book_cover_url}
                title={item?.title}
                rating={item?.rating}
                subject={item?.subject?.name}
                price={item?.price}
                handleClick={() => navigate(`/library/course/${item?._id}`)}
              />
            ))}
          </RowLayout2>
        )}
      </ColumnLayout2>

      <ColumnLayout2>
        <h2 className={`${style.title} ${style.mtN3}`}>Top rated contents</h2>
        <hr />
        {fetchingTopRated ? (
          <div className={style.loaderWrapper}>
            {[...new Array(3).values()].map((_, index) => (
              <SkeletonLoader className={style.skeletonLogLoader} key={index} />
            ))}
          </div>
        ) : !allTopRated?.length ? (
          <div className={style.emptyHistory}>
            <p>No record available.</p>
          </div>
        ) : (
          <RowLayout2>
            {allTopRated.map((item, index) => (
              <BookCard2
                key={index}
                img={item?.book_cover_url}
                title={item?.title}
                rating={item?.rating}
                subject={item?.subject?.name}
                price={item?.price}
                handleClick={() => navigate(`/library/course/${item?._id}`)}
              />
            ))}
          </RowLayout2>
        )}
      </ColumnLayout2>

      <ApplicationFooter />
    </div>
  );
};

export default Dashboard;
