import QandACardRow from "../../../components/QandACardRow";
import classes from "./ManageQuestion.module.css";
import useManageQuestion from "./hooks/useManageQuestion";
import Dropdown from "../../../components/Dropdown/Dropdown";
import { questionLevel } from "../../../utils/constants";
import Button from "../../../components/Button/Button";
function ManageQuestion() {
  const {
    state,
    subject,
    topics,
    fetchingTopics,
    fetchSubject,
    uploadingData,
    dispatch,
    onChangeQuestion,
    onChangeAnswerType,
    onSelectCorrectAnswer,
    handleUpdateExerciseAnswerOption,
    handlePraticeQuestion,
    onSave,
  } = useManageQuestion();
  const {
    questionData,
    selectedSubject,
    selectedTopic,
    selectedLevel,
    isPraticeQuestion,
  } = state;
  return (
    <div className={classes.pageRoot}>
      <h4 className={classes.pageTitle}>Pratice Question</h4>
      <div className={classes.pageContent}>
        <div className={classes.praticeInfo}>
          <div className={classes.inputRow}>
            <div className={classes.inputCulumn}>
              <div className={classes.inputLabel}>Subject*</div>
              <Dropdown
                options={subject}
                isLoading={fetchSubject}
                value={selectedSubject?.name}
                onChange={(value) => {
                  dispatch({ selectedTopic: {}, selectedSubject: value });
                }}
                className={classes.dropdown}
              />
            </div>
            <div className={classes.inputCulumn}>
              <div className={classes.inputLabel}>Topic*</div>
              <Dropdown
                options={topics}
                isLoading={fetchingTopics}
                value={selectedTopic.name}
                onChange={(value) => {
                  dispatch({
                    selectedTopic: value,
                  });
                }}
                className={classes.dropdown}
              />
            </div>
            <div className={classes.inputCulumn}>
              <div className={classes.inputLabel}>Level*</div>
              <Dropdown
                options={questionLevel}
                value={selectedLevel}
                onChange={(value) => {
                  dispatch({
                    selectedLevel: value?.value,
                  });
                }}
                className={classes.dropdown}
              />
            </div>
            {/* <div className={classes.questionText}>
              <input type="checkbox"
                onChange={(e) => {
                  dispatch({
                    isPraticeQuestion:e.target.checked                  })
                }}
              />
              Examprep Question
            </div> */}
          </div>
        </div>
        <div className={classes.questionWrapper}>
          <QandACardRow
            isPraticeQuestion={isPraticeQuestion}
            exercise={questionData}
            updateAnswersOption={handleUpdateExerciseAnswerOption}
            onChangeQuestion={onChangeQuestion}
            onChangeAnswerType={onChangeAnswerType}
            handlePraticeQuestion={handlePraticeQuestion}
            onSelectCorrectAnswer={onSelectCorrectAnswer}
          />
        </div>
      </div>
      <div className={classes.pageFooter}>
        <Button
          isLoading={uploadingData}
          onClick={onSave}
          className={classes.saveButton}
        >
          Save Question
        </Button>
      </div>
    </div>
  );
}

export default ManageQuestion;
