import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
};

export const splashLoaderSlice = createSlice({
  name: "splash-loader",
  initialState,
  reducers: {
    setSplashLoader: (state, action) => {
      state.loading = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setSplashLoader } = splashLoaderSlice.actions;

export default splashLoaderSlice.reducer;
