import { AiOutlineSave } from "react-icons/ai";
import { RxArrowLeft } from "react-icons/rx";
import BlockEditor from "../../../components/BlockEditor";
import Button from "../../../components/Button";
import InputField from "../../../components/InputField";
import classes from "./CreateNote.module.css";
import useCreateNote from "./hooks/useCreateNote";

function CreateNote() {
  const {
    noteTitle,
    isSaveing,
    noteText,
    saveNote,
    setNoteTitle,
    setNoteText,
    navigate,
  } = useCreateNote();
  return (
    <div className={classes.manageNoteRoot}>
      <div>
        <button onClick={() => navigate(-1)} className={classes.backBtn}>
          <RxArrowLeft className={classes.backIcon} />
          <span>Back</span>
        </button>
      </div>

      <div className={classes.editorWrapper}>
        <div className={classes.actionbuttonWrapper}>
          <Button
            isLoading={isSaveing}
            onClick={saveNote}
            className={classes.saveNoteButton}
          >
            <AiOutlineSave />
          </Button>
        </div>
        <div style={{ height: "100%", marginTop: 10 }}>
          <InputField
            placeholder="Note Title"
            value={noteTitle}
            onChange={({ target }) => setNoteTitle(target.value)}
            className={classes.noteTitleInput}
          />
          <BlockEditor
            value={noteText}
            onChange={setNoteText}
            className={classes.blockTextEditor}
          />
        </div>
      </div>
    </div>
  );
}

export default CreateNote;
