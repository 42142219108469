import React, { useRef } from "react";
// import { RiPauseMiniLine } from "react-icons/ri";
// import { CiPlay1 } from "react-icons/ci";
import classes from "./VideoPlayer.module.css";

function VideoPlayer(props) {
  const videoRef = useRef(null);
  // const [isPlaying, setIsPlaying] = useState(false);

  // function handlePlay() {
  //   videoRef.current.play();
  //   setIsPlaying(true);
  // }

  // function handlePause() {
  //   videoRef.current.pause();
  //   setIsPlaying(false);
  // }

  if (!props?.videoUrl) return null;

  return (
    <div className={classes.compWrapper}>
      <video
        className={classes.videoPlay}
        ref={videoRef}
        controls
        controlsList="nodownload"
      >
        <source src={props.videoUrl} type={props?.type} />
        Your browser does not support the video tag.
      </video>
      {/* <div className={classes.buttonWrapper}>
        <div>
          {!isPlaying ? (
            <button className={classes.playPauseButton} onClick={handlePlay}>
              <CiPlay1 />
            </button>
          ) : (
            <button className={classes.playPauseButton} onClick={handlePause}>
              <RiPauseMiniLine />
            </button>
          )}
        </div>
      </div> */}
    </div>
  );
}

export default VideoPlayer;
