import { useMutation } from "@tanstack/react-query";
import { useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";

function useAppMutation(mutationKeys, queryFn, options) {
  // const navigate = useNavigate();
  const { user } = useSelector(({ currentUser }) => currentUser);

  return useMutation(
    async (params) => queryFn(params),
    {
      mutationKey: [options, ...mutationKeys, user?.email],
      ...options,
    },
    options
  );
}

export default useAppMutation;
