import classes from "./CoursesExplore.module.css";
import CourseTypeCard from "../../../components/CourseTypeCard";
import { useNavigate } from "react-router-dom";
import ApplicationFooter from "../../../components/ApplicationFooter";

function CoursesExplore() {
  const history = useNavigate();
  return (
    <div className={classes.pageRoot}>
      <section className={classes.courseBanner}>
        <article>
          <h2>Learning Path</h2>
          <p>
            Explore Expertly Curated Paths to Success Guided by Instructors.
          </p>
        </article>
      </section>

      <div className={classes.cardWrapper}>
        <CourseTypeCard
          onClick={() => history("/courses/certificates")}
          title="DIY & Certificate Courses"
          description={`Specialized training on industry-recognized certifications for exciting opportunities.`}
        />
        <CourseTypeCard
          onClick={() => history("/courses/curriculum")}
          title="Curriculum Based Learning"
          description="Master subjects using an organized  subject-topic approach in building a solid foundation."
        />
      </div>

      <ApplicationFooter />
    </div>
  );
}

export default CoursesExplore;
