import libraryService from "../../../../service/library.service";
import useCdReactQuery from "../../../../utils/hooks/useCdReactQuery";


const useAdminTopic = () => {
    const { queryEndpoint } = useCdReactQuery();

    const createTopic = async (item) => {
        const resp = await queryEndpoint({
            endpointUrl : libraryService.topics,
            httpMethod : "POST",
            body : JSON.stringify(item),
        });

        return await resp
    }

    const editTopic = async (item) => {
        const resp = await queryEndpoint({
            endpointUrl : libraryService.topics,
            httpMethod : "PUT",
            body : JSON.stringify(item),
        });

        return await resp
    }

    return {
        createTopic,
        editTopic,

    }

}

export default useAdminTopic;