import { useState } from "react";
import classes from "./PaymentGateways.module.css";
import { toast } from "react-toastify";
import stripeLogo from "../../assets/png/stripe.png";
import paystack_logo from "../../assets/png/paystack_logo.png";
import { Loader } from "../Modal";
import Button from "../Button/Button";
import CashViewer from "../CashViewer";

function PaymentGateways({ onPay, book_details, onCancel, isLoading }) {
  const [selectedGateway, setSelectedGateway] = useState("paystack");

  const gateways = [
    {
      name: "Paystacks",
      icon: paystack_logo,
      isDisabled: false,
      value: "paystack",
    },
    {
      name: "Stripe",
      icon: stripeLogo,
      isDisabled: true,
      value: "stripe",
    },
  ];

  return (
    <>
      <div className={classes.pageRoot}>
        <div className={classes.pageBody}>
          <div className={classes.innerBody}>
            <div className={classes.header_section}>
              <div className={classes.book_title}>
                {book_details?.book_type}
              </div>
              <div className={classes.book_price}>
                <CashViewer amount={book_details?.price} />
              </div>
            </div>
            <div className={classes.payment_gateway_title}>
              <span>Payment Gateway</span>
              <hr />
            </div>
            <div className={classes.paymentGateways}>
              {gateways.map(({ icon, value, isDisabled }) => (
                <div
                  onClick={() => {
                    if (isDisabled) {
                      toast.error("Payment Gateway not available");
                      return;
                    }
                    setSelectedGateway(value);
                  }}
                  className={`${classes.gatewayCard} ${
                    isDisabled && classes.disabled_gateway
                  } ${selectedGateway === value && classes.active_gateway} `}
                >
                  <img src={icon} alt="" className={classes.gatewayIcon} />
                </div>
              ))}
            </div>
            <div className={classes.bottomSection}>
              <Button
                className={classes.cancel_payment_button}
                onClick={onCancel}
              >
                Cancel
              </Button>
              <Button
                onClick={() => onPay?.(selectedGateway)}
                isLoading={isLoading}
                className={classes.continue_payment_button}
              >
                Continue
              </Button>
            </div>
          </div>
        </div>
      </div>
      {isLoading && <Loader />}
    </>
  );
}

export default PaymentGateways;
