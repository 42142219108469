import React, { useState, createContext } from "react";

const ExamPrepStorage = createContext();
const data = {
  assessmentModalToggle: false,
  modalPage: "AssessmentDetails",
};

export const ExamPrepStorageProvider = ({ children }) => {
  const [state, setState] = useState(data);

  const updateStorage = (obj) => {
    setState((prev) => {
      return { ...prev, ...obj };
    });
  };
  const storage = {
    ...state,
    updateStorage,
  };
  return (
    <>
      <ExamPrepStorage.Provider value={storage}>
        {children}
      </ExamPrepStorage.Provider>
    </>
  );
};

export default ExamPrepStorage;
