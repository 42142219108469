import { AiOutlineDelete } from "react-icons/ai";
import { thumbnailPlaceholder } from "../../assets/image";
import Button from "../Button";
import classes from "./TopCard.module.css";
import { MDBProgress, MDBProgressBar } from "mdb-react-ui-kit";

function TopCard({
  coverImage,
  bookTitle,
  subcategory,
  status,
  onDelete,
  isDeleting,
  onClick,
}) {
  return (
    <div onClick={onClick} className={classes.courseCardRoot}>
      <div style={{ width: 100 }}>
        <img
          className={classes.imgThumbNail}
          src={coverImage || thumbnailPlaceholder}
          alt=""
        />
      </div>
      <div className={classes.cardBody}>
        <div style={{ width: "40%" }}>
          <h4 className={classes.courseTitle}>{bookTitle}</h4>
          <div className={classes.category}>{subcategory}</div>
        </div>
        <div className={classes.progressBar}>
          <div className={classes.uploadStatusText}>
            {status < 100 ? "Finish your upload" : "material uploaded"}
          </div>
          <div style={{ flex: 1 }}>
            <MDBProgress>
              <MDBProgressBar
                striped
                bgColor={status < 100 ? "danger" : "success"}
                width={status}
                valuemin={0}
                valuemax={100}
              ></MDBProgressBar>
            </MDBProgress>
          </div>
        </div>
        <div>
          <Button
            isLoading={isDeleting}
            onClick={(e) => {
              e.stopPropagation();
              onDelete?.();
            }}
            className={classes.deleteBtn}
          >
            <AiOutlineDelete className={classes.deleteIcon} />
          </Button>
        </div>
      </div>
    </div>
  );
}

export default TopCard;
