export const uploadData = [
  {
    id: 1,
    title: "Course",
    pathName: "/library/courses",
    regexPath: /\/library\/courses/,
  },
  {
    id: 2,
    title: "Question",
    pathName: "/library/pratice-test",
    regexPath: /\/library\/pratice-test/,
  },
];
