import React from "react";
import classes from "./Banner.module.css";
import elipse1 from "../../assets/png/Ellipse-974-copy-1.png";
import elipse2 from "../../assets/png/Ellipse-974-copy-2.png";
import { bookImg } from "../../assets/image";
const Banner = ({ forwardedRef }) => {
  return (
    <div ref={forwardedRef} className={classes.root}>
      <img src={elipse1} alt="" className={classes.elipseImage1} />
      <img src={elipse2} alt="" className={classes.elipseImage2} />
      <div className={classes.banner}>
        <div className={classes.content}>
          <h1>Controlled</h1>
          <h1>
            Learning <i>Platform</i>
          </h1>
          <div className={classes.description}>
            <h3>A Trusted Resource for Learning</h3>
            <p>
              At ctrLearn, we empower schools with a cutting-edge library app
              featuring school-designed materials and interactive questions. Our
              platform tracks student progress, offering personalized
              recommendations to improve underperforming topics and highlighting
              strengths and weaknesses, ensuring a superior, personalized
              learning experience.
            </p>
          </div>
        </div>
        <img src={bookImg} alt="" className={classes.bannerImg} />
      </div>
    </div>
  );
};

export default Banner;
