import classes from "./ItemRow.module.css";

function ItemRow({ icon, text }) {
  return (
    <div className={classes.itemRoot}>
      <div className={classes.itemIcon}>{icon}</div>
      <div className={classes.itemText}>{text}</div>
    </div>
  );
}

export default ItemRow;
