// import SkeletonLoader from "../../components/SkeletonLoader";
import useNotificationPage from "./hooks/useNotificationPage";
import style from "./NotificationPage.module.css";
import ApplicationFooter from "../../components/ApplicationFooter";
import { useEffect, useState } from "react";
import { DotIcon, EditIcon, InboxIcon, OutboxIcon } from "../../assets/icons";
import MessageComposer from "./MessageComposer";
import { useParams } from "react-router-dom";
import moment from "moment";
import { FullpageModal, Loader } from "../../components/Modal";
import ConfirmDialog from "../../components/ConfirmationDialogCD/ConfirmDialog";
import { toast } from "react-toastify";
import MessageReader from "./MessageReader";
import useResponsiveness from "../../utils/hooks/useResponsiveness";
import { SCREEN_SIZE } from "../../utils/constants";

function NotificationPage() {
  const [itemSelected, setItemSelected] = useState([]);
  const [toggleMsgComposer, setToggleMsgComposer] = useState(false);
  const [viewMessage, setViewMessage] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [toggleTab, setToggleTab] = useState({
    inbox: true,
    sentMessages: false,
  });
  const [notification, setNotification] = useState({});
  const [isSent, setIsSent] = useState(false);
  const { responsive } = useResponsiveness(SCREEN_SIZE.sm);

  const {
    deleteNotifications,
    error,
    getNotification,
    inboxMessages,
    markAsReadUnRead,
    notifications,
    sendMessage,
    sentMessages,
    isLoading,
  } = useNotificationPage();
  const { notificationId = null } = useParams();

  useEffect(() => {
    if (error) {
      const error_data = error?.response?.data;
      toast.error(`
        ${error_data.error || error_data.msg || error_data.detail}
      `);
    }
  }, [error]);

  useEffect(() => {
    (async () => {
      if (notificationId) {
        const res = await getNotification(notificationId);
        const noti = res?.msg;
        setNotification(noti);
        setItemSelected([noti]);
        if (!noti?.is_read) {
          setTimeout(() => {
            (async () => {
              await markAsReadUnRead([noti?._id], true);
            })();
          }, 5000);
        }
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationId]);

  useEffect(() => {
    checker();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemSelected]);

  const checker = () => {
    const checkedIDs = itemSelected.map((item) => item._id);

    document.querySelectorAll(".check").forEach((check) => {
      check.checked = checkedIDs.includes(check.value) ? true : false;
    });
  };

  const selectNotifications = (e) => {
    const { value, checked } = e.target;
    if (value !== "all") {
      const notis = notifications?.find((item) => item?._id === value);
      const delNotis = itemSelected.filter((item) => item?._id !== value);
      setItemSelected(checked ? [...itemSelected, notis] : delNotis);
    } else {
      setItemSelected(checked ? notifications : []);
    }
  };

  const viewNotification = async (id) => {
    const res = await getNotification(id);
    setNotification(res?.msg);
  };

  const viewSentMessages = async () => {
    setNotification({});
    setIsSent(true);
    await sentMessages();
    setItemSelected([]);
  };

  const viewInbox = async () => {
    setNotification({});
    setIsSent(false);
    await inboxMessages();
    if (notificationId) {
      const res = await getNotification(notificationId);
      setNotification(res?.msg);
      setItemSelected([res?.msg]);
    }
  };

  const mark_as_read_unread = async (action) => {
    const ids = itemSelected.map((item) => item?._id);
    const action_type = action === "read" ? true : false;
    const res = await markAsReadUnRead(ids, action_type);
    if (res?.msg) {
      toast.success(res.msg);
    }
  };

  const handleDeleteNotifications = async () => {
    const ids = itemSelected.map((item) => item?._id);
    const res = await deleteNotifications(ids);
    if (res?.msg) {
      toast.success(res.msg);
      toggleTab.inbox ? await viewInbox() : await sentMessages();
    }
  };

  const composeMessage = async (message) => {
    const res = await sendMessage(message);
    if (res?.success) {
      toast.success(res.msg);
      setToggleMsgComposer(false);
    }
  };

  const formatDate = (date) => {
    const dateMoment = moment(date, "DD-MM-YYYY HH:mm:ss");
    // const now = moment()
    // if (dateMoment.isBefore(now, 'day')) {
    //   // Display month and year if the date is older than today
    //   return dateMoment.format("DD/MM YYYY");
    // } else {
    //   // Display time if the date is today or in the future
    //   return dateMoment.format("HH:mm");
    // }
    return dateMoment.format("DD/MM YYYY");
  };

  return (
    <>
      <div className={style.rootPage}>
        <ul className={style.tabs}>
          <li
            onClick={() => {
              viewInbox();
              setToggleTab((prev) => ({ inbox: true }));
            }}
            className={`${style.tab} ${toggleTab?.inbox ? style.active : ""}`}
          >
            <InboxIcon size={20} /> <span>Inbox</span>
          </li>
          <li
            onClick={() => {
              viewSentMessages();
              setToggleTab((prev) => ({ sentMessages: true }));
            }}
            className={`${style.tab} ${
              toggleTab?.sentMessages ? style.active : ""
            }`}
          >
            <OutboxIcon size={20} />
            <span>Sent Messages</span>
          </li>
        </ul>
        <div className={style.content}>
          <ul className={style.menu}>
            {notifications.length > 0 && (
              <li className={style.checkbox}>
                <input
                  type="checkbox"
                  onChange={selectNotifications}
                  value={"all"}
                />{" "}
                All
              </li>
            )}
            {itemSelected.length > 0 && (
              <>
                <li
                  className={style.action}
                  onClick={() => mark_as_read_unread("read")}
                >
                  Mark as Read
                </li>
                <li
                  className={style.action}
                  onClick={() => mark_as_read_unread("unread")}
                >
                  Mark as Unread
                </li>
                <li className={style.delete} onClick={() => setIsDelete(true)}>
                  Delete
                </li>
              </>
            )}
            <li
              className={style.compose}
              onClick={() => setToggleMsgComposer((prev) => !prev)}
            >
              <EditIcon /> <span>Compose</span>
            </li>
          </ul>

          <div className={style.inbox}>
            <div className={style.messageList}>
              {notifications && notifications.length ? (
                notifications?.map((item, index) => (
                  <div
                    className={`${style.notification} ${
                      notification?._id === item?._id
                        ? style.notification_active
                        : ""
                    }`}
                    key={index}
                    onClick={() => {
                      if (!responsive) return;
                      setViewMessage(true);
                    }}
                  >
                    <div className={style.selectMsg}>
                      {!isSent && (
                        <DotIcon
                          size={50}
                          className={!item?.is_read && style.unread}
                        />
                      )}
                      <input
                        type="checkbox"
                        onChange={selectNotifications}
                        value={item?._id}
                        className={`check check_${item?._id}`}
                      />
                    </div>
                    <div
                      className={style.left}
                      onClick={() => viewNotification(item?._id)}
                    >
                      <div className={style.initials}>{item?.author[0]}</div>
                      <div className={style.date}>
                        <h4>{formatDate(item?.date_created)?.split(" ")[0]}</h4>
                        <h4>{formatDate(item?.date_created)?.split(" ")[1]}</h4>
                      </div>
                    </div>
                    <div
                      className={style.right}
                      onClick={() => viewNotification(item?._id)}
                    >
                      <div className={style.author}>{item?.author}</div>
                      <div className={style.title}>{notification?.title}</div>
                      <hr />
                      <p className={style.message}>{item?.description}</p>
                      <div className={style.dateOnMobileView}>
                        <h4>{formatDate(item?.date_created)?.split(" ")[0]}</h4>
                        <h4>{formatDate(item?.date_created)?.split(" ")[1]}</h4>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p>No message yet!</p>
              )}
            </div>
            <div className={style.messageReader}>
              {Object.keys(notification).length > 0 && (
                <MessageReader notification={notification} />
              )}
            </div>
          </div>
        </div>
        {toggleMsgComposer && (
          <MessageComposer
            close={() => setToggleMsgComposer(false)}
            composeMessage={composeMessage}
          />
        )}
      </div>
      <ApplicationFooter />
      {isLoading && <Loader />}
      {isDelete && (
        <ConfirmDialog
          confirm={async () => {
            handleDeleteNotifications();
            // call delete action here
            setIsDelete(false);
          }}
          cancel={() => setIsDelete(false)}
          message={"Click continue to DELETE selected messages"}
        />
      )}
      {viewMessage && responsive && (
        <FullpageModal
          toggleModal={() => {
            setViewMessage(false);
          }}
          className={style.messageModal}
        >
          <div className={style.messageReadModal}>
            {Object.keys(notification).length > 0 && (
              <MessageReader notification={notification} />
            )}
          </div>
        </FullpageModal>
      )}
    </>
  );
}

export default NotificationPage;
