import { useEffect, useState } from "react";
import useCdReactQuery from "../../../utils/hooks/useCdReactQuery";
import { debounce } from "lodash";

import authApplication from "../../../service/auth";
import { toast } from "react-toastify";

const useContactUI2 = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    query_type: { id: 0, name: "" },
    message: "",
    recaptcha_token: "",
  });
  const [queryType, setQueryType] = useState("Select Query");
  const [response, setResponse] = useState(null);
  const { queryEndpoint, isLoading: loading, error } = useCdReactQuery();

  const sumbmitContact = async () => {
    const { name, email, query_type, message, recaptcha_token } = formData;
    const res = await queryEndpoint({
      endpointUrl: authApplication.contactUs,
      httpMethod: "POST",
      body: {
        name,
        email,
        query_type,
        message,
        recaptcha_token,
      },
    });

    setResponse(res);
  };

  const debouncedFn = debounce(
    (obj) => setFormData((prev) => ({ ...prev, ...obj })),
    500
  );

  const dropdownHandler = (option) => {
    setQueryType(option.name);
    setFormData((prev) => ({ ...prev, query_type: option }));
  };

  // Display api response message
  useEffect(() => {
    if (error?.code) {
      // Using this because the message from the abi is in an array. Clean up the api and refactor
      // this code to use the message coming from the api
      const msg =
        error.code === "ERR_BAD_REQUEST"
          ? "All fields are required"
          : "Network errror. Try again later or contact your school.";
      toast.error(msg);
    }
  }, [error?.code]);

  useEffect(() => {
    if (response?.msg) {
      toast.success(response.msg);
      setFormData({
        name: "",
        email: "",
        query_type: { id: 0, name: "" },
        message: "",
        recaptcha_token: "",
      });
    }
    setResponse(null); // This prevents toast success from coming up again
  }, [response?.msg]);

  return {
    loading,
    formData,
    setFormData,
    queryType,
    debouncedFn,
    dropdownHandler,
    sumbmitContact,
  };
};
export default useContactUI2;
