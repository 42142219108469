import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  user: {}
}

export const authSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setCurrentUser: (state, action) =>{
      state.user = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { setCurrentUser } = authSlice.actions

export default authSlice.reducer