import React from "react";
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  ResponsiveContainer,
} from "recharts";

const StrengthIndicator = ({ data, color }) => {
  const {
    fontColor,
    stroke,
    fill,
    fontSize = 12,
    gridColor = "#cccccc",
  } = color;

  return (
    <ResponsiveContainer width="100%" height="100%">
      <RadarChart cx="50%" cy="50%" outerRadius="60%" data={data}>
        <PolarGrid stroke={gridColor} />
        <PolarAngleAxis
          dataKey="subject"
          domain={[0, 100]}
          tick={{ fill: fontColor, fontSize }}
        />
        <Radar
          name="Strength_Weakness"
          dataKey="score"
          stroke={stroke}
          fill={fill}
          fillOpacity={0.6}
        />
      </RadarChart>
    </ResponsiveContainer>
  );
};

export default StrengthIndicator;
