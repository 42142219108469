/***********************************************************
HOW to update this file.
1. After you have created your page template in the templates folder,
2. Update the TEMPLATE_LIST with the 
   a. name (Always check to ensure the name you are about using does not exit. If it does, change your name to avoid collision)
   b. tempType (This can be any of the following - cover_page, table_of_content, book_page, question)
   c. tempGroup (This holds the type of content the template holds which are: text_img (For image and/or text content), 
      video_text (for text and/or video content), and audio_text (for text and/or audio content))
3. Call your View and Form components in the viewComponent and formComponent object function and pass the various properties to it.
   
***********************************************************/

// import { ContentTable1Form, ContentTable1View } from "./content_table1";
import { DefaultForm, DefaultView } from "./default";
import { QthemeForm1, QthemeView1 } from "./qtheme1";
// import { Theme1Form, Theme1View } from "./theme1";
// import { Theme2Form, Theme2View } from "./theme2";
// import { Theme3Form, Theme3View } from "./theme3";

export const TEMPLATE_LIST = [
  // Page themes
  {
    name: "default",
    tempType: "book_page",
    tempGroup: "text_img",
    viewComponent: (data, viewType) => (
      <DefaultView data={data} viewType={viewType} />
    ),
    formComponent: (data, pageUpdateHandler, updateImage) => (
      <DefaultForm
        data={data}
        submitFn={pageUpdateHandler}
        updateImage={updateImage}
      />
    ),
  },
  // Question Themes
  {
    name: "Qtheme1",
    tempType: "question",
    tempGroup: "text_img",
    viewComponent: (data, viewType) => (
      <QthemeView1 data={data} viewType={viewType} />
    ),

    formComponent: (data, pageUpdateHandler, updateImage) => (
      <QthemeForm1
        data={data}
        submitFn={pageUpdateHandler}
        updateImage={updateImage}
      />
    ),
  },
  // // Table of Content
  // {
  //   name: "content_table1",
  //   tempType: "table_of_content",
  //   tempGroup: "text_img",
  //   viewComponent: (data, viewType) => (
  //     <ContentTable1View data={data} viewType={viewType} />
  //   ),
  //   formComponent: (data) => <ContentTable1Form />,
  // },
  // // Book Theme
  // {
  //   name: "theme1",
  //   tempType: "book_page",
  //   tempGroup: "text_img",
  //   viewComponent: (data, viewType) => (
  //     <Theme1View data={data} viewType={viewType} />
  //   ),
  //   formComponent: (data) => <Theme1Form />,
  // },
  // {
  //   name: "theme2",
  //   tempType: "book_page",
  //   tempGroup: "text_img",
  //   viewComponent: (data, viewType) => (
  //     <Theme2View data={data} viewType={viewType} />
  //   ),
  //   formComponent: (data) => <Theme2Form />,
  // },
  // {
  //   name: "theme3",
  //   tempType: "book_page",
  //   tempGroup: "text_img",
  //   viewComponent: (data, viewType) => (
  //     <Theme3View data={data} viewType={viewType} />
  //   ),
  //   formComponent: (data) => <Theme3Form />,
  // },
];
