import React, { useState, useEffect } from "react";
import style from "./Timer.module.css";
import { BsClockHistory } from "react-icons/bs";

const Timer = ({ time, callbackFn }) => {
  const [hour, setHour] = useState(Math.floor(time / 60));
  const [minute, setMinute] = useState(time % 60);
  const [second, setSecond] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (second > 0) {
        setSecond((prevSecond) => prevSecond - 1);
      } else {
        if (minute > 0) {
          setMinute((prevMinute) => prevMinute - 1);
          setSecond(59);
        } else {
          if (hour > 0) {
            setHour((prevHour) => prevHour - 1);
            setMinute(59);
            setSecond(59);
          }
        }
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [hour, minute, second]);

  useEffect(() => {
    if (hour === 0 && minute === 0 && second === 0) {
      callbackFn();
    }
  }, [hour, minute, second, callbackFn]);

  return (
    <div className={style.clock}>
      <BsClockHistory />
      <span>{hour}</span> : <span>{minute}</span> : <span>{second}</span>
    </div>
  );
};

export default Timer;
