import { request } from "./api";
import env from "../env";

const API = env.API_URL;

const URL = {
  getNotifications: `${API}/notifications/get-all`,
  markNotificationAsRead: `${API}/notifications/notification/mark_as_read`,
};

function getNotifications(params) {
  return request(URL.getNotifications, params, "GET", {});
}
function markNotificationAsRead(params) {
  return request(URL.markNotificationAsRead, params, "PUT", {});
}


const notificationService = {
  getNotifications,
  markNotificationAsRead,
  fetchNotifications : `${API}/notification/get-all-received`,
  notificationByID : `${API}/notification/get-by-id`,
  sentMessages : `${API}/notification/get-all-sent`,
  markAsRead : `${API}/notification/mark-as-read`,
  markAsUnRead : `${API}/notification/mark-as-unread`,
  deleteNotifications : `${API}/notification/delete`,
  sendMessage : `${API}/notification/post`,
};

export default notificationService;
