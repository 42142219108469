import ExamPrepDashboard from "../pages/ExamPrep/ExamPrepDashboard";
import ExamPrepBucket from "../pages/ExamPrep/ExamPrepBuckets";
import QuickReview from "../pages/ExamPrep/ExamPrepQuickReview";
import PerformanceTracker from "../pages/ExamPrep/ExamPrepPerformanceTracker/PerformanceTracker";
export const examprepRoutes = [
  {
    path: "",
    element: <ExamPrepDashboard />,
  },
  {
    path: "buckets",
    element: <ExamPrepBucket />,
  },
  {
    path: "quick-review",
    element: <QuickReview />,
  },
  {
    path: "performance-tracker",
    element: <PerformanceTracker />,
  },
];
