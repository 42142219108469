export const totalStudents = {
  data: [
    {
      name: "Jan",
      value: 4000,
    },
    {
      name: "Feb",
      value: 3000,
    },
    {
      name: "Mar",
      value: 5000,
    },
    {
      name: "Apr",
      value: 2780,
    },
    {
      name: "May",
      value: 1890,
    },
    {
      name: "Jun",
      value: 2390,
    },
  ],
  activeIndex: 2,
};
export const totalRevenue = [
  {
    name: "Jan",
    value: 4000,
  },
  {
    name: "Feb",
    value: 3000,
  },
  {
    name: "Mar",
    value: 5000,
  },
  {
    name: "Apr",
    value: 2780,
  },
  {
    name: "May",
    value: 1890,
  },
  {
    name: "Jun",
    value: 2390,
  },
];
export const retainer = [
  {
    name: "Jan",
    renewed: 4000,
    exited: 1300,
  },
  {
    name: "Feb",
    renewed: 3000,
    exited: 1100,
  },
  {
    name: "Mar",
    renewed: 5000,
    exited: 3000,
  },
  {
    name: "Apr",
    renewed: 2780,
    exited: 1000,
  },
  {
    name: "May",
    renewed: 2780,
    exited: 4000,
  },
  {
    name: "Jun",
    renewed: 2390,
    exited: 300,
  },
];
export const mostViewedBooks = [
  { image: "", topic: "Indices", subject: "Mathematics", views: 20 },
  { image: "", topic: "Electromagnetism", subject: "Physics", views: 10 },
  { image: "", topic: "Algebra", subject: "Mathematics", views: 6 },
];
export const expiryList = [
  {
    expiry_date: "2024-03-15",
    name: "James Anthony",
    class: "Primary",
    amount: 1000,
    status: "Expired",
  },
  {
    expiry_date: "2024-06-20",
    name: "Richie Blake",
    class: "Secondary",
    amount: 1500,
    status: "Active",
  },
  {
    expiry_date: "2024-09-10",
    name: "Karo Chika",
    class: "Secondary",
    amount: 2000,
    status: "Active",
  },
  {
    expiry_date: "2024-05-30",
    name: "Test Daudu",
    class: "Primary",
    amount: 1000,
    status: "Expired",
  },
  {
    expiry_date: "2024-07-12",
    name: "Ehibor Emegaih",
    class: "Primary",
    amount: 1200,
    status: "Active",
  },
  {
    expiry_date: "2024-04-25",
    name: "Rustle Faroque",
    class: "Secondary",
    amount: 900,
    status: "Expired",
  },
  {
    expiry_date: "2024-08-05",
    name: "Morgan Grace",
    class: "Secondary",
    amount: 1000,
    status: "Active",
  },
  {
    expiry_date: "2024-11-22",
    name: "Agohgoh Henry",
    class: "Primary",
    amount: 1800,
    status: "Active",
  },
  {
    expiry_date: "2024-10-08",
    name: "Ruky Ijoma",
    class: "Primary",
    amount: 1300,
    status: "Expired",
  },
  {
    expiry_date: "2024-12-01",
    name: "Fred Akpobeta",
    class: "Secondary",
    amount: 1000,
    status: "Active",
  },
];
export const courseStat = [
  { name: "Read", value: 402 },
  { name: "Sold", value: 120 },
];
