import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { darkTranspezuium } from "../../assets/image";
import classes from "./CatalogBanner.module.css";
function CatalogBanner({ className, image, bannerText, bannerTitle, onClick }) {
  return (
    <div className={`${classes.bannerRoot} ${className}`}>
      {!!(bannerTitle || onClick) && (
        <button onClick={onClick} className={classes.pageTile}>
          {!!onClick && (
            <MdOutlineKeyboardBackspace className={classes.backIcon} />
          )}
          {bannerTitle}
        </button>
      )}
      <div className={classes.bannerSmallText}>{bannerText}</div>
      {!!image && (
        <img className={classes.darkImgBanner} src={darkTranspezuium} alt="" />
      )}
      <img className={classes.bookStack} src={image} alt="" />
    </div>
  );
}

export default CatalogBanner;
