import { BsArrowLeft } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
// import OtpInput from "react-otp-input";
import Button from "../../components/Button";
import classes from "./EmailConfirmationForm.module.css";
import OtpInputComp from "../../components/OtpIputComp/OtpIputComp";

function EmailConfirmationForm() {
  const history = useNavigate();
  return (
    <div className={classes.compRoot}>
      <div className={classes.mainBody}>
        <h2 className={classes.formTitle}>Congratulations !!</h2>
        <p className={classes.formText}>
          Your account has been created.
          <br />
          We have send a confirmation code to your email -{" "}
          <b>prince@gmail.com</b>
          <br />
          Copy your activation code and paste in the box below.
        </p>
        <div className={classes.otpInputContainer}>
          <OtpInputComp numInputs={6} />
        </div>
        <div className={classes.actionBtnWrapper}>
          <button
            onClick={() => history("/login")}
            className={classes.backTologin}
          >
            <BsArrowLeft className={classes.arrowBack} />
            <span>Back To Login</span>
          </button>
          <Button className={classes.verifyBtn}>Verify</Button>
        </div>
        <p className={classes.infoText}>
          Didn’t get the code?{" "}
          <b onClick={() => {}} className={classes.requestNewCode}>
            {" "}
            Request a new code{" "}
          </b>{" "}
          <br /> If you cannot find the email in your inbox, please check your
          junk or spam folder.
        </p>
      </div>
    </div>
  );
}

export default EmailConfirmationForm;
