import React, { useState, useCallback } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./styles.css";

interface QuillTextAreaProps {
  initialValue?: string;
  onChange: (value: string) => void;
}

const QuillTextArea: React.FC<QuillTextAreaProps> = ({
  initialValue = "",
  onChange = () => {},
}) => {
  const [value, setValue] = useState<string>(initialValue);
  const handleChange = useCallback(
    (content: string) => {
      setValue(content);
      onChange(content);
    },
    [onChange]
  );

  return (
    <div>
      <ReactQuill
        value={value}
        onChange={handleChange}
        modules={{
          toolbar: [
            [{ header: [1, 2, 3, false] }],
            ["bold", "italic", "underline"],
            [{ list: "ordered" }, { list: "bullet" }],
            ["link"],
            [{ align: [] }],
            ["clean"],
          ],
        }}
        formats={[
          "header",
          "size",
          "bold",
          "italic",
          "underline",
          "list",
          "bullet",
          "link",
          "align",
        ]}
        theme="snow"
        className="my-quill"
      />
    </div>
  );
};

export default QuillTextArea;
