import { connect } from "react-redux";
import { showConfirmationPopup } from "../../redux/feature/confrimationPopupSlice";
import ConfirmationPopup from "./ConfirmationPopup";

function mapStateToProps({ confirmationPopup }) {
  return {
    isConfirmOpen: confirmationPopup.isConfirmOpen,
    headerText: confirmationPopup.headerText,
    bodyText: confirmationPopup.bodyText,
    onModalConfirmDismissed: confirmationPopup.onConfirmModalDismissed,
    onConfirm: confirmationPopup.onConfirm,
    buttonText: confirmationPopup.buttonText,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showConfirmationPopup: (data) => dispatch(showConfirmationPopup(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationPopup);
