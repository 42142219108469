import adminService from "../../../../service/admin.service";
import useCdReactQuery from "../../../../utils/hooks/useCdReactQuery";

const useCurriculum = ( ) => {
    const { queryEndpoint, isLoading, error } = useCdReactQuery();

    const curriculumSemesterTopics = async (params) => {
        const response = await queryEndpoint({
            endpointUrl: adminService.curriculumSemesterTopics,
            httpMethod : "GET",
            params : params
          });
          return response?.topics;
    }

    const topicsBySubjectID = async (subject_id) => {
        const response = await queryEndpoint({
            endpointUrl: adminService.topicsBySubjectID(subject_id),
            httpMethod : "GET",
        });
        const topics = response?.data || []
        return topics.map(topic => ({name : topic.name, id : topic._id.$oid}));
    }

    const saveCurriculum = async (curriculum, isEdit) => {
        const response = await queryEndpoint({
            endpointUrl: adminService.curriculum,
            httpMethod : isEdit ? "PUT" : "POST",
            body : curriculum
        });
        return response;
    }

    const deleteCurriculum = async (curriculum_id) => {
        const response = await queryEndpoint({
            endpointUrl: adminService.curriculums(curriculum_id),
            httpMethod : "DELETE",
        });
        return response?.msg;
    }

    const saveTopicToCurriculumTerm = async (topic) => {

        const response = await queryEndpoint({
            endpointUrl: adminService.curriculumTopic,
            httpMethod : topic.curriculum_details_id ? "PUT" : "POST" ,
            body : topic
        });
        
        return response?.msg;
    }

    const curriculumTopic = async (params) => {
        const response = await queryEndpoint({
            endpointUrl: adminService.curriculumTopic,
            httpMethod : "GET",
            params : params
          });
          return response;
    }

    const deleteCurriculumTopic = async (topic_id) => {

        const response = await queryEndpoint({
            endpointUrl: adminService.deleteCurriculumTopic(topic_id),
            httpMethod : "DELETE",
        });
        return response?.msg;
    }


    return {
        curriculumTopic,
        curriculumSemesterTopics,
        deleteCurriculum,
        saveCurriculum,
        topicsBySubjectID,
        deleteCurriculumTopic,
        saveTopicToCurriculumTerm,
        isLoading,
        error,
    }
}

export default useCurriculum;