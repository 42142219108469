export const catalogColors = {
  0: "#3c78e9",
  1: "#8a0f49",
  2: "#4c8695",
  3: "#86258f",
  4: "#e38906",
  5: "#147cc4",
  6: "#7027a5",
  7: "#d74726",
  8: "#0b863b",
  9: "#eabc00",
};
export const catalogBgColors = {
  0: "#edf2fd",
  1: "#fdf0f6",
  2: "#f3f8f9",
  3: "#fdf8fd",
  4: "#fffaf4",
  5: "#147cc4",
  6: "#7027a5",
  7: "#d74726",
  8: "#0b863b",
  9: "#eabc00",
};
