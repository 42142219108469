import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { setIsloading } from "../../../redux/feature/progressLoaderSlice";
import authApplication from "../../../service/auth";

function useResetPassword() {
  const [email, setEmail] = useState("");
  const reduxDispatch = useDispatch();

  function handlePasswordReset() {
    reduxDispatch(setIsloading(true));
    authApplication
      .resetPassword({ email })
      .then(() => {
        toast.success("Password reset link has been sent to your email");
      })
      .catch(({ msg }) => {
        toast.error("Invalid email address");
      })
      .finally(() => {
        reduxDispatch(setIsloading(false));
      });
  }

  return {
    email,
    setEmail,
    handlePasswordReset,
  };
}

export default useResetPassword;
