import { useNavigate } from "react-router-dom";
// import { sampleLogo } from "../../assets/image";
import style from "./AuthLayout.module.css";
import { MdOutlineKeyboardBackspace } from "react-icons/md";

function AuthLayout({ children }) {
  const history = useNavigate();
  return (
    <div className={style.pageRoot}>
      <div className={style.aside}>
        <button className={style.backHomeButton} onClick={() => history("/")}>
          <MdOutlineKeyboardBackspace />
          <span style={{ fontSize: "0.8em" }}>Back</span>
        </button>
      </div>
      <div className={style.content}>{children}</div>
    </div>
  );
}

export default AuthLayout;
