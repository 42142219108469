import { MDBSpinner } from "mdb-react-ui-kit";
import classes from "./Button.module.css";

const Button = ({
  children,
  className,
  type = "button",
  onClick,
  isLoading,
  ...res
}) => {
  return (
    <button
      onClick={onClick}
      type={type}
      disabled={isLoading}
      className={`${classes.button} ${className}`}
      {...res}
    >
      {isLoading ? (
        <MDBSpinner className={classes.loader} role="status" />
      ) : (
        children
      )}
    </button>
  );
};

export default Button;
