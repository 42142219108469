// Module
import { DeleteIcon, EditIcon } from "../../../../assets/icons";

// Components
import Tooltip from "../../../../components/Tooltip";

// Hooks
import useBookForm from "../hooks/useBookForm";

// Style
import style from "./EditBook.module.css";

export const PageAction = ({
  templateForm,
  index,
  pageId,
  data,
  position,
  totalPages,
  deletePage,
}) => {
  const { state, updateFormState } = useBookForm();

  const deletePageHandler = () => {
    deletePage(pageId);
  };

  const positionChangeHandler = (e) => {
    const indx = parseInt(e.target.value);
    const pagesCopy = [...state.pages];
    const selectedPage = pagesCopy[position];
    pagesCopy.splice(parseInt(position), 1); // remove the element at the specified position to avoid having it twice
    pagesCopy.splice(indx, 0, selectedPage); // add element into the specified index position
    updateFormState({ pages: pagesCopy });
  };

  return (
    <>
      <span className={style.thumbnailAction}>
        <Tooltip data="Edit">
          <i onClick={() => templateForm(index, pageId, data)}>
            <EditIcon />
          </i>
        </Tooltip>
        <Tooltip data="Delete">
          <i onClick={deletePageHandler}>
            <DeleteIcon />
          </i>
        </Tooltip>

        <select value={position} onChange={positionChangeHandler}>
          {[...new Array(totalPages)].map((count, index) => (
            <option key={index} value={index}>
              {index + 1}
            </option>
          ))}
        </select>
      </span>
    </>
  );
};
