import classNames from "classnames";
import style from "./OverviewModal.module.css";
import { FullpageModal } from "../../../../components/Modal";

function OverviewModal({ Component, isOpen, onClose }) {
  return (
    isOpen && (
      <FullpageModal
        className={classNames(style.modalWrapper)}
        toggleModal={onClose}
      >
        <Component />
      </FullpageModal>
    )
  );
}

export default OverviewModal;
