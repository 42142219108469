import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../components/Button";
import Dropdown from "../../components/Dropdown/Dropdown";
import InputField from "../../components/InputField";
import AuthLayout from "../../layouts/AuthLayout";
import { setPopupChild, showPopup } from "../../redux/feature/popupSlice";
import TermsAndConditions from "../TermsAndConditions";
import useRegistrationPage from "./hooks/useRegistrationPage";
import style from "./RegistrationPage.module.css";
import { useEffect, useState } from "react";
import useCategories from "./hooks/useCategories";
import {
  MdOutlineKeyboardBackspace,
  MdOutlineKeyboardArrowRight,
  MdOutlineKeyboardArrowLeft,
} from "react-icons/md";

function RegistrationPage() {
  // get the registration type
  const { account_type } = useParams();

  const { state, isLoading, handleRegistration, dispatch } =
    useRegistrationPage();

  const { categories, studentClasses } = useCategories();
  const [selectedCategoryClasses, setSelectedCategoryClasses] = useState([]);

  const {
    firstName,
    lastName,
    email,
    phoneNumber,
    password,
    cPassword,
    accountType,
    hasAree,
  } = state;

  const reduxDispatch = useDispatch();

  function handleTermsAndCondition() {
    reduxDispatch(setPopupChild(<TermsAndConditions />));
    reduxDispatch(
      showPopup({
        open: true,
      })
    );
  }

  useEffect(() => {
    if (account_type) {
      dispatch({ accountType: account_type });
    }
    // eslint-disable-next-line
  }, [account_type]);

  //Page display
  const [currentPage, setCurrentPage] = useState(1);
  const history = useNavigate();
  return (
    <AuthLayout>
      {/* <div className={style.white}>
        <div className={style.mainBody}>
          <div className={style.formRootWrapper}>
            <div className={style.formContainer}>
              <div className={style.logoWrapper}>
                <h4 className={style.logoName}>
                  Ctr<span> learn</span>{" "}
                </h4>
              </div>
              <h2 className={style.header}>Get on board</h2>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <div className={style.formField}>
                  <div className={style.inputRow}>
                    <div className={style.eachInput}>
                      <label>First Name</label>
                      <InputField
                        type="text"
                        placeholder="First Name"
                        className={style.inputField}
                        onChange={({ target }) =>
                          dispatch({ firstName: target.value })
                        }
                        value={firstName}
                      />
                    </div>
                    <div className={style.eachInput}>
                      <label>Last Name</label>
                      <InputField
                        type="text"
                        placeholder="Last Name"
                        className={style.inputField}
                        onChange={({ target }) =>
                          dispatch({ lastName: target.value })
                        }
                        value={lastName}
                      />
                    </div>
                  </div>

                  <div className={style.inputRow}>
                    <div className={style.eachInput}>
                      <label>Email</label>
                      <InputField
                        type="email"
                        placeholder="E-mail "
                        className={style.inputField}
                        onChange={({ target }) =>
                          dispatch({ email: target.value })
                        }
                        value={email}
                      />
                    </div>
                    <div className={style.eachInput}>
                      <label>Phone Number</label>
                      <InputField
                        type="phone"
                        placeholder="Phone Number "
                        className={style.inputField}
                        name="phoneNumber"
                        onChange={({ target }) =>
                          dispatch({ phoneNumber: target.value })
                        }
                        value={phoneNumber}
                      />
                    </div>
                  </div>

                  <div
                    className={`${style.inputRow} ${
                      accountType !== "student" ? style.invisible : null
                    }`}
                  >
                    <div className={style.eachInput}>
                      <label>Category</label>
                      <Dropdown
                        options={categories}
                        value="Select"
                        onChange={(e) => {
                          dispatch({ category_id: e?.id });
                          setSelectedCategoryClasses(studentClasses[e.name]);
                        }}
                      />
                    </div>
                    <div className={style.eachInput}>
                      <label>Class </label>
                      <Dropdown
                        options={selectedCategoryClasses}
                        value={"Select"}
                        onChange={(e) => {
                          dispatch({ subcategory_id: e?.id });
                        }}
                      />
                    </div>
                  </div>

                  <div className={style.inputRow}>
                    <div className={style.eachInput}>
                      <label>Password</label>
                      <InputField
                        type="password"
                        placeholder="Password"
                        className={style.inputField}
                        onChange={({ target }) =>
                          dispatch({ password: target.value })
                        }
                        value={password}
                      />
                    </div>
                    <div className={style.eachInput}>
                      <label>Confirm Password</label>
                      <InputField
                        type="password"
                        placeholder="Confirm-Password"
                        className={style.inputField}
                        onChange={({ target }) =>
                          dispatch({ cPassword: target.value })
                        }
                        value={cPassword}
                      />
                    </div>
                  </div>
                </div>

                <div className={style.termCondition}>
                  <input
                    type="checkbox"
                    checked={hasAree}
                    onChange={({ target }) =>
                      dispatch({ hasAree: target.checked })
                    }
                  />
                  <label className={style.signupContent}>
                    I agree all statements in{" "}
                    <span onClick={handleTermsAndCondition}>
                      Terms of service{" "}
                    </span>
                  </label>
                </div>
                <div className={style.actionsSection}>
                  <Button
                    onClick={handleRegistration}
                    className={style.signupSubmit}
                    type="submit"
                    isLoading={isLoading}
                  >
                    Create account
                  </Button>
                </div>
              </form>
            </div>
          </div>

          <div className={style.routeLogin}>
            Already have an account?{" "}
            <Link to="/login">
              <span className={style.under}>Log-In</span>
            </Link>
          </div>
        </div>
      </div> */}
      <div className={style.root}>
        <p>
          <button
            className={`${style.mobile} ${style.back}`}
            href="#"
            onClick={() => history("/login")}
          >
            <MdOutlineKeyboardBackspace />{" "}
            <span style={{ fontSize: "1em", paddingLeft: "5px" }}>
              ctrLearn
            </span>
          </button>
          {/* ctrLearn */}
        </p>
        <div className={style.header}>
          <h2>Registration</h2>
          <p>Get on board!!!</p>
        </div>
        <ul className={style.pages}>
          <li
            className={`${style.page} ${currentPage === 1 ? style.active : ""}`}
            onClick={() => setCurrentPage(1)}
          >
            1
          </li>
          <li
            className={`${style.page} ${currentPage === 2 ? style.active : ""}`}
            onClick={() => setCurrentPage(2)}
          >
            2
          </li>
        </ul>
        <form onSubmit={(e) => e.preventDefault()}>
          <div
            className={`${style.formGroup} ${
              currentPage !== 1 ? style.hide : ""
            }`}
          >
            <div>
              <label>First Name</label>
              <InputField
                type="text"
                placeholder="First Name"
                onChange={({ target }) => dispatch({ firstName: target.value })}
                value={firstName}
                className={style.inputField}
              />
            </div>
            <div>
              <label>Last Name</label>
              <InputField
                type="text"
                placeholder="Last Name"
                onChange={({ target }) => dispatch({ lastName: target.value })}
                value={lastName}
              />
            </div>
            <div>
              <label>Email</label>
              <InputField
                type="email"
                placeholder="E-mail "
                onChange={({ target }) => dispatch({ email: target.value })}
                value={email}
              />
            </div>
            <div>
              <label>Phone Number</label>
              <InputField
                type="phone"
                placeholder="Phone Number "
                name="phoneNumber"
                onChange={({ target }) =>
                  dispatch({ phoneNumber: target.value })
                }
                value={phoneNumber}
              />
            </div>
          </div>
          <div
            className={`${style.formGroup} ${
              currentPage !== 2 ? style.hide : ""
            }`}
          >
            <div className={`${accountType !== "student" ? style.hide : ""}`}>
              <label>Category</label>
              <Dropdown
                options={categories}
                value="Select"
                onChange={(e) => {
                  dispatch({ category_id: e?.id });
                  setSelectedCategoryClasses(studentClasses[e.name]);
                }}
              />
            </div>

            <div className={`${accountType !== "student" ? style.hide : ""}`}>
              <label>Class </label>
              <Dropdown
                options={selectedCategoryClasses}
                value={"Select"}
                onChange={(e) => {
                  dispatch({ subcategory_id: e?.id });
                }}
              />
            </div>

            <div>
              <label>Password</label>
              <InputField
                type="password"
                placeholder="Password"
                onChange={({ target }) => dispatch({ password: target.value })}
                value={password}
              />
            </div>
            <div>
              <label>Confirm Password</label>
              <InputField
                type="password"
                placeholder="Confirm-Password"
                onChange={({ target }) => dispatch({ cPassword: target.value })}
                value={cPassword}
              />
            </div>
            <div className={style.termCondition}>
              <input
                type="checkbox"
                checked={hasAree}
                onChange={({ target }) => dispatch({ hasAree: target.checked })}
              />
              <label>
                I agree all statements in
                <span onClick={handleTermsAndCondition}>Terms of service </span>
              </label>
            </div>
          </div>
          <div className={style.formBtn}>
            {currentPage > 1 && (
              <button
                className={style.previousBtn}
                onClick={() => setCurrentPage((prev) => prev - 1)}
              >
                <MdOutlineKeyboardArrowLeft />
                Previous
              </button>
            )}
            {currentPage < 2 && (
              <button
                className={style.nextBtn}
                onClick={() => setCurrentPage((prev) => prev + 1)}
              >
                Next
                <MdOutlineKeyboardArrowRight />
              </button>
            )}
            {currentPage >= 2 && (
              <Button
                onClick={handleRegistration}
                className={style.submitBtn}
                type="submit"
                isLoading={isLoading}
              >
                Create account
              </Button>
            )}
          </div>
        </form>
      </div>
    </AuthLayout>
  );
}

export default RegistrationPage;
