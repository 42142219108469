import { useEffect, useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import authApplication from "../../../service/auth";
import { QUERY_KEY } from "../../../utils/constants";
import useAppMutation from "../../../utils/hooks/useAppMutation";
import { useLocation } from "react-router-dom";
import { URL } from "../../../service/subscription.service";
import env from "../../../env";
import useCdReactQuery from "../../../utils/hooks/useCdReactQuery";

function useLogin() {
  const initialState = {
    email: "",
    password: "",
  };
  const history = useNavigate();
  const [state, dispatch] = useReducer(
    (stateValue, value) => ({ ...stateValue, ...value }),
    initialState
  );

  const [popup, setPopup] = useState({
    open: false,
    type: "",
  });

  const { email, password } = state;

  const { isLoading, mutateAsync: asycRegistration } = useAppMutation(
    QUERY_KEY.login,
    authApplication.login
  );

  const { queryEndpoint, isLoading: loading } = useCdReactQuery();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paymentReference = queryParams.get("reference");
  const planId = queryParams.get("plan_id");
  // const { queryEndpoint: validatePayment, isLoading: validatingPayment } =
  //   useCdReactQuery();

  function handleLogin(e) {
    e.preventDefault();
    if (!email) {
      toast.error("Email is required");
      return;
    }
    if (!password) {
      toast.error("Passowrd is required");
      return;
    }
    asycRegistration({
      email,
      password,
    })
      .then(({ data: token, msg, subCategory = "" }) => {
        // toast.success(msg);
        localStorage.setItem("accessToken", token);
        localStorage.setItem("accessEmail", email);
        localStorage.setItem("subcategory", JSON.stringify(subCategory));
        history("/dashboard");
      })
      .catch((err) => {
        if (err?.subscription_status) {
          localStorage.setItem("accessToken", err.data);
          localStorage.setItem("email", email);
          setPopup({
            open: true,
            type: err?.subscription_status,
            email: email,
            plan_id: err?.plan_id,
          });
        } else {
          toast.error(err.msg);
        }
      })
      .finally(() => {});
  }

  const togglePopup = () => {
    setPopup({ ...popup, open: false });
  };

  const handleTopUp = async () => {
    try {
      await queryEndpoint({
        endpointUrl: URL.topupUnit,
        httpMethod: "PUT",
        body: {
          plan_id: popup?.plan_id,
          callback_url: `${env.APP_URL}/login?plan_id=${popup?.plan_id}`,
          currency: "NGN",
          email: popup?.email,
        },
      });
    } catch (error) {
      if (error?.auth_url) {
        window.location.href = error?.auth_url;
        return;
      }
      toast.error(error?.detail);
    }
  };

  const handleSubRenew = async () => {
    try {
      await queryEndpoint({
        endpointUrl: URL.subscriptionPayment,
        httpMethod: "POST",
        body: {
          plan_id: popup?.plan_id,
          callback_url: `${env.APP_URL}/login?plan_id=${popup?.plan_id}`,
          currency: "NGN",
          email: popup?.email,
        },
      });
    } catch (error) {
      if (error?.auth_url) {
        window.location.href = error?.auth_url;
        return;
      }
      toast.error(error?.detail);
    }
  };

  const validatePayment = async (
    plan_id,
    reference_number,
    email,
    revalidate = true
  ) => {
    try {
      const response = await queryEndpoint({
        endpointUrl: URL.verifySubscription,
        httpMethod: "POST",
        body: { reference_number, email, plan_id, revalidate },
      });
      toast.success(response.msg);
    } catch (error) {
      toast.error(error.detail);
    }
    history("/login");
  };

  useEffect(() => {
    const email = localStorage.getItem("email");
    if (paymentReference && email)
      validatePayment(planId, paymentReference, email);
  }, [paymentReference, planId]); // eslint-disable-line

  // async function revalidatePayment({
  //   plan_id,
  //   reference_number,
  //   revalidate = false,
  // }) {
  //   await validatePlanPayment({ plan_id, reference_number, revalidate });
  // }

  return {
    isLoading,
    state,
    handleLogin,
    dispatch,
    popup,
    togglePopup,
    handleTopUp,
    handleSubRenew,
    loading,
  };
}

export default useLogin;
