import Button from "../../../../components/Button/Button";
import Dropdown from "../../../../components/Dropdown";
import style from "./Modal.module.css";
import useBookForm from "../../Library/hooks/useBookForm";
import { updateState } from "../../../../redux/feature/questionBankSlice";
import { useDispatch } from "react-redux";
const ClassSingleModal = ({ closeModal }) => {
  const dispatch = useDispatch();
  const { classes, updateClassSubjectList } = useBookForm();

  const searchHandler = () => {
    closeModal();
  };
  return (
    <div className={style.modalBackdrop}>
      <div className={style.modal}>
        <div className={style.formGroup}>
          <div>Class</div>
          <Dropdown
            options={classes}
            value={"Select"}
            onChange={(e) => {
              updateClassSubjectList(e.name, e.id);
              dispatch(updateState({ userClass: { id: e.id, name: e.name } }));
            }}
            className={style.cls}
          />
        </div>
        <Button onClick={searchHandler}>SEARCH</Button>
      </div>
    </div>
  );
};
export default ClassSingleModal;
