import { useEffect, useReducer } from "react";
import { useParams } from "react-router-dom";
import libraryService from "../../../../service/library.service";
import { QUERY_KEY } from "../../../../utils/constants";
import { search } from "../../../../utils/helpers";
import useAppQuery from "../../../../utils/hooks/useAppQuery";

function useBookCatalog() {
  const initialState = {
    selectedCategory: {},
    searchText: "",
  };

  const [state, dispatch] = useReducer(
    (stateValue, value) => ({ ...stateValue, ...value }),
    initialState
  );
  const { searchText } = state;
  const { courseId } = useParams();

  const {
    data: { data: categories = [] } = {},
    isLoading: fetchingCategories,
  } = useAppQuery(QUERY_KEY.getCategories, libraryService.getCategories, {});

  useEffect(() => {
    if (!courseId && categories?.length) {
      dispatch({ selectedCategory: categories[0] });
    }
  }, [categories, courseId]);

  const searchCategories = search(searchText, categories, ["name"]);

  return {
    state,
    searchCategories,
    fetchingCategories,
    dispatch,
  };
}

export default useBookCatalog;
