import useMyShelf from "./hooks/useMyShelf";
import CatalogLayout from "../BookCatalog/Catalog";

const MyShelf = () => {
  const { data, isLoading, returnBook, searchShelf } = useMyShelf();

  return (
    <CatalogLayout
      data={data}
      searchPlaceholder="Search Shelf"
      navLink="/library"
      navText="My Shelf"
      isLoading={isLoading}
      returnBook={returnBook}
      searchFn={searchShelf}
    />
  );
};

export default MyShelf;
