import classes from "./ProgressLoader.module.css";

function ProgressLoader({ isLoading }) {
  return (
    <div
      className={`${classes.loaderRoot} ${
        isLoading ? classes.onLoading : undefined
      }`}
    />
  );
}

export default ProgressLoader;
