import { useEffect, useState } from "react";
import useCdReactQuery from "../../../../utils/hooks/useCdReactQuery";
import libraryService from "../../../../service/library.service";

function useSubjects() {
  const { data, queryEndpoint, isLoading } = useCdReactQuery();
  const [subjects, setSubjects] = useState([])


  useEffect(() => {
    (async () => {
      await queryEndpoint({
        endpointUrl: libraryService.subjects,
      });
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data) {
        setSubjects(data.data)
    }
  }, [data]);


  return {
    isLoading,
    subjects,
  };
}

export default useSubjects;
