import React from "react";
// import style from "./ImageView.module.css";

const ImageView = ({ imageUrl, question, style = "" }) => {
  if (!imageUrl) return null;
  return (
    <>
      <div className={style.questionImage}>
        <img src={imageUrl} alt="Question" />
      </div>
      <div className={`${style.question} ${style}`}>
        {/* <h5>Question :</h5> */}
        {/* <p>{question}</p> */}
      </div>
    </>
  );
};

export default ImageView;
