import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { navPaths } from "../../utils/constants";
import { SlHome } from "react-icons/sl";
import { BsInfoCircle } from "react-icons/bs";
import { LuMailPlus } from "react-icons/lu";
import { FaBoxesStacked } from "react-icons/fa6";
import { AiOutlineLogout } from "react-icons/ai";
import "./style.css";
const Navbar = ({ forwardedRef }) => {
  const [showNavItems, setShowNavItems] = useState(false);
  const [color, setColor] = useState(false);
  const history = useNavigate();

  function handleChangeNavColor() {
    if (window.scrollY >= 90) {
      setColor(true);
    } else {
      setColor(false);
    }
  }

  window.addEventListener("scroll", handleChangeNavColor);

  const iconLoader = (menuName) => {
    switch (menuName.toLowerCase()) {
      case "home":
        return <SlHome size={20} className="mobile" />;
      case "about":
        return <BsInfoCircle size={20} className="mobile" />;
      case "contacts":
        return <LuMailPlus size={20} className="mobile" />;
      case "features":
        return <FaBoxesStacked size={20} className="mobile" />;
      default:
        return "";
    }
  };

  return (
    <>
      <div
        ref={forwardedRef}
        className={`nav-section ${color ? "nav-section-bg" : undefined}`}
      >
        <nav>
          <div className="nav-content">
            <div className="logo">ctrLearn</div>
            <div className={showNavItems ? "nav-menu-list" : "hide-menu-list"}>
              <ul>
                {navPaths.map(({ name, path }) => (
                  <li key={name}>
                    {iconLoader(name)}
                    <span
                      onClick={() => {
                        setShowNavItems(false);
                        history("/", { state: path });
                      }}
                    >
                      {name}
                    </span>
                  </li>
                ))}
              </ul>
              <div className="aside-nav1">
                <Link to="/login" className="nav-btn mobile under">
                  <AiOutlineLogout size={20} className="mobile" />
                  SignUp / SignIn
                </Link>
              </div>
            </div>
            <div className="aside-nav">
              <Link to="/login" className="nav-btn under">
                SignUp / SignIn
              </Link>
            </div>
            <div
              onClick={() => setShowNavItems(!showNavItems)}
              className="hambuger"
            >
              {showNavItems ? (
                <i className="fa-solid fa-xmark"></i>
              ) : (
                <i className="fa-solid fa-bars"></i>
              )}
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Navbar;
