import { useContext, useEffect, useState } from "react";
import AdminContext from "../../../../context/AdminContext";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import {
  deleteImage,
  deleteImageFolder,
  addImage,
} from "./functions/image_manager";
import { toast } from "react-toastify";

const useBookForm = () => {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const {
    state,
    updateFormState,
    updatePages,
    saveBook,
    deleteBook,
    createBook,
    books,
    isLoading,
    classes,
    subjects,
    updatePulishStatus,
  } = useContext(AdminContext);

  const [previewClicked, setPreviewClicked] = useState({
    modal: false,
    index: 0,
    pageId: 0,
    type: "",
    data: {},
  });

  const [readerModal, setReaderModal] = useState(false);
  const [classSubjects, setClassSubjects] = useState({});
  const [subjectTopics, setSubjectTopics] = useState([]);

  /******************************************/
  /******** Update states operations ********/
  /******************************************/

  // Subjects for a class and the corresponding topics updated when edit is clicked for a book
  useEffect(() => {
    updateClassSubjectList(state.class, state.class_id);
    // eslint-disable-next-line
  }, [state.class]);

  useEffect(() => {
    updateTopicList(state.subject);
    // eslint-disable-next-line
  }, [classSubjects]);

  /******************************************/
  /************ Form operations *************/
  /******************************************/
  // Reduce number of event calls using debounce
  const debounceFn = _.debounce((event, fieldName) => {
    updateFormState({ [fieldName]: event.target.value });
  }, 500);

  const updateFormStateDataFromInputField = (event, fieldName) =>
    debounceFn(event, fieldName);

  /******************************************/
  /*********** Toggle operations ************/
  /******************************************/
  const toggleModal = () => {
    setModalOpen((prev) => !prev);
  };

  const toggleReaderModal = () => {
    setReaderModal((prev) => !prev);
  };

  /******************************************/
  /********** Form Page Operations **********/
  /******************************************/
  const previewPage = (index) =>
    setPreviewClicked((prev) => ({
      modal: !prev.modal,
      index,
      type: "preview",
    }));

  const openPageForm = (index, pageId, data) =>
    setPreviewClicked((prev) => ({
      modal: !prev.modal,
      index: index,
      pageId: pageId,
      data,
      type: "form",
    }));

  const pageUpdateHandler = async (pageData) => {
    updatePages(pageData);
  };

  const updateClassSubjectList = (className, classId) => {
    const filteredClasses = classes?.filter((item) => item.name === className);
    const subjects = filteredClasses?.map((item) => item.subjects);

    setClassSubjects({
      subjects: subjects?.[0],
      classId,
    });
  };

  const updateTopicList = (subjectName) => {
    const filteredSubjects = subjects?.filter(
      (item) =>
        item.subcategory_id === classSubjects?.classId &&
        item.name === subjectName
    );
    const topics = filteredSubjects?.map((item) => item.topics);
    setSubjectTopics((prev) => topics?.[0]);
  };

  /******************************************/
  /************ Book operations *************/
  /******************************************/
  // Create book
  const createBookHandler = async () => {
    updateFormState({
      book_id: "",
      bookType: "",
      language: "",
      subject: "",
      subject_id: "",
      class: "",
      class_id: "",
      topic: "",
      topic_id: "",
      is_published: false,
      title: "",
      bookSummary: "",
      book_cover_url: "",
      content_type: "",
      price: 0,
      pages: [],
    });
    const bookID = await createBook();
    navigate("/admin/edit-book/" + bookID);
  };

  // Publish book
  const publishBook = () => {
    if (
      !!state.bookType === true &&
      !!state.bookSummary === true &&
      !!state.language === true &&
      !!state.class_id === true &&
      !!state.subject_id === true &&
      (!!state.pages && state.pages.length > 0) === true &&
      !!state.title === true &&
      !!state.topic_id === true &&
      !!state.book_cover_url === true
    ) {
      // updateFormState({ is_published: true });
      updatePulishStatus(true);
      toast.success("Book published successfully");
    } else {
      // console.log("state***** ", state);
      toast.error(
        "All form fields must be filled, and the book needs at least one page to be published."
      );
    }
  };

  const unpublishBook = () => {
    updatePulishStatus(false);
  };

  // Edit book
  const editBookHandler = (data) => {
    updateFormState({
      book_id: data._id,
      bookType: data?.book_type,
      language: data?.language,
      subject: data?.subject?.name,
      subject_id: data?.subject?.id,
      class: data?.sub_category?.name,
      class_id: data?.sub_category?.id,
      topic: data?.topic?.name,
      topic_id: data?.topic?.id,
      title: data?.title,
      bookSummary: data?.description,
      pages: data?.pages,
      book_cover_url: data?.book_cover_url,
      content_type: data?.content_type,
      price: data?.price,
    });
    navigate("/admin/edit-book/" + data._id);
  };
  // Delete book
  const deleteBookHandler = (book_id) => {
    deleteBook(book_id);
    deleteImageFolder("bid_" + book_id);
  };

  /******************************************/
  /************ Image operations ************/
  /******************************************/

  const updateImage = async (event, book_id, pageId, imageUrl) => {
    const file = event.target.files[0];
    if (file) {
      const pathFolder = `bid_${book_id}/pgid_${pageId}`;
      const form = new FormData();
      form.append("folder", pathFolder);
      form.append("file", file);
      imageUrl && deleteImage(imageUrl);
      return await addImage(form);
    }
    return "";
  };

  return {
    saveBook,
    state,
    updateFormState,
    updateFormStateDataFromInputField,
    toggleModal,
    modalOpen,
    previewClicked,
    setPreviewClicked,
    openPageForm,
    previewPage,
    books,
    isLoading,
    pageUpdateHandler,
    editBookHandler,
    createBookHandler,
    readerModal,
    toggleReaderModal,
    classes,
    classSubjects,
    updateClassSubjectList,
    subjectTopics,
    updateTopicList,
    updateImage,
    deleteImageFolder,
    publishBook,
    unpublishBook,
    deleteBookHandler,
  };
};
export default useBookForm;
