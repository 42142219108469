import { TEMPLATE_LIST } from "../../templates/dictionary";
import style from "./Reader.module.css";
import {
  ReaderRightArrow,
  ReaderLeftArrow,
  ReaderMenu,
  DotIcon,
} from "../../assets/icons";
import useReader from "./hook/useReader";
import { toast } from "react-toastify";
import { qRetakeAssessment } from "../../redux/feature/bookQuestionsSlice";
import { useDispatch, useSelector } from "react-redux";
import NoteModal from "../../pages/library/Notes/NoteModal";
import NoteForm from "../../pages/library/Notes/form/NoteForm";
import { useState } from "react";
import { AiFillEdit, AiOutlineEdit } from "react-icons/ai";

const Reader = ({ pages, bookTitle, bookId, note }) => {
  const {
    templateIndex,
    pageData,
    pageIndex,
    totalPages,
    previous,
    next,
    skip,
    isMenu,
    setIsMenu,
    pageSelectionHandler,
    hasQuestion,
    position,
    totalQuestions,
    lastVisitedPageIndex,
  } = useReader(pages);

  const dispatch = useDispatch();
  const { reTakeAssessmentFlag } = useSelector((store) => store.bookQuestions);

  const [toggleModal, setToggleModal] = useState(false);

  const modalHandler = () => setToggleModal((prev) => !prev);

  return (
    <>
      <div className={style.reader}>
        <i className={style.manageNoteBtn} onClick={modalHandler}>
          {note ? <AiFillEdit /> : <AiOutlineEdit />}
        </i>
        <div className={style.header}>{bookTitle}</div>
        <div className={style.body}>
          {pageIndex < totalPages ? (
            TEMPLATE_LIST[templateIndex]?.viewComponent(pageData, "view")
          ) : (
            <div className={style.end}>
              THE<span className={style.part}>END</span>
              <span className={style.dot}>
                <DotIcon />
              </span>
            </div>
          )}
        </div>
        <div className={style.footer}>
          <div className={style.pageHolder}>
            <span
              className={style.menu}
              onClick={() => setIsMenu((prev) => !prev)}
            >
              <ReaderMenu />
            </span>
            <label>
              Page{" "}
              {`${
                pageIndex < totalPages ? pageIndex + 1 : totalPages
              } of ${totalPages}`}
            </label>
          </div>
          <div>
            {pageIndex > 0 && (
              <button onClick={previous} className={`${style.btn}`}>
                <ReaderLeftArrow />{" "}
                <span className={style.largeScreen}>Previous</span>
              </button>
            )}
            {(pageIndex < totalPages ||
              (hasQuestion && position < totalQuestions - 1)) && (
              <button
                onClick={() =>
                  reTakeAssessmentFlag ? dispatch(qRetakeAssessment()) : next()
                }
                className={`${style.btn}`}
              >
                <span className={style.largeScreen}>Next</span>
                <ReaderRightArrow />
              </button>
            )}
            {pageIndex < lastVisitedPageIndex && hasQuestion ? (
              <button onClick={skip} className={`${style.skip}`}>
                Skip <ReaderRightArrow />
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {/* Menu */}
      {isMenu && (
        <div className={style.menuUI}>
          <div className={style.closeMenu}>
            <span onClick={() => setIsMenu((prev) => !prev)}>
              <ReaderLeftArrow />
            </span>
          </div>
          <ol className={style.menuList}>
            {pages.map((page, index) => (
              <li
                key={index}
                onClick={() => {
                  // check if pageIndex is <= lastVisitedPageIndex
                  index <= lastVisitedPageIndex
                    ? pageSelectionHandler(index)
                    : toast.info(
                        `You cannot access this page until you are done with PAGE ${
                          lastVisitedPageIndex + 1
                        }`
                      );
                }}
              >
                {page.title || `Page ${index + 1}`}
              </li>
            ))}
          </ol>
        </div>
      )}
      {toggleModal && (
        <NoteModal style={{ width: "100%" }}>
          <NoteForm
            formAction={note ? "UPDATE_NOTE" : "newNote"}
            data={
              note
                ? note
                : {
                    title: bookTitle || "",
                    book_id: bookId || "",
                    content: [""],
                    note_style: { backgroundColor: "#fec46d", color: "#000" },
                  }
            }
            closeModal={modalHandler}
          />
        </NoteModal>
      )}
    </>
  );
};
export default Reader;
