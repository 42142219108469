import libraryService from "../../../../service/library.service";
import useCdReactQuery from "../../../../utils/hooks/useCdReactQuery";


const useAdminSubCategory = () => {
    const { queryEndpoint } = useCdReactQuery();

    const deleteSubCategory = async (id) => {
        const resp = await queryEndpoint({
            endpointUrl : libraryService.getClassById(id),
            httpMethod : "DELETE",
        });

        return await resp
        
    }

    const createSubCategory = async (item) => {
        const resp = await queryEndpoint({
            endpointUrl : libraryService.createClass,
            httpMethod : "POST",
            body : JSON.stringify(item),
        });

        return await resp
    }

    const editSubCategory = async (item) => {
        const resp = await queryEndpoint({
            endpointUrl : libraryService.getClassById(item.id),
            httpMethod : "PUT",
            body : JSON.stringify(item),
        });

        return await resp
    }

    return {
        createSubCategory,
        deleteSubCategory,
        editSubCategory,

    }

}

export default useAdminSubCategory;