import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useAdminLibrary from "../../Library/hooks/useAdminLibrary";
import { FullpageModal } from "../../../../components/Modal";
import style from "./Classes.module.css"
import { DeleteIcon, EditIcon, GridAddIcon, SaveIcon, SearchIcon } from "../../../../assets/icons";
import InputField from "../../../../components/InputField";
import Dropdown from "../../../../components/Dropdown";
import TextArea from "../../../../components/TextArea";
import useAdminSubCategory from "../hooks/useAdminSubcategory";
import { toast } from "react-toastify";
import ConfirmDialog from "../../../../components/ConfirmationDialogCD/ConfirmDialog";

const INITIAL_DATA = { name: "", category_id: "", category_name: "", description : "" };

const Classes = () => {
    const { getCategories, getClasses, isLoading } = useAdminLibrary();
    const { createSubCategory, deleteSubCategory, editSubCategory } = useAdminSubCategory()
    const [classes, setClasses] = useState([]);
    const [categories, setCategories] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [subcategory, setSubcategory] = useState(INITIAL_DATA);
    const [dialogModal, setDialogModal] = useState({ categoryId: "", modal: false });

    useEffect(() => {
        (async () => {
          setCategories(await getCategories());
          setClasses(await getClasses());

          console.log(await getClasses(), "hjjjkj")
        })();
    
        // eslint-disable-next-line
    }, []);

    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };

    const deleteClassHandler = (class_id) => {
        setDialogModal((prev) => ({ classId: class_id, modal: !prev.modal }));
      };

    const updateSubCategory = async () => {
        const postData = subcategory
        delete postData.category_name
        const resp = await editSubCategory(postData);
        if (resp.subcategory) {
          setClasses((curr) =>
            curr.map((data) => {
              if (data.id === resp.subcategory.id) {
                return resp.subcategory;
              }
              return data;
            })
          );
          toggleModal();
          toast.success(`Subject renamed to ${resp.subcategory.name} successfully`);
        } else {
          toast.error("Error updating class");
        }
      };

    const handleDelete = async (id) => {
        const resp = await deleteSubCategory(id);
        if(resp.msg) {
          toast.success(resp.msg);
          setClasses(curr => curr.filter(sub => sub.id !== id));
        } else {
          toast.error("Error deleting class");
        }
    };

    const saveSubcategory = async () => {
        const postData = subcategory
        delete postData.category_name
        const resp = await createSubCategory(postData);
        if (resp.new_subcategory) {
          setClasses([...classes, resp.new_subcategory]);
          toast.success("class created successfully");
          setSubcategory(INITIAL_DATA);
          setTimeout(() => {
            toggleModal();
          }, 1000);
        } else {
          toast.success("Error creating class");
        }
    };

    const getCategoryName = id => {
        return [...categories].find(category => category.id === id)
      }

    return (
        <div className={style.root}>
            <div className={style.bookWrapper}>
                <div className={style.header}>
                <span>
                    <Link to="/admin/settings/">Back</Link>
                </span>
                <div className={style.right}>
                    <i className={style.searchIcon}>
                    <SearchIcon />
                    </i>
                    <i>
                    <GridAddIcon
                        title="Add class"
                        onClick={() => {
                        setIsEdit(false);
                        // setSubject(INITIAL_DATA);
                        toggleModal();
                        }}
                    />
                    </i>
                </div>
                </div>
                <div className={style.scrollWrapper}>
                <table className={style.bookList}>
                    <thead>
                    <tr>
                        <th colspan="8">Name</th>
                        <th className={style.center}>Category</th>
                        <th className={style.center}>Action</th>
                    </tr>
                    </thead>
                    <tbody className={style.items}>
                    {isLoading ? (
                        "Loading all classes"
                    ) : classes && classes.length > 0 ? (
                        classes.map((item, index) => (
                        <tr key={index}>
                            <td colspan="8">{item.name}</td>
                            <td className={style.center}>{getCategoryName(item.category_id)?.name}</td>
                            <td className={`${style.actions} ${style.center}`}>
                            <i
                                onClick={() => {
                                setSubcategory(item);
                                setIsEdit(true);
                                toggleModal();
                                }}
                            >
                                <EditIcon />
                            </i>{" "}
                            <i>
                                <DeleteIcon onClick={() => deleteClassHandler(item.id)} />
                            </i>
                            </td>
                        </tr>
                        ))
                    ) : (
                        <tr>
                        <td colspan="6">No record found</td>
                        </tr>
                    )}
                    </tbody>
                </table>
                </div>
            </div>

            {modalOpen && (
                <FullpageModal toggleModal={toggleModal} className={style.previewSize}>
                <h5 style={{fontWeight : 700, textAlign : "center"}}>{isEdit ? "Edit" : "Add"} Class</h5>
                <div className={style.scrollWrapper}>
                    <div className={style.fluid}>
                    <div className={style.formGroup}>
                        <div className={style.label}>Name</div>
                        <InputField
                        className={style.title}
                        value={subcategory.name}
                        onChange={(e) =>
                            setSubcategory({ ...subcategory, name: e.target.value })
                        }
                        />
                    </div>

                    <div className={style.formGroup}>
                        <div className={style.label}>Category</div>
                        <Dropdown
                            options={categories}
                            value={subcategory.category_name || "Select"}
                            className={style.bookType}
                            onChange={(e) =>
                            setSubcategory({
                                ...subcategory,
                                category_id: e.id,
                                category_name: e.name,
                            })
                            }
                        />
                    </div>

                    {!isEdit && (
                        <div className={style.formGroup}>
                            <div className={style.label}>Description</div>
                            <TextArea
                            onChange={(e) => setSubcategory({...subcategory, description : e.target.value})}
                            rows={4}
                            name="description"
                            className={style.textarea}
                            value={subcategory.description}
                            />
                        </div>
                    )}

                    <div>
                        {isEdit ? (
                        <button className={style.btn} onClick={updateSubCategory}>
                            Edit <EditIcon />
                        </button>
                        ) : (
                        <button className={style.btn} onClick={saveSubcategory}>
                            Save <SaveIcon />
                        </button>
                        )}
                    </div>
                    </div>
                </div>
                </FullpageModal>
            )}
            {dialogModal.modal && (
                <ConfirmDialog
                confirm={() => {
                    handleDelete(dialogModal.classId);
                    setDialogModal((prev) => ({ classId: "", modal: false }));
                }}
                cancel={() =>
                    setDialogModal((prev) => ({ ...prev, modal: !prev.modal }))
                }
                message="Click continue to DELETE this class"
                />
            )}
        </div>
    )
}

export default Classes;