import React from "react";
import style from "./UserTag.module.css";
const UserTag = ({ intials, onClick, className, bgColor }) => {
  return (
    <div style={{ cursor: "pointer" }} onClick={onClick}>
      <div className={style.bio}>
        <div
          style={{ backgroundColor: bgColor ? bgColor : "brown" }}
          className={`${style.userImg} ${className}`}
        >
          {intials}
        </div>
      </div>
    </div>
  );
};

export default UserTag;
