import { AddIcon } from "../../../assets/icons";
import style from "./Overview.module.css";
import Card from "./widgets/Card";
import {
  TotalStudents,
  TotalRevenue,
  CourseStats,
  Retainer,
  ExpiryList,
  MostViewedBooks,
} from "./widgets/Widgets";

const Overview = () => {
  return (
    <>
      <div className={style.rootPage}>
        <div className={style.top}>
          <button className={style.addStudent}>
            ADD STUDENT <AddIcon />
          </button>
        </div>
        <div className={style.row}>
          <Card Component={TotalStudents} className={style.flex1} />
          <Card Component={TotalRevenue} className={style.flex1} />
          <Card Component={CourseStats} className={style.flex1} />
        </div>
        <div className={style.row}>
          <Card Component={Retainer} className={style.flex1} />
          <Card Component={MostViewedBooks} className={style.flex1} />
        </div>
        <div className={style.row}>
          <Card Component={ExpiryList} className={style.flex1} />
        </div>
      </div>
    </>
  );
};

export default Overview;
