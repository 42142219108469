import TopDashboard from "../../../layouts/TopDashboard/TopDashboard";
import classes from "./LibraryLayout.module.css";
import { Outlet, useLocation } from "react-router-dom";
import { useCallback, useEffect } from "react";
import SideMenu from "../../../components/SideMenu/SideMenu";
import { emitter } from "../../../utils/constants";
import useLibraryLayout from "./hooks/useLibraryLayout";

function LibraryLayout() {
  const { showChildren, clearSessionTimeout, setShowChildren, history } =
    useLibraryLayout();
  const location = useLocation();

  const onUnauthorizedEventFired = useCallback(
    () => {
      const { reason, from } = location?.state || {};
      localStorage.setItem("token", "");
      localStorage.setItem("CurrentUser", null);

      history(`/login${reason || from ? `?from=${from}` : ""}`, {
        state: {
          reason: "unauthorized",
          from: location.pathname,
        },
      });
    },
    [] // eslint-disable-line
  );

  useEffect(() => {
    emitter.sessionTimeOutEventEmitter.removeListener(
      "unauthorized",
      onUnauthorizedEventFired
    );

    emitter.sessionTimeOutEventEmitter.addListener(
      "unauthorized",
      onUnauthorizedEventFired
    );

    setTimeout(() => setShowChildren(true), 200);

    return () => {
      emitter.sessionTimeOutEventEmitter.removeListener(
        "unauthorized",
        onUnauthorizedEventFired
      );
    };
  }, []); // eslint-disable-line
  return (
    <div
      onClick={(e) => {
        clearSessionTimeout();
      }}
      className={classes.viewPort}
    >
      {showChildren && (
        <>
          <div className={classes.rightView}>
            <div>
              <TopDashboard />
            </div>
            <div className={classes.mainPage}>
              <Outlet />
              <SideMenu />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default LibraryLayout;
