import { useReducer } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import authApplication from "../../../service/auth";
import { QUERY_KEY } from "../../../utils/constants";
import useAppMutation from "../../../utils/hooks/useAppMutation";

// TODO:: Clean up registration api call

function useRegistrationPage() {
  const initialState = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    password: "",
    cPassword: "",
    gender: "Male",
    accountType: "student",
    hasAree: false,
    category_id: "",
    subcategory_id: "",
  };

  const [state, dispatch] = useReducer(
    (stateValue, value) => ({ ...stateValue, ...value }),
    initialState
  );

  const history = useNavigate();

  const {
    firstName,
    lastName,
    email,
    phoneNumber,
    password,
    cPassword,
    accountType,
    hasAree,
    category_id,
    subcategory_id,
  } = state;

  const { isLoading, mutateAsync: asycRegistration } = useAppMutation(
    QUERY_KEY.register,
    authApplication.register
  );

  function handleRegistration() {
    if (!firstName) {
      toast.error("First name is required");
      return;
    }
    if (!lastName) {
      toast.error("Last name is required");
      return;
    }
    if (!email) {
      toast.error("Email is required");
      return;
    }
    if (password?.length === 0) {
      toast.error("Password is required");
      return;
    }
    if (password !== cPassword) {
      toast.error("Password does not match");
      return;
    }
    if (!hasAree) {
      toast.error("You have not accepted terms of service");
      return;
    }
    asycRegistration({
      firstName,
      lastName,
      email,
      phoneNumber,
      password,
      accountType,
      gender: "male",
      category_id,
      subcategory_id,
    })
      .then((res) => {
        history("/login");
        toast.success("Account registration was successful");
      })
      .catch(({ msg }) => {
        toast.error(msg);
      })
      .finally(() => {});
  }

  return {
    state,
    isLoading,
    handleRegistration,
    dispatch,
  };
}

export default useRegistrationPage;
