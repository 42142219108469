import React from "react";
// import style from "./TextView.module.css";

const TextView = ({ question, style = "" }) => {
  return (
    // <>
    <div className={`${style.question} ${style}`}>
      {/* <h5>Question :</h5> */}
      {/* <p> */}
      {question}
      {/* </p> */}
    </div>
    // </>
  );
};

export default TextView;
