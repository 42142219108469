import { CameraIcon, DeleteIcon } from "../../../../assets/icons";
import { defaultImage } from "../../../../assets/image";
import TextArea from "../../../../components/TextArea";
import { OPTION_LABEL } from "../../../../utils/constants";
import style from "./EditQuestionBank.module.css";
import { useState, useEffect } from "react";

const Options = ({ data, deleteOption, callback }) => {
  // const { label = "", inputValue = "", isChecked = false } = data;
  const [optionValues, setOptionValues] = useState(data);
  useEffect(() => setOptionValues(data), [data]);

  const inputHandler = (e) => {
    const data = { ...optionValues, inputValue: e.target.value };
    callback(data);
    setOptionValues(data);
  };
  const checkboxHandler = (e) => {
    const data = { ...optionValues, isChecked: e.target.checked };
    callback(data);
    setOptionValues(data);
  };
  return (
    <div className={style.optionGroup}>
      <span className={style.label}>{optionValues.label}</span>{" "}
      <input
        type="text"
        className={style.input}
        value={optionValues.inputValue}
        onChange={inputHandler}
      />
      <input
        type="checkbox"
        checked={optionValues.isChecked}
        onChange={checkboxHandler}
      />
      <span
        className={style.deleteOption}
        onClick={() => deleteOption(optionValues.label)}
      >
        <DeleteIcon />
      </span>
    </div>
  );
};

const QuestionUI = ({
  classes,
  hasParagraph = false,
  questionDetails = {},
  submitForm = () => {},
  returnFormData = () => {},
}) => {
  // models
  const optionModel = { label: "A", inputValue: "", isChecked: false };
  const questionModel = {
    question: "",
    options: [optionModel],
    imageURL: "",
    answer: "",
  };

  const initRecords =
    questionDetails?.options?.length > 0 ? questionDetails : questionModel;

  // local states
  const [questionData, setQuestionData] = useState(initRecords);

  // Functions
  const addOptionHandler = () => {
    setQuestionData((prev) => ({
      ...prev,
      options: [
        ...prev.options,
        { ...optionModel, label: OPTION_LABEL[prev.options.length] },
      ],
    }));
  };

  const removeOptionHandler = (label) => {
    const updatedOptions = questionData.options
      .filter((option) => option.label !== label)
      .map((option, index) => ({ ...option, label: OPTION_LABEL[index] }));

    setQuestionData((prev) => ({ ...prev, options: updatedOptions }));
  };

  const updateOptions = (optionDetails, index) => {
    let _updatedOptions = [...questionData.options];
    let updatedAnswer = questionData.answer;
    _updatedOptions[index] = optionDetails;

    const { label, isChecked } = optionDetails;

    // if label is checked and not in answer string
    if (isChecked && updatedAnswer.indexOf(label) === -1) {
      updatedAnswer += label;
    } else if (!isChecked) {
      updatedAnswer = updatedAnswer.replace(label, "");
    }
    // Sort the updatedAnswer
    updatedAnswer = updatedAnswer.split("").sort().join("");

    setQuestionData((prev) => ({
      ...prev,
      answer: updatedAnswer,
      options: _updatedOptions,
    }));
  };

  const submitFormHandler = () => {
    submitForm([questionData]);
  };

  const returnFormDataHandler = () => {
    returnFormData(questionData);
    setQuestionData((prev) => ({
      ...prev,
      question: "",
      options: [optionModel],
    }));
  };

  return (
    <div className={`${style.questionUI} ${classes}`}>
      <div className={style.label}>Question</div>
      <TextArea
        onChange={(e) =>
          setQuestionData((prev) => ({ ...prev, question: e.target.value }))
        }
        rows={6}
        name="Question"
        value={questionData?.question || ""}
        className={style.question}
      />
      <div className={`${style.fluid} ${style.actionGroup}`}>
        <div className={style.btn} onClick={addOptionHandler}>
          ADD OPTION
        </div>
      </div>
      <div className={style.fluid}>
        {/* image */}
        <div className={style.image}>
          <p className={style.coverLbl}>Image question</p>
          <img src={defaultImage} alt="" className={style.img} />
          <div className={style.imageUpload}>
            <label>
              <CameraIcon />
              <input
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={() => {}}
                name="file"
              />
            </label>
          </div>
        </div>
        {/* options */}
        <div className={style.options}>
          Options
          {questionData.options.map((optionData, index) => (
            <Options
              data={optionData}
              deleteOption={removeOptionHandler}
              callback={(data) => updateOptions(data, index)}
              key={index}
            />
          ))}
        </div>
      </div>
      {hasParagraph ? (
        <div className={`${style.fluid} ${style.actionGroup}`}>
          <div className={style.btn} onClick={returnFormDataHandler}>
            {/* Check for update or add new question */}
            {questionDetails?.question?.length > 0
              ? "SAVE UPDATE"
              : "ADD QUESTION"}
          </div>
        </div>
      ) : (
        <div className={`${style.fluid} ${style.actionGroup}`}>
          <div className={style.btn} onClick={submitFormHandler}>
            SAVE QUESTION
          </div>
        </div>
      )}
    </div>
  );
};
export default QuestionUI;
