// Module
import { useEffect, useState } from "react";
import _ from "lodash";

// Icons
import {
  CameraIcon,
  DeleteIcon,
  EditIcon,
  CloseIcon,
} from "../../assets/icons";

// Assets
import { defaultImage } from "../../assets/image";

// Style
import style from "./Form.module.css";

// Components
import { Loader } from "../../components/Modal";
import { toast } from "react-toastify";
import ConfirmDialog from "../../components/ConfirmationDialogCD/ConfirmDialog";
import { cdEncryption, cdDecryption } from "../../utils/helpers";
import { OPTION_LABEL } from "../../utils/constants";

export const Option = ({
  deleteOption,
  updateOption,
  optionIndex,
  label,
  value,
  isSelected,
}) => {
  const [boxChecked, setBoxChecked] = useState(false);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    setBoxChecked(isSelected);
    setInputValue(value);
  }, [isSelected, value]);

  const deleteOptionHandler = () => deleteOption(optionIndex);

  const inputChange = (e) => {
    const updatedValue = e.target.value;
    setInputValue((prev) => updatedValue);
    updateOption({ label, isSelected, value: updatedValue });
  };

  const checkBoxChange = (e) => {
    const status = e.target.checked;
    setBoxChecked((prev) => status);
    updateOption({ label, value, isSelected: status });
  };

  return (
    <div className={style.option}>
      <span className={style.label}>{label}</span>{" "}
      <input
        type="text"
        className={style.input}
        value={inputValue}
        onChange={inputChange}
      />
      <div className={style.customCheckbox}>
        <input type="checkbox" checked={boxChecked} onChange={checkBoxChange} />
      </div>
      <span className={style.deleteOption} onClick={deleteOptionHandler}>
        <DeleteIcon />
      </span>
    </div>
  );
};

export const QuestionFormModal = ({
  closeQuestionModal,
  uploadImage,
  book_id,
  pageId,
  questionDetails,
  updateFormData,
}) => {
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [details, setDetails] = useState({
    text: questionDetails?.question?.text || "",
    imageUrl: questionDetails?.question?.imageUrl || "",
    imageFile: null,
  });

  useEffect(() => {
    // add isSelected to options. Not the best implementation but use it as a patch
    // for now because the initial load data is not having isSelected. Refactor this code later
    const updatedOptions = (questionDetails?.options || []).map((option) => ({
      ...option,
      isSelected: questionDetails.answer.includes(option.label) ? true : false,
    }));
    setOptions(updatedOptions);
  }, [questionDetails]);

  const addOption = () => {
    setOptions((prev) => [
      ...prev,
      {
        label: OPTION_LABEL[options.length <= 25 ? options.length : 25],
        value: "",
        isSelected: false,
      },
    ]);
  };
  const updateOption = (arg) => {
    const updatedOptions = options.map((option) =>
      option.label === arg.label ? arg : option
    );
    setOptions((prev) => updatedOptions);
  };
  const deleteOption = (optionIndex) => {
    const tempOptions = [...options];
    tempOptions.splice(optionIndex, 1);
    const updatedOptions = tempOptions.map((item, index) => ({
      ...item,
      label: OPTION_LABEL[index],
    }));
    setOptions(updatedOptions);
  };

  const debounceFn = _.debounce((e) => {
    const { name, value } = e.target;
    setDetails({ ...details, [name]: value });
  }, 500);

  const inputChangeHandler = (e) => debounceFn(e);

  const fileChangeHandler = (e) => {
    const tmep_file = URL.createObjectURL(e.target.files[0]);
    setDetails({
      ...details,
      imageFile: e,
      imageUrl: tmep_file,
    });
  };

  const saveForm = async (event) => {
    let imagePath = "";
    setIsLoading((prev) => !prev);
    if (!!details.imageFile) {
      imagePath = await uploadImage(
        details.imageFile,
        book_id,
        pageId,
        questionDetails?.question?.imageUrl || ""
      );
    }

    // Format the options in a form required by formData and also extract the answer
    let answer = "";
    let counter = 0;
    const formatedOptions = options.map(({ isSelected, label, value }) => {
      if (isSelected) {
        answer += label;
        counter += 1;
      }

      return { label, value };
    });

    const returnData = {
      answer,
      optionType: counter > 1 ? "multiple" : "single",
      options: formatedOptions,
      question: {
        imageUrl: imagePath,
        text: details.text,
        videoUrl: "",
      },
    };
    updateFormData(returnData);
    setIsLoading((prev) => !prev);
    closeQuestionModal(false);
  };

  return (
    <div className={style.formModal}>
      <div className={style.formClose}>
        <span onClick={() => closeQuestionModal(false)}>
          <CloseIcon />
        </span>
      </div>
      <div className={style.form}>
        <div className={style.lbl}>Question</div>
        <textarea
          className={style.paragraphTextAare}
          name="text"
          onChange={inputChangeHandler}
          defaultValue={questionDetails?.question?.text}
        ></textarea>
        <div className={style.image}>
          <img
            src={details?.imageUrl || defaultImage}
            alt=""
            className={style.img}
          />

          {/* Select image icon */}
          <div className={style.imageUpload}>
            <label>
              <CameraIcon style={{ marginTop: "-8px" }} />
              <input
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={fileChangeHandler}
                name="file"
              />
            </label>
          </div>
        </div>
        <div className={style.button}>
          <button className={style.btn} onClick={addOption}>
            ADD OPTION
          </button>
        </div>
        {options?.map((option, index) => (
          <Option
            key={index}
            deleteOption={deleteOption}
            updateOption={updateOption}
            optionIndex={index}
            label={option.label}
            value={option.value}
            isSelected={
              option?.isSelected ||
              questionDetails?.answer?.includes(option.label)
            }
          />
        ))}
      </div>
      {!isLoading && (
        <div>
          <button className={style.btn} onClick={saveForm}>
            SAVE QUESTION
          </button>
        </div>
      )}
      {isLoading && <Loader />}
    </div>
  );
};

export const QuestionList = ({ data, index, editList, deleteList }) => {
  const { question, options, answer } = data || {};
  const [isDelete, setIsDelete] = useState(false);

  const deleteListHandler = () => {
    setIsDelete(true);
  };
  return (
    <div className={style.questionList}>
      <div className={style.action}>
        <p>Question {index + 1}</p>
        <div>
          <span onClick={() => editList(index)}>
            <EditIcon />
          </span>
          <span onClick={deleteListHandler}>
            <DeleteIcon />
          </span>
        </div>
      </div>

      <p>{question?.text}</p>
      {question?.imageUrl && (
        <img src={question.imageUrl} alt="" className={style.img} />
      )}
      <ul className={style.options}>
        {options?.map((option, index) => (
          <li
            key={index}
            className={answer.includes(option.label) ? style.selected : ""}
          >
            <span>{option.label}</span> {option.value}
          </li>
        ))}
      </ul>
      {isDelete && (
        <ConfirmDialog
          confirm={() => {
            deleteList(index);
            setIsDelete(false);
          }}
          cancel={() => setIsDelete(false)}
          message="Click continue to DELETE question"
        />
      )}
    </div>
  );
};

const QthemeForm1 = ({ data, submitFn, updateImage }) => {
  const [formData, setFormData] = useState({
    ...data,
    questions: [],
    title: "Question",
    page_type: "question",
  });
  const [isUpdate, setIsUpdate] = useState(false);
  const [isFormModal, setIsFormModal] = useState(false);
  const [questionIndex, setQuestionIndex] = useState(null);
  const [triggerSubmit, setTriggerSubmit] = useState(false);

  useEffect(() => {
    const decryptedQuestions = cdDecryption(data?.questions);
    console.log("decrypt ", decryptedQuestions);
    setFormData((prevData) => ({
      ...prevData,
      questions: decryptedQuestions,
    }));

    // eslint-disable-next-line
  }, []);

  const encryptAndSubmit = () => {
    const encryptedQuestions = cdEncryption(formData.questions);
    console.log("submit ", formData);
    submitFn({
      ...formData,
      questions: encryptedQuestions,
    });
  };

  // NOTE THIS FOR IMAGE UPLOAD IN FORM MODAL
  useEffect(() => {
    console.log(formData.image);
    formData.image && encryptAndSubmit();
    // eslint-disable-next-line
  }, [formData.image]);

  // This guarantees that the formData is fully updated before calling the submit function
  useEffect(() => {
    if (triggerSubmit) {
      console.log("trigger");
      encryptAndSubmit();
      setTriggerSubmit(false);
    }
    // eslint-disable-next-line
  }, [triggerSubmit]);

  const debounceFn = _.debounce((e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  }, 500);

  const inputChangeHandler = (e) => debounceFn(e);

  const updateFormDataHandler = async (arg) => {
    setFormData((prevData) => {
      // Check if formData.questions is defined
      if (prevData.questions !== undefined) {
        // check if action is for update
        if (isUpdate) {
          prevData.questions[questionIndex] = arg;
          setIsUpdate((prev) => false);
          return prevData;
        }
        return {
          ...prevData,
          questions: [...prevData.questions, arg],
        };
      } else {
        // If formData.questions doesn't exist, create a new array with newQuestions
        return { ...prevData, questions: [arg] };
      }
    });
    setTriggerSubmit(true);
  };

  const submitHandler = async () => {
    encryptAndSubmit();
    toast.success("Page saved");
  };

  const editListHandler = (questionIndex) => {
    setQuestionIndex(questionIndex);
    setIsFormModal((prev) => true);
    setIsUpdate((prev) => true);
  };

  const deleteListItemHandler = (questionIndex) => {
    const udpatedQuestions = formData.questions.filter(
      (item, index) => index !== questionIndex
    );
    setFormData((prev) => ({ ...prev, questions: udpatedQuestions }));
  };

  return (
    <>
      <div className={style.lg}>
        <div className={style.row}>
          <div>
            <div className={style.lbl}>Passmark (%)</div>
            <input
              type="text"
              className={style.passmark}
              placeholder="e.g 50"
              name="pass_mark"
              onChange={inputChangeHandler}
              defaultValue={formData.pass_mark}
            />
          </div>
          <button
            className={style.addQuestionBtn}
            onClick={() => setIsFormModal(true)}
          >
            ADD QUESTION
          </button>
        </div>

        {/* Question List */}
        {formData?.questions?.map((item, index) => (
          <QuestionList
            key={index}
            data={item}
            index={index}
            editList={editListHandler}
            deleteList={deleteListItemHandler}
          />
        ))}

        <div className={style.button}>
          <button className={style.btn} type="submit" onClick={submitHandler}>
            SAVE PAGE
          </button>
        </div>
      </div>

      {isFormModal && (
        <QuestionFormModal
          closeQuestionModal={() => {
            setIsFormModal(false);
            setQuestionIndex(null);
          }}
          uploadImage={updateImage}
          book_id={formData.book_id}
          pageId={formData.pageId}
          questionDetails={formData?.questions?.[questionIndex]}
          updateFormData={updateFormDataHandler}
        />
      )}
    </>
  );
};

export default QthemeForm1;
