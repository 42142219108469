/* eslint-disable */
import { BsHeadphones } from "react-icons/bs";
import { HiOutlineBookOpen } from "react-icons/hi";
import { MdOutlineVideocam } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/Button";
import SkeletonLoader from "../../../components/SkeletonLoader";
import classes from "./StudyLayout.module.css";
import useStudyLayout from "./hooks/useStudyLayout";
import { RxArrowLeft } from "react-icons/rx";
import BlockEditor from "../../../components/BlockEditor";
import InputField from "../../../components/InputField";
import { AiOutlineSave } from "react-icons/ai";
import useBookmark from "../Bookmark/hooks/useBookmark";
import useMyShelf from "../MyShelf/hooks/useMyShelf";
import useCourseDetails from "../CourseDetails/hooks/useCourseDetails";

function StudyLayout({ children, mediaType, isFetching }) {
  const navigate = useNavigate();
  const { courseId } = useParams();
  const { createBookmark, removeBookmark } = useBookmark();
  const { isBookmarked } = useCourseDetails();
  const {
    noteText,
    state,
    isLoading,
    isSaveing,
    borrowedBook,
    courseDetails,
    saveNote,
    dispatch,
    setNoteText,
    handleRequestHelp,
  } = useStudyLayout();
  const { onReturnBook: onReturningBook } = useMyShelf();
  const { noteTitle } = state;

  const audio = courseDetails?.format_types?.includes("Audio");
  const ebook = courseDetails?.format_types?.includes("Ebook");
  const video = courseDetails?.format_types?.includes("Video");

  return (
    <div className={classes.pageRoot}>
      <div className={classes.pageTop}>
        <button
          onClick={() => {
            navigate(-1);
          }}
          className={classes.backButton}
        >
          <RxArrowLeft className={classes.backIcon} /> <span>Back</span>
        </button>
      </div>
      <div className={classes.pageBody}>
        <div className={classes.leftView}>{children}</div>
        {/* <div className={classes.rightView}>
          {isFetching ? (
            <SkeletonLoader className={classes.loader} />
          ) : (
            <div className={classes.rightItemsMain}>
              <div className={classes.actionWrapper}>
                <Button
                  onClick={handleRequestHelp}
                  className={classes.requestHelp}
                >
                  Request Help
                </Button>
              </div>
              <div className={classes.returnWrapper}>
                {ebook && (
                  <Button
                    onClick={() => navigate(`/library/read/${courseId}`)}
                    children={
                      <HiOutlineBookOpen className={classes.studyIcon} />
                    }
                    className={`${classes.studyBtn} ${
                      audio || video ? classes.readBtn : classes.audioBtn
                    } ${mediaType === "book" ? classes.activeNow : undefined}`}
                  />
                )}

                {video && (
                  <Button
                    onClick={() => navigate(`/library/video/${courseId}`)}
                    children={
                      <MdOutlineVideocam className={classes.studyIcon} />
                    }
                    className={`${classes.studyBtn}  ${
                      audio && video ? classes.video : classes.videoBtn
                    } ${mediaType === "video" ? classes.activeNow : undefined}`}
                  />
                )}

                {audio && (
                  <Button
                    onClick={() => navigate(`/library/audio/${courseId}`)}
                    className={`${classes.studyBtn}  ${classes.headsetBtn} ${
                      mediaType === "audio" ? classes.activeNow : undefined
                    }`}
                    children={<BsHeadphones className={classes.studyIcon} />}
                  />
                )}
              </div>
              <div className={classes.noteWrapper}>
                <InputField
                  value={noteTitle}
                  onChange={({ target }) =>
                    dispatch({ noteTitle: target.value })
                  }
                  placeholder="Note Title"
                  className={classes.nteTitle}
                />
                <BlockEditor
                  value={noteText}
                  onChange={setNoteText}
                  className={classes.blockInput}
                />
                <div className={classes.sendNoteBtnWrapper}>
                  <Button
                    onClick={saveNote}
                    isLoading={isSaveing}
                    className={classes.saveNoteBtn}
                  >
                    <AiOutlineSave />
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div> */}
      </div>
    </div>
  );
}

export default StudyLayout;
