import { useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import userProfile from "../../../service/userProfile.service";
import { setCurrentUser } from "../../../redux/feature/authSlice";
import { QUERY_KEY } from "../../../utils/constants";
import useAppMutation from "../../../utils/hooks/useAppMutation";

function useEditLocation(onSuccess) {
  const { user } = useSelector(({ currentUser }) => currentUser);
  const reduxDispatch = useDispatch();
  const initialState = {
    city: "",
    country: "",
    country_code: "",
    state: "",
    firstName: user.firstName,
    lastName: user.lastName,
    phoneNumber: user.phoneNumber,
    gender: user?.gender,
    email: user.email,
  };
  const [store, dispatch] = useReducer(
    (stateValue, value) => ({ ...stateValue, ...value }),
    initialState
  );
  const { city, country, country_code, state } = store;
  const { isLoading, mutateAsync: asyncEditLocation } = useAppMutation(
    QUERY_KEY.editProfile,
    userProfile.editProfile
  );
  function onSubmit() {
    if (!country || !state || !city) {
      toast.error("country, state and city is required");
      return;
    }

    asyncEditLocation({
      country,
      country_code,
      state,
      city,
      firstName: user.firstName,
      lastName: user.lastName,
      phoneNumber: user.phoneNumber,
      gender: user?.gender,
      email: user.email,
    })
      .then(({ data: userData }) => {
        toast.success("updated successfully");
        reduxDispatch(setCurrentUser(userData));
        onSuccess?.();
      })
      .catch(({ msg }) => {
        toast.error(msg);
      })
      .finally(() => {});
  }
  return {
    store,
    isLoading,
    dispatch,
    onSubmit,

  };
  
}

export default useEditLocation;
