import SkeletonLoader from "../SkeletonLoader";
import classes from "./BookDetailsLoader.module.css";

function BookDetailsLoader() {
  return (
    <div className={classes.loaderRoot}>
      <div className={classes.topSection}>
        <div className={classes.loaderWrapper}>
          <SkeletonLoader className={classes.bookCover} />
        </div>
        <div className={classes.bookInfoWrapper}>
          {[...new Array(4).values()].map((_, index) => (
            <SkeletonLoader className={classes.bookInfo} key={index} />
          ))}
        </div>
        <div className={classes.loaderWrapper}>
          <SkeletonLoader className={classes.aboutBook} />
        </div>
      </div>
      <div className={classes.bookTopics}>
        <div className={classes.bookInfoWrapper}>
          {[...new Array(4).values()].map((_, index) => (
            <SkeletonLoader className={classes.bookInfo} key={index} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default BookDetailsLoader;
