import { BiLogOut } from "react-icons/bi";

export const accountNavPths = [
  [
    {
      name: "My Shelf",
      path: "/library/myshelf",
      regex: /^\/myshelf/,
      icon: {},
    },
    {
      name: "Message",
      path: "/notification",
      regex: /^\/notification/,
      icon: {},
    },
    {
      name: "Account settings",
      path: "/profile",
      regex: /^\/dashboard/,
      icon: {},
    },
    {
      name: "Subscription",
      path: "/subscription",
      regex: /^\/subscription/,
      icon: {},
    },
  ],
  // [
  //   {
  //     name: "Purchase history",
  //     path: "/dashboard",
  //     regex: /^\/dashwboard/,
  //     icon: {},
  //   },
  // ],
  [
    // {
    //   name: "Help",
    //   path: "/dashboard",
    //   regex: /^\/dashbwoard/,
    //   icon: {},
    // },
    {
      name: "Log out",
      regex: /logout/,
      onclick: () => {},
      icon: {
        customClasName: "",
        asset: BiLogOut,
      },
    },
  ],
];
