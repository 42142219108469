import Button from "../../../components/Button/Button";
import CourseSyllabus from "../../../components/CourseSyllabus/CourseSyllabus";
import ItemRow from "../../../components/ItemRow/ItemRow";
import classes from "./CourseTopics.module.css";
import { MdOutlineTopic } from "react-icons/md";
import { GiProgression } from "react-icons/gi";
import { MDBProgress, MDBProgressBar } from "mdb-react-ui-kit";
import { useLocation, useNavigate } from "react-router-dom";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { BsTrophy } from "react-icons/bs";
import useCourseTopics from "./hooks/useCourseTopics";
import CourseDetailsLoader from "../CourseDetailsLoader";

function CourseTopics() {
  const navigate = useNavigate();
  const percentageScore = 50;
  const { books, topicId, loadingBooks } = useCourseTopics();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const topicName = queryParams.get("topicName");

  if (loadingBooks) {
    return <CourseDetailsLoader />;
  }

  console.log(books);
  return (
    <div className={classes.pageRoot}>
      <div className={classes.completionTop}>
        <div>
          <h3 className={classes.courseTitle}>{topicName}</h3>
          <div className={classes.completionTopAction}>
            <Button className={classes.resumeCourseBtn}>Resume Course</Button>
          </div>
          <p className={classes.courseDescription}>
            Learn about ChatGPT, one of the most advanced AI systems available
            today, and dive into the world of Generative AI.
          </p>
        </div>
        <div style={{ paddingRight: "3em" }}>
          <div>
            <ItemRow
              icon={<MdOutlineTopic />}
              text={`${books?.length} Material${books?.length > 1 ? "s" : ""}`}
            />
            <div className={classes.lineDiver} />
            <ItemRow icon={<GiProgression />} text="Primary 3" />
            <div className={classes.lineDiver} />
          </div>
          <div style={{ marginTop: "3em" }}>
            <h3 className={classes.courseProgressTitle}>Course Progress</h3>
            <div className={classes.archiementProgressWap}>
              <div className={classes.progressBar}>
                <MDBProgress>
                  <MDBProgressBar
                    striped
                    bgColor="success"
                    width="2"
                    valuemin={0}
                    valuemax={100}
                  ></MDBProgressBar>
                </MDBProgress>
              </div>
              <BsTrophy className={classes.trouphyIcon} />
            </div>
          </div>
        </div>
      </div>

      <div style={{ marginTop: "3em" }}>
        <h3>Materials</h3>
        <div className={classes.sylabusWrapper}>
          {books.map((material, index) => (
            <div>
              <CourseSyllabus
                progressIcon={
                  <div style={{ width: 40 }}>
                    <CircularProgressbar
                      value={percentageScore}
                      text={`${percentageScore}%`}
                      strokeWidth={0}
                      styles={buildStyles({
                        textColor: "#000000",
                        textSize: "20px",
                        pathColor: "#5073f4",
                        trailColor: "#d6dded",
                      })}
                    />
                  </div>
                }
                onClickTopic={() =>
                  navigate(
                    `/topic/study/${material?._id?.$oid}?topic=${topicId}&name=${topicName}`
                  )
                }
                topic={material?.owner}
                description="Learn about ChatGPT, one of the most advanced AI systems available today, and dive into the world of Generative AI."
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default CourseTopics;
