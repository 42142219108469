import style from "./Loader.module.css";
import { SpinnerIcon } from "../../../assets/icons";
const Loader = () => {
  return (
    <div className={style.loader}>
      <SpinnerIcon className={style.spinner} />
      <span>Loading...</span>
    </div>
  );
};

export default Loader;
