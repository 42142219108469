import axios from "axios";

function useFileStorage() {
  const deleteImage = async (imageUrl) => {
    const parsedURL = new URL(imageUrl);
    const _path = parsedURL.pathname;

    // Split the path by '/'
    const pathParts = _path.split("/");

    // Get the last two parts
    const _imagePath = pathParts.slice(-3).join("/");

    try {
      await axios.delete(
        "https://sample.ctrlearn.com/ctrlearnFileManager/delete_image/" +
          _imagePath
      );
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // This function deletes all the images in the folder specified
  const deleteImageFolder = async (folder) => {
    // folder can be book_id/page_id or only book_id. For the first, it will delete everything in page_id
    // and the second will do same for book_id
    try {
      await axios.delete(
        "https://sample.ctrlearn.com/ctrlearnFileManager/delete_folder/" +
          folder
      );
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const addImage = async (data) => {
    try {
      const response = await axios.post(
        "https://sample.ctrlearn.com/ctrlearnFileManager/",
        data
      );

      if (response.status === 200) {
        return (
          "https://sample.ctrlearn.com/ctrlearnFileManager/get_image/" +
          response.data.path
        );
      } else {
        return "";
      }
    } catch (error) {
      console.error("Error:", error);
      return new Promise((_, reject) => {
        reject(error);
      });
    }
  };

  return { deleteImage, deleteImageFolder, addImage };
}

export default useFileStorage;
