import { Link, useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import InputField from "../../components/InputField";
import AuthLayout from "../../layouts/AuthLayout";
import useResetPassword from "./hooks/useResetPassword";
import style from "./ResetPassword.module.css";

import { MdOutlineKeyboardBackspace } from "react-icons/md";

function ResetPassword() {
  const { email, setEmail, handlePasswordReset } = useResetPassword();
  const history = useNavigate();
  return (
    <AuthLayout>
      <div className={style.root}>
        <div className={style.formWrapper}>
          <button
            className={`${style.mobile} ${style.back}`}
            href="#"
            onClick={() => history("/login")}
          >
            <MdOutlineKeyboardBackspace />
            <span style={{ fontSize: "1em", paddingLeft: "5px" }}>
              ctrLearn
            </span>
          </button>
          <h4>Retrieve Password?</h4>
          <p>
            Enter your registered email below to receive a reset link for a new
            password.
          </p>
          <div className={style.formGroup}>
            <InputField
              type="text"
              placeholder="you@company.com"
              value={email}
              onChange={({ target }) => setEmail(target?.value)}
            />
          </div>
          <Button
            onClick={handlePasswordReset}
            className={style.resetSubmit}
            type="submit"
          >
            Reset Password
          </Button>
          <div className={style.loginLink}>
            Remember password?{" "}
            <span>
              <Link to="/login">Login</Link>
            </span>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
}

export default ResetPassword;
