import { RxDashboard } from "react-icons/rx";
import { GiBookshelf, GiNotebook } from "react-icons/gi";
import { PiExamFill } from "react-icons/pi";
// import { TfiMoreAlt } from "react-icons/tfi";
import { SiBookstack } from "react-icons/si";
import { FaChalkboardTeacher, FaGraduationCap } from "react-icons/fa";
import {
  // BsTrophy,
  BsFillJournalBookmarkFill,
  // BsFillClipboard2DataFill,
} from "react-icons/bs";
import { ImLibrary } from "react-icons/im";

export const navPaths = [
  {
    name: "Dashboard",
    path: "/dashboard",
    regex: /^\/dashboard/,
    icon: RxDashboard,
  },
  {
    name: "Library",
    path: "/library",
    regex: /^\/library/,
    icon: ImLibrary,
  },
  {
    name: "Courses",
    path: "/courses",
    regex: /^\/courses/,
    icon: FaChalkboardTeacher,
  },
  {
    name: "Exam Prep",
    path: "/examprep",
    regex: /^\/examprep/,
    icon: FaGraduationCap,
  },
  // {
  //   name: "Edu League",
  //   path: "/leaderboard",
  //   regex: /^\/leaderboard/,
  //   icon: BsTrophy,
  // },
  // {
  //   name: "More",
  //   path: "/dashboard",
  //   regex: /^\/more/,
  //   icon: TfiMoreAlt,
  // },
];

export const libraryPaths = [
  {
    name: "Library",
    path: "/library",
    regex: /^\/library$/,
    icon: ImLibrary,
  },
  {
    name: "Catalogue",
    path: "/library/catalogue",
    regex: /^\/library\/catalogue/,
    icon: SiBookstack,
  },
  {
    name: "Shelf",
    path: "/library/myshelf",
    regex: /^\/library\/myshelf/,
    icon: GiBookshelf,
  },
  {
    name: "Notes",
    path: "/library/notes",
    regex: /^\/library\/notes/,
    icon: GiNotebook,
  },
  {
    name: "Bookmarks",
    path: "/library/bookmark",
    regex: /^\/library\/bookmark/,
    icon: BsFillJournalBookmarkFill,
  },
];

export const examPrepPaths = [
  {
    name: "Exam Prep",
    path: "/examprep",
    regex: /^\/examprep$/,
    icon: FaGraduationCap,
  },
  {
    name: "Buckets",
    path: "/examprep/buckets",
    regex: /^\/examprep\/buckets/,
    icon: SiBookstack,
  },
  {
    name: "Quick Review",
    path: "/examprep/quick-review",
    regex: /^\/examprep\/quick-review/,
    icon: PiExamFill,
  },
  // {
  //   name: "Performance",
  //   path: "/examprep/performance-tracker",
  //   regex: /^\/examprep\/performance-tracker/,
  //   icon: BsFillClipboard2DataFill,
  // },
];
