// Modules
import moment from "moment";

// React icons
import { ViewIcon } from "../../../../assets/icons";

// Style
import style from "./EditBook.module.css";

// Hooks
import useBookForm from "../hooks/useBookForm";

export const ThumbnailAction = ({ previewFn, index, name }) => {
  const { state, updateFormState } = useBookForm();

  const removeTemplateHandler = () => {
    const newPages = state.pages.slice(0, state.pages.length - 1);
    updateFormState({ pages: newPages });
  };

  const addTemplateHandler = () => {
    updateFormState({
      pages: [
        ...state.pages,
        {
          name: name,
          index: index,
          pageId: moment().valueOf(),
          book_id: state.book_id,
        },
      ],
    });
  };

  return (
    <>
      <span className={style.thumbnailAction}>
        <input
          type="checkbox"
          className={style.checkbox}
          onChange={(e) =>
            e.target.checked ? addTemplateHandler() : removeTemplateHandler()
          }
        />

        <i onClick={() => previewFn(index)}>
          <ViewIcon />
        </i>
      </span>
    </>
  );
};
