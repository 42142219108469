import style from "./Tooltip.module.css";

const Tooltip = ({ children, data, classes = "", callbackFn = () => {} }) => {
  return (
    <span
      className={`${style.tooltip} ${classes}`}
      data-tooltip={data}
      onClick={callbackFn}
    >
      {children}
    </span>
  );
};

export default Tooltip;
