import { useEffect } from "react";
import useCdReactQuery from "../../../../utils/hooks/useCdReactQuery";
import { toast } from "react-toastify";
import assessmentService from "../../../../service/assessment.service";

export const useAssessment = () => {
  const { data, queryEndpoint, isLoading } = useCdReactQuery();

  useEffect(() => {
    if (data && data.msg) {
        toast.success(data.msg)
    }
  }, [data]);

  const submitAssessment = (submission) => {
    (async () => {
        await queryEndpoint({
          endpointUrl: assessmentService.bucketSubmission,
          httpMethod : "POST",
          body : submission
    })})()
  }



  return {
    isLoading,
    submitAssessment,

  };
}

export default useAssessment;
