import { request } from "./api";
import env from "../env";

const API = env.API_URL;

const URL = {
  uploadQuestion: `${API}/questions`,
  getQuestionByTopic: `${API}/questions/filter`,
  getUserQuestion: `${API}/questions/user_questions`,
  deleteQuestion: (questionId) => `${API}/questions/${questionId}/delete`,
};

function getQuestionByTopic(params) {
  return request(URL.getQuestionByTopic, params, "GET", {});
}

function uploadQuestion(params) {
  return request(URL.uploadQuestion, params, "POST", {});
}
function getUserQuestion(params) {
  return request(URL.getUserQuestion, params, "GET", {});
}
function deleteQuestion({ questionId }) {
  return request(URL.deleteQuestion(questionId), {}, "DELETE", {});
}

const questionService = {
  getQuestionByTopic,
  uploadQuestion,
  getUserQuestion,
  deleteQuestion,
};
export default questionService;
