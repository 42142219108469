import style from "./ColorPicker.module.css";
import { useEffect, useState } from "react";
const colorThemes = [
  { backgroundColor: "#fec46d", color: "#000" },
  { backgroundColor: "#98b7db", color: "#000" },
  { backgroundColor: "#fcf478", color: "#000" },
  { backgroundColor: "#aae2a7", color: "#000" },
  { backgroundColor: "#deedfb", color: "#000" },
];
const ColorPicker = ({ callbackFn, currentTheme }) => {
  const [toggleColorPicker, setToggleColorPicker] = useState(false);
  const [colorTheme, setColorTheme] = useState(currentTheme);
  useEffect(() => {
    callbackFn(colorTheme);
    // eslint-disable-next-line
  }, [colorTheme]);
  return (
    <div className={style.root}>
      <div
        className={style.colorPicker}
        style={{ ...colorTheme }}
        onClick={() => setToggleColorPicker((prev) => !prev)}
      ></div>
      {toggleColorPicker && (
        <div className={style.colorPickerContainer}>
          {colorThemes.map((item, index) => (
            <div
              className={style.colorPicker}
              style={{ ...item }}
              key={index}
              onClick={() => {
                setColorTheme({ ...item });
                setToggleColorPicker(false);
              }}
            ></div>
          ))}
        </div>
      )}
    </div>
  );
};
export default ColorPicker;
