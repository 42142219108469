import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import style from "./ProfilePage.module.css";
import { BsFillPencilFill } from "react-icons/bs";
import useProfilePage from "./hooks/useProfilePage";
import { initCaps } from '../../utils/helpers';
import { CalendarIcon, CityIcon, EmailIcon, GlobeIcon, LanguageIcon, PhoneIcon, UserIcon, ZipCode } from '../../assets/icons';
import CountryCode from "../../components/CountryStateData/CountryCode.json"
import useCdReactQuery from '../../utils/hooks/useCdReactQuery';
import { toast } from 'react-toastify';
import userProfile from '../../service/userProfile.service';
import { Loader } from '../../components/Modal';
import { FaLongArrowAltLeft } from 'react-icons/fa';


const ProfilePage = () => {
  const { queryEndpoint, isLoading, error } = useCdReactQuery();
  const { user, handleDeleteAccount} = useProfilePage();
  const [initForm, setInitForm] = useState({})
  const [editBio, setEditBio] = useState(false)
  const [editLocation, setEditLocation] = useState(false)
  const [editNotification, setEditNotification] = useState(false)
  const [editOthers, setEditOthers] = useState(false)
  const [countryData, setCountryData] = useState([])
  const [getState, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const navigate = useNavigate();


  const userGender = [
    {name: 'Male', id: 'male'},
    {name: 'Female', id: 'female'}
  ]

  useEffect(() => {
    setInitForm({
      email : user?.email || "",
      firstName : user?.firstName || "",
      city: user?.city || "",
      country : user?.country || "",
      country_code : user?.country_code || "",
      gender : user?.gender || "",
      lastName : user?.lastName || "",
      phoneNumber : user?.phoneNumber || "",
      state : user?.state || "",
      category_id : user?.category?.$id?.$oid || "",
      subcategory_id : user?.subcategory?.$id?.$oid || "",
    })
    
  }, [user])

  useEffect(() => {
    const COUNTRY_API = "https://pkgstore.datahub.io/core/world-cities/world-cities_json/data/5b3dd46ad10990bca47b04b4739a02ba/world-cities_json.json";
    fetch(
      `${COUNTRY_API}`
    )
      .then((response) => response.json())
      .then((data) => {
        setCountryData(data)
      });
  }, []);

  useEffect(() => {
    if (error) {
      const error_data = error?.response?.data;
      toast.error(`
        ${error_data?.error || error_data?.msg || error_data?.detail}
      `);
    }
  }, [error]);

  const countries = [...new Set(countryData.map((item) => item.country))];
  countries.sort();

  const handleCountry = (e) => {
    let states = countryData.filter(state => state.country === e.target.value);
    states = [...new Set(states.map((item) => item.subcountry))];
    states.sort();
    setStates(states);
    const sortcode = CountryCode.find((item) => item.country === e.target.value);
    setInitForm({...initForm, country_code : sortcode?.code, country : e.target.value })
  };

  const handleState = (e) => {
    const cities = countryData.filter((city) => city.subcountry === e.target.value);
    setInitForm({...initForm, state : e.target.value })
    setCities(cities);
  };


  const handleChange = ({target}) => {
    const {name, value} = target
    setInitForm({
      ...initForm,
      [name] : value
    })

  }

  const handleSubmit = async() => {
    const response = await queryEndpoint({
      endpointUrl: userProfile.edit_profile_url,
      httpMethod : "PUT",
      body : initForm
    });
    if(response.msg){
      toast.success(response.msg)
    }
  }
  return (
    <div className={style.container}>
      {isLoading && <Loader />}
      <button onClick={() => navigate(-1)} className={style.back_btn}>
          <FaLongArrowAltLeft /> Back
        </button>
      <div className={style.header_container}>
        <h5>Personal Information</h5>
        {editBio ? (
          <div>
            <button className={`${style.action_btn} ${style.btn_cancel}`} onClick={() => {
              setEditBio(false)
            }}>Cancel</button>
            <button className={`${style.action_btn}`} onClick={() => {
              handleSubmit()
              setEditBio(false)
            }}>Save</button>
          </div>
        ) : (
        <span onClick={() => setEditBio(true)}>
            <BsFillPencilFill />
        </span>
        )}
      </div>
      <div className={`${style.section} ${style.bio_section}`}>
        <div className={`${style.image_container} ${!user?.image && style.image_placeholder}`}>
          {user?.image ? (
            <img src="https://i0.wp.com/worldmusicviews.com/wp-content/uploads/2022/09/wizkid-2021-thatgrapejuice.jpeg?resize=750%2C375&ssl=1"
            alt={`${user?.lastName}-${user?.firstName}`}
            />
          ): (
            <div>
                {user?.firstName?.charAt(0) || ""}{user?.lastName?.charAt(0) || ""}
            </div>
          )}
        </div>
        <div className={style.personal_profile_container}>
          <h4>{user?.firstName && initCaps(user?.firstName)} {user?.lastName && initCaps(user?.lastName)}</h4>
          <div>
            <div className={style.section}>
              <div className={style.form_container}>
                <label>Email Address</label>
                <div className={`${style.input_container}`}>
                  <EmailIcon />|<input value={initForm?.email} name="email" readOnly={!editBio}/>
                </div>
              </div>
            </div>
            <div className={style.section}>
              <div className={style.form_container}>
                <label>First Name</label>
                <div className={`${style.input_container}`}>
                  <UserIcon />|<input name='firstName' value={initForm?.firstName} onChange={e => handleChange(e)} readOnly={!editBio}/>
                </div>
              </div>
            </div>
            <div className={style.section}>
              <div className={style.form_container}>
                <label>Last Name</label>
                <div className={`${style.input_container}`}>
                  <UserIcon />|<input name='lastName' value={initForm?.lastName} onChange={e => handleChange(e)} readOnly={!editBio}/>
                </div>
              </div>
            </div>
            <div className={`${style.section}`}>
              <div className={style.form_container}>
                <label>Current Date</label>
                <div className={`${style.input_container}`}>
                  <CalendarIcon />|<input value={new Date().toDateString()}/>
                </div>
              </div>
            </div>
            <div className={style.section}>
              <div className={style.form_container}>
                <label>Phone Number</label>
                <div className={`${style.input_container}`}>
                  <PhoneIcon />|<input name='phoneNumber' value={initForm?.phoneNumber} onChange={e => handleChange(e)} readOnly={!editBio}/>
                </div>
              </div>
            </div>
            <div className={style.section}>
              <div className={style.form_container}>
                <label>Gender</label>
                <div className={`${style.input_container}`}>
                  <UserIcon />|
                  <select name='gender' value={initForm?.gender} disabled={!editBio} onChange={e => handleChange(e)}>
                    {userGender.map(gender => (<option value={gender.id}>{gender.name}</option>))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className={style.header_container}>
        <h5>Location</h5>
        {editLocation ? (
          <div>
            <button className={`${style.action_btn} ${style.btn_cancel}`} onClick={() => {
              setEditLocation(false)
            }}>Cancel</button>
            <button className={`${style.action_btn}`} onClick={() => {
              handleSubmit()
              setEditLocation(false)
            }}>Save</button>
          </div>
        ) : (
        <span onClick={() => setEditLocation(true)}>
            <BsFillPencilFill />
        </span>
        )}
      </div>
      <div className={style.personal_profile_container}>
        <div>
          <div className={style.section}>
            <div className={style.form_container}>
              <label>Country</label>
              <div className={`${style.input_container}`}>
                <GlobeIcon />|
                <select name='country' value={initForm?.country} disabled={!editLocation} onChange={handleCountry}>
                  <option value={""} >Select Country</option>
                  {countries.map(country => (<option value={country}>{country}</option>))}
                </select>
              </div>
            </div>
          </div>
          <div className={style.section}>
            <div className={style.form_container}>
              <label>State</label>
              <div className={`${style.input_container}`}>
                <GlobeIcon />|
                <select name='state' value={initForm?.state} disabled={!editLocation} onChange={handleState}>
                  <option value={""} >Select State</option>
                  {getState && getState.map(state => (<option value={state}>{state}</option>))}
                </select>
              </div>
            </div>
          </div>
          <div className={style.section}>
            <div className={style.form_container}>
              <label>City</label>
              <div className={`${style.input_container}`}>
                <CityIcon />|
                <select name='city' value={initForm?.city} disabled={!editLocation} onChange={handleChange}>
                  <option value={""} >Select City</option>
                  {cities && cities.map(city => (<option value={city.name}>{city.name}</option>))}
                </select>
              </div>
            </div>
          </div>
          <div className={style.section}>
            <div className={style.form_container}>
              <label>Country Code</label>
              <div className={`${style.input_container}`}>
                <ZipCode />|<input name='country_code' value={initForm?.country_code} readOnly/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className={style.header_container}>
        <h5>Notifications</h5>
        {editNotification ? (
          <div>
            <button className={`${style.action_btn} ${style.btn_cancel}`} onClick={() => {
              setEditNotification(false)
            }}>Cancel</button>
            <button className={`${style.action_btn}`} onClick={() => {
              // handleSubmit()
              setEditNotification(false)
            }}>Save</button>
          </div>
        ) : (
        <span onClick={() => setEditNotification(true)}>
            <BsFillPencilFill />
        </span>
        )}
      </div>
      <div className={`${style.section} ${style.gap_30} ${style.margin_30}`}>
        <div className={style.checkbox_container}>
          <input type="checkbox" disabled={!editNotification}/>
          <label>SMS</label>
        </div>
        <div className={style.checkbox_container}>
          <input type="checkbox" disabled={!editNotification}/>
          <label>Payment Reminders</label>
        </div>
        <div className={style.checkbox_container}>
          <input type="checkbox" disabled={!editNotification}/>
          <label>Subscription to Newsletter</label>
        </div>
      </div>
      <hr />
      <div className={style.header_container}>
        <h5>Other Settings</h5>
        {editOthers ? (
          <div>
          <button className={`${style.action_btn} ${style.btn_cancel}`} onClick={() => {
            setEditOthers(false)
          }}>Cancel</button>
          <button className={`${style.action_btn}`} onClick={() => setEditOthers(false)}>Save</button>
        </div>
        ) : (
        <span onClick={() => setEditOthers(true)}>
            <BsFillPencilFill />
        </span>
        )}
      </div>
      <div className={style.personal_profile_container}>
        <div>
          <div className={style.section}>
            <div className={style.form_container}>
              <label>Preferred Language</label>
              <div className={`${style.input_container}`}>
                <LanguageIcon />|
                <select disabled={!editOthers}>
                  <option value="English">English</option>
                  <option value="Spanish">Spanish</option>
                  <option value="Portuguese">Portuguese</option>
                  <option value="Bengali">Bengali</option>
                  <option value="Russian">Russian</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`${style.section} ${style.margin_30} ${style.action_section}`}>
        <button className={`${style.action_btn}`}>Change Password</button>
        <button className={`${style.action_btn} ${style.background_danger}`} onClick={handleDeleteAccount}>Delete Account</button>
      </div>
    </div>
  );
};

export default ProfilePage;
