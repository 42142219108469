import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { AddIcon, ViewIcon } from "../../../assets/icons";
import style from "./Settings.module.css";
import { FullpageModal } from "../../../components/Modal";
import useAdminLibrary from "../Library/hooks/useAdminLibrary";
const Settings = () => {
  const { getBookTypes } = useAdminLibrary();
  const [modalOpen, setModalOpen] = useState(false);
  const [bookTypes, setBookTypes] = useState([]);

  useEffect(() => {
    (async () => {
      setBookTypes(await getBookTypes());
    })();

    // eslint-disable-next-line
  }, []);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };
  return (
    <div className={style.scroller}>
      <div className={style.row}>
        <div className={style.card}>
          <div>
            <h3 className={style.title}>Book Types</h3>
            <small>{bookTypes.length} types available</small>
          </div>

          <ViewIcon className={style.icon} onClick={toggleModal} />
        </div>
        <div className={style.card}>
          <div>
            <h3 className={style.title}>Subjects</h3>
            <small>Add subject to different categories</small>
          </div>
          <Link to="/admin/subjects/">
            <AddIcon className={style.icon} />
          </Link>
        </div>
        <div className={style.card}>
          <div>
            <h3 className={style.title}>Categories</h3>
            <small>Group classes into categories</small>
          </div>
          <Link to="/admin/categories/">
            <AddIcon className={style.icon} />
          </Link>
        </div>
      </div>
      <div className={style.row}>
        <div className={style.card}>
          <div>
            <h3 className={style.title}>Classes</h3>
            <small>Add class to categories</small>
          </div>

          <Link to="/admin/classes/">
            <AddIcon className={style.icon} />
          </Link>
        </div>
      </div>
      <div className={style.row}></div>
      {modalOpen && (
        <FullpageModal toggleModal={toggleModal} className={style.previewSize}>
          <div className={style.scrollWrapper}>
            <div className={style.row}>
              {bookTypes &&
                bookTypes.map((type) => (
                  <div className={style.card} key={type.id}>
                    <div style={{ textAlign: "center", width: "100%" }}>
                      <h3
                        className={style.title}
                        style={{ textTransform: "capitalize" }}
                      >
                        {type.name}
                      </h3>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </FullpageModal>
      )}
    </div>
  );
};
export default Settings;
