import { useState } from "react";
import classes from "./CourseSyllabus.module.css";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { MdOutlineQuiz, MdOutlineTopic } from "react-icons/md";
import { CiLock } from "react-icons/ci";

function CourseSyllabus({
  onClickTopic,
  topic,
  description,
  quiz,
  progressIcon,
}) {
  const [open, setOpen] = useState(false);
  return (
    <div className={classes.cardRoot}>
      <div style={{ marginTop: "1.5em" }}>
        {progressIcon || (
          <div className={classes.lockIcon}>
            <CiLock />
          </div>
        )}
      </div>

      <div>
        <div onClick={() => setOpen(!open)} className={classes.cardTopSect}>
          <div className={classes.topTextColumn}>
            <h3 className={classes.cardTitle}>{topic}</h3>
            <p className={classes.cardDescriptionText}>{description}</p>
          </div>
          <div className={classes.toggleIconWrap}>
            {open ? <IoIosArrowUp /> : <IoIosArrowDown />}
          </div>
        </div>
        <div
          className={`${classes.cardBody} ${
            open ? classes.openCardBody : undefined
          }`}
        >
          <div>
            <div onClick={onClickTopic} className={classes.cardBodyItemRow}>
              <div className={classes.cardItemNameWrap}>
                <div className={classes.itemNameIcon}>
                  <MdOutlineTopic />
                </div>
                <div className={classes.itemName}>Topic</div>
              </div>
              <div className={classes.itemName}>{topic}</div>
            </div>
            {!!quiz && (
              <div
                className={classes.cardBodyItemRow}
                style={{ marginTop: 20 }}
              >
                <div className={classes.cardItemNameWrap}>
                  <div className={classes.itemNameIcon}>
                    <MdOutlineQuiz />
                  </div>
                  <div className={classes.itemName}>Quiz</div>
                </div>
                <div className={classes.itemName}>{quiz}</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CourseSyllabus;
