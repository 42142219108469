import style from "./NoteForm.module.css";
import { BsCheckLg } from "react-icons/bs";
import { MdClose, MdDelete } from "react-icons/md";
import ColorPicker from "../ColorPicker";
import { useContext, useState } from "react";
import ConfirmDialog from "../../../../components/ConfirmationDialogCD";
import { NoteContext } from "../noteContext";
const NoteDetails = ({ data, formAction, closeModal }) => {
  const [formTheme, setFormTheme] = useState(data?.note_style || {});
  // const [pages, setPages] = useState(data?.content || []);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const { dispatch } = useContext(NoteContext);

  const themeHandler = (theme) => setFormTheme(theme);
  const actionHandler = (action) => {
    switch (action.type) {
      case "save":
        const updatedNoteDetails = {
          ...data,
          content: data?.content,
          note_style: formTheme,
        };
        dispatch({ type: "UPDATE_NOTE", payload: updatedNoteDetails });
        closeModal();
        break;
      case "cancel":
        closeModal();
        break;
      default:
        break;
    }
  };

  return (
    <div className={style.root}>
      <div className={style.actions}>
        <i onClick={() => actionHandler({ type: "save" })}>
          <BsCheckLg />
        </i>
        <i onClick={() => actionHandler({ type: "cancel" })}>
          <MdClose />
        </i>
        <i onClick={() => setConfirmDelete((prev) => !prev)}>
          <MdDelete />
        </i>
        <ColorPicker callbackFn={themeHandler} currentTheme={formTheme} />
      </div>
      <div className={style.form} style={formTheme}>
        <p className={style.noteTitle}>{data?.title || "Untitled"}</p>
        <div className={style.pageAction}>
          <span>[ Page {currentPageIndex + 1} ]</span>
          <hr />
        </div>
        <pre className={style.noteContent}>
          {data?.content[currentPageIndex] || ""}
        </pre>
        <div className={style.pages}>
          {(data?.content || []).map((counter, key) => (
            <i onClick={() => setCurrentPageIndex(key)} key={key}>
              {key + 1}
            </i>
          ))}
        </div>
      </div>
      {confirmDelete && (
        <ConfirmDialog
          message="Are you sure you want to delete this note?"
          cancel={() => {
            setConfirmDelete(false);
          }}
          confirm={() => {
            dispatch({ type: "DELETE_NOTE", payload: data.id });
            setConfirmDelete(false);
            closeModal();
          }}
        />
      )}
    </div>
  );
};
export default NoteDetails;
