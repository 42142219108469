import { useEffect, useState } from "react";
import style from "./ResultView.module.css";
import { GoodIcon, BadIcon } from "../../assets/icons";
import { useDispatch } from "react-redux";
import {
  qRetakeAssessment,
  setRetakeAssessmentStatus,
} from "../../redux/feature/bookQuestionsSlice";
import { roundToDecimalPlaces } from "../../utils/helpers";

export const RetakeAssessment = () => {
  const dispatch = useDispatch();

  return (
    <div>
      <p className={style.msg}>
        You have not met the passmark for this assessment. Click on the link to
        <span onClick={() => dispatch(qRetakeAssessment())}>
          Retake Assessment
        </span>
      </p>
    </div>
  );
};

const ResultView = ({ closeModalFn, data = [], pass_mark }) => {
  const dispatch = useDispatch();
  const [percentScore, setPercentScore] = useState(0);

  const result = Object.keys(data).map((key) => {
    const isCorrect = data[key].userAnswer === data[key].answer;
    return {
      questionPosition: key,
      answers: data[key],
      isCorrect,
    };
  });

  useEffect(() => {
    const count = result.filter((item) => item.isCorrect).length;
    const score = (count / result.length) * 100;

    setPercentScore(count ? roundToDecimalPlaces(score, 1) : 0);
    // Prevent user from going to next page if passmark is not reached. Automatically restart the assessment
    percentScore < pass_mark
      ? dispatch(setRetakeAssessmentStatus(true))
      : dispatch(setRetakeAssessmentStatus(false));

    // TODO:: send user score to redux for upload into database only if user scored above or equal to the passmark

    // eslint-disable-next-line
  }, [result]);

  return (
    <div className={style.modal}>
      <h5 className={style.title}>Your Overall Performance</h5>
      <p className={style.intro}>
        {percentScore}
        <span>%</span>
      </p>
      <div>
        {percentScore < pass_mark ? (
          <RetakeAssessment />
        ) : pass_mark === "" ? (
          <p className={style.msg}>
            Find below, your performance on the question(s) attempted. This
            assessment has no passmark. Click next to continue.
          </p>
        ) : (
          <p className={style.msg}>
            Contratulations, you've shown considerable knowledge on the lessons
            thought. Click next to continue your studies.
          </p>
        )}
      </div>
      <ul className={style.list}>
        {result.map((item, index) => {
          return (
            <li key={index}>
              Question {index + 1}{" "}
              {item.isCorrect ? (
                <GoodIcon className={style.good} />
              ) : (
                <BadIcon className={style.bad} />
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ResultView;
