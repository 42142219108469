import { useState, useEffect } from "react";

const useResponsiveness = (breakPoint) => {
  const [width, setWidth] = useState();

  useEffect(() => {
    setWidth(window.innerWidth);
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
  }, []);

  const responsive = breakPoint > width ? true : false;

  return {
    responsive,
    width,
  };
};

export default useResponsiveness;
