import { useEffect, useState } from "react";
import {
  AddIcon,
  BackIcon,
  DeleteIcon,
  ImageIcon,
  PublishIcon,
  SaveIcon,
  UnPublishedIcon,
  VideoIcon,
  ViewIcon,
} from "../../../../assets/icons";
import classes from "./ExamprepManageReview.module.css";
import InputField from "../../../../components/InputField";
import Dropdown from "../../../../components/Dropdown";
import useExamprepManageReview from "./hooks/useExamprepManageReview";
import { MDBSpinner } from "mdb-react-ui-kit";
import useCdReactQuery from "../../../../utils/hooks/useCdReactQuery";
import adminService from "../../../../service/admin.service";
import { useNavigate } from "react-router-dom";
import ReviewLoader from "./ReviewLoader";
import Tooltip from "../../../../components/Tooltip";
import { Loader } from "../../../../components/Modal";
import ConfirmDialog from "../../../../components/ConfirmationDialogCD/ConfirmDialog";
import { Controller } from "../../../ExamPrep/ExamPrepQuickReview/Controller";

function CreateReviewButton({ onClick }) {
  return (
    <div onClick={onClick} className={classes.createReviewWraper}>
      <div className={classes.addTitle}>
        <AddIcon />
        <span>Add Review Question</span>
      </div>
      <div className={classes.buttonInfo}>
        Click to add new review question & answer
      </div>
    </div>
  );
}

function ReviewInputCard({
  imageUrl,
  placeholder,
  value,
  onChangeImage,
  onChange,
  loadingImage,
}) {
  return (
    <div className={classes.cardWrapper}>
      <div>
        <textarea
          value={value}
          onChange={onChange}
          rows={5}
          cols={50}
          placeholder={placeholder}
        />
      </div>
      <div className={classes.mediaSection}>
        <button className={classes.mediaButton}>
          <label>
            {imageUrl ? <img src={imageUrl} alt="" /> : <ImageIcon />}

            <input
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              onChange={onChangeImage}
              name="file"
            />
          </label>
          {!!loadingImage && (
            <MDBSpinner className={classes.loader} role="status" />
          )}
        </button>
        <button className={classes.mediaButton}>
          <label>
            <VideoIcon />

            <input
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              onChange={() => {}}
              name="file"
            />
          </label>
        </button>
      </div>
    </div>
  );
}

function ExamprepManageReview({ isEdit }) {
  const { data = {}, queryEndpoint: fetchTopics } = useCdReactQuery();
  const [newTopic, setNewTopic] = useState({});
  const navigate = useNavigate();

  const topics = data?.data || [];

  const {
    state,
    isDeleting,
    assessmentModalToggle,
    isUpdatingPublish,
    onPublish,
    dispatch,
    handleAddReview,
    onDeleteReview,
    onQuestionChange,
    onAnswerChange,
    submitHandler,
    onUploadImage,
    deleteReview,
    previewReview,
  } = useExamprepManageReview({ isEdit });
  const {
    reviews,
    isUploadingImage,
    selectedSubject,
    selectedClass,
    isSaving,
    isFetchingReview,
    dialogModal,
    isPublished,
    isChangeTopic,
    topic,
  } = state;

  useEffect(() => {
    fetchTopics({
      endpointUrl: adminService.getTopicsBySubjectId(selectedSubject?.id),
      httpMethod: "GET",
    });
  }, [selectedSubject]); // eslint-disable-line

  if (isFetchingReview) {
    return <ReviewLoader />;
  }

  function handleChangeTopic() {
    dispatch({ isChangeTopic: false, topic: newTopic, reviews: [] });
  }

  return (
    <>
      <div className={classes.pageRoot}>
        <div className={classes.root_header}>
          <div className={classes.root_header_title}>
            <Tooltip data="Back">
              <span onClick={() => navigate("/admin/examprep")}>
                <BackIcon />
              </span>
            </Tooltip>
            <h5>{isEdit ? "Edit" : "Add"} Review</h5>
          </div>
          <div className={classes.actions}>
            <Tooltip data="Save" callbackFn={submitHandler}>
              <SaveIcon />
            </Tooltip>
            <Tooltip data="Preview" callbackFn={previewReview}>
              <ViewIcon />
            </Tooltip>

            {isEdit && (
              <>
                <Tooltip
                  data={isPublished ? "Unpublish" : "Publish"}
                  callbackFn={onPublish}
                >
                  {isPublished ? <UnPublishedIcon /> : <PublishIcon />}
                </Tooltip>
                <Tooltip data="Delete">
                  <span
                    onClick={() => {
                      dispatch({ dialogModal: true });
                    }}
                  >
                    <DeleteIcon />
                  </span>
                </Tooltip>
              </>
            )}
          </div>
        </div>
        <hr />
        <div className={classes.pageBody}>
          <div className={classes.textInputRow}>
            <div style={{ flex: 1 }}>
              <label>Class*</label>
              <InputField
                name="class"
                type="text"
                disabled
                value={selectedClass?.name}
                className={classes.titleInput}
              />
            </div>
            <div style={{ flex: 1 }}>
              <label>Subject*</label>
              <InputField
                name="subject"
                type="text"
                disabled
                value={selectedSubject?.name}
                className={classes.titleInput}
              />
            </div>
            <div style={{ flex: 1 }}>
              <label>Topics*</label>
              <Dropdown
                options={topics}
                value={topic?.name}
                className={classes.dropDown}
                onChange={(e) => {
                  if (isEdit) {
                    setNewTopic(e);
                    dispatch({ isChangeTopic: true });
                    return;
                  }
                  dispatch({ topic: e });
                }}
              />
            </div>
          </div>
          {reviews.map((item, index) => (
            <div>
              <div className={classes.rowDivider}>
                <span className={classes.questionNumber}>{index + 1}</span>
                <hr className={classes.dividerLine} />
                <button
                  onClick={() =>
                    onDeleteReview(index, item?.question?.imageUrl)
                  }
                  className={classes.deleteButton}
                >
                  <DeleteIcon />
                </button>
              </div>
              <div className={classes.questionAndAnswerRow}>
                <ReviewInputCard
                  loadingImage={isUploadingImage?.question}
                  imageUrl={item?.question?.imageUrl}
                  value={item?.question?.text}
                  onChange={(e) => onQuestionChange(e, index)}
                  onChangeImage={(e) => {
                    onUploadImage(
                      e,
                      index,
                      item?.question?.imageUrl,
                      "question"
                    );
                  }}
                  placeholder="Question"
                />
                <ReviewInputCard
                  onChange={(e) => onAnswerChange(e, index)}
                  loadingImage={isUploadingImage?.answer}
                  value={item?.answer?.text}
                  imageUrl={item?.answer?.imageUrl}
                  placeholder="Answer"
                  onChangeImage={(e) => {
                    onUploadImage(e, index, item?.answer?.imageUrl, "answer");
                  }}
                />
              </div>
            </div>
          ))}

          <div style={{ padding: "0 30px", marginTop: 15 }}>
            <CreateReviewButton onClick={handleAddReview} />
          </div>
        </div>

        {(isSaving || isDeleting || isUpdatingPublish) && <Loader />}

        {dialogModal && (
          <ConfirmDialog
            confirm={deleteReview}
            cancel={() => {
              dispatch({ dialogModal: false });
            }}
            message="Click continue to DELETE review"
          />
        )}
        {isChangeTopic && (
          <ConfirmDialog
            confirm={handleChangeTopic}
            cancel={() => {
              dispatch({ isChangeTopic: false, topic: topic });
            }}
            message="On clicking continue you will lose all previous questions"
          />
        )}
      </div>
      {assessmentModalToggle && <Controller />}
    </>
  );
}

export default ExamprepManageReview;
