import SkeletonLoader from "../../../../../components/SkeletonLoader/SkeletonLoader";
import classes from "./UploadSetupLoader.module.css";

function UploadSetupLoader() {
  return (
    <div className={classes.loaderRoot}>
      <div className={classes.coverLoaderWrapper}>
        <SkeletonLoader className={classes.coverImageLoader} />
      </div>
      <div className={classes.inputLoaderWrapper}>
        {[...new Array(3).values()].map((_, index) => (
          <SkeletonLoader className={classes.inputLoader} />
        ))}
      </div>
      <div className={classes.inputLoaderWrapper}>
        {[...new Array(2).values()].map((_, index) => (
          <SkeletonLoader className={classes.inputLoader} />
        ))}
      </div>
    </div>
  );
}

export default UploadSetupLoader;
