import {
  FilterIcon,
  GridAddIcon,
  QuestionBucketIcon,
  CourseReviewIcon,
} from "../../../assets/icons";
import style from "./Examprep.module.css";
import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import ClassModal from "../QuestionBank/modal/ClassModal";
import { useNavigate } from "react-router-dom";
import BucketCard from "./bucket/BucketCard";
import Tooltip from "../../../components/Tooltip/Tooltip";
import ReviewsCard from "./Reviews/ReviewsCard";
import { useDispatch } from "react-redux";
import { setTabClicked } from "../../../redux/feature/examprepSlice";
import adminService from "../../../service/admin.service";
import useCdReactQuery from "../../../utils/hooks/useCdReactQuery";
import { Loader } from "../../../components/Modal";

export const TabContent = ({ children, tab, callbackFn = () => {} }) => {
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const { userClass, subject } = useSelector((store) => store.questionBank);
  const { queryEndpoint, isLoading } = useCdReactQuery();
  const searchRef = useRef("");

  const fetch = async (url, params) => {
    setLoader(true);
    const response = await queryEndpoint({
      endpointUrl: url,
      params: params,
    });
    setLoader(false);
    return response;
  };

  const searchHandler = async (searchItem) => {
    let responseData = [];
    let params = {
      subcategory_id: userClass.id,
      subject_id: subject.id,
      limit: 100,
    };
    if (tab === "bucket") {
      if (searchItem) {
        if (["easy", "medium", "hard"].includes(searchItem.toLowerCase())) {
          params = { ...params, difficulty_level: searchItem };
        } else {
          params = { ...params, title: searchItem };
        }
        responseData = await fetch(adminService.getBuckets, params);
      } else {
        responseData = await fetch(adminService.getBuckets, params);
        callbackFn(responseData);
      }
    } else {
      callbackFn(searchItem);
    }
  };

  useEffect(() => {
    // show filter modal if not subject and user class is not set
    if (userClass.id === "" && subject.id === "") setModal(true);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {/* Search, Filter and Add UI */}
      <div className={`${style.row} ${style.actions}`}>
        <div className={style.searchContainer}>
          <input
            type="search"
            className={style.search}
            placeholder={`Search ${
              tab === "courseReview" ? "Coure Review " : "Bucket "
            } `}
            onKeyUp={(e) => {
              if (e.key === "Enter") searchHandler(e.target.value);
            }}
            ref={searchRef}
          />
          <button
            className={style.searchBtn}
            onClick={() => searchHandler(searchRef?.current?.value)}
          >
            GO
          </button>
        </div>

        <Tooltip
          data="Filter"
          classes={style.tooltip}
          callbackFn={() => setModal(true)}
        >
          <FilterIcon size={22} />
        </Tooltip>

        <Tooltip
          data="Add"
          classes={style.tooltip}
          callbackFn={() => {
            if (tab === "courseReview") {
              navigate("/admin/examprep/review/create");
              return;
            }
            navigate("/admin/examprep/0");
          }}
        >
          <GridAddIcon size={25} />
        </Tooltip>
      </div>
      <div>{children}</div>
      {modal && <ClassModal closeModal={() => setModal(false)} />}
      {(loader || isLoading) && <Loader />}
    </>
  );
};

const Examprep = () => {
  const reduxDispatch = useDispatch();

  const { tabclicked } = useSelector(({ examprep }) => examprep);
  const {
    queryEndpoint,
    data: reviewDate,
    isLoading: isLoadingReview,
  } = useCdReactQuery();

  const { userClass, subject } = useSelector(
    ({ questionBank }) => questionBank
  );

  const fechReview = async (searchValue = "") => {
    await queryEndpoint({
      endpointUrl: adminService.courseReview,
      params: {
        subject_id: subject.id,
        topic_name: searchValue || "",
        limit: 1000,
      },
    });
  };

  useEffect(() => {
    fechReview();

    // eslint-disable-next-line
  }, [userClass.id, subject.id]);
  const [bucketSearchResult, setBucketSearchResult] = useState(null);

  // Load mathJax for preview of mathematical expressions
  useEffect(() => {
    if (typeof window?.MathJax !== "undefined") {
      window.MathJax.typesetClear();
      window.MathJax.typeset();
    }
  });

  function searchReview(searchValue) {
    fechReview(searchValue);
  }
  const bucketSearchHandler = (data) => {
    setBucketSearchResult(data);
  };

  return (
    <div className={style.root}>
      {/* Tab Links */}
      <ul className={style.tabs}>
        <li
          className={`${tabclicked === "bucket" ? style.active : ""}`}
          onClick={() => reduxDispatch(setTabClicked("bucket"))}
        >
          <QuestionBucketIcon /> Buckets
        </li>
        <li
          className={`${tabclicked === "courseReview" ? style.active : ""}`}
          onClick={() => reduxDispatch(setTabClicked("courseReview"))}
        >
          <CourseReviewIcon /> Reviews
        </li>
      </ul>
      {/* Tabs */}
      {tabclicked === "bucket" ? (
        <TabContent tab="bucket" callbackFn={bucketSearchHandler}>
          <BucketCard data={bucketSearchResult} />
        </TabContent>
      ) : (
        <TabContent callbackFn={searchReview} tab="courseReview">
          <ReviewsCard
            fechReview={fechReview}
            reviewDate={reviewDate}
            isLoadingReview={isLoadingReview}
          />
        </TabContent>
      )}
    </div>
  );
};

export default Examprep;
