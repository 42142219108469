import { useEffect, useState } from "react";
// import { toast } from "react-toastify";
import useCdReactQuery from "../../../utils/hooks/useCdReactQuery";
import libraryService from "../../../service/library.service";
import { useSelector } from "react-redux";
import env from "../../../env";
import { useLocation } from "react-router-dom";

const useBookDetails = ({
  borrowed,
  bookmarked,
  book_id,
  book_details,
  fetchCourseDetails,
}) => {
  const [state, setState] = useState({ borrowed, bookmarked });
  const { user } = useSelector(({ currentUser }) => currentUser);
  const [isValidatingPayment, setIsValidatingPayment] = useState(false);
  const [isInitiatingPayment, setIsInitiatingPayment] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paymentReference = queryParams.get("reference");
  const { queryEndpoint } = useCdReactQuery();
  const { queryEndpoint: bookPayment } = useCdReactQuery();

  // useEffect(() => {
  //   updateBorrowedStatus(book_id, borrowed ? false : true);
  //   updateBookmark(bookmarked);
  // }, []);

  const updateBorrowedStatus = (isReturned = false) => {
    (async () => {
      await queryEndpoint({
        endpointUrl: isReturned
          ? libraryService.returnBook(book_id)
          : libraryService.borrowBook(book_id),
        httpMethod: "PUT",
      });

      setState((prev) => ({
        ...prev,
        borrowed: !prev.borrowed,
      }));
    })();
  };

  const updateBookmark = () => {
    (async () => {
      state.bookmarked
        ? await queryEndpoint({
            endpointUrl: libraryService.deleteBookmark(book_id),
            httpMethod: "DELETE",
          })
        : await queryEndpoint({
            endpointUrl: libraryService.addBookmark,
            httpMethod: "POST",
            body: { book_id: book_id },
          });
    })();
    setState((prev) => ({ ...prev, bookmarked: !prev.bookmarked }));
  };
  async function payForBook(payment_gateway) {
    setIsInitiatingPayment(true);
    try {
      const response = await bookPayment({
        endpointUrl: libraryService.buyBook,
        httpMethod: "POST",
        body: {
          email: user?.email,
          amount_paid: `${book_details?.price}`,
          callback_url: `${env.APP_URL}/library/course/${book_details?._id}`,
          user_id: user?._id?.$oid,
          payment_gateway: payment_gateway,
          book_id: book_details?._id,
          currency: "NGN",
        },
      });
      if (response?.auth_url) {
        window.location.href = response?.auth_url;
      }
    } catch (error) {}
    setIsInitiatingPayment(false);
  }

  async function validatePayment() {
    setIsValidatingPayment(true);
    try {
      const response = await bookPayment({
        endpointUrl: libraryService.validateBookPayment,
        httpMethod: "POST",
        body: { reference_number: paymentReference, email: user?.email },
      });
      if (response) fetchCourseDetails?.();
    } catch (error) {}
    setIsValidatingPayment(false);
  }

  useEffect(() => {
    if (paymentReference?.length) {
      validatePayment();
    }
  }, [paymentReference]); // eslint-disable-line

  return {
    isBorrowed: state.borrowed,
    isBookmarked: state.bookmarked,
    isValidatingPayment,
    isInitiatingPayment,
    updateBorrowedStatus,
    updateBookmark,
    payForBook,
  };
};
export default useBookDetails;
