import React from "react";
import { createBrowserRouter } from "react-router-dom";
import DashboardLayout from "../layouts/DashboardLayout";
import LibraryLayout from "../pages/library/LibraryLayout";
import EmailConfirmation from "../pages/EmailConfirmation";
import HomePage from "../pages/HomePage/HomePage";
import LogIn from "../pages/LogIn/LogIn";
import NewPassword from "../pages/NewPassword";
import RegistrationPage from "../pages/RegistrationPage";
import ResetPassword from "../pages/ResetPassword";
import { dashboardRoutes } from "./dashboardRoutes";
import { libraryoutRoutes } from "./libraryoutRoutes";
import { examprepRoutes } from "./examprepRoutes";
import { adminRoutes } from "./adminRoutes";
import PrivateRoute from "./PrivateRoute";
import ExamPrepLayout from "../pages/ExamPrep/ExamPrepLayout/ExamPrepLayout";
import AdminLayout from "../pages/Admin/Layout/AdminLayout";
export const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/register/:account_type",
    element: <RegistrationPage />,
  },
  {
    path: "/login",
    element: <LogIn />,
  },
  {
    path: "/reset",
    element: <ResetPassword />,
  },
  {
    path: "/userverify",
    element: <EmailConfirmation />,
  },
  {
    path: "/auth/newpassword",
    element: <NewPassword />,
  },

  {
    path: "",
    element: (
      <PrivateRoute>
        <DashboardLayout />
      </PrivateRoute>
    ),
    children: dashboardRoutes,
  },
  {
    path: "/library",
    element: (
      <PrivateRoute>
        <LibraryLayout />
      </PrivateRoute>
    ),
    children: libraryoutRoutes,
  },
  {
    path: "examprep",
    element: (
      <PrivateRoute>
        <ExamPrepLayout />
      </PrivateRoute>
    ),
    children: examprepRoutes,
  },
  {
    path: "admin",
    element: (
      <PrivateRoute>
        <AdminLayout />
      </PrivateRoute>
    ),
    children: adminRoutes,
  },
]);
