import useBookmark from "./hooks/useBookmark";
import CatalogLayout from "../BookCatalog/Catalog";

const Bookmark = () => {
  const { data, isLoading, returnBook, searchBookmarks } = useBookmark();

  // console.log("bookmark ", data);

  return (
    <CatalogLayout
      data={data}
      searchPlaceholder="Search Bookmark"
      navLink="/library"
      navText="Bookmark"
      isLoading={isLoading}
      returnBook={returnBook}
      searchFn={searchBookmarks}
    />
  );
};

export default Bookmark;
