import { useEffect, useState } from "react";
import classes from "./TextArea.module.css";
// import _debounce from "lodash/debounce";

const TextArea = ({
  label,
  rows = 4,
  className,
  placeholder,
  value,
  name,
  onChange,
}) => {
  // const debounce = _debounce((e) => onChange(e), 500);
  const [data, setData] = useState("");

  useEffect(() => {
    setData(value);
  }, [value]);

  const changeHandler = (e) => {
    setData(e.target.value);
    onChange(e);
  };

  return (
    <div className={classes.textAreaWrap}>
      <label htmlFor="" className={classes.textAreaLabel}>
        {label}
      </label>
      <textarea
        name={name}
        value={data}
        onChange={changeHandler}
        placeholder={placeholder}
        rows={rows}
        className={`${classes.textInput} ${className}`}
      ></textarea>
    </div>
  );
};

export default TextArea;
