import moment from "moment";
import { initCaps } from "../../utils/helpers";
import style from "./NotificationPage.module.css";
import { BsPeople, BsEnvelope } from "react-icons/bs";

function MessageReader({ notification }) {
  return (
    <>
      <div className={style.readerTitle}>{notification.title}</div>
      <div className={style.readerDetails}>
        <span className={style.senderInitials}>{notification?.author[0]}</span>
        <div className={style.sender}>
          <span className={style.authorFullName}>{notification?.author}</span>
          <div className={style.messageDate}>
            {moment(notification?.date_created, "DD-MM-YYYY HH:mm:ss").format(
              "MMMM Do YYYY, h:mm:ss a"
            )}
          </div>
        </div>
      </div>
      <hr />
      <BsEnvelope style={{ marginTop: 10, fontSize: "1.5em", opacity: 0.6 }} />
      <div className={style.message}>{notification?.description}</div>
      <div style={{ marginTop: 30 }}>
        <strong>
          <BsPeople style={{ fontSize: "1.5em", opacity: 0.6 }} />
        </strong>
        <ul>
          {notification.recipient_names?.map((name) => (
            <li>{initCaps(name)}</li>
          ))}
        </ul>
      </div>
    </>
  );
}

export default MessageReader;
