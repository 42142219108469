import { useMemo, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Button from "../../../components/Button";
import SwitchUploads from "../../../components/SwitchUploads";
import classes from "./TutorUploadsLayput.module.css";
import { uploadData } from "./constant";

function TutorUploadsLayput() {
  const history = useNavigate();
  const location = useLocation();
  const [isCourse, setIsCourse] = useState(true);

  const newUploadPath = useMemo(
    () =>
      /\/library\/courses/.test(location.pathname)
        ? "/library/manage-course"
        : "/library/create/question/categories",
    [location?.pathname]
  );

  return (
    <div className={classes.pageBody}>
      <div className={classes.pageTop}>
        <div className={classes.pageHeader}>
          <SwitchUploads
            buttonData={uploadData}
            setIsCourse={setIsCourse}
            isCourse={isCourse}
          />
        </div>
        <Button
          onClick={() => history(newUploadPath)}
          className={classes.uploadCourseBtn}
        >
          New Upload
        </Button>
      </div>
      <div className={classes.mainBody}>
        <Outlet />
      </div>
    </div>
  );
}

export default TutorUploadsLayput;
