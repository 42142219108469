/* eslint-disable */
import CourseList from "../pages/library/CourseList";
import MyShelf from "../pages/library/MyShelf";
import LibraryHome from "../pages/library/LibraryHome";
import CourseDetails from "../pages/library/CourseDetails";
import Notes from "../pages/library/Notes";
import ReadNote from "../pages/library/ReadNote";
import Bookmark from "../pages/library/Bookmark";
import TutorUploadsLayput from "../pages/library/TutorUploadsLayput";
import AudioBook from "../pages/library/AudioCourse";
import ManageQuestion from "../pages/library/ManageQuestion";
import BookCatalog from "../pages/library/BookCatalog";
import TutorUploadedCourses from "../pages/library/TutorUploadedCourses";
import TutorUploadedQuestions from "../pages/library/TutorUploadedQuestions";
import VideoCourse from "../pages/library/VideoCourse";
import CreateNote from "../pages/library/CreateNote";
import CourseUploadSetup from "../pages/library/CourseUploadSetup";
import CourseIntendedLearners from "../pages/library/CourseIntendedLearners";
import ManageCourseEBook from "../pages/library/ManageCourseEBook";
import ManageQuestionCategory from "../pages/library/ManageQuestionCategory/ManageQuestionCategory";
import { NoteContextProvider } from "../pages/library/Notes/noteContext";

export const libraryoutRoutes = [
  {
    path: "",
    element: <LibraryHome />,
  },

  {
    path: "catalogue",
    element: <BookCatalog />,
  },
  {
    path: "catalog/:subcategoryId",
    element: <CourseList />,
  },

  {
    path: "course/:courseId",
    element: (
      <NoteContextProvider>
        <CourseDetails />
      </NoteContextProvider>
    ),
  },
  { path: "video/:courseId", element: <VideoCourse /> },

  {
    path: "myshelf",
    element: <MyShelf />,
  },
  {
    path: "notes",
    element: <Notes />,
  },
  {
    path: "note/edit/:noteId",
    element: <ReadNote />,
  },
  {
    path: "note/create",
    element: <CreateNote />,
  },
  {
    path: "bookmark",
    element: <Bookmark />,
  },
  {
    path: "",
    element: <TutorUploadsLayput />,
    children: [
      {
        path: "courses",
        element: <TutorUploadedCourses />,
      },

      {
        path: "pratice-test",
        element: <TutorUploadedQuestions />,
      },
    ],
  },

  {
    path: "create/question/categories",
    element: <ManageQuestionCategory />,
  },

  {
    path: "create/questions/:subcategoryId",
    element: <ManageQuestion />,
  },
  { path: "audio/:courseId", element: <AudioBook /> },
  {
    path: "manage-course",
    element: <CourseIntendedLearners />,
  },
  {
    path: "manage-course/:subcategoryId",
    element: <CourseUploadSetup />,
  },
  {
    path: "manage-course/edit/:courseId",
    element: <CourseUploadSetup isEdit />,
  },
  {
    path: "manage-course/e-book/:courseId",
    element: <ManageCourseEBook />,
  },
];
