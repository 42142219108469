import { Link } from "react-router-dom";
import Notification from "../../components/Notification";
import ProfileCard from "../../components/ProfileCard/ProfileCard";
import style from "./TopDashboard.module.css";


const TopDashboard = () => {

  return (
    <div className={style.topbarField}>
      <div className={style.leftTopbar}>
        <Link to="/dashboard">
          <h2 className={style.topbarContent}>ctrLearn</h2>
        </Link>
      </div>

      <div className={style.rightTop}>
        <div>
          <Notification />
        </div>

        <div>
          <ProfileCard />
        </div>
      </div>
    </div>
  );
};

export default TopDashboard;
