import ProductFeatureCard from "../../components/ProductFeatureCard";
import classes from "./ProductFeatures.module.css";
import { phoneImg } from "../../assets/image";

const ProductFeatures = ({ forwardedRef }) => {
  return (
    <div ref={forwardedRef} className={classes.compRoot}>
      <div className={classes.leftWrapper}>
        <img className={classes.bannerImage} src={phoneImg} alt="" />
      </div>
      <div className={classes.rightWrapper}>
        <h4 className={classes.titleSmallText}>MEET WITH OUR</h4>
        <h2 className={classes.titleBigText}>Splendid Features</h2>
        <ProductFeatureCard
          title="Interactive & Guided Learning"
          body="Our library's materials facilitate active student engagement through discussions and
            group activities. We provide structured guidance to enhance critical thinking and learning outcomes."
        />
        <ProductFeatureCard
          title="CBT Assessment & Progress Tracking"
          body="Students can better understand their cognitive and behavioural patterns,
            gain self-awareness, coping mechanisms, and enhance their understanding of the study material."
        />
        <ProductFeatureCard
          title="Exam Prep with Flip Cards"
          body="Supports active learning and self-evaluation, boost comprehension, enable on-the-go learning, and
            assisting students in identifying their strengths and shortcomings for focused progress."
        />
        <ProductFeatureCard
          title="Staff Refresher Training and lots more"
          body="Staff are equips with the most recent information and skills, boosts productivity and job performance, increases job satisfaction and motivation,
            and fosters an organisational culture of ongoing learning and development."
        />
      </div>
    </div>
  );
};

export default ProductFeatures;
