/* eslint-disable */
import React, { useEffect } from "react";
import "./App.css";
import { RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-circular-progressbar/dist/styles.css";
import "react-calendar/dist/Calendar.css";
import "react-quill/dist/quill.snow.css";
import "react-circular-progressbar/dist/styles.css";
import "aos/dist/aos.css";
import { router } from "./routes/routes";
import Popup from "./components/Popup";
import PageLoader from "./components/PageLoader/";
import ConfirmationPopup from "./components/ConfirmationPopup";
import ProgressLoader from "./components/ProgressLoader";
import AOS from "aos";

function App() {
  AOS.init();

  useEffect(() => {
    if (window.MathJax) {
      window.MathJax.typeset();
    }
  }, []);

  useEffect(() => {
    AOS.init({
      // Global settings:
      disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
      startEvent: "DOMContentLoaded", // name of the event dispatched on the document, that AOS should initialize on
      initClassName: "aos-init", // class applied after initialization
      animatedClassName: "aos-animate", // class applied on animation
      useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
      throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)

      // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      offset: 100, // offset (in px) from the original trigger point
      delay: 0, // values from 0 to 3000, with step 50ms
      duration: 900, // values from 0 to 3000, with step 50ms
      easing: "ease-in-out", // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: true, // whether elements should animate out while scrolling past them
      anchorPlacement: "top-bottom", // defines which position of the element regarding to window should trigger the animation
    });
  });

  return (
    <div className="App">
      <Popup />
      <PageLoader />
      <ConfirmationPopup />
      <ProgressLoader />
      <ToastContainer />
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
