import React from "react";
import classes from "./BookCard2.module.css";
import Button from "../Button/Button";
import { BsHeadphones } from "react-icons/bs";
import { MdOutlineVideocam } from "react-icons/md";
import { HiOutlineBookOpen } from "react-icons/hi";
import Img from "../Img";
import CashViewer from "../CashViewer";
import StarRating from "../StarRating";
const BookCard2 = ({
  img,
  title,
  publisher,
  price,
  rating,
  handleClick,
  type = "book",
  subject,
  status,
  hasWidth,
  audio,
  video,
  handleReturnBook,
  isReturning,
  onClickBookMedia,
  onClickAudioMedia,
  onClickVideoMedia,
  borrowDate,
  dueDate,
  ebook,
}) => {
  return (
    <div
      onClick={handleClick}
      className={type === "shelf" ? classes.shelfCard : classes.imageCard}
      style={{ boxShadow: "0 0 2px 2px #ddd" }}
    >
      <div className={classes.bookContainer}>
        <div className={classes.bookWrapper}>
          <Img className={classes.bookImg} src={img} alt="book" />
        </div>
        <div className={classes.bookContent}>
          <p className={classes.title}>{title}</p>
          <p className={classes.subject}>{subject}</p>
          <p className={classes.author}>{publisher}</p>
          <div className={classes.rateContent}>
            <StarRating rating={rating} />
          </div>
          {price > 0 && (
            <p className={classes.price}>
              {price ? <CashViewer amount={price} /> : <span>Free copy</span>}
            </p>
          )}
        </div>
      </div>
      {type === "shelf" && (
        <div className={classes.rightContainer}>
          {status === "borrowed" ? (
            <div>
              <p className={classes.bookTask}>Borrowed on</p>
              <p className={classes.bookTime}>{borrowDate}</p>
              <p className={classes.bookTask}>Submission Due</p>
              <p className={classes.bookTime}>{dueDate}</p>
            </div>
          ) : (
            <div>
              <p className={classes.bookTask}>Paid on</p>
              <p className={classes.bookTime}>{borrowDate}</p>
              <p className={classes.bookTask}>Amount</p>
              <p className={classes.bookTime}>
                <CashViewer amount={price} />
              </p>
            </div>
          )}
          <div onClick={(e) => e.stopPropagation()}>
            {status === "borrowed" ? (
              <Button
                isLoading={isReturning}
                onClick={() => {
                  handleReturnBook?.();
                }}
                children="Return"
                className={classes.returnBtn}
              />
            ) : (
              <Button children="Personal Copy" className={classes.ebookBtn} />
            )}

            <div className={classes.returnWrapper}>
              {ebook && (
                <Button
                  onClick={onClickBookMedia}
                  children={<HiOutlineBookOpen className={classes.mediaIcon} />}
                  className={`${classes.mediaTypeBtn} ${
                    audio || video ? classes.readBtn : classes.audioBtn
                  }`}
                />
              )}

              {video && (
                <Button
                  onClick={onClickVideoMedia}
                  children={<MdOutlineVideocam className={classes.mediaIcon} />}
                  className={`${classes.mediaTypeBtn} ${
                    audio && video ? classes.video : classes.videoBtn
                  }`}
                />
              )}

              {audio && (
                <Button
                  onClick={onClickAudioMedia}
                  className={`${classes.mediaTypeBtn} ${classes.headsetBtn}`}
                  children={<BsHeadphones className={classes.mediaIcon} />}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BookCard2;
