import React, { useContext } from "react";
import ExamPrepStorage from "../../storage/ExamPrepStorage";
import { AiOutlineCloseCircle } from "react-icons/ai";
import style from "./PerformanceTrackerByTopics.module.css";
import BookCard2 from "../../../../components/BookCard2/BookCard2";
import { ColumnLayout } from "../../../Dashboard/layout/PageLayout";

export const PerformanceTrackerByTopics = ({ toggleModal }) => {
  const { recommendations } = useContext(ExamPrepStorage);
  return (
    <>
      <div className={style.close}>
        <button onClick={() => toggleModal()}>
          <AiOutlineCloseCircle />
        </button>
      </div>
      {
        <div className={style.content}>
          <div className={style.left}>
            <h4>Performance By Topics</h4>
            <hr />
            <div className={style.topicsContainer}>
              <div className={style.topicsInnerContainer}>
                <ul className={style.topics}>
                  {[
                    {
                      topic: "Addition",
                      correctAnswers: 3,
                      totalQuestions: 10,
                    },
                    {
                      topic: "Chemistry",
                      correctAnswers: 7,
                      totalQuestions: 10,
                    },
                  ].map((item, index) => (
                    <li key={index}>
                      {item.topic}
                      <span>
                        {item.correctAnswers}/{item.totalQuestions}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className={style.right}>
            <p>Recommendations</p>
            <div className={style.rightScroll}>
              <ColumnLayout>
                {recommendations && recommendations.map((item, index) => (
                  <BookCard2 key={index} data={item}/>
                ))}
              </ColumnLayout>
            </div>
          </div>
        </div>
      }
    </>
  );
};
