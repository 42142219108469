import EmailConfirmationForm from "../../layouts/EmailConfirmationForm";
import classes from "./EmailConfirmation.module.css";

function EmailConfirmation() {
  return (
    <div className={classes.pageRoot}>
      <EmailConfirmationForm />
    </div>
  );
}

export default EmailConfirmation;
