import { GrFormAdd } from "react-icons/gr";
import EBookManagePage from "../../../components/EBookManagePage";
import ManageCourseWrapper from "../../../layouts/ManageCourseWrapper";
import classes from "./ManageCourseEBook.module.css";
import useManageCourseEBook from "./hooks/useManageCourseEBook";
import Button from "../../../components/Button/Button";
import { templates } from "./constants";

function ManageCourseEBook() {
  const { state, dispatch, handleNewPage, handleContentUpload } =
    useManageCourseEBook();

  const { pages, isUploading, templateName } = state;
  return (
    <ManageCourseWrapper
      rightContent={
        <Button
          isLoading={isUploading}
          onClick={handleContentUpload}
          className={classes.publishButton}
        >
          Publish
        </Button>
      }
      title="Create E-Book Content"
    >
      <div className={classes.pageRootWrap}>
        <div style={{ height: 400, display: "flex", flexDirection: "column" }}>
          <div className={classes.sectionTitle}>Select Course Template </div>
          <div className={classes.templateBoxRow}>
            {templates.map((template) => (
              <div
                onClick={() => dispatch({ templateName: template?.name })}
                className={`${classes.templateWrap} ${
                  template?.name === templateName
                    ? classes.isSelectedTemplate
                    : undefined
                }`}
              >
                <div className={classes.templateImage}>{/* image */}</div>
                <div className={classes.templateName}>{template?.name}</div>
              </div>
            ))}
          </div>
        </div>
        <div>
          <div style={{ display: "flex", flexDirection: "column", gap: 25 }}>
            {Object.keys(pages || {}).map((page, index) => (
              <EBookManagePage page={page} state={state} dispatch={dispatch} />
            ))}
          </div>
          <div className={classes.addCardBtn}>
            <div onClick={handleNewPage} className={classes.addCardItem}>
              <GrFormAdd />
              <span>Add Card</span>
            </div>
          </div>
        </div>
      </div>
    </ManageCourseWrapper>
  );
}

export default ManageCourseEBook;
