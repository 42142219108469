import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { timeout } from "../../../utils/constants";

function useDashboardLayout() {
  const history = useNavigate();
  const [showChildren, setShowChildren] = useState(false);
  const location = useLocation();
  const [timeOutHandler, setTimeoutHandler] = useState(null);

  const initSessionTimeout = () => {
    clearTimeout(timeOutHandler);

    const handler = setTimeout(() => {
      history("/login", {
        state: {
          reason: "unauthorized",
          from: location.pathname,
        },
      });
    }, timeout);

    setTimeoutHandler(handler);
  };

  const clearSessionTimeout = () => initSessionTimeout();

  return {
    showChildren,
    history,
    clearSessionTimeout,
    setShowChildren,
  };
}

export default useDashboardLayout;
