import React from "react";
import classes from "./RequestHelp.module.css";
import InputField from "../../../components/InputField/InputField";
import Button from "../../../components/Button/Button";
import TextArea from "../../../components/TextArea";
import useRequestHelp from "./hooks/useRequestHelp";

function RequestHelp({ courseDetails }) {
  const { state, user, dispatch } = useRequestHelp(courseDetails);

  const { courseTitle, helpText } = state;
  return (
    <div className={classes.requestSection}>
      <div>
        <h2 className={classes.help}>
          Help <span>centre</span>{" "}
        </h2>
        <p className={classes.aboutBook}>
          Everything you need to know about the book
        </p>
      </div>
      <div className={classes.inputRow}>
        <div className={classes.inputColumn}>
          <InputField disabled value={user?.firstName} />
        </div>
        <div className={classes.inputColumn}>
          <InputField disabled value={user?.lastName} />
        </div>
      </div>
      <div className={classes.inputRow}>
        <InputField disabled value={user?.email} />
      </div>
      <div className={classes.inputRow}>
        <InputField
          className={classes.titleName}
          disabled
          value={courseTitle}
        />
      </div>

      <div className={classes.dataField}>
        <div className={classes.inputFieldWrapper}>
          <TextArea
            rows="4"
            cols="69"
            value={helpText}
            onChange={({ target }) => dispatch({ helpText: target?.value })}
            placeholder=" Ask Questions..."
            className={classes.askQuestion}
          />
        </div>
        <div className={classes.submitWrapper}>
          <Button children="Submit" className={classes.submitBtn} />
        </div>
      </div>
    </div>
  );
}

export default RequestHelp;
