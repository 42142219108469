import { useReducer } from "react";
import { QUERY_KEY } from "../../../../utils/constants";
import questionService from "../../../../service/questions.service";
import useAppMutation from "../../../../utils/hooks/useAppMutation";
import { useNavigate, useParams } from "react-router-dom";
import useAppQuery from "../../../../utils/hooks/useAppQuery";
import libraryService from "../../../../service/library.service";
import { toast } from "react-toastify";

function useManageQuestion() {
  const { subcategoryId } = useParams();
  const navigate = useNavigate();
  const initialState = {
    questionData: {
      options: [{ id: 0, option: "", text: "", image: "" }],
      question: { text: "", image: "" },
      correctAnswer: [],
      answerType: "singleAnswer",
    },
    is_Pratice: false,
    templateName: "elipse",
    selectedSubject: {},
    selectedTopic: {},
    selectedLevel: "Easy",
    selectQuestionType:""
  };
  const [state, dispatch] = useReducer(
    (stateValue, value) => ({ ...stateValue, ...value }),
    initialState
  );
  const {
    questionData,
    selectedSubject,
    templateName,
    selectedTopic,
    selectedLevel,
    isPraticeQuestion,
  } = state;

  const {
    data: { subcategory: { subject = [] } = {} } = {},
    isLoading: fetchSubject,
  } = useAppQuery(
    [QUERY_KEY.getAllSubjectsBySubId],
    libraryService.getAllSubjectsBySubId,
    { subcategoryId: subcategoryId }
  );

  const { data: { data: topics = [] } = {}, isLoading: fetchingTopics } =
    useAppQuery(
      [QUERY_KEY.getTopicBySubjectId],
      libraryService.getTopicBySubjectId,
      { subjectId: selectedSubject?._id?.$oid },
      { enabled: !!selectedSubject?._id?.$oid }
    );

  const { isLoading: uploadingData, mutateAsync: asycUploadQuestion } =
    useAppMutation(QUERY_KEY.uploadQuestion, questionService.uploadQuestion);

  function onSave() {
    asycUploadQuestion({
      is_practice: isPraticeQuestion,
      level: selectedLevel,
      subcategory_id: subcategoryId,
      subject: selectedSubject?.name,
      template_data: questionData,
      template_name: templateName,
      topic: selectedTopic?.name,
      type: "MCQ",
    })
      .then(() => {
        toast.success("Uploaded Successfully");
        navigate("/library/pratice-test");
      })
      .catch(() => {
        toast.error("Error occurred while uploading question");
      });
  }

  function onChangeQuestion(e) {
    const value = e.target.value;
    const question = questionData;
    question.question.text = value;

    dispatch({ questionData: question });
  }
  function onChangeAnswerType(value) {
    const question = questionData;
    question.correctAnswer = [];
    question.answerType = value;
    dispatch({ questionData: question });
  }
  function handlePraticeQuestion({ target }) {
    dispatch({ isPraticeQuestion: target.checked });
  }

  function handleUpdateExerciseAnswerOption(value) {
    const question = questionData;
    question.options = value;
    dispatch({ questionData: question });
  }

  function onSelectCorrectAnswer(e, value) {
    const { name, checked } = e?.target;
    const correctAnswer = questionData?.correctAnswer;
    const doesAnswerExist = correctAnswer?.filter(({ id }) => value?.id !== id);
    switch (name) {
      case "checkbox":
        if (checked) {
          dispatch({
            questionData: {
              ...questionData,
              correctAnswer: [...doesAnswerExist, value],
            },
          });
        } else {
          dispatch({
            questionData: {
              ...questionData,
              correctAnswer: doesAnswerExist,
            },
          });
        }
        return;
      case "radio":
        dispatch({ questionData: { ...questionData, correctAnswer: [value] } });
        return;
      default:
    }
  }

  return {
    state,
    subject,
    topics,
    fetchingTopics,
    fetchSubject,
    uploadingData,
    dispatch,
    onChangeQuestion,
    onChangeAnswerType,
    onSelectCorrectAnswer,
    handleUpdateExerciseAnswerOption,
    handlePraticeQuestion,
    onSave,
  };
}

export default useManageQuestion;
