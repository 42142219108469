import { request } from "./api";
import env from "../env";

const API = env.API_URL;

const URL = {
  register: `${API}/auth/register`,
  verifyEmail: `${API}/`,
  resetPassword: (email) =>
    `${API}/auth/send_reset_password_link?email=${email}`,
  login: `${API}/auth/login`,
  logout: `${API}/auth/logout`,
  getCurrentUser: `${API}/auth/user`,
  newPassword: (token) => `${API}/auth/reset_password?reset_token=${token}`,
};

function register(params) {
  return request(URL.register, params, "POST", {});
}
function verifyEmail(params) {
  return request(URL.verifyEmail, params, "POST", {});
}
function login(params) {
  return request(URL.login, params, "POST", {});
}
function getCurrentUser(params) {
  return request(URL.getCurrentUser, params, "GET", {});
}
function logout(params) {
  return request(URL.logout, params, "POST", {});
}
function resetPassword({ email }) {
  return request(URL.resetPassword(email), {}, "GET", {});
}
function newPassword({ email, new_password, reset_token }) {
  return request(
    URL.newPassword(reset_token),
    { confirm_password: new_password, password: new_password },
    "PUT",
    {}
  );
}
const authApplication = {
  register,
  verifyEmail,
  login,
  logout,
  getCurrentUser,
  resetPassword,
  newPassword,
  categories: `${API}/categories`,
  logout_url: `${API}/auth/logout`,
  users: `${API}/auth/users`,
  contactUs: `${API}/enquiry`,
};

export default authApplication;
