import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tabclicked: "bucket",
};

export const examprepSlice = createSlice({
  name: "examprep",
  initialState,
  reducers: {
    setTabClicked: (state, action) => {
      state.tabclicked = action.payload;
    },
  },
});

export const { setTabClicked } = examprepSlice.actions;

export default examprepSlice.reducer;
