import React, { useContext } from "react";
import style from "./Controller.module.css";
import ExamPrepStorage from "../storage/ExamPrepStorage";
import { AssessmentDetails } from "./AssessmentDetails/AssessmentDetails";
import { Assessment } from "./Assessment/Assessment";
import { AssessmentResult } from "./AssessmentResult/AssessmentResult";
import { PerformanceByTopics } from "./PerformanceByTopics/PerformanceByTopics";

export const Controller = () => {
  const { modalPage } = useContext(ExamPrepStorage);

  const UI = {
    AssessmentDetails: <AssessmentDetails />,
    Assessment: <Assessment />,
    AssessmentResult: <AssessmentResult />,
    PerformanceByTopics: <PerformanceByTopics />,
  };
  return (
    <div className={style.rootPage}>
      <div className={style.modal}>{UI[modalPage]}</div>
    </div>
  );
};
