import libraryService from "../../../../service/library.service";
import useCdReactQuery from "../../../../utils/hooks/useCdReactQuery";


const useAdminSubject = () => {
    const { queryEndpoint } = useCdReactQuery();

    const deleteSubject = async (id) => {
        const resp = await queryEndpoint({
            endpointUrl : libraryService.subjectById(id),
            httpMethod : "DELETE",
        });

        return await resp
        
    }

    const createSubject = async (item) => {
        const resp = await queryEndpoint({
            endpointUrl : libraryService.subjects,
            httpMethod : "POST",
            body : JSON.stringify(item),
        });

        return await resp
    }

    const editSubject = async (item) => {
        const resp = await queryEndpoint({
            endpointUrl : libraryService.subjects,
            httpMethod : "PUT",
            body : JSON.stringify(item),
        });

        return await resp
    }

    const getSubjectById = async (id) => {
        const resp = await queryEndpoint({
            endpointUrl : libraryService.subjectById(id),
        });

        return await resp
    }

    return {
        createSubject,
        deleteSubject,
        editSubject,
        getSubjectById,

    }

}

export default useAdminSubject;