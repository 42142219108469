// Module
import { useEffect, useState } from "react";
import _ from "lodash";

// Icons
import { CameraIcon } from "../../assets/icons";

// Assets
import { defaultImage } from "../../assets/image";

// Style
import style from "./Page.module.css";
import { Loader } from "../../components/Modal";
import { toast } from "react-toastify";
import QuillTextArea from "../../v2/common/components/quill_text_area/QuillTextArea.tsx";

const DefaultForm = ({ data, submitFn, updateImage }) => {
  const [formData, setFormData] = useState(data);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    submitFn(formData);
    // eslint-disable-next-line
  }, [formData.image]);

  const updateImageHandler = async (event) => {
    setIsLoading((prev) => !prev);
    const imagePath = await updateImage(
      event,
      formData.book_id,
      formData.pageId,
      formData.image
    );

    setFormData({
      ...formData,
      image: imagePath,
    });
    setIsLoading((prev) => !prev);
  };

  // const debounceFn = _.debounce((e) => {
  //   const { name, value } = e.target;
  //   setFormData({ ...formData, [name]: value });
  // }, 500);

  const debounceFn = _.debounce((name, value) => {
    // const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  }, 500);

  const quillTextHandler = (name, value) => debounceFn(name, value);

  const inputChangeHandler = (e) => {
    const { name, value } = e.target;
    debounceFn(name, value);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    await submitFn(formData);
    toast.success("Page saved");
  };

  return (
    <>
      <form onSubmit={submitHandler}>
        <div className={style.lg}>
          <div className={style.lbl}>Title / Heading</div>
          <input
            type="text"
            className={style.titleInput}
            name="title"
            onChange={inputChangeHandler}
            defaultValue={formData.title}
          />
          <div className={style.lbl}>Paragraph 1</div>
          <QuillTextArea
            initialValue={formData.paragraph1}
            onChange={(value) => quillTextHandler("paragraph1", value)}
          />
          {/* <textarea
            className={style.paragraphTextAare}
            name="paragraph1"
            onChange={inputChangeHandler}
            defaultValue={formData.paragraph1}
          ></textarea> */}
          <div className={style.image}>
            <img
              src={formData?.image || defaultImage}
              alt=""
              className={style.img}
            />

            {/* Select image icon */}
            <div className={style.imageUpload}>
              <label>
                <CameraIcon style={{ marginTop: "-8px" }} />
                <input
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={updateImageHandler}
                  name="file"
                />
              </label>
            </div>
          </div>
          <div className={style.lbl}>Paragraph 2</div>
          {/* <textarea
            className={style.paragraphTextAare}
            name="paragraph2"
            onChange={inputChangeHandler}
            defaultValue={formData.paragraph2}
          ></textarea> */}
          <QuillTextArea
            initialValue={formData.paragraph2}
            onChange={(value) => quillTextHandler("paragraph2", value)}
          />
          {!isLoading && (
            <div className={style.button}>
              <button className={style.btn} type="submit">
                SAVE PAGE
              </button>
            </div>
          )}
        </div>
      </form>
      {isLoading && <Loader />}
    </>
  );
};

export default DefaultForm;
