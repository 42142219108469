import { MDBBadge } from "mdb-react-ui-kit";
import { HiOutlineEnvelope } from "react-icons/hi2";
import moment from "moment";
import classes from "./Notification.module.css";
// import { defaultProfileImg } from "../../assets/image";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
// import { BsEnvelope } from "react-icons/bs";
import useNotification from "./hooks/useNotification";
import SkeletonLoader from "../SkeletonLoader";
import { DotIcon } from "../../assets/icons";

function Notification() {
  const [isOpen, setIsOpen] = useState(false);
  const history = useNavigate();

  const { notifications, fetchingNotification, getNotifications, unReadNotifications } = useNotification();

  const ref = useRef();

  useEffect(() => {

  }, [notifications])

  useEffect(() => {
    /**
     * close when clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  // const unReadNotifications = notifications.reduce(
  //   (acc, notification) => acc + !notification?.is_read,
  //   0
  // );

  return (
    <div ref={ref} className={classes.compRoot}>
      <div
        className="position-relative"
        style={{ cursor: "pointer" }}
        onClick={() => setIsOpen(!isOpen)}
      >
        <HiOutlineEnvelope className={`${classes.bellIcon}`} />
        <MDBBadge
          className={`${classes.itemBagde} position-absolute translate-middle`}
          notification
          pill
        >
          {unReadNotifications}
        </MDBBadge>
      </div>
      <div className={classes.dropDownWrapper}>
        <div
          className={`${classes.dropDownCard} ${
            isOpen ? classes.onOpen : undefined
          }`}
        >
          <div>
            <h4 className={classes.sectionTitle}>Notifications</h4>
            {/* <button>
              <IoCheckmarkDone />
            </button> */}
          </div>

          <div style={{ marginTop: 20 }}>
            {fetchingNotification ? (
              <div>
                {[...new Array(3).values()].map((_, index) => (
                  <SkeletonLoader
                    className={classes.notificationLoader}
                    key={index}
                  />
                ))}
              </div>
            ) : (
              <div>
                {!notifications?.length ? (
                  <div className={classes.emptyNotification}>
                    <h3>No New Notification.</h3>
                  </div>
                ) : (
                  (notifications || [])
                    .slice(0, 3)
                    .map((notification, index) => (
                      <div key={index}>
                        <div
                          onClick={() => {
                            setIsOpen(false);
                            getNotifications(notification?._id)
                            history(`/notification/${notification?._id}`);
                          }}
                          className={classes.notificationRow}
                        >
                          <div style={{ display: "flex", gap: "8px" }}>
                            <div>
                              {/* {!notification?.is_read && (
                                <BsEnvelope
                                  className={classes.newAlertNotice}
                                />
                              )} */}
                              <DotIcon
                                size={50}
                                className={
                                  notification?.is_read
                                    ? classes.read
                                    : classes.unread
                                }
                              />
                            </div>
                            <div>
                              <div className={classes.description}>
                                {notification?.description || "Ohhh"}
                              </div>
                              <div className={classes.senderInfo}>
                                <div>{notification?.author}</div>
                                <div>
                                  {moment(notification?.date).format(
                                    "MMMM Do YYYY, h:mm:ss a"
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <div>
                            <img
                              className={classes.profileImg}
                              src={defaultProfileImg}
                              alt=""
                            />
                          </div> */}
                        </div>
                        <div className={classes.lineDivider} />
                      </div>
                    ))
                )}
              </div>
            )}
          </div>

          {notifications.length > 3 && (
            <div>
              <button
                onClick={() => {
                  history("/notification");
                  setIsOpen(false);
                }}
                className={classes.viewAllNotificationBtn}
              >
                View all Notifications
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Notification;
