import { useNavigate } from "react-router-dom";
import authApplication from "../../service/auth";
import useCdReactQuery from "./useCdReactQuery";

function useLogout() {
  const history = useNavigate();

  const { queryEndpoint } = useCdReactQuery();

  return () => {
    history("/login");
    localStorage.removeItem("accessEmail");
    localStorage.removeItem("accessToken");

    queryEndpoint({
      endpointUrl: authApplication.logout_url,
      httpMethod: "POST",
    });
  };
}

export default useLogout;
