import { useReducer } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { setIsloading } from "../../../redux/feature/progressLoaderSlice";
import authApplication from "../../../service/auth";
import { QUERY_KEY } from "../../../utils/constants";
import useAppMutation from "../../../utils/hooks/useAppMutation";

function useNewPassword() {
  const location = useLocation();
  const history = useNavigate();
  const reduxDispatch = useDispatch();
  const query = new URLSearchParams(location.search);
  const resetToken = query.get("reset_token");
  const email = query.get("email");

  const initialState = {
    password: "",
    cPassword: "",
    token: location?.state?.token || "",
  };

  const [state, dispatch] = useReducer(
    (stateValue, value) => ({ ...stateValue, ...value }),
    initialState
  );

  const { password, cPassword } = state;

  function handlePasswordResend() {
    reduxDispatch(setIsloading(true));
    authApplication
      .resetPassword({ email })
      .then(() => {
        toast.success("token has been sent to your email address");
      })
      .catch(({ msg }) => {
        toast.error(msg);
      })
      .finally(() => {
        reduxDispatch(setIsloading(false));
      });
  }

  const { isLoading, mutateAsync: asycResetPassword } = useAppMutation(
    QUERY_KEY.newPassword,
    authApplication.newPassword
  );

  function resetPassword() {
    if (!resetToken) {
      toast.error("Token is required");
      return;
    }
    if (password?.length === 0) {
      toast.error("Password is required");
      return;
    }
    if (password?.length < 8) {
      toast.error("Password is too short");
      return;
    }
    if (password !== cPassword) {
      toast.error("Password does not match");
      return;
    }
    asycResetPassword({
      email: `${email}`,
      new_password: password,
      reset_token: resetToken,
    })
      .then(() => {
        toast.success("Password reset was success");
        history("/login");
      })
      .catch(({ msg }) => {
        toast.error("Error occurred");
      });
  }

  return {
    state,
    email,
    isLoading,
    resetToken,
    dispatch,
    resetPassword,
    handlePasswordResend,
  };
}

export default useNewPassword;
