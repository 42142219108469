import { setPopupChild, showPopup } from "../../../redux/feature/popupSlice";
import EditBio from "../../../layouts/EditBio";
import EditLocation from "../../../layouts/EditLocation";
import { useDispatch, useSelector } from "react-redux";
import { showConfirmationPopup } from "../../../redux/feature/confrimationPopupSlice";
import { setIsloading } from "../../../redux/feature/progressLoaderSlice";
import { toast } from "react-toastify";
import useAppMutation from "../../../utils/hooks/useAppMutation";
import { QUERY_KEY } from "../../../utils/constants";
import userProfile from "../../../service/userProfile.service";
import CountryCode from "../../../components/CountryStateData/CountryCode.json";
import useLogout from "../../../utils/hooks/useLogout";

function useProfilePage() {
  const reduxDispatch = useDispatch();
  const logOut = useLogout();
  const { user } = useSelector(({ currentUser }) => currentUser);
  function handleEditBio() {
    reduxDispatch(
      setPopupChild(
        <EditBio
          onSuccess={() => {
            reduxDispatch(
              showPopup({
                open: false,
              })
            );
          }}
        />
      )
    );
    reduxDispatch(
      showPopup({
        open: true,
      })
    );
  }
  function handleEditLocation() {
    reduxDispatch(
      setPopupChild(
        <EditLocation
          onSuccess={() => {
            reduxDispatch(
              showPopup({
                open: false,
              })
            );
          }}
        />
      )
    );
    reduxDispatch(
      showPopup({
        open: true,
      })
    );
  }
  const { mutateAsync: asycDeleteAccount } = useAppMutation(
    QUERY_KEY.deleteAccount,
    userProfile.deleteAccount
  );

  function handleDeleteAccount() {
    reduxDispatch(
      showConfirmationPopup({
        open: true,
        headerText: "Delete Account",
        bodyText: <p>You are about to permanently delete your account</p>,
        onConfirm: () => {
          reduxDispatch(showConfirmationPopup({ open: false }));
          reduxDispatch(setIsloading(true));
          asycDeleteAccount({ email: user?.email })
            .then(() => {
              toast.success("Account deleted successfully");
              logOut();
            })
            .catch(({ msg }) => {
              toast.error(msg);
            })
            .finally(() => {
              reduxDispatch(setIsloading(false));
            });
        },
        buttonText: "Delete",
      })
    );
  }
  function getCountrySortCode() {
    const sortCode = CountryCode.find(
      (item) => item.code === user.country_code
    );
    if (!sortCode) {
      return "";
    }
    return sortCode.iso;
  }
  // let abbrColors = {
  //   AGDKX: "hsl(330, 68%, 20%)",
  //   BEYIQ: "brown",
  //   RMNOP: "	hsl(195, 100%, 27%)	",
  //   SWZHFJ: "hsl(59, 57%, 31%)",
  //   TVLUWC: "hsl(281, 89%, 39%)",
  // };
  function displayProfileColor() {
    // for (const key in abbrColors) {
    //   if (key.includes(user?.firstName?.charAt(0).toUpperCase())) {
    //     return abbrColors[key];
    //   }
    // }
    return "#203b52";
  }
  return {
    displayProfileColor,
    user,
    handleEditBio,
    handleEditLocation,
    handleDeleteAccount,
    getCountrySortCode,
  };
}

export default useProfilePage;
