import { DUMMY_DATA } from "./DUMMY_DATA";
import style from "./Page.module.css";
import { useEffect, useRef } from "react";

const DefaultView = ({ data, viewType }) => {
  // if data is empty (i.e no value was given), use dummy data for preview.
  const { title, paragraph1, paragraph2, image } =
    data?.title || data?.paragraph1 || data?.paragraph2 || data.image
      ? data
      : DUMMY_DATA;
  const classes = viewType === "thumbnail" ? "xs" : "lg";

  // Ensure the page scroll to the beginning when it is loaded
  const topElementRef = useRef(null);
  useEffect(() => {
    // Check if the ref is attached and scroll it into view
    if (topElementRef.current) {
      topElementRef.current.scrollIntoView();
    }
  }, [data]);

  return (
    // best not to change the first div naming convention because it helps with thumnail and full page rendering.
    // The css can be changed to meet each page needs
    <div className={`${style[classes]}`} ref={topElementRef}>
      <h5 className={style.title}>{title}</h5>
      {/* <div className={style.pre}>{paragraph1}</div> */}
      <div dangerouslySetInnerHTML={{ __html: paragraph1 }} />
      <img src={image} alt="" className={style.img} />
      {/* <p className={style.pre}>{paragraph2}</p> */}
      <div dangerouslySetInnerHTML={{ __html: paragraph2 }} />
    </div>
  );
};

export default DefaultView;
