import ContactRow from "../../../components/ContactRow";
import classes from "./ContactInfo.module.css";
import InputField from "../../../components/InputField";
import TextArea from "../../../components/TextArea";
import Button from "../../../components/Button";
import { BsArrowRight } from "react-icons/bs";

function ContactInfo({ forwardedRef }) {
  return (
    <div ref={forwardedRef} className={classes.compWrapper}>
      <div className={classes.leftWrapper}>
        <h1 className={classes.infoTitle}>Contact Info</h1>
        <div className={classes.contactInfoBody}>
          <div className={classes.lineDivider} />
          <ContactRow title="PHONE" text="+234-803-238-1370" />
          <div className={classes.lineDivider} />
          <ContactRow
            title="ADDRESS"
            text="3 Ola-Olaigbe Street, Meiran, Lagos"
          />
          <div className={classes.lineDivider} />
          <ContactRow title="EMAIL" text="enquiry@ctrlearn.com" />
          <div className={classes.lineDivider} />
        </div>
      </div>
      <div className={classes.rightWrapper}>
        <h3 className={classes.compTitle}>SEND US A MESSAGE</h3>
        <div>
          <div className={classes.formInputRow}>
            <div className={classes.textInputWrapper}>
              <label className={classes.inputLabel}>Name</label>
              <InputField className={classes.inputField} />
            </div>
          </div>
          <div className={`mt-3 ${classes.formInputRow}`}>
            <div className={classes.textInputWrapper}>
              <label className={classes.inputLabel}>Email</label>
              <InputField className={classes.inputField} />
            </div>
          </div>
          <div className={classes.textAreaWrapper}>
            <label className={classes.inputLabel}>Message</label>
            <TextArea />
          </div>
          <Button className={classes.sendMessageBtn}>
            SEND MESSAGE {<BsArrowRight />}
          </Button>
        </div>
      </div>
      <div className={classes.aside}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3962.886026330055!2d3.2699405371936066!3d6.661044674426466!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b974ee5b01793%3A0xc678aaca25e833ae!2s3%20Ola%20Olaigbe%20St%2C%20Alagbado%20102213%2C%20Lagos%2C%20Nigeria!5e0!3m2!1sen!2sie!4v1681027755352!5m2!1sen!2sie"
          width="100%"
          height="100%"
          style={{
            border: "0",
          }}
          // allowfullscreen=""
          loading="lazy"
          title="p"
          // referrerpolicy="no-referrer-when-downgrade"
          className={classes.iframeMap}
        ></iframe>
      </div>
    </div>
  );
}

export default ContactInfo;
