import OtpInput from "react-otp-input";
import classes from "./OtpIputComp.module.css";

function OtpInputComp({ value, onChange, numInputs }) {
  return (
    <OtpInput
      value={value}
      inputStyle={classes.otpInput}
      containerStyle={classes.otpContainner}
      onChange={onChange}
      numInputs={numInputs}
    />
  );
}

export default OtpInputComp;
