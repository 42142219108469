import { createContext, useEffect, useReducer, useRef, useState } from "react";
import useCdReactQuery from "../../../utils/hooks/useCdReactQuery";
import libraryService from "../../../service/library.service";

export const NoteContext = createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_NOTES": {
      return { ...state, notes: action.payload };
    }
    case "ADD_NOTE": {
      return {
        ...state,
        notes: [action.payload, ...state.notes],
      };
    }
    case "UPDATE_NOTE": {
      const updatedState = state.notes.map((note) => {
        if (note._id === action.payload._id) return action.payload;
        return note;
      });
      return {
        ...state,
        notes: updatedState,
      };
    }
    case "DELETE_NOTE": {
      const updatedState = state.notes.filter(
        (note) => note._id !== action.payload
      );
      return {
        ...state,
        notes: updatedState,
      };
    }
    default:
      break;
  }
};

export const NoteContextProvider = ({ children }) => {
  const { data, queryEndpoint, isLoading } = useCdReactQuery(null);
  const [updatedNote, setUpdatedNote] = useState(false);
  const [state, setState] = useReducer(reducer, {
    notes: data || [],
  });

  const status = useRef(0);

  useEffect(() => {
    (async () => {
      await queryEndpoint({
        endpointUrl: libraryService.getNotes,
      });
    })();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (status.current <= 1) {
      status.current = status.current + 1;
      dispatch({ type: "FETCH_NOTES", payload: data || [] });
    }
    // eslint-disable-next-line
  }, [data]);

  // Dispatch acts as a middleware to update the backend database.
  const dispatch = (obj) => {
    switch (obj.type) {
      case "FETCH_NOTES": {
        setState({ type: "FETCH_NOTES", payload: data || [] });
        break;
      }
      case "ADD_NOTE": {
        (async () => {
          await queryEndpoint({
            endpointUrl: libraryService.addNote,
            httpMethod: "POST",
            body: obj.payload,
          });
        })();
        setUpdatedNote(true);
        break;
      }
      case "UPDATE_NOTE": {
        (async () => {
          await queryEndpoint({
            endpointUrl: libraryService.deleteNote,
            httpMethod: "PATCH",
            body: {
              content: obj.payload.content,
              note_style: obj.payload.note_style,
              title: obj.payload.title,
            },
            params: { note_id: obj.payload._id },
          });
        })();
        setUpdatedNote(true);
        break;
      }
      case "DELETE_NOTE": {
        (async () => {
          await queryEndpoint({
            endpointUrl: libraryService.deleteNote,
            httpMethod: "DELETE",
            params: { note_id: obj.payload },
          });
        })();
        setUpdatedNote(true);
        break;
      }
      default:
        break;
    }
    setState(obj);
  };

  return (
    <NoteContext.Provider
      value={{ ...state, dispatch, isLoading, updatedNote }}
    >
      {children}
    </NoteContext.Provider>
  );
};
