import moment from "moment";
import CashViewer from "../CashViewer";
import classes from "./NextPlanPayment.module.css";

function NextPlanPayment({ currentPlan, onManagePlan }) {
  return (
    <div className={classes.cardWrapper}>
      <div className={classes.leftSection}>
        <div className={classes.currentPlanInfor}>
          <div>
            <div className={classes.leftTop}>
              <span className={classes.nextPaymentDate}>Next Invoice</span>
              <span className={classes.invoiceDuration}>
                - {currentPlan?.next_invoice?.plan_name}
              </span>
            </div>
            <div className={classes.nextPaymetText}>
              {currentPlan?.next_invoice?.effective_date
                ? moment(
                    currentPlan?.next_invoice?.effective_date,
                    "DD-MM-YYYY HH:mm:ss"
                  ).format("MMMM Do YYYY")
                : "--/--"}
            </div>
          </div>
        </div>

        <div className={classes.cardBottom}>
          <div>
            <button onClick={onManagePlan} className={classes.cancelBtn}>
              Manage Plan
            </button>
          </div>
        </div>
      </div>
      <div className={classes.rightSection}>
        <div className={classes.invoiceAmount}>
          <CashViewer amount={currentPlan?.next_invoice?.amount} />
        </div>
      </div>
    </div>
  );
}

export default NextPlanPayment;
