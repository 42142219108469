import { useContext, useState } from "react";
import ApplicationFooter from "../../../components/ApplicationFooter/ApplicationFooter";

import classes from "./ExamPrepBucket.module.css";
import { BsFillCaretRightFill } from "react-icons/bs";
import { SiSecurityscorecard } from "react-icons/si";
import { AiOutlineBorderlessTable } from "react-icons/ai";

import ExamPrepStorage from "../storage/ExamPrepStorage";
import { Controller } from "./Controller";
import { RowLayout } from "../../Dashboard/layout/PageLayout";
import useBuckets from "./hooks/useBuckets";
import useSubjects from "./hooks/useSubjects";
import { Loader } from "../../../components/Modal";
import { SearchUI } from "../../../components/searchUI";

export const BucketCard = ({ data }) => {
  const { updateStorage, assessmentModalToggle } = useContext(ExamPrepStorage);
  const {
    submission: { max_score, no_attempts },
    subject,
    difficulty_level,
    title,
  } = data;
  const viewBucketDetails = () => {
    updateStorage({
      ...data,
      assessmentModalToggle: !assessmentModalToggle,
      modalPage: "AssessmentDetails",
    });
  };
  return (
    <div className={classes.card}>
      <div className={classes.subject}>{subject.name} </div>
      <div className={classes.cardTitle}>
        <div className={classes[difficulty_level.toLowerCase()]}>
          {difficulty_level}
        </div>
        <div className={classes.cardRow}>
          <div>
            <label>Bucket</label>
            <h4>{title}</h4>
          </div>
          <div>
            <label>Attempts</label>
            <h4>
              <AiOutlineBorderlessTable /> {no_attempts}
            </h4>
          </div>
          <div>
            <label>Highest Score</label>
            <h4>
              <SiSecurityscorecard /> {max_score}
            </h4>
          </div>
        </div>
      </div>
      <div className={classes.cardFooter}>
        <button onClick={viewBucketDetails}>
          VIEW <BsFillCaretRightFill />
        </button>
      </div>
    </div>
  );
};

function ExamPrepBucket() {
  const { buckets, getBucketByID, isLoading, getBuckets } = useBuckets();
  const { subjects } = useSubjects();
  const [currentSubject, setCurrentSubject] = useState("");
  const { assessmentModalToggle } = useContext(ExamPrepStorage);

  const filterSubject = (value) => {
    const selectedSubject = subjects.find((item) => item.name === value);
    setCurrentSubject(selectedSubject);
    getBucketByID(selectedSubject.id);
  };

  const searchHandler = async (searchItem) => {
    let params = {
      subject_id: currentSubject.id,
    };
    if (searchItem) {
      if (["easy", "medium", "hard"].includes(searchItem.toLowerCase())) {
        params = { ...params, difficulty_level: searchItem };
      } else {
        params = { ...params, title: searchItem };
      }
      getBuckets(params);
    } else {
      getBuckets();
    }
  };

  return (
    <>
      <div className={classes.root}>
        <div className={classes.page}>
          <SearchUI
            navText="Buckets"
            navLink="/examprep"
            filterBySubject={filterSubject}
            searchHandler={searchHandler}
            subjects={[...subjects.map((item) => item.name)]}
            MaxSubjectView={2}
            placeholder="Search Buckets"
            hasClearnBtn={false}
          />

          <div className={classes.bucket_container}>
            {buckets.map((bucket, index) => (
              <BucketCard data={bucket} key={index} />
            ))}
          </div>
        </div>
        <RowLayout>
          <ApplicationFooter />
        </RowLayout>
      </div>
      {assessmentModalToggle && <Controller />}
      {isLoading && <Loader />}
    </>
  );
}

export default ExamPrepBucket;
