import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const initialState = {
  questionPage: {},
  position: 0, // question index position
  totalQuestions: 0,
  hasQuestion: false,
  selectedOptions: {}, //{[pageId]:{},}
  resultModal: false,
  reTakeAssessmentFlag: false,
  pageId: 0,
};

export const bookQuestionsSlice = createSlice({
  name: "book_questions",
  initialState,
  reducers: {
    qInitState: (state, action) => ({ ...state, ...action.payload }),
    qNext: (state, action) => {
      // Prevent user from going to the next question without providing an answer
      if (!!state.selectedOptions[state.pageId]?.[state.position] === false) {
        toast.error("Provide answer for the question");
      } else {
        if (state.position < state.totalQuestions - 1) state.position += 1;
        else if (state.position === state.totalQuestions - 1) {
          state.resultModal = true;
          state.position += 1;
        } else {
          state.hasQuestion = false;
          state.resultModal = false;
          state.position = 0;
        }
      }
    },
    qPrevious: (state, action) => {
      if (action.payload.position > 0) state.position -= 1;
    },
    qStatus: (state, action) => {
      const { hasQuestion, totalQuestions, pageId } = action.payload;
      state.hasQuestion = hasQuestion;
      state.totalQuestions = totalQuestions;
      state.position = 0;
      state.pageId = pageId;
    },
    qUpdateSelectedOptions: (state, action) => {
      const { questionNumber, userAnswer, answer } = action.payload;

      state.selectedOptions[state.pageId] = {
        ...state.selectedOptions[state.pageId],
        [questionNumber]: { userAnswer, answer },
      };
    },
    qResultModal: (state, action) => {
      state.resultModal = action.payload;
    },
    qRest: (state, action) => {
      return initialState;
    },
    qRetakeAssessment: (state, action) => {
      state.position = 0;
      state.resultModal = false;
      state.selectedOptions[state.pageId] = {};
      state.reTakeAssessmentFlag = false;
    },
    setRetakeAssessmentStatus: (state, action) => {
      state.reTakeAssessmentFlag = action.payload;
    },
  },
});

export const {
  qNext,
  qPrevious,
  qStatus,
  qUpdateSelectedOptions,
  qResultModal,
  qRest,
  qRetakeAssessment,
  setRetakeAssessmentStatus,
} = bookQuestionsSlice.actions;

export default bookQuestionsSlice.reducer;
