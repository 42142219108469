import Overview from "../pages/Admin/Overview";
import Library from "../pages/Admin/Library";
import { EditBook } from "../pages/Admin/Library/form";
import Settings from "../pages/Admin/Settings/Settings";
import SubjectList from "../pages/Admin/Settings/Subject/SubjectList";
import BookTypes from "../pages/Admin/Settings/BookTypes";
import Categories from "../pages/Admin/Settings/Category/Categories";
import Classes from "../pages/Admin/Settings/Subcategory/Classes";
import SubjectDetail from "../pages/Admin/Settings/Subject/SubjectDetail";
import QuestionBank from "../pages/Admin/QuestionBank/QuestionBank";
import EditQuestionBank from "../pages/Admin/QuestionBank/form/EditQuestionBank";
import Examprep from "../pages/Admin/Examprep/Examprep";
import EditBucket from "../pages/Admin/Examprep/form/EditBucket";
import { ExamPrepStorageProvider } from "../pages/ExamPrep/storage/ExamPrepStorage";
import ExamprepManageReview from "../pages/Admin/Examprep/ExamprepManageReview/ExamprepManageReview";
import Curriculum from "../pages/Admin/Curriculum/Curriculum";

export const adminRoutes = [
  {
    path: "",
    element: <Overview />,
  },
  {
    path: "examprep",
    element: <Examprep />,
  },
  {
    path: "examprep/review/create",
    element: (
      <ExamPrepStorageProvider>
        <ExamprepManageReview />
      </ExamPrepStorageProvider>
    ),
  },
  {
    path: "examprep/review/edit/:reviewId",
    element: (
      <ExamPrepStorageProvider>
        <ExamprepManageReview isEdit />
      </ExamPrepStorageProvider>
    ),
  },
  {
    path: "examprep/:bucketId",
    element: (
      <ExamPrepStorageProvider>
        <EditBucket />
      </ExamPrepStorageProvider>
    ),
  },
  {
    path: "library",
    element: <Library />,
  },
  {
    path: "settings",
    element: <Settings />,
  },
  {
    path: "subjects",
    element: <SubjectList />,
  },
  {
    path: "subjects/:subjectId",
    element: <SubjectDetail />,
  },
  {
    path: "classes",
    element: <Classes />,
  },
  {
    path: "categories",
    element: <Categories />,
  },
  {
    path: "book-types",
    element: <BookTypes />,
  },
  {
    path: "edit-book/:bookId",
    element: <EditBook />,
  },
  {
    path: "question-bank",
    element: <QuestionBank />,
  },
  {
    path: "question-bank/:questionId",
    element: <EditQuestionBank />,
  },
  {
    path: "curriculum/",
    element: <Curriculum />,
  },
];
