import React from "react";
import style from "./ApplicationFooter.module.css";
const ApplicationFooter = () => {
  return (
    <>
      <div className={style.FooterMargin}></div>
      <div className={style.root}>
        &copy; 2024 StarhubNG Ltd. All rights reserved.
      </div>
    </>
  );
};

export default ApplicationFooter;
