import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { motion, useAnimation } from "framer-motion";
import style from "./CardDeck.module.css";
// import Img from "../../../components/Img";
import { RightArrowIcon } from "../../../assets/icons";
import useResponsiveness from "../../../utils/hooks/useResponsiveness";
import { SCREEN_SIZE } from "../../../utils/constants";

const ProgressBar = ({ progress }) => {
  const variants = {
    initial: { width: 0 },
    animate: { width: `${progress}%` },
  };

  const getProgressColor = (progress) => {
    if (progress <= 75) {
      return "#6c68b0";
    } else {
      return "#198754";
    }
  };

  return (
    <div className={style.progressBar}>
      <motion.div
        style={{
          backgroundColor: getProgressColor(progress),
          height: "100%",
          borderRadius: "20px",
        }}
        variants={variants}
        initial="initial"
        animate="animate"
        transition={{ duration: 1 }}
      />
    </div>
  );
};

const UILayout = ({ data }) => {
  // eslint-disable-next-line
  const {
    book_id,
    book_title,
    book_cover_url,
    book_description,
    percentage_completion,
  } = data;

  const { responsive } = useResponsiveness(SCREEN_SIZE.sm);

  return (
    <div>
      <div className={style.cardContainer}>
        <div className={style.cardImg}>
          <img src={book_cover_url} alt={book_title} />
        </div>
        <div className={style.cardBody}>
          <h2 className={style.cardTitle}>{book_title}</h2>
          <p className={style.cardDetails}>{book_description}</p>
          {!responsive && (
            <>
              <div>
                <ProgressBar progress={percentage_completion} />
              </div>
              <button className={style.resumeCourseBtn}>
                <Link to={`/library/course/${book_id}`}>RESUME COURSE</Link>
              </button>
            </>
          )}
        </div>
      </div>
      {responsive && (
        <>
          <div className="mb-1">
            <ProgressBar progress={percentage_completion} />
          </div>
          <button className={style.resumeCourseBtn}>
            <Link to={`/library/course/${book_id}`}>RESUME COURSE</Link>
          </button>
        </>
      )}
    </div>
  );
};

const CardDeck = ({ books }) => {
  const [index, setIndex] = useState(0);
  const controls = useAnimation();

  const cardsContent = books.map((item, i) => {
    return {
      content: <UILayout data={item} />,
      className:
        i % 3 === 0 ? style.div1 : i % 3 === 1 ? style.div2 : style.div3,
    };
  });

  const nextCard = () =>
    setIndex((prevIndex) => (prevIndex + 1) % cardsContent.length);
  // eslint-disable-next-line
  const prevCard = () =>
    setIndex(
      (prevIndex) => (prevIndex - 1 + cardsContent.length) % cardsContent.length
    );

  useEffect(() => {
    controls.start((i) => ({
      x: i === index ? 0 : i < index ? 0 : 0,
      rotate: i === index ? 0 : i < index ? -15 : 15,
      transition: { type: "spring", stiffness: 50, damping: 20 },
    }));
  }, [index, controls]);

  return (
    <div className={style.root}>
      <div className={style.pack}>
        {cardsContent.map(({ content, className }, i) => (
          <motion.div
            custom={i}
            animate={controls}
            className={`item ${style.card} ${className}`}
            style={{
              zIndex: i === index ? 2 : 1,
            }}
            key={i}
          >
            {content}
          </motion.div>
        ))}
        {books.length >= 1 && (
          <div className={style.bottom}>
            <button onClick={nextCard}>
              Next <RightArrowIcon />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default CardDeck;
