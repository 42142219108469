import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isConfirmOpen: false,
  headerText: "",
  // onModalConfirmDismissed: () => {},
  // onConfirm: () => {},
  buttonText: "",
};

export const confrimationPopupSlice = createSlice({
  name: "confirmation-popup",
  initialState,
  reducers: {
    showConfirmationPopup: (state, action) => {
      return {
        ...state,
        isConfirmOpen: action.payload?.open,
        headerText: action.payload?.headerText,
        bodyText: action.payload?.bodyText,
        onModalConfirmDismissed: action.payload?.onDismissed,
        onConfirm: action.payload?.onConfirm,
        buttonText: action.payload?.buttonText,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { showConfirmationPopup } = confrimationPopupSlice.actions;

export default confrimationPopupSlice.reducer;
