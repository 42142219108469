import { useEffect, useReducer } from "react";
import useAppMutation from "../../../../utils/hooks/useAppMutation";
import libraryService from "../../../../service/library.service";
import { QUERY_KEY } from "../../../../utils/constants";
import { exerciseDummy } from "../constants";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import useAppQuery from "../../../../utils/hooks/useAppQuery";

function useManageCourseEBook() {
  const { courseId } = useParams();
  const navigate = useNavigate();
  const initialState = {
    pages: {
      page1: {
        exercise: exerciseDummy,
        title: "",
        author: "",
        content: "",
        images: {},
      },
    },
    templateName: "eclipse",
    isUploading: false,
  };

  const [state, dispatch] = useReducer(
    (stateVal, value) => ({ ...stateVal, ...value }),
    initialState
  );

  const { pages, templateName } = state;

  const {
    data: { template_data: courseDetails = {} } = {},
    // isLoading: isFetching,
  } = useAppQuery(QUERY_KEY.getBookDetails, libraryService.getECourse, {
    courseId,
  });

  useEffect(() => {
    if (courseDetails?.pages) {
      dispatch({ pages: courseDetails.pages });
    }
  }, [courseDetails]);

  // eslint-disable-next-line
  const { isLoading: uploadingData, mutateAsync: asycUploadEbook } =
    useAppMutation(
      QUERY_KEY.uploadTemplateContent,
      libraryService.uploadTemplateContent
    );

  // eslint-disable-next-line
  function handleContentUpload() {
    dispatch({ isUploading: true });
    asycUploadEbook({
      bookId: courseId,
      template_data: { pages },
      template_name: templateName,
    })
      .then(() => {
        toast.success("Uploaded successfully");
        navigate("/library/courses");
      })
      .catch(() => {
        toast.error("Failed to upload");
      })
      .finally(() => {
        dispatch({ isUploading: false });
      });
  }

  const exerciseData = {
    template: "elipse",
    data: [
      {
        options: [
          {
            option: "",
            text: "",
            image: "",
          },
        ],
        question: { text: "", image: "" },
        correctAnswer: [],
        answerType: "multiAnswer",
      },
    ],
  };

  function handleNewPage() {
    const pagesPresent = Object.keys(pages);
    dispatch({
      pages: {
        ...pages,
        [`page${pagesPresent.length + 1}`]: {
          exercise: exerciseData,
          title: "",
          author: "",
          content: "",
          images: {},
        },
      },
    });
  }

  return {
    state,
    dispatch,
    handleNewPage,
    handleContentUpload,
  };
}

export default useManageCourseEBook;
