import { AiOutlineDown } from "react-icons/ai";
import classes from "./EBookManagePage.module.css";
import { useState } from "react";
import { MdOutlineKeyboardControlKey } from "react-icons/md";
import BlockEditor from "../../components/BlockEditor";
import QandACardRow from "../QandACardRow/QandACardRow";
import { GrFormAdd } from "react-icons/gr";
import InputField from "../InputField/InputField";
import { IoCloseOutline } from "react-icons/io5";
import { FiEdit2 } from "react-icons/fi";
import { BsCardImage, BsFileEarmarkPlay } from "react-icons/bs";

function EBookManagePage({ page, state, dispatch }) {
  const [openContentTypes, setOpenContentTypes] = useState(false);
  const [showExercise, setShowExercise] = useState(false);
  const [editTitle, setEditTitle] = useState(false);

  const { pages } = state;

  function handleEditor(e) {
    const allPages = pages;
    allPages[page].content = e;
    dispatch({ pages: allPages });
  }
  function handlePageTitel(e) {
    const value = e.target.value;
    const allPages = pages;
    allPages[page].title = value;
    dispatch({ pages: allPages });
  }

  const currentPage = pages[page];

  function addNewQuestion() {
    const allPages = pages;
    allPages[page]?.exercise?.data.push({
      options: [
        {
          option: "",
          text: "exercitation",
          image: "",
        },
      ],
      question: { text: "" },
      correctAnswer: [],
      answerType: "multiAnswer",
    });
    dispatch({ pages: allPages });
  }

  function deleteQuestion() {
    const allPages = pages;
    delete allPages[page];
    dispatch({ pages: allPages });
  }

  function removeQuestion(index) {
    const allPages = pages;
    allPages[page]?.exercise?.data?.splice(index, 1);
    dispatch({ pages: allPages });
  }

  function onChangeQuestion(e, index) {
    const value = e.target.value;
    const allPages = pages;
    allPages[page].exercise.data[index].question.text = value;

    dispatch({ pages: allPages });
  }
  function onChangeAnswerType(value, index) {
    const allPages = pages;
    allPages[page].exercise.data[index].answerType = value;
    dispatch({ pages: allPages });
  }

  function handleUpdateExerciseAnswerOption(value, index) {
    const allPages = pages;
    allPages[page].exercise.data[index].options = value;
    dispatch({ pages: allPages });
  }

  function upLoadImage(e) {
    const file = e.target.files?.[0];
    const imageurl = URL.createObjectURL(file);
    // eslint-disable-next-line
    const result = {
      imageUrl: imageurl,
      bookFile: file,
    };
    //   const allPages = pages;
    //   allPages[page].content = e;
    // dispatch({ bookCover: result });
  }

  return (
    <div className={classes.compRoot}>
      <div className={classes.pageTitleWrapper}>
        <div className={classes.pageNumber}>{page}:</div>
        <button onClick={deleteQuestion} className={classes.removePageButton}>
          Remove page
        </button>
      </div>
      <div className={classes.CompContent}>
        <div>
          <div className={classes.manageContentSelector}>
            <div className={classes.pageTitleBox}>
              {editTitle ? (
                <div className={classes.editTitleBox}>
                  <div style={{ flex: 1 }}>
                    <InputField
                      autoFocus
                      value={currentPage?.title}
                      onChange={handlePageTitel}
                      className={classes.titleEditInput}
                    />
                  </div>
                  <button
                    className={classes.closeTitleEdit}
                    onClick={() => setEditTitle(false)}
                  >
                    <IoCloseOutline />
                  </button>
                </div>
              ) : (
                <div>
                  <span className={classes.titleText}>
                    {currentPage?.title}
                  </span>
                  <button
                    className={classes.titleEditButton}
                    onClick={() => setEditTitle(true)}
                  >
                    <FiEdit2 />
                  </button>
                </div>
              )}
            </div>

            <button
              onClick={() => setOpenContentTypes(!openContentTypes)}
              className={classes.addContentBtn}
            >
              <span>Content</span>
              {openContentTypes ? (
                <MdOutlineKeyboardControlKey />
              ) : (
                <AiOutlineDown />
              )}
            </button>
          </div>
          <div
            className={`${classes.contentTypeSelection} ${
              openContentTypes ? classes.showcontentTypes : undefined
            }`}
          >
            <div className={classes.contentTypeNotice}>
              <span>
                Select the main type of content. Files and links can be added as
                resources.
              </span>
              <button> Learn about content types.</button>
            </div>
            <div className={classes.selectorWrapper}>
              <label
                htmlFor="contained-button-file"
                className={classes.addImage}
              >
                <div className={classes.contentSelectBtn}>
                  <div className={classes.contentTypeIcon}>
                    <BsCardImage />
                  </div>
                  <div className={classes.contentTypeText}>Image</div>
                </div>
                <input
                  accept="image/*"
                  className={classes.imageInput}
                  id="contained-button-file"
                  multiple
                  type="file"
                  onChange={upLoadImage}
                />
              </label>
              <label
                htmlFor="contained-button-file"
                className={classes.addImage}
              >
                <div className={classes.contentSelectBtn}>
                  <div className={classes.contentTypeIcon}>
                    <BsFileEarmarkPlay />
                  </div>
                  <div className={classes.contentTypeText}>Video</div>
                </div>
                <input
                  accept="image/*"
                  className={classes.imageInput}
                  id="contained-button-file"
                  multiple
                  type="file"
                  // onChange={upLoadImage}
                />
              </label>
            </div>
            <div className={classes.textEditorWrapper}>
              <BlockEditor
                value={currentPage?.content}
                onChange={handleEditor}
                theme="snow"
                className={classes.blockTextEdit}
              />
            </div>
          </div>
        </div>

        {/* Exercise  */}
        <div className={classes.pageExerciseSection}>
          <div className={classes.exerciseTopBar}>
            <button
              onClick={() => setShowExercise(!showExercise)}
              className={classes.addExerciseBtn}
            >
              <span>Exercise</span>
              {showExercise ? (
                <MdOutlineKeyboardControlKey />
              ) : (
                <AiOutlineDown />
              )}
            </button>
          </div>

          <div
            className={`${classes.pageQuestionWrapper} ${
              showExercise ? classes.showExercise : undefined
            }`}
          >
            <div className={classes.questionWrapper}>
              {currentPage?.exercise?.data?.map((exercise, index) => (
                <QandACardRow
                  courseTemplateQuestion
                  updateAnswersOption={(values) => {
                    handleUpdateExerciseAnswerOption(values, index);
                  }}
                  onChangeQuestion={(e) => onChangeQuestion(e, index)}
                  onRemove={() => removeQuestion(index)}
                  onChangeAnswerType={(value) =>
                    onChangeAnswerType(value, index)
                  }
                  exercise={exercise}
                  questionNumber={index + 1}
                />
              ))}
              <div className={classes.addCardBtn}>
                <div onClick={addNewQuestion} className={classes.addCardItem}>
                  <GrFormAdd />
                  <span>Add Exercise</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EBookManagePage;
