import style from "./CourseDetails.module.css";
import BookDetailsLoader from "../../../components/BookDetailsLoader";
import BookDetailsOM from "../../../components/BookDetailsOM/BookDetailsOM";
import useCourseDetailsNew from "./hooks/useCourseDetailsNew";
import BookCard2 from "../../../components/BookCard2/BookCard2";
import BookReviews from "../../../components/BookDetailsOM/BookReviews";
import { RowLayout2 } from "../../Dashboard/layout/PageLayout";
import { useNavigate } from "react-router-dom";

function CourseDetails() {
  const { data, fetchCourseDetails } = useCourseDetailsNew();

  const navigate = useNavigate();
  if (!data?.book_details?._id) {
    return <BookDetailsLoader />;
  }
  return (
    <div className={style.pageRoot}>
      <div className={style.left}>
        <BookDetailsOM data={data} fetchCourseDetails={fetchCourseDetails} />

        {data?.recommendations?.length > 0 && (
          <p className={style.suggestionLabel}>Suggested topics</p>
        )}
        <RowLayout2>
          {data?.recommendations?.map((item, index) => (
            <BookCard2
              key={index}
              img={item?.book_cover_url}
              title={item?.title}
              rating={item?.rating}
              subject={item?.subject?.name}
              handleClick={() => navigate(`/library/course/${item?._id}`)}
            />
          ))}
        </RowLayout2>
      </div>
      <div className={style.right}>
        {/* Reviews */}
        <BookReviews reviews={data?.reviews} />
      </div>
    </div>
    // </div>
  );
}

export default CourseDetails;
