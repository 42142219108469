import { request } from "./api";
import env from "../env";

const API = env.API_URL;

const URL = {
  getAllCourses: `${API}/books`,
  createCourse: `${API}/curriculums`,
  getCourseBySubjectId: `${API}/curriculums/subject`,
  getCourseById: (courseId) => `${API}/curriculums/${courseId}`,
  getUserCurriculum: `${API}/curriculums/user_curriculums`,
  deletUploadedCurriculum: (curriculumId) =>
    `${API}/curriculums/${curriculumId}`,
};

function getAllCourses(params) {
  return request(URL.getAllCourses, params, "GET", {});
}
function createCourse(params) {
  return request(URL.createCourse, params, "POST", {});
}
function getCourseBySubjectId(params) {
  return request(URL.getCourseBySubjectId, params, "GET", {});
}
function getCourseById({ courseId }) {
  return request(URL.getCourseById(courseId), {}, "GET", {});
}
function getUserCurriculum(params) {
  return request(URL.getUserCurriculum, params, "GET", {});
}
function deletUploadedCurriculum({ curriculumId }) {
  return request(URL.deletUploadedCurriculum(curriculumId), {}, "DELETE", {});
}

const coursesService = {
  getAllCourses,
  createCourse,
  getCourseBySubjectId,
  getCourseById,
  getUserCurriculum,
  deletUploadedCurriculum,
  allBooks : `${API}/books`,
  booksFilter: `${API}/books/filter`,
};

export default coursesService;
