import React, { useRef, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import defaultProductImage from "../../assets/png/default-placeholder.png";
import LazyLoad from "react-lazy-load";
import classes from "./Img.module.css";

function Img({
  alt,
  src,
  className,
  wrapperClass,
  product,
  isNotLazyLoad = false,
  ...props
}) {
  const [showDefault, setShowDefault] = useState(false);
  const refPlaceholder = useRef();

  const removePlaceholder = () => {
    refPlaceholder.current.remove();
  };

  if (isNotLazyLoad) {
    return (
      <img
        {...props}
        className={className}
        src={!showDefault && src ? src : defaultProductImage}
        onError={() => {
          setShowDefault(true);
        }}
        alt={alt}
      />
    );
  }

  return (
    <div className={`${classes.imageWrapper} ${wrapperClass}`}>
      <div className={classes.loaderWraper} ref={refPlaceholder}>
        <ThreeDots
          visible={true}
          height="80"
          width="80"
          color="#bbb"
          radius="9"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClass=""
        />
      </div>
      <LazyLoad>
        <img
          {...props}
          className={className}
          src={!showDefault && src ? src : defaultProductImage}
          onLoad={removePlaceholder}
          onError={() => {
            setShowDefault(true);
            removePlaceholder();
          }}
          alt={alt}
        />
      </LazyLoad>
    </div>
  );
}

export default Img;
