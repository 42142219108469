import style from "./AdminLayout.module.css";
import { BsFillGridFill } from "react-icons/bs";
// import { FaBookOpen } from "react-icons/fa";
import { Link, Outlet } from "react-router-dom";
import ProfileCard from "../../../components/ProfileCard/ProfileCard";
import { AdminContextProvider } from "../../../context/AdminContext";
import Notification from "../../../components/Notification";

const AdminLayout = () => {
  return (
    <AdminContextProvider>
      <div className={style.container}>
        <div className={style.sidebar}>
          <div className={style.sidebar_header}>ctrLearn</div>
          <div className={style.sidebar_body}>
            <ul>
              <li>
                <Link to="/admin">
                  <BsFillGridFill /> Dashboard
                </Link>
              </li>
              <li>
                <Link to="/admin/library">
                  <BsFillGridFill /> Library
                </Link>
              </li>
              <li>
                <Link to="/admin/curriculum">
                  <BsFillGridFill /> Curriculum
                </Link>
              </li>
              <li>
                <Link to="/admin/question-bank">
                  <BsFillGridFill /> Question Bank
                </Link>
              </li>
              <li>
                <Link to="/admin/examprep/">
                  <BsFillGridFill /> Exam Prep
                </Link>
              </li>

              <li>
                <Link to="">
                  <BsFillGridFill /> Edu League
                </Link>
              </li>
              <li>
                <Link to="/admin/settings">
                  <BsFillGridFill /> Settings
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className={style.maincontainer}>
          <div className={style.navbar}>
            Admin
            <div style={{ display: "flex", alignItems: "center" }}>
              <Notification />
              <ProfileCard />
            </div>
          </div>
          <div className={style.container__outlet}>
            <Outlet />
          </div>
        </div>
      </div>
    </AdminContextProvider>
  );
};

export default AdminLayout;
