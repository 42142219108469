import React, { useContext } from "react";
import ExamPrepStorage from "../../storage/ExamPrepStorage";
import { AiOutlineCloseCircle } from "react-icons/ai";
import style from "./PerformanceByTopics.module.css";
import { ColumnLayout } from "../../../Dashboard/layout/PageLayout";
import BookCard2 from "../../../../components/BookCard2/BookCard2";
import { useNavigate } from "react-router-dom";
import useMyShelf from "../../../library/MyShelf/hooks/useMyShelf";

export const PerformanceByTopics = () => {
  const { updateStorage, performance, title, recommendations } =
    useContext(ExamPrepStorage);

  const { returnBook } = useMyShelf();

  const navigate = useNavigate();

  const getPerformanceData = (item) => {
    const data = Object.values(
      item.reduce(function (r, e) {
        if (!r[e.topic_id]) r[e.topic_id] = e;
        else if (e.score > r[e.topic_id].score) r[e.topic_id] = e;
        return r;
      }, {})
    );

    return data;
  };

  return (
    <>
      <div className={style.close}>
        <button
          onClick={() =>
            updateStorage({
              modalPage: "AssessmentDetails",
            })
          }
        >
          <AiOutlineCloseCircle />
        </button>
      </div>
      {performance.length > 0 ? (
        <div className={style.content}>
          <div className={style.left}>
            <h3>{title} Bucket</h3>
            <p>Performance By Topics</p>
            <hr />
            <div className={style.topicsContainer}>
              <div className={style.topicsInnerContainer}>
                <ul className={style.topics}>
                  {getPerformanceData(performance).map((item, index) => (
                    <li key={index}>
                      {item.topic_name}
                      <span>
                        {item.score}/{item.total_questions}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className={style.right}>
            <p>Recommendations</p>
            <div className={style.rightScroll}>
              <ColumnLayout>
                {recommendations.map((item, index) => (
                  // <BookCard2 key={index} />
                  <BookCard2
                    key={index}
                    img={item?.book_cover_url}
                    title={item?.title}
                    rating={item?.rating}
                    subject={item?.subject?.name}
                    price={item?.price}
                    handleClick={() => navigate(`/library/course/${item?._id}`)}
                    handleReturnBook={() => returnBook(item?._id)}
                  />
                ))}
              </ColumnLayout>
            </div>
          </div>
        </div>
      ) : (
        <div style={{ color: "red", textAlign: "center", marginTop: "15%" }}>
          <h4>No historical data available</h4>
        </div>
      )}
    </>
  );
};
