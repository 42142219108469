import classes from "./CourseTypeCard.module.css";

function CourseTypeCard({ disabled, icon, title, description, onClick }) {
  return (
    <div onClick={onClick} disabled={disabled} className={classes.cardWrapper}>
      <div className={classes.icon}>{icon}</div>
      <h4 className={classes.cardTitle}>{title}</h4>
      <p className={classes.cardDescription}>{description}</p>
    </div>
  );
}

export default CourseTypeCard;
