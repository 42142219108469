import { useState, useEffect } from "react";
import axios from "axios";

export const headers = {
  Accept: "application/json",
  "Cache-Control": "no-cache",
  "Content-Type": "application/json",
};

const useCdReactQuery = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // Set authorization in headers
  const persistedToken = localStorage.getItem("accessToken");

  persistedToken && (headers.Authorization = `Bearer ${persistedToken}`);

  useEffect(() => {
    return () => {
      // remove all pending API calls
      axios.CancelToken.source().cancel("Cancelling in cleanup");
    };
  }, []);

  const queryEndpoint = async ({
    endpointUrl = "",
    httpMethod = "GET",
    params = {},
    body = null,
  }) => {
    setIsLoading(true);

    try {
      const res = await axios({
        method: httpMethod,
        url: endpointUrl,
        params,
        data: body,
        headers,
        cancelToken: axios.CancelToken.source().token,
      });
      setData(res.data); // remove the setData during refactoring
      setError(null);
      return res.data;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled", error.message);
      }
      setError(error);
      setIsLoading(false);
      return new Promise((_, reject) => {
        reject(error?.response?.data);
      });
    } finally {
      setIsLoading(false);
    }
  };

  return { data, queryEndpoint, isLoading, error };
};

export default useCdReactQuery;
