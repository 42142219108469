import { useEffect, useState } from "react";
import Dropdown from "../../../../components/Dropdown";
import { TERMS } from "../../../../utils/constants";
import { initCaps } from "../../../../utils/helpers";
import style from "./AddCurriculum.module.css"
import useAdminLibrary from "../../Library/hooks/useAdminLibrary";
import { Loader } from "../../../../components/Modal";

const AddCurriculum = ({ handleSave, editData }) => {
    const [initForm , setInitForm] = useState({
        sub_category_id: "",
        subject_id: "",
        terms: [1]
      })
    const [classes, setClasses] = useState([])
    const [subjects, setSubjects] = useState([])
    const { getClasses, isLoading } = useAdminLibrary();

    useEffect(() => {
        const fetch = async() => {
            const res = await getClasses()
            setClasses(res?.map(_ => _))
        }
        fetch()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if(editData?.id){
            setInitForm({
                curriculum_id : editData?.id,
                sub_category_id: editData?.sub_category?.id,
                subject_id: editData?.subject?.id,
                terms: editData?.terms
            }) 
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        const classData = classes?.find(item => item.id === initForm.sub_category_id)
        setSubjects(classData?.subjects)


    // eslint-disable-next-line
    }, [classes])

    const selectTerms = (term) => {
        const new_terms = initForm.terms.includes(term) ? initForm.terms.filter(t => t !== term) : [...initForm.terms, term]
        setInitForm({...initForm, terms : new_terms})
    }

    const saveCurriculum = () => {
        handleSave(initForm, initForm?.curriculum_id ? true : false )
    }

    const getClassByID = (id) => {
        const classData = classes?.find(item => item.id === id)
        return classData?.name
    }

    const getSubjectByID = (id) => {
        return (subjects?.find(item => item.id === id))?.name
    }
    
    return (
        <div className={style.form_container}>
            <h5 className={style.header_title}>{initForm?.curriculum_id ? "edit" : "create"} Curriculum</h5>
            <div className={style.fluid}>
              <div className={style.formGroup}>
                <div className={style.label}>Class</div>
                <Dropdown
                  options={classes}
                  value={getClassByID(initForm.sub_category_id) || ""}
                  onChange={(e) =>{
                    setSubjects(e.subjects)
                    setInitForm({ ...initForm, sub_category_id: e.id })
                  }}
                  className={style.difficulty}
                />
              </div>
              <div className={style.formGroup}>
                <div className={style.label}>Subject</div>
                <Dropdown
                  options={subjects}
                  value={getSubjectByID(initForm.subject_id) || ""}
                  onChange={(e) => {
                    setInitForm({ ...initForm, subject_id: e.id })
                  }}
                  className={style.difficulty}
                />
              </div>
              
            </div>
            <div className={style.term_section}>
                <div className={style.label}>Terms</div>
                <div className={style.fluid}>
                    {[1, 2, 3].map(_ => (
                        <div className={`${style.row} ${style.term_container}`} key={_}>
                            <input type="checkbox" checked={initForm.terms.includes(_)} 
                            onChange={ () => selectTerms(_)}
                            />
                            <span className={style.term_name}>{initCaps(TERMS[_])} term</span>
                        </div>
                    ))}
                </div>
            </div>
            <div className={`${style.fluid} ${style.actionGroup}`}>
                  <div
                    className={style.btn}
                    onClick={saveCurriculum}
                  >
                    SAVE CURRICULUM
                  </div>
            </div>
            {isLoading && <Loader />}
        </div>
    )
}


export default AddCurriculum;