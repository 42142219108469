import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPage: null,
  courseData: {},
  coursePages: {},
  assessmentPassed: [],
  finalAssessment: [],
};

export const courseDataSlice = createSlice({
  name: "course-data",
  initialState,
  reducers: {
    initializeCourseData: (state, action) => {
      state.courseData = action.payload?.pages;
      state.currentPage = action.payload?.currentPage;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },

    setAnsweredAssessment: (state, action) => {
      state.assessmentPassed = action.payload;
    },
    setFinalAssessment: (state, action) => {
      state.finalAssessment = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  initializeCourseData,
  setFinalAssessment,
  setCurrentPage,
  setAnsweredAssessment,
} = courseDataSlice.actions;

export default courseDataSlice.reducer;
