import { useEffect, useReducer } from "react";
import libraryService from "../../../../service/library.service";
import { QUERY_KEY } from "../../../../utils/constants";
import useAppQuery from "../../../../utils/hooks/useAppQuery";
import useAppMutation from "../../../../utils/hooks/useAppMutation";
import { useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
function useCourseUploadSetup(isEdit) {
  const [category] = useSearchParams();
  const subCategory = category.get("subcategory");
  const { user } = useSelector(({ currentUser }) => currentUser);
  const { subcategoryId, courseId } = useParams();
  const navigate = useNavigate();
  const initialState = {
    selectedSubject: {},
    selectedTopic: {},
    publisher: `${user?.firstName} ${user?.lastName}`,
    yearPublished: "",
    selectedLanguage: {},
    bookCover: {},
    savingBookData: false,
    price: 0,
  };

  const [state, dispatch] = useReducer(
    (stateVal, value) => ({ ...stateVal, ...value }),
    initialState
  );
  const {
    selectedTopic,
    selectedSubject,
    publisher,
    yearPublished,
    selectedLanguage,
    bookCover,
    price,
  } = state;
  const {
    data: { subcategory: subjects = [] } = {},
    isLoading: fetchingSubjects,
  } = useAppQuery(
    QUERY_KEY.getAllSubjectsBySubId,
    libraryService.getAllSubjectsBySubId,
    { subcategoryId: subcategoryId || subCategory },
    { enabled: subcategoryId || subCategory }
  );

  const { data: { data: topics = [] } = {}, isLoading: fetchingTopics } =
    useAppQuery(
      QUERY_KEY.getTopicBySubjectId,
      libraryService.getTopicBySubjectId,
      { subjectId: selectedSubject?._id?.$oid },
      { enabled: !!selectedSubject?._id?.$oid }
    );

  const { data: { book_details } = {}, isLoading: fetchingCourseDetails } =
    useAppQuery(
      QUERY_KEY.getBookDetails,
      libraryService.getBookDetails,
      {
        bookId: courseId,
      },
      { enabled: !!courseId }
    );

  useEffect(() => {
    if (book_details?._id?.$oid) {
      dispatch({
        selectedTopic: book_details?.topic,
        selectedSubject: book_details?.subject,
        publisher: book_details?.publisher,
        yearPublished: book_details?.year_published,
        subcategory: book_details?.sub_category,
        price: book_details?.price || "",
        bookCover: {
          imageUrl: book_details?.book_cover_url,
        },
      });
    }
  }, [book_details]);

  // eslint-disable-next-line
  const { mutateAsync: asycPublish } = useAppMutation(
    QUERY_KEY.publishBook,
    libraryService.publishBook
  );

  // eslint-disable-next-line
  const { mutateAsync: asycUpdateCourse } = useAppMutation(
    QUERY_KEY.updateCourse,
    libraryService.updateCourse
  );

  // eslint-disable-next-line
  const { isLoading: isUploadings, mutateAsync: asycFileUpload } =
    useAppMutation(
      QUERY_KEY.courseFilesUpload,
      libraryService.courseFilesUpload
    );

  function uploadCourseMedia(courseId) {
    dispatch({ savingBookData: true });
    const mediaData = new FormData();
    mediaData.append("image", bookCover?.bookFile);
    mediaData.append("for_book_cover", true);
    mediaData.append("book_id", courseId);
    asycFileUpload(mediaData).finally(() => {
      dispatch({ savingBookData: false });
      navigate(`/library/manage-course/e-book/${courseId}`);
    });
  }

  function handleSaveBook() {
    if (!selectedSubject?.name) {
      toast.error("Subject is required");
      return;
    }
    if (!selectedTopic?.name) {
      toast.error("Topic is required");
      return;
    }
    dispatch({ savingBookData: true });
    asycPublish({
      language: selectedLanguage?.value,
      publisher: publisher,
      sub_category_id: subcategoryId,
      subject: selectedSubject?.name,
      topic: selectedTopic?.name,
      year_published: +yearPublished || 0,
    })
      .then(({ data }) => {
        toast.success("Saved successfully");
        if (bookCover?.bookFile) {
          uploadCourseMedia(data?._id?.$oid);
          return;
        }
        navigate(`/library/manage-course/e-book/${data?._id?.$oid}`);
      })
      .catch(({ detail, msg }) => {
        toast.error(detail || msg);
      })
      .finally(() => {
        dispatch({ savingBookData: false });
      });
  }
  function handleUpdateBook() {
    dispatch({ savingBookData: true });
    asycUpdateCourse({
      courseId,
      params: {
        price,
        language: selectedLanguage?.value,
        publisher: publisher,
        subject: selectedSubject?._id?.$oid,
        topic: selectedTopic?._id?.$oid,
        year_published: +yearPublished || 0,
        sub_category_id: book_details?.subject?.$id?.$oid || "",
      },
    })
      .then(({ data }) => {
        toast.success("Updated successfully");
        if (bookCover?.bookFile) {
          uploadCourseMedia(book_details?._id?.$oid);
          return;
        }
        navigate(`/library/manage-course/e-book/${book_details?._id?.$oid}`);
      })
      .catch(({ detail }) => {
        toast.error(detail);
      })
      .finally(() => {
        dispatch({ savingBookData: false });
      });
  }
  function upLoadImage(e) {
    const file = e.target.files?.[0];
    const reader = new FileReader();
    const imageurl = URL.createObjectURL(file);

    const onLoadedImageString = (event) => {
      const base64String = event.target.result;
      const dataLength = base64String.match(/^data:image\/\w+;base64,/g)?.[0];
      const base64WithougPrefix = dataLength
        ? base64String.slice(dataLength.length, base64String.length)
        : null;

      const result = {
        imageUrl: imageurl,
        imageString: base64WithougPrefix,
        bookFile: file,
      };
      dispatch({ bookCover: result });
    };
    reader.addEventListener("load", onLoadedImageString);
    reader.readAsDataURL(file);
  }
  return {
    subjects,
    fetchingSubjects,
    state,
    fetchingCourseDetails,
    topics,
    fetchingTopics,
    dispatch,
    handleSaveBook,
    navigate,
    upLoadImage,
    handleUpdateBook,
  };
}

export default useCourseUploadSetup;
