import React, { useState } from "react";
import style from "./PerformanceBySubject.module.css";
import {
  BiRightArrowCircle,
  BiDownArrowCircle,
  BiUpArrowCircle,
} from "react-icons/bi";

export const Score = ({ previousScore, currentScore, difficultyLevel }) => {
  const colorIndicator = (() => {
    switch (true) {
      case previousScore === 0 || previousScore === currentScore:
        return { color: "side", component: <BiRightArrowCircle /> };

      case previousScore < currentScore:
        return { color: "up", component: <BiUpArrowCircle /> };
      case previousScore > currentScore:
        return { color: "down", component: <BiDownArrowCircle /> };

      default:
        return { color: "", component: "" };
    }
  })();

  return (
    <div className={style[colorIndicator.color]}>
      <span>{currentScore}</span> {colorIndicator.component}
      <div className={style.difficultyLevel}>{difficultyLevel}</div>
    </div>
  );
};

export const Subject = ({ data }) => {
  return (
    <>
      {" "}
      <div className={style.desktopOnly}>
        <table className={style.customTable}>
          <tbody>
            <tr className={style.subject}>
              <td className={style.label}>{data.subject}</td>
              {data.scores.map((item, index) => {
                const _scores = data.scores;
                if (index <= _scores.length - 1) {
                  if (index === 0)
                    return (
                      <td key={index}>
                        <Score
                          previousScore={0}
                          currentScore={item.score}
                          difficultyLevel={item.difficultyLevel}
                        />
                      </td>
                    );
                  else
                    return (
                      <td key={index}>
                        <Score
                          previousScore={_scores[index - 1]["score"]}
                          currentScore={item.score}
                          difficultyLevel={item.difficultyLevel}
                        />
                      </td>
                    );
                }
                return "";
              })}
            </tr>
          </tbody>
        </table>
      </div>
      <div className={style.mobileOnly}>
        <div className={style.label}>{data.subject}</div>
        {data.scores.map((item, index) => {
          const _scores = data.scores;
          if (index <= _scores.length - 1) {
            if (index === 0)
              return (
                <div className={style.score} key={index}>
                  <Score
                    previousScore={0}
                    currentScore={item.score}
                    difficultyLevel={item.difficultyLevel}
                  />
                </div>
              );
            else
              return (
                <div className={style.score} key={index}>
                  <Score
                    previousScore={_scores[index - 1]["score"]}
                    currentScore={item.score}
                    difficultyLevel={item.difficultyLevel}
                  />
                </div>
              );
          }
          return "";
        })}
      </div>
    </>
  );
};

const PerformanceBySubject = ({ performance }) => {
  const [pagination, setPagination] = useState({
    lowerLimit: 0,
    upperLimit: 4,
    counter: 0,
  });
  const pageSize = 4;
  const performanceSize = performance ? performance.length : 1;
  const totalDataGroups = performanceSize / pageSize;
  const remenderAfterDivision = performanceSize % pageSize;
  // if there is a remender after the division, add one to the total page size
  const paginationGroups =
    remenderAfterDivision > 0
      ? Math.floor(totalDataGroups) + 1
      : Math.floor(totalDataGroups);

  const performanceData = performance ? performance : [];

  let data = performanceData.slice(
    pagination.lowerLimit,
    pagination.upperLimit
  );

  const paginationHandler = (index, pageSize) => {
    setPagination((prev) => {
      if (index === 0) {
        return {
          ...prev,
          counter: index,
          lowerLimit: 0,
          upperLimit: pageSize,
        };
      } else {
        const lower = index * pageSize;
        return {
          ...prev,
          counter: index,
          lowerLimit: lower,
          upperLimit: lower + pageSize,
        };
      }
    });
    data = performance?.slice(pagination.lowerLimit, pagination.upperLimit);
  };
  return (
    <div className={style.root}>
      <p className={style.sub_heading}>Performance by Subject</p>
      {data.length ? (
        data.map((item, index) => <Subject data={item} key={index} />)
      ) : (
        <p style={{ marginTop: "-20px" }}>No record available</p>
      )}
      <div className={style.pagination}>
        {/* simulate an array with the paginationGroups number using Array class */}
        {paginationGroups > 1 &&
          Array(paginationGroups)
            .fill("1")
            .map((_, index) => (
              <button
                key={index}
                className={pagination.counter === index && style.active}
                onClick={() => paginationHandler(index, pageSize)}
              >
                {index + 1}
              </button>
            ))}
      </div>
    </div>
  );
};
export default PerformanceBySubject;
