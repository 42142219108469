import Notes from "./Notes";
import { NoteContextProvider } from "./noteContext";

const Main = () => {
  return (
    <NoteContextProvider>
      <Notes />
    </NoteContextProvider>
  );
};
export default Main;
