import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { DeleteIcon, EditIcon, GridAddIcon, SaveIcon, SearchIcon } from "../../../../assets/icons";
import style from "./SubjectsList.module.css"
import InputField from "../../../../components/InputField";
import { FullpageModal } from "../../../../components/Modal";
import useAdminTopic from "../hooks/useAdminTopic";
import useAdminSubject from "../hooks/useAdminSubject";
import { toast } from "react-toastify";

const INITIAL_DATA = { name: ""};
const SubjectDetail = () => {
    const { subjectId } = useParams()
    const { getSubjectById, isLoading } = useAdminSubject();
    const { createTopic, editTopic } = useAdminTopic();
    const [subject, setSubject] = useState({})
    const [topic, setTopic] = useState(INITIAL_DATA)
    const [modalOpen, setModalOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    useEffect(() => {
        (async () => {
            const response = await getSubjectById(subjectId)
            setSubject(response.subject)
        })();
    
        // eslint-disable-next-line
    }, []);

    const toggleModal = () => {
        setModalOpen(!modalOpen);
      };

    const saveTopic = async () => {
        const newTopic = {...topic, subject_name : subject.name}
        const resp = await createTopic(newTopic);
        if (resp.topic) {
            setSubject({...subject, topics : [...subject.topics, resp.topic]});
            toast.success("Topic added successfully");
            setTopic(INITIAL_DATA);
            setTimeout(() => {
              toggleModal();
            }, 1000);
          } else {
            toast.success("Error adding topic to subject");
        }
    }

    const updateTopic = async () => {
        const topicData = {name : topic.name, topic_id : topic.id}
        const resp = await editTopic(topicData);
        if (resp.topic) {
            setSubject({...subject, topics : subject.topics.map(data => {
                if(data.id === topic.id){
                    return {...data, name : topic.name}
                }
                return data
            })})
            toggleModal();
            toast.success(`${resp.msg}`);
          } else {
            toast.error("Error updating subject");
          }
    }


    return (
        <div className={style.root}>
      <div className={style.bookWrapper}>
        <div className={style.header}>
          <span>
            <Link to="/admin/subjects/">Back</Link>
          </span>
          <div className={style.right}>
            <i className={style.searchIcon}>
              <SearchIcon />
            </i>
            <i>
              <GridAddIcon
                title="Add subject"
                onClick={() => {
                    setIsEdit(false);
                    setTopic(INITIAL_DATA);
                    toggleModal();
                }}
              />
            </i>
          </div>
        </div>
        <div className={style.scrollWrapper}>
          <table className={style.bookList}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody className={style.items}>
              {isLoading ? (
                "Loading all subject topics"
              ) : subject?.topics  ? (
                subject?.topics.map((item, index) => (
                  <tr key={index}>
                    <td>{item.name}</td>
                    <td className={style.actions}>
                      <i
                        onClick={() => {
                            setTopic(item);
                            setIsEdit(true);
                            toggleModal();
                        }}
                      >
                        <EditIcon />
                      </i>{" "}
                      <i>
                        <DeleteIcon onClick={() => {}} />
                      </i>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colspan="6">No record found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {modalOpen && (
        <FullpageModal toggleModal={toggleModal} className={style.previewSize}>
          <div className={style.scrollWrapper}>
            <div className={style.fluid}>
              <div className={style.formGroup}>
                <div className={style.label}>Name</div>
                <InputField
                  className={style.title}
                  value={topic.name}
                  onChange={(e) =>
                    setTopic({ ...topic, name: e.target.value })
                  }
                />
              </div>
              <div>
                {isEdit ? (
                  <button className={style.btn} onClick={updateTopic}>
                    Edit <EditIcon />
                  </button>
                ) : (
                  <button className={style.btn} onClick={saveTopic}>
                    Save <SaveIcon />
                  </button>
                )}
              </div>
            </div>
          </div>
        </FullpageModal>
      )}
    </div>
    )
}

export default SubjectDetail;