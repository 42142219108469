import { useNavigate } from "react-router-dom";
import BookCard2 from "../../../components/BookCard2";
import SkeletonLoader from "../../../components/SkeletonLoader";
import classes from "./CourseList.module.css";
import useCourseList from "./hooks/useCourseList";
import Banner2 from "../../../components/Banner2";

function CourseList() {
  const history = useNavigate();
  const {
    fetchSubject,
    subject,
    selectedSubject,
    loadingBooks,
    books,
    setSelectedSubject,
  } = useCourseList();

  const allSubject = [{ name: "All", value: "ALL" }, ...subject];

  return (
    <div className={classes.pageRoot}>
      <Banner2
        label="Course List"
        text="Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,"
        onClick={() => {
          history(-1);
        }}
      />

      {fetchSubject ? (
        <div className={classes.subjectsWrapper}>
          {[...new Array(6).values()].map((_, index) => (
            <SkeletonLoader className={classes.subjectskeletonLoader} />
          ))}
        </div>
      ) : (
        <div className={classes.subjectsWrapper}>
          {allSubject.map((item, index) => (
            <button
              onClick={() => setSelectedSubject(item)}
              className={`${classes.subjectButton} ${
                selectedSubject?.name === item.name
                  ? classes.selectedSubject
                  : undefined
              }`}
              key={index}
            >
              {item?.name}
            </button>
          ))}
        </div>
      )}
      <div className={classes.pageBody}>
        {loadingBooks ? (
          <div className={classes.booksWrapper}>
            {[...new Array(6).values()].map((_, index) => (
              <SkeletonLoader className={classes.skeletonLogLoader} />
            ))}
          </div>
        ) : (
          <>
            {!books.length ? (
              <div className={classes.emptyHistory}>
                <h3 className={classes.noBooks}>
                  No course available for this category.
                </h3>
              </div>
            ) : (
              <div className={classes.booksWrapper}>
                {books?.map((course, i) => (
                  <BookCard2
                    img={course?.book_cover_url}
                    price={course?.price}
                    title={course?.topic}
                    author={course?.subject}
                    rating={course?.rating}
                    handleClick={() => {
                      history(`/library/course/${course?._id?.$oid}`);
                    }}
                  />
                ))}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default CourseList;
