import React from "react";
import "./TeamComponent.css";

export const TeamComponent = () => {
  return (
    <>
      <div className="boardHeader">
        <div className="team">
          <div style={{ width: "50px" }}>#</div>
          <div>TEAM</div>
        </div>
        <div className="pts">
          <div>PLAYED</div>
          <div>WINS</div>
          <div>DRAWS</div>
          <div>LOSES</div>
          <div>POINTS</div>
        </div>
      </div>
      <div className="boardBody campionship">
        <div className="team">
          <div style={{ width: "50px" }}>1</div>
          <div style={{ textAlign: "left" }}>
            <a href="/sample" className="team-link">
              Hussey College
            </a>
          </div>
        </div>
        <div className="pts">
          <div>3</div>
          <div>2</div>
          <div>1</div>
          <div>0</div>
          <div>7</div>
        </div>
      </div>
      <div className="boardBody qualification">
        <div className="team">
          <div style={{ width: "50px" }}>2</div>
          <div style={{ textAlign: "left" }}>
            <a href="/sample" className="team-link">
              Hussey College
            </a>
          </div>
        </div>
        <div className="pts">
          <div>3</div>
          <div>2</div>
          <div>1</div>
          <div>0</div>
          <div>7</div>
        </div>
      </div>
      <div className="boardBody">
        <div className="team">
          <div style={{ width: "50px" }}>3</div>
          <div style={{ textAlign: "left" }}>
            <a href="/sample" className="team-link">
              Hussey College
            </a>
          </div>
        </div>
        <div className="pts">
          <div>3</div>
          <div>2</div>
          <div>1</div>
          <div>0</div>
          <div>7</div>
        </div>
      </div>
      <div className="boardBody relegation">
        <div className="team">
          <div style={{ width: "50px" }}>4</div>
          <div style={{ textAlign: "left" }}>
            <a href="/sample" className="team-link">
              Hussey College
            </a>
          </div>
        </div>
        <div className="pts">
          <div>3</div>
          <div>2</div>
          <div>1</div>
          <div>0</div>
          <div>7</div>
        </div>
      </div>
    </>
  );
};

export const SinglePlayerComponent = () => {
  return (
    <>
      <div className="boardHeader">
        <div className="team">
          <div style={{ width: "50px" }}>#</div>
          <div>Name</div>
        </div>
        <div className="pts">
          <div>Mon</div>
          <div>Tue</div>
          <div>Wed</div>
          <div>Thur</div>
          <div>Fri</div>
          <div>Sat</div>
          <div>WTD</div>
          <div>MTD</div>
        </div>
      </div>
      <div className="boardBody campionship">
        <div className="team">
          <div style={{ width: "50px" }}>1</div>
          <div style={{ textAlign: "left" }}>
            <a href="/sample" className="team-link">
              Hussey College
            </a>
          </div>
        </div>
        <div className="pts">
          <div>3</div>
          <div>2</div>
          <div>1</div>
          <div>0</div>
          <div className="max">7</div>
          <div>7</div>
          <div>7</div>
          <div>7</div>
        </div>
      </div>
      <div className="boardBody qualification">
        <div className="team">
          <div style={{ width: "50px" }}>2</div>
          <div style={{ textAlign: "left" }}>
            <a href="/sample" className="team-link">
              Hussey College
            </a>
          </div>
        </div>
        <div className="pts">
          <div>3</div>
          <div>2</div>
          <div>1</div>
          <div className="max">0</div>
          <div>7</div>
          <div>7</div>
          <div>7</div>
          <div>7</div>
        </div>
      </div>
      <div className="boardBody">
        <div className="team">
          <div style={{ width: "50px" }}>3</div>
          <div style={{ textAlign: "left" }}>
            <a href="/sample" className="team-link">
              Hussey College
            </a>
          </div>
        </div>
        <div className="pts">
          <div className="max">3</div>
          <div>2</div>
          <div>1</div>
          <div>0</div>
          <div>7</div>
          <div>7</div>
          <div>7</div>
          <div>7</div>
        </div>
      </div>
      <div className="boardBody relegation">
        <div className="team">
          <div style={{ width: "50px" }}>4</div>
          <div style={{ textAlign: "left" }}>
            <a href="/sample" className="team-link">
              Hussey College
            </a>
          </div>
        </div>
        <div className="pts">
          <div>3</div>
          <div>2</div>
          <div>1</div>
          <div>0</div>
          <div>7</div>
          <div>7</div>
          <div>7</div>
          <div className="max">7</div>
        </div>
      </div>
    </>
  );
};
