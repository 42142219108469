import CashViewer from "../CashViewer";
import classes from "./CurrentPlanInfoCard.module.css";
import moment from "moment";

function CurrentPlanInfoCard({ onClickTopup, currentPlan }) {
  return (
    <div className={classes.cardWrapper}>
      <div className={classes.currentPlanInfor}>
        <div>
          <div className={classes.currentWrapper}>
            <span className={classes.currentPlanText}>Current:</span>
            <span className={classes.planName}>
              {currentPlan?.current_plan || "--"}
            </span>
          </div>
          <div className={classes.timeLeft}>{`Up until ${
            currentPlan?.expiry_date
              ? moment(currentPlan?.expiry_date, "DD-MM-YYYY HH:mm:ss").format(
                  "MMMM Do YYYY"
                )
              : "--/--"
          }`}</div>
        </div>

        <div className={classes.cardBottom}>
          <div>
            <button onClick={onClickTopup} className={classes.cancelBtn}>
              Topup Unit
            </button>
          </div>
        </div>
      </div>

      <div className={classes.planPricing}>
        <span className={classes.PlanAmout}>
          <CashViewer amount={currentPlan?.amount} />
        </span>
      </div>
    </div>
  );
}

export default CurrentPlanInfoCard;
