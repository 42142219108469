import style from "./NoteForm.module.css";
import { BsCheckLg } from "react-icons/bs";
import { MdClose, MdDelete, MdAddBox } from "react-icons/md";
import ColorPicker from "../ColorPicker";
import { useContext, useState } from "react";
import { toast } from "react-toastify";
import { NoteContext } from "../noteContext";

const NoteForm = ({ formAction, data, closeModal }) => {
  const { dispatch } = useContext(NoteContext);
  const [formData, setFormData] = useState(data);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [formTheme, setFormTheme] = useState(
    data?.note_style || {
      backgroundColor: "#fec46d",
      color: "#000",
    }
  );

  const maxCharactersPerPage = 532;
  const maxTitleCharacters = 103;
  const maxPages = 4;

  const themeHandler = (theme) => {
    setFormTheme(theme);
    setFormData((prev) => ({ ...prev, note_style: theme }));
  };

  const actionHandler = (action) => {
    const totalPages = formData.content.length;
    switch (action) {
      case "save": {
        formAction === "newNote"
          ? dispatch({ type: "ADD_NOTE", payload: formData })
          : dispatch({ type: "UPDATE_NOTE", payload: formData });
        closeModal();
        break;
      }
      case "cancel":
        closeModal();
        break;
      case "add_page": {
        if (totalPages < maxPages) {
          setFormData((prev) => ({
            ...prev,
            content: [...prev.content, ""],
          }));
          // update current page index to the new page created
          setCurrentPageIndex((prev) => totalPages);
          return;
        }
        toast.warning(
          `Maximum pages allowed by your current subscription plan is ${maxPages}`
        );
        break;
      }

      case "delete_page":
        const updatedPages = [...formData.content];
        // TODO:: call a modal to confirm delete
        // delete item at current page index but ensure that the note has more than 1 pages
        if (totalPages > 1) {
          updatedPages.splice(currentPageIndex, 1);
          if (currentPageIndex !== 0) {
            // switch the page to the previous page
            setCurrentPageIndex((prev) => prev - 1);
          }
          // update the form data
          setFormData((prev) => ({ ...prev, content: updatedPages }));
          return;
        }
        toast.warning(
          "Note has only one page. To delete the note, click on view"
        );
        break;

      default:
        break;
    }
  };

  const contentUpdateHandler = (e) => {
    const updatedContent = [...formData.content];
    const totalPages = updatedContent.length;
    const totalPageIndex = totalPages - 1;
    const userInput = e.target.value;

    // check if page has upto the maximum characters allowed
    if (userInput.length > maxCharactersPerPage) {
      // populate page with text up to the maximum character
      updatedContent[currentPageIndex] = userInput.slice(
        0,
        maxCharactersPerPage
      );
      // update form data
      setFormData((prev) => ({ ...prev, content: updatedContent }));

      // add new page if current page is the last page on the note and the total notes is less than maxPage
      if (currentPageIndex === totalPageIndex && totalPages < maxPages) {
        // create a new page with the remaining characters after the max value is reached.
        setFormData((prev) => ({
          ...prev,
          content: [...prev.content, userInput.slice(maxCharactersPerPage)],
        }));
        // update current page index
        setCurrentPageIndex((prev) => prev + 1);
      } else if (currentPageIndex < totalPageIndex)
        toast.info(
          "The content entered is more than what is allowed in a page. The excess has been truncated"
        );
      else if (totalPages >= maxPages)
        toast.info(
          `A maximum of ${maxPages} pages is allowed for a note and the content entered is more thant what is 
          allowed in a page. The excess has been truncated.`
        );

      // exit the function
      return;
    }
    updatedContent[currentPageIndex] = userInput;
    setFormData({ ...formData, content: updatedContent });
  };

  const titleUpdateHandler = (e) => {
    const userInput = e.target.value;
    if (userInput.length > maxTitleCharacters) {
      toast.warn(
        "Maximum characters allowed for note title has been exceeded."
      );
      return;
    }
    setFormData((prev) => ({ ...prev, title: userInput }));
  };

  return (
    <div className={style.root}>
      <div className={style.actions}>
        <i onClick={() => actionHandler("save")}>
          <BsCheckLg />
        </i>
        <i onClick={() => actionHandler("cancel")}>
          <MdClose />
        </i>
        <ColorPicker callbackFn={themeHandler} currentTheme={formTheme} />
      </div>
      <div className={style.form} style={{ ...formTheme }}>
        <textarea
          className={style.title}
          placeholder="Title"
          value={formData.title}
          onChange={titleUpdateHandler}
        />

        <div className={style.pageAction}>
          <span>[ Page {currentPageIndex + 1} ]</span>
          <span>
            <i
              className={style.delete}
              onClick={() => actionHandler("delete_page")}
            >
              <MdDelete />
            </i>
            <i
              className={style.delete}
              onClick={() => actionHandler("add_page")}
            >
              <MdAddBox />
            </i>
          </span>
        </div>

        <textarea
          className={style.content}
          placeholder="Content"
          value={formData.content[currentPageIndex]}
          onChange={contentUpdateHandler}
        />
        <div className={style.pages}>
          {formData.content.map((_, key) => (
            <i onClick={() => setCurrentPageIndex((prev) => key)} key={key}>
              {key + 1}
            </i>
          ))}
        </div>
      </div>
    </div>
  );
};
export default NoteForm;
