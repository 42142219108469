export const exerciseDummy = {
  template: "elipse",
  data: [
    {
      options: [
        {
          option: "",
          text: "",
          image: "",
        },
      ],
      question: { text: "", image: "" },
      correctAnswer: [],
      answerType: "multiAnswer",
    },
  ],
};

export const templates = [
  {
    name: "eclipse",
  },
];
