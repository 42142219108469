import React from "react";
import { useNavigate } from "react-router-dom";
import style from "./LibraryHome.module.css";
import "swiper/css";
import "swiper/css/navigation";
import useDiscover from "./hooks/useDiscover";
import SkeletonLoader from "../../../components/SkeletonLoader";
import Button from "../../../components/Button/Button";
import BookCard2 from "../../../components/BookCard2";
import { BsFillJournalBookmarkFill } from "react-icons/bs";
import { GiNotebook, GiBookshelf } from "react-icons/gi";
import { SiBookstack } from "react-icons/si";
import useFeaturedContent from "./hooks/useFeaturedContent";
import Img from "../../../components/Img";
import { RowLayout2 } from "../../Dashboard/layout/PageLayout";
import ApplicationFooter from "../../../components/ApplicationFooter";

const LibraryHome = () => {
  const navigate = useNavigate();
  const {
    allRecentUploads,
    fetchingRecentUploads,
    allTopRated,
    fetchingTopRated,
  } = useDiscover();

  const { featuredContent, isLoading } = useFeaturedContent();
  const _featuredContent =
    featuredContent && featuredContent.length && featuredContent[0];

  return (
    <div className={style.root}>
      <div className={style.top}>
        <p className={style.bookSampleHeader}>
          Library <span>Featured Content</span>
        </p>
        <div className={style.featuredTop}>
          <div className={style.solveProblem}>
            {isLoading ? (
              <div className={style.sectionContent}>
                <div className={style.featureImageSectLoader}>
                  <SkeletonLoader />
                </div>
                <div className={style.featureTextLoader}>
                  {new Array(8).fill("").map((_, index) => (
                    <SkeletonLoader
                      key={`skeleton-${index}`}
                      animation="wave"
                      width="98%"
                      height={70}
                      className={style.textLoader}
                    />
                  ))}
                </div>
              </div>
            ) : (
              <div className={style.sectionContent}>
                {_featuredContent?.title ? (
                  <>
                    <Img
                      className={style.courseCover}
                      wrapperClass={style.imageWrapper}
                      alt={_featuredContent?.title}
                      src={_featuredContent?.book_cover_url}
                    />
                    <div className={style.myQuestion}>
                      <h3 className={style.instruction2}>
                        {_featuredContent?.title}
                      </h3>
                      <p className={style.instruction3}>
                        {_featuredContent?.description}
                      </p>
                      {_featuredContent && (
                        <div className={style.startButtonWrap}>
                          <Button
                            className={style.courseBtn}
                            children="Start Course"
                            onClick={() =>
                              navigate(
                                `/library/course/${_featuredContent?._id}`
                              )
                            }
                          />
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  <p
                    className={style.noFeatureDisplay}
                    style={{ height: "210px" }}
                  >
                    No record available
                  </p>
                )}
              </div>
            )}
          </div>
          <div className={style.subMenu}>
            <div className={style.row}>
              <div
                className={style.catalogue}
                onClick={() => navigate("/library/catalogue")}
              >
                <SiBookstack />
                <p>Catalogue</p>
              </div>
              <div
                className={style.self}
                onClick={() => navigate("/library/myshelf")}
              >
                <GiBookshelf />
                <p>Shelf</p>
              </div>
            </div>
            <div className={style.row}>
              <div
                className={`${style.notes}`}
                onClick={() => navigate("/library/notes")}
              >
                <GiNotebook />
                <p>Notes</p>
              </div>
              <div
                className={`${style.bookmark}`}
                onClick={() => navigate("/library/bookmark")}
              >
                <BsFillJournalBookmarkFill />
                <p>Bookmark</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={style.discoverPage}>
        <div>
          <div className={style.mainContent}>
            <div className={style.topComponent}>
              <h3 className={style.header}>Top Rated</h3>
              <hr />
              {fetchingTopRated ? (
                <div className={style.loaderWrapper}>
                  {[...new Array(3).values()].map((_, index) => (
                    <SkeletonLoader
                      className={style.skeletonLogLoader}
                      key={index}
                    />
                  ))}
                </div>
              ) : !allTopRated?.length ? (
                <div className={style.emptyHistory}>
                  <p>No record available.</p>
                </div>
              ) : (
                <RowLayout2>
                  {allTopRated.map((item, index) => (
                    <BookCard2
                      key={index}
                      img={item?.book_cover_url}
                      title={item?.title}
                      rating={item?.rating}
                      subject={item?.subject?.name}
                      price={item?.price}
                      handleClick={() =>
                        navigate(`/library/course/${item?._id}`)
                      }
                    />
                  ))}
                </RowLayout2>
              )}
            </div>
            <div>
              <h3 className={style.header}>Recent Uploads</h3>
              <hr />
              {fetchingRecentUploads ? (
                <div className={style.loaderWrapper}>
                  {[...new Array(3).values()].map((_, index) => (
                    <SkeletonLoader
                      className={style.skeletonLogLoader}
                      key={index}
                    />
                  ))}
                </div>
              ) : !allRecentUploads?.length ? (
                <div className={style.emptyHistory}>
                  <p>No record available.</p>
                </div>
              ) : (
                <RowLayout2>
                  {allRecentUploads.map((item, index) => (
                    <BookCard2
                      key={index}
                      img={item?.book_cover_url}
                      title={item?.title}
                      rating={item?.rating}
                      subject={item?.subject?.name}
                      price={item?.price}
                      handleClick={() =>
                        navigate(`/library/course/${item?._id}`)
                      }
                    />
                  ))}
                </RowLayout2>
              )}
            </div>

            <div style={{ marginTop: "40px" }}>
              <h3 className={style.header}>Most Viewed Contents</h3>
              <hr />
              {isLoading ? (
                <div className={style.loaderWrapper}>
                  {[...new Array(3).values()].map((_, index) => (
                    <SkeletonLoader
                      className={style.skeletonLogLoader}
                      key={index}
                    />
                  ))}
                </div>
              ) : !featuredContent?.length ? (
                <div className={style.emptyHistory}>
                  <p>No record available.</p>
                </div>
              ) : (
                <RowLayout2>
                  {featuredContent.map((item, index) => (
                    <BookCard2
                      key={index}
                      img={item?.book_cover_url}
                      title={item?.title}
                      rating={item?.rating}
                      subject={item?.subject?.name}
                      price={item?.price}
                      handleClick={() =>
                        navigate(`/library/course/${item?._id}`)
                      }
                    />
                  ))}
                </RowLayout2>
              )}
            </div>
          </div>
        </div>
      </div>
      <ApplicationFooter />
    </div>
  );
};

export default LibraryHome;
