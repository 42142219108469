import classes from "./ManageCourseWrapper.module.css";
// import CourseUploadNavs from "../../components/CourseUploadNavs";
// import { pathSections } from "./constants";

function ManageCourseWrapper({ children, title, rightContent }) {
  return (
    <div className={classes.pageRoot}>
      {/* <div className={classes.navigationPaths}>
        <CourseUploadNavs paths={pathSections} />
      </div> */}
      <div className={classes.contentuploader}>
        <div className={classes.pageHeader}>
          <div className={classes.pageTitle}>{title}</div>
          <div>{rightContent}</div>
        </div>
        <div className={classes.linedivider} />
        <div style={{ flex: 1, overflowY: "scroll" }}>{children}</div>
      </div>
    </div>
  );
}

export default ManageCourseWrapper;
