import { useNavigate } from "react-router-dom";
import ManageCourseWrapper from "../../../layouts/ManageCourseWrapper";
import classes from "./CourseIntendedLearners.module.css";
import useCourseIntendedLearners from "./hooks/useCourseIntendedLearners";
import { MDBSpinner } from "mdb-react-ui-kit";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import SkeletonLoader from "../../../components/SkeletonLoader/SkeletonLoader";
import BookCatalogCard from "../../../components/BookCatalogCard/BookCatalogCard";
import { catalogBgColors, catalogColors } from "./constants";

function CourseIntendedLearners() {
  const navigate = useNavigate();
  const { categories, fetchingCategories } = useCourseIntendedLearners();

  return (
    <ManageCourseWrapper title="Select Course Intended learners">
      {fetchingCategories ? (
        <div className={classes.loaderWrapper}>
          <MDBSpinner className={classes.loader} role="status" />
        </div>
      ) : (
        <div className={classes.pageRoot}>
          <div className={classes.pageBody}>
            {fetchingCategories ? (
              <div className={classes.catalogItems}>
                {[...new Array(6).values()].map((_, index) => (
                  <SkeletonLoader className={classes.skeletonLogLoader} />
                ))}
              </div>
            ) : (
              <div className={classes.catalogItems}>
                {(categories || []).map((category, i) => {
                  const string = `${i}`;
                  const firstVal = string?.charAt(0);
                  return (
                    <BookCatalogCard
                      name={category?.name}
                      subcategory={category?.subcategories}
                      colorCode={catalogColors[firstVal]}
                      bgColorCode={catalogBgColors[firstVal]}
                      onSelectSubcategory={(item) =>
                        navigate(`/library/manage-course/${item?._id?.$oid}`)
                      }
                    />
                  );
                })}
              </div>
            )}
          </div>
          <div style={{ padding: 20 }}>
            <button
              className={classes.backHomeButton}
              onClick={() => navigate(-1)}
            >
              <MdOutlineKeyboardBackspace />
              <span>Back</span>
            </button>
          </div>
        </div>
      )}
    </ManageCourseWrapper>
  );
}

export default CourseIntendedLearners;
