import { DUMMY_DATA } from "./DUMMY_DATA";
import style from "./View.module.css";
import { useSelector, useDispatch } from "react-redux";
import {
  qUpdateSelectedOptions,
  qResultModal,
} from "../../redux/feature/bookQuestionsSlice";
import ResultView from "./ResultView";
import { cdDecryption } from "../../utils/helpers";

export const OptionView = ({ label, value, selected, selectFn }) => {
  return (
    <div
      className={`${style.option} ${selected === true ? style.selected : ""}`}
      onClick={() => selectFn()}
    >
      <div className={style.optionLabel}>{label}</div>
      <div className={style.optionValue}>{value}</div>
    </div>
  );
};

const QthemeView1 = ({ data, viewType }) => {
  const dispatch = useDispatch();

  // if data is empty, use dummy data for preview.

  const { questions } = data?.page_type ? data : DUMMY_DATA;

  const _questions =
    typeof questions === "string" ? cdDecryption(questions) : questions;

  const classes = viewType === "thumbnail" ? "xs" : "lg";

  const { position, selectedOptions, resultModal, pageId } = useSelector(
    (store) => store.bookQuestions
  );

  const handleSelectOption = (label) => {
    let userAnswer = label;
    if (_questions[position].optionType === "multiple") {
      let currentUserAns =
        selectedOptions[pageId]?.[position]?.userAnswer || "";

      if (!currentUserAns?.includes(label)) {
        currentUserAns += label;
        userAnswer = currentUserAns.split("").sort().join(""); // arrange the answer in alphabetical order
      } else {
        userAnswer = currentUserAns.replace(label, "");
      }
    }
    dispatch(
      qUpdateSelectedOptions({
        questionNumber: position,
        userAnswer,
        answer: _questions[position].answer,
      })
    );
  };

  return (
    // Best not to change the first div style because it is used to render
    //  thumnail and full page. The css can be changed to  meet your need

    <>
      <div className={`${style[classes]}`}>
        <ul className={style.indicators}>
          {_questions?.map((_, index) => (
            <li
              key={index}
              className={index <= position ? style.answered : ""}
            ></li>
          ))}
        </ul>
        <div className={style.page}>
          <div className={style.half}>
            <p className={style.pre}>
              {_questions?.[position]?.question?.text}
            </p>
            {_questions?.[position]?.question?.imageUrl && (
              <div>
                <img
                  src={_questions[position].question.imageUrl}
                  alt=""
                  className={style.img}
                />
              </div>
            )}
          </div>
          <div className={style.half}>
            <div className={style.optionGroup}>
              {_questions?.[position]?.options?.map((option, index) => (
                <OptionView
                  label={option.label}
                  value={option.value}
                  selected={selectedOptions[pageId]?.[
                    position
                  ]?.userAnswer?.includes(option.label)}
                  key={index}
                  selectFn={() => handleSelectOption(option.label)}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      {/* modal component to show summary of _questions answered. */}
      {resultModal && viewType !== "thumbnail" && (
        <ResultView
          closeModalFn={() => dispatch(qResultModal(false))}
          data={selectedOptions[pageId]}
          pass_mark={data.pass_mark}
        />
      )}
    </>
  );
};

export default QthemeView1;
