import { useEffect, useState } from "react";
import useCdReactQuery from "../../../../utils/hooks/useCdReactQuery";
import libraryService from "../../../../service/library.service";

function useFeaturedContent() {
  const { data, queryEndpoint, isLoading } = useCdReactQuery();
  const [featuredContent, setFeaturedContent] = useState({});

  useEffect(() => {
    (async () => {
      await queryEndpoint({
        endpointUrl: libraryService.mostViewedBooks+`?limit=10`,
      });
    })();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (data) {
      setFeaturedContent(data);
    }
  }, [data]);

  return {
    isLoading,
    featuredContent,
  };
}

export default useFeaturedContent;
