import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { setSplashLoader } from "../../redux/feature/splashLoaderSlice";
import { setCurrentUser } from "../../redux/feature/authSlice";
import { useDispatch } from "react-redux";
import authApplication from "../../service/auth";

function usePermission() {
  const location = useLocation();
  const history = useNavigate();
  const [showChildren, setShowChildren] = useState(false);

  const reduxDispatch = useDispatch();
  const email = localStorage.getItem("accessEmail");

  useEffect(() => {
    reduxDispatch(setSplashLoader(true));
    authApplication
      .getCurrentUser({ email })
      .then(({ data: userData }) => {
        if (
          !/admin/.test(location?.pathname) &&
          userData?.accountType !== "student"
        ) {
          history("/admin");
        }
        if (
          /admin/.test(location?.pathname) &&
          userData?.accountType === "student"
        ) {
          history("/dashboard");
        }
        reduxDispatch(setCurrentUser(userData));
        setShowChildren(true);
      })
      .catch(() => {
        reduxDispatch(setCurrentUser(null));
        setShowChildren(false);
        history("/login");
      })
      .finally(() => {
        reduxDispatch(setSplashLoader(false));
      });
  }, [email]); // eslint-disable-line

  return { showChildren };
}

export default usePermission;
