import moment from "moment";
import CryptoJS from "crypto-js";

export function whichHour() {
  const hours = new Date().getHours();
  const min = new Date().getMinutes(); // eslint-disable-line
  return hours < 12
    ? "Morning"
    : hours >= 12 && hours < 17
    ? "Afternoon"
    : "Evening";
}
export function dateFormate(date) {
  return new Date(date).getDay()
    ? moment(new Date(date)).format("DD MMM, YYYY")
    : "-- / --";
}

export function formatDate(dateString) {
  // Split the date and time
  const [datePart, timePart] = dateString?.split?.(" ");

  // Split the date part into day, month, and year
  const [day, month, year] = datePart?.split?.("-");

  // Create a new Date object
  const date = new Date(`${year}-${month}-${day}T${timePart}`);

  // Get the day name
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  return date.toLocaleDateString("en-US", options);
}

export function cashFormater(cash) {
  cash += "";
  const indexOfDecimal = cash.indexOf(".");

  cash =
    cash.substr(indexOfDecimal).length > 3
      ? cash.substr(0, indexOfDecimal + 3)
      : cash;
  cash += cash.indexOf(".") === -1 ? ".00" : "";
  cash = cash.substr(cash.indexOf(".")).length === 2 ? `${cash}0` : cash;
  return cash
    .replace(/(\d((?=(.{3})+$)))/g, "$1,")
    .replace(/,\./, ".")
    .replace(".00", "");
}

export function search(searchText, data, searchParams, options = {}) {
  if (searchText.length === 0) return data;

  return data.filter((item, index) =>
    searchParams.reduce((cum, searchParam) => {
      const searchParamHasTwinParam = /^\w+&&\w+$/g.test(searchParam);

      if (searchParamHasTwinParam) {
        const firstTwinParam = searchParam.match(/^\w+/g)?.[0] || "";
        const secondTwinParam = searchParam.match(/\w+$/g)?.[0] || "";

        return item[firstTwinParam] !== undefined &&
          item[secondTwinParam] !== undefined &&
          `${item[firstTwinParam]} ${item[secondTwinParam]}`
            .toLowerCase()
            .indexOf(searchText.toLowerCase()) !== -1
          ? true
          : cum;
      }
      return item[searchParam] !== undefined &&
        item[searchParam].toLowerCase().indexOf(searchText.toLowerCase()) !== -1
        ? true
        : cum;
    }, false)
  );
}

export function persistData(key, value) {
  localStorage.setItem(key, value);
  return { [key]: value };
}

export function getPersist(key) {
  return localStorage.getItem(key);
}

export function roundToDecimalPlaces(number, decimalPlaces) {
  const multiplier = 10 ** decimalPlaces;
  return Math.round(number * multiplier) / multiplier;
}

export const cdEncryption = (originalData) => {
  const secretKey = "mySecretKey123";
  const encrypted = CryptoJS.AES.encrypt(
    JSON.stringify(originalData),
    secretKey
  ).toString();
  return encrypted;
};

export const cdDecryption = (encryptedData) => {
  const secretKey = "mySecretKey123";
  try {
    const decryptedBytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
    const decryptedData = JSON.parse(
      decryptedBytes.toString(CryptoJS.enc.Utf8)
    );
    return decryptedData;
  } catch (error) {
    console.error("Error decrypting data:", error.message);
  }
};

export const initCaps = (str) => {
  return str.replace(/\b\w/g, (match) => match.toUpperCase());
};

// time complexity O( n log n)
export const stringSorting = (str) => {
  const uniqueCharacters = new Set();

  for (const c of str) {
    uniqueCharacters.add(c);
  }
  // convert set to array, and then convert to string.
  return Array.from(uniqueCharacters).join("");
};

// time complexity o(n) // Nice but not friendly
export const fastStringSorting = (str) => {
  const map = new Map();

  for (const c of str) {
    if (!map.has(c)) {
      map.set(c, 0);
    }
    map.set(c, map.get(c) + 1);
  }

  // Convert map to array.
  const sortedCharacters = [];
  const keys = Array.from(map.keys());
  keys.sort();

  for (const key of keys) {
    sortedCharacters.push(key);
  }

  return sortedCharacters.join("");
};

export const getInitials = (name) => {
  // Split the name into words
  const words = name.split(" ");
  // Extract the first letter of each word and concatenate
  const initials = words.map((word) => word.charAt(0)).join("");

  return initials;
};

export const getClassByID = (classes, id) => {
  const classData = classes?.find((item) => item.id === id);
  return classData?.name;
};

export const shortenLargeNumber = (dataVal) => {
  let figure = "";

  dataVal = `${dataVal}`;

  if (dataVal.length >= 4 && dataVal.length <= 6) {
    figure = "K";
  }
  if (dataVal.length > 6 && dataVal.length <= 9) {
    figure = "M";
  }
  if (dataVal.length > 9 && dataVal.length <= 12) {
    figure = "B";
  }
  if (dataVal.length > 12 && dataVal.length <= 15) {
    figure = "T";
  }

  dataVal =
    dataVal.length > 3
      ? dataVal.match(/^(.|.{1}|.{2}|.{3})(?=((\w{3})+$))/g)?.[0]
      : dataVal;

  return `${dataVal}${figure}`;
};

export function trend_percentage(
  previous_year_total = 0,
  percentage_growth = 0
) {
  const current_year_total =
    previous_year_total * (1 + percentage_growth / 100);
  return current_year_total;
}
