import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import libraryService from "../../../../service/library.service";
import { QUERY_KEY } from "../../../../utils/constants";
import useAppMutation from "../../../../utils/hooks/useAppMutation";

function useCreateNote() {
  const [noteTitle, setNoteTitle] = useState("");
  const [noteText, setNoteText] = useState("");
  const navigate = useNavigate();
  const { isLoading: isSaveing, mutateAsync: asycSaveNote } = useAppMutation(
    QUERY_KEY.saveNote,
    libraryService.saveNote
  );

  function saveNote() {
    if (!noteText) {
      toast.error("Note cannot be empty");
      return;
    }
    if (!noteTitle) {
      toast.error("Note Title cannot be empty");
      return;
    }
    asycSaveNote({
      filename: noteTitle,
      content: noteText,
    })
      .then(() => {
        toast.success("Note saved successfully");
        navigate("/library/notes");
        setNoteText("");
      })
      .catch(() => {});
  }

  return {
    noteTitle,
    noteText,
    isSaveing,
    setNoteTitle,
    setNoteText,
    saveNote,
    navigate,
  };
}

export default useCreateNote;
