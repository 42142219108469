import style from "./FullpageModal.module.css";
import { CloseIcon } from "../../../assets/icons";

const FullpageModal = ({ children, toggleModal, className = "" }) => {
  return (
    <div className={style.backdrop}>
      <div className={`${style.modal} ${className}`}>
        <div className={style.action}>
          <i onClick={() => toggleModal()}>
            <CloseIcon />
          </i>
        </div>
        {children}
      </div>
    </div>
  );
};
export default FullpageModal;
