import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userClass: { id: "", name: "" },
  subject: { id: "", name: "" },
  topic: { id: "", name: "" },
  difficulty: { id: "", name: "" },
  questionType: { id: true, name: "True" },
  hasParagraph: { id: false, name: "False" },
  form: {
    paragraph: "",
    questions: [], //[{ question: "", answer: "", imageURL: "", options: [] }]
  },
  forceUpdate: false, // this will keeps toggling to ensure the component is updated. true or false has no meaning here
};

export const questionBankSlice = createSlice({
  name: "question_bank",
  initialState,
  reducers: {
    updateState: (state, action) => ({ ...state, ...action.payload }),
    clearState: (state, action) => initialState,
    updateForm: (state, action) => ({
      ...state,
      form: { ...state.form, ...action.payload },
    }),
    addQuestion: (state, action) => ({
      ...state,
      form: {
        ...state.form,
        questions: [...state.form.questions, action.payload],
      },
    }),
    removeQuestion: (state, action) => ({
      ...state,
      form: {
        ...state.form,
        questions: state.form.questions.filter(
          (_, index) => index !== action.payload
        ),
      },
    }),
    updateQuestion: (state, action) => ({
      ...state,
      form: {
        ...state.form,
        questions: state.form.questions.map((item, index) => {
          if (index === action.payload.index) {
            return action.payload.data;
          }
          return item;
        }),
      },
    }),
  },
});

export const {
  updateState,
  updateForm,
  addQuestion,
  removeQuestion,
  updateQuestion,
  clearState,
} = questionBankSlice.actions;

export default questionBankSlice.reducer;
