import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import classes from "./LeaderBoard.module.css";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import {
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
} from "mdb-react-ui-kit";
import {
  SinglePlayerComponent,
  TeamComponent,
} from "../../../components/LeaderCard/TeamComponent";

function getPreviousDay(date = new Date()) {
  const previous = new Date(date.getTime());
  previous.setDate(date.getDate() - 1);
  return previous;
}
function getNextDay(date = new Date()) {
  const Today = moment(new Date()).format("YYYY-MM-DD");
  const CurrentDate = moment(new Date(date)).format("YYYY-MM-DD");
  const isAfter = CurrentDate >= Today;
  if (!isAfter) {
    const next = new Date(date.getTime());
    next.setDate(date.getDate() + 1);
    return next;
  }
  // return;
}

const LeaderBoard = () => {
  const [value, setValue] = useState(new Date());
  const [showCalender, setShowCalender] = useState(false);

  const ref = useRef();

  useEffect(() => {
    /**
     * close when clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowCalender(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  const [basicActive, setBasicActive] = useState("tab1");

  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }

    setBasicActive(value);
  };

  return (
    <>
      <div className={classes.leaderboardcontainer}>
        <hr />
        <div className={classes.calenderWrapper}>
          <div className={classes.calender}>
            <AiOutlineLeft
              onClick={() => {
                const previousDay = getPreviousDay(value);
                setValue(previousDay);
              }}
              className={classes.control}
            />
            <AiOutlineRight
              onClick={() => {
                const nextDay = getNextDay(value);
                if (nextDay) {
                  setValue(nextDay);
                }
              }}
              className={classes.control}
            />
            <div
              onClick={() => {
                setShowCalender(!showCalender);
              }}
              className={classes.month}
            >
              {moment(value).format("MMM Do YYYY")}
            </div>
          </div>
          <div style={{ position: "relative" }}>
            <div
              ref={ref}
              className={`${classes.calenderView} ${
                showCalender ? classes.showCalender : undefined
              }`}
            >
              <Calendar
                onClickDay={(value, event) => {
                  setValue(value);
                  setShowCalender(!showCalender);
                }}
                maxDate={new Date()}
                onChange={setValue}
                value={value}
              />
            </div>
          </div>
        </div>
        <div className={classes.board}>
          <MDBTabs className={`mb-3 ${classes.pillMenu}`}>
            <MDBTabsItem>
              <MDBTabsLink
                onClick={() => handleBasicClick("tab1")}
                active={basicActive === "tab1"}
                // className={classes.tab}
              >
                Edu League
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem>
              <MDBTabsLink
                onClick={() => handleBasicClick("tab2")}
                active={basicActive === "tab2"}
              >
                Charity Cup
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem>
              <MDBTabsLink
                onClick={() => handleBasicClick("tab3")}
                active={basicActive === "tab3"}
              >
                Pace Setters
              </MDBTabsLink>
            </MDBTabsItem>
          </MDBTabs>

          <MDBTabsContent>
            <MDBTabsPane show={basicActive === "tab1"}>
              <div className="mx-4 mb-2">
                <button
                  className="btn btn-dark btn-sm me-2 px-2 py-0"
                  style={{ borderRadius: "0" }}
                >
                  Table
                </button>
                <button
                  className="btn btn-outline-dark btn-sm px-2 py-0"
                  style={{ borderRadius: "0" }}
                >
                  Matches
                </button>
              </div>
              <TeamComponent />
            </MDBTabsPane>
            <MDBTabsPane show={basicActive === "tab2"}>
              <div className="mx-4 mb-2">
                <button
                  className="btn btn-dark btn-sm me-2 px-2 py-0"
                  style={{ borderRadius: "0" }}
                >
                  Table
                </button>
                <button
                  className="btn btn-outline-dark btn-sm px-2 py-0"
                  style={{ borderRadius: "0" }}
                >
                  Matches
                </button>
              </div>
              <TeamComponent />
            </MDBTabsPane>
            <MDBTabsPane show={basicActive === "tab3"}>
              <SinglePlayerComponent />
            </MDBTabsPane>
          </MDBTabsContent>
        </div>
      </div>
      {/* <Pagination2 /> */}
    </>
  );
};

export default LeaderBoard;
