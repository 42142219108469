import { useState } from "react";
import style from "./BookDetailsOM.module.css";
import { BsFillBookmarkFill, BsBookmark } from "react-icons/bs";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { getBooktype } from "./utils";
// import { getBooktype, getBooktypeIcon } from "./utils";
import { useNavigate } from "react-router-dom";
import Img from "../Img/Img";
import StarRating from "../StarRating/StarRating";
import useBookDetails from "./hook/useBookDetails";
import Tooltip from "../Tooltip";
import Reader from "../BookReader/Reader";
import { FullpageModal } from "../Modal";
import NoteModal from "../../pages/library/Notes/NoteModal";
import NoteForm from "../../pages/library/Notes/form/NoteForm";
import { AiOutlineExpand } from "react-icons/ai";
import CashViewer from "../CashViewer";
import PaymentGateways from "../PaymentGateways";
import Button from "../Button/Button";

const BookDetailsOM = ({ data, fetchCourseDetails }) => {
  const { book_details = {}, notes = [], reviews = [] } = data || {};
  const total_reviews = reviews?.length;
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [noteToggleModal, setNoteToggleModal] = useState(false);
  const [isGatewayModalOpen, setIsGatewayModalOpen] = useState(false);

  const {
    isBorrowed,
    isBookmarked,
    isValidatingPayment,
    isInitiatingPayment,
    updateBorrowedStatus,
    updateBookmark,
    payForBook,
  } = useBookDetails({
    borrowed: book_details?.isBorrowed,
    bookmarked: book_details?.isBookMarked,
    book_id: book_details?._id,
    book_details,
    fetchCourseDetails,
  });

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };
  const note = !!notes?.length && notes[0];

  function noteModalHandler() {
    setNoteToggleModal((prev) => !prev);
  }

  return (
    <>
      <div className={style.book__container}>
        <div className={style.book__left}>
          <Img
            className={style.courseCover}
            src={book_details?.book_cover_url}
            alt="book-cover"
          />
          <div className={style.back__btn} onClick={() => navigate(-1)}>
            <FaLongArrowAltLeft />
          </div>
        </div>
        <div className={style.book__right}>
          <div className={style.book__right__top}>
            {/* <div>
              {book_details && getBooktypeIcon(book_details?.book_type)}
              <span> {book_details?.book_type}</span>
            </div> */}
            <div></div>

            <div className="d-flex gap-3 items-center">
              {note && (
                <Tooltip data="View note">
                  <i onClick={noteModalHandler}>
                    <AiOutlineExpand />
                  </i>
                </Tooltip>
              )}
              <span onClick={() => updateBookmark()}>
                {(
                  isBookmarked === undefined
                    ? book_details?.isBookMarked
                    : isBookmarked
                ) ? (
                  <Tooltip data="Remove Bookmark">
                    <BsFillBookmarkFill className={style.bookmarked} />
                  </Tooltip>
                ) : (
                  <Tooltip data="Add Bookmark">
                    <BsBookmark className={style.notBookmarked} />
                  </Tooltip>
                )}
              </span>
            </div>
          </div>
          <div className={style.book__right__content}>
            <h4 className={style.title}>{book_details?.title}</h4>
            <div className={style.subtitle}>
              <p> {book_details?.total_pages || 0} pages </p>
            </div>
            <p>{book_details?.description}</p>
            {
              // (isBorrowed === undefined
              //   ? book_details?.isBorrowed
              //   : isBorrowed) && (
              book_details?.borrow_details?.borrowed_date &&
              book_details?.borrow_details?.return_date ? (
                <div className={style.root_borrow_details}>
                  <hr />
                  <div className={style.borrow_details}>
                    <p>
                      Borrowed Date{" "}
                      {book_details?.borrow_details?.borrowed_date}
                    </p>
                    <p>
                      Returned Date {book_details?.borrow_details?.return_date}
                    </p>
                  </div>
                  <hr />
                </div>
              ) : (
                ""
              )
              // )
            }
          </div>
          <div className={style.rating}>
            <i>
              <StarRating rating={book_details?.rating} className={""} />
            </i>{" "}
            <span>{` [${total_reviews} ${
              total_reviews > 1 ? "reviews" : "review"
            }]`}</span>
          </div>
          <div className={`${style.rating} ${style.bottom_40}`}>
            <span>
              By: <b>{book_details?.publisher}</b>
            </span>
          </div>

          {book_details?._id && (
            <>
              {!!book_details?.price && (
                <div className={style.book_price}>
                  <CashViewer amount={book_details?.price} />
                </div>
              )}
              {!!book_details?.price ? (
                <div className={style.book__right__bottom}>
                  {book_details?.paid ? (
                    <>
                      <button
                        className={style.right__btn}
                        onClick={() => toggleModal()}
                      >
                        {getBooktype(book_details?.book_type)}
                      </button>
                    </>
                  ) : (
                    <Button
                      onClick={() => setIsGatewayModalOpen(true)}
                      className={style.right__btn}
                      isLoading={isValidatingPayment}
                    >
                      PAY NOW
                    </Button>
                  )}
                </div>
              ) : (
                <div className={style.book__right__bottom}>
                  {(
                    isBorrowed === undefined
                      ? book_details?.isBorrowed
                      : isBorrowed
                  ) ? (
                    <>
                      <button
                        className={`${style.right_btn} ${style.right__btn_danger}`}
                        onClick={() => updateBorrowedStatus(true)} // true switch the updateBorrowedStatu to return book
                      >
                        RETURN BOOK
                      </button>
                      <button
                        className={style.right__btn}
                        onClick={() => toggleModal()}
                      >
                        {getBooktype(book_details?.book_type)}
                      </button>
                    </>
                  ) : (
                    <button
                      className={style.right__btn}
                      onClick={() => updateBorrowedStatus(false)}
                    >
                      Borrow
                    </button>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {modalOpen && (
        <FullpageModal toggleModal={toggleModal}>
          <Reader
            pages={book_details?.pages}
            note={!!notes?.length && notes?.[0]}
            bookTitle={book_details?.title}
            bookId={book_details?._id}
          />
        </FullpageModal>
      )}
      {noteToggleModal && (
        <NoteModal>
          <NoteForm
            formAction={"UPDATE_NOTE"}
            data={note}
            closeModal={noteModalHandler}
          />
        </NoteModal>
      )}
      {isGatewayModalOpen && (
        <NoteModal>
          <PaymentGateways
            book_details={book_details}
            onCancel={() => setIsGatewayModalOpen(!isGatewayModalOpen)}
            onPay={payForBook}
            isLoading={isInitiatingPayment}
          />
        </NoteModal>
      )}
    </>
  );
};

export default BookDetailsOM;
