import { useState } from "react";
import {
  DeleteIcon,
  EditIcon,
  PublishIcon,
  UnPublishedIcon,
} from "../../../../assets/icons";
import Tooltip from "../../../../components/Tooltip/Tooltip";
import useCdReactQuery from "../../../../utils/hooks/useCdReactQuery";
import classes from "./ReviewsCard.module.css";
import adminService from "../../../../service/admin.service";
import SkeletonLoader from "../../../../components/SkeletonLoader";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ConfirmDialog from "../../../../components/ConfirmationDialogCD/ConfirmDialog";
import { Loader } from "../../../../components/Modal";

function ReviewsCard({ fechReview, reviewDate, isLoadingReview }) {
  const navigate = useNavigate();
  const { queryEndpoint: onDeleteReview } = useCdReactQuery();
  const { queryEndpoint: managePublisher, isLoading: isUpdatingPublish } =
    useCdReactQuery();
  const [isDelete, setIsDelete] = useState({
    reviewId: null,
    isDeleting: false,
  });

  async function deleteReview() {
    setIsDelete({ ...isDelete, isDeleting: false });
    const deleteResponse = await onDeleteReview({
      endpointUrl: adminService.deleteReview(isDelete?.reviewId),
      httpMethod: "DELETE",
    });
    if (deleteResponse) {
      toast.success("review deleted successfully");
      fechReview();
    }
    setIsDelete({ reviewId: null, isDeleting: false });
  }

  async function onPublish(review) {
    try {
      await managePublisher({
        endpointUrl: adminService.publishReview,
        httpMethod: "PATCH",
        body: {
          is_published: !review?.is_published,
          review_id: review?._id,
        },
      });
      toast.success(
        review?.is_published
          ? "Review unpublished successfully"
          : "Review published successfully"
      );
      fechReview();
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      <div className={classes.row}>
        {isLoadingReview ? (
          <div className={classes.row}>
            {[...new Array(4).values()].map((_, index) => (
              <SkeletonLoader
                className={classes.skeletonLogLoader}
                key={index}
              />
            ))}
          </div>
        ) : (
          (reviewDate?.course_reviews || []).map((item, index) => (
            <div className={classes.questionCard} key={index}>
              <div className={classes.actions}>
                <span
                  onClick={() =>
                    navigate(`/admin/examprep/review/edit/${item?._id}`)
                  }
                >
                  <Tooltip data="edit" classes={classes.tooltip}>
                    <EditIcon />
                  </Tooltip>
                </span>
                <span
                  onClick={() => {
                    setIsDelete({ reviewId: item?._id, isDeleting: true });
                  }}
                >
                  <Tooltip data="delete" classes={classes.tooltip}>
                    <DeleteIcon />
                  </Tooltip>
                </span>
                <span
                  onClick={() => {
                    onPublish(item);
                  }}
                >
                  <Tooltip
                    data={item.is_published ? "Unpublish" : "Publish"}
                    classes={classes.tooltip}
                  >
                    {item.is_published ? <UnPublishedIcon /> : <PublishIcon />}
                  </Tooltip>
                </span>
              </div>
              <div
                className={`${classes.row} ${classes.reiewcard_header_container}`}
              >
                <h5 className={classes.topic}>{item?.topic?.name}</h5>
              </div>

              <div className={`${classes.row} ${classes.review_info}`}>
                <div className={`${classes.row} ${classes.review_content}`}>
                  {item?.subject?.name}
                </div>
                <div className={`${classes.row} ${classes.review_content}`}>
                  {item?.subcategory?.name}
                </div>
              </div>
            </div>
          ))
        )}
      </div>
      {isUpdatingPublish && <Loader />}
      {isDelete?.isDeleting && (
        <ConfirmDialog
          confirm={async () => {
            deleteReview();
          }}
          cancel={() => setIsDelete({ reviewId: null, isDeleting: false })}
          message={"Click continue to DELETE this review"}
        />
      )}
    </>
  );
}

export default ReviewsCard;
