import classNames from "classnames";
import { VscTriangleRight } from "react-icons/vsc";
import Button from "../Button";
import SkeletonLoader from "../SkeletonLoader";
import usePlanSubscription from "./hooks/usePlanSubscription";
import classes from "./PlanSubscription.module.css";
import CashViewer from "../CashViewer";

function PlanSubscription({ onSuccess, currentPlan, onClose, type }) {
  const {
    state,
    plans: allPlans,
    isMakingPayment,
    fetchingPlans,
    handsub,
    dispatch,
  } = usePlanSubscription({ currentPlan, onSuccess, type });
  const { selectedPlan } = state;
  const plans =
    type === "upgrade"
      ? allPlans.filter((plan) => !/free/.test(plan?.name))
      : allPlans;

  return (
    <div className={classes.mainRoot}>
      <div className={classes.overlayBackground} />
      <div className={classes.rootWrapper}>
        <div className={classes.pageBody}>
          <div className={classes.allPlansSection}>
            {fetchingPlans ? (
              <div>
                {[...new Array(3).values()].map((_, index) => (
                  <SkeletonLoader className={classes.plansLoader} />
                ))}
              </div>
            ) : (
              <div>
                {plans.map((item) => (
                  <div
                    className={classNames(
                      classes.planCard,
                      item?.id === selectedPlan?.id
                        ? classes.activePlan
                        : undefined
                    )}
                    onClick={() => {
                      dispatch({ selectedPlan: item });
                    }}
                  >
                    <div className={classes.planName}>{item?.name} Plan</div>
                    <div>
                      <span className={classes.planPrice}>
                        <CashViewer amount={item?.amount} />
                      </span>
                      /
                      <span className={classes.planDuration}>
                        {`${item?.total_days_in_plan} days`}
                      </span>
                    </div>
                    <VscTriangleRight
                      className={classNames(
                        classes.pointer,
                        item?.id === selectedPlan?.id ? "d-block" : undefined
                      )}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className={classes.selectedPlanSection}>
            <div className={classes.selectedPlanInfo}>
              {selectedPlan?.id ? (
                <div className={classes.selectedPlanWrapper}>
                  <div className={classes.selectedPlanName}>
                    {selectedPlan?.name} Plan
                  </div>
                  <div
                    className={classes.selectedPlanUnits}
                  >{`${selectedPlan?.credit_units} Credit units for`}</div>
                  <div className={classes.selectedPlanPrice}>
                    <span>
                      <CashViewer amount={selectedPlan?.amount} />
                    </span>
                    /<span>{`${selectedPlan?.total_days_in_plan} days`}</span>
                  </div>
                </div>
              ) : (
                <div style={{ opacity: ".7" }}>
                  <h3>👈 Select a plan </h3>
                </div>
              )}
            </div>

            <div className={classes.acctionBtnWrapper}>
              <Button onClick={onClose} className={classes.cancelPlanBtn}>
                Cancel
              </Button>
              <Button
                disabled={!selectedPlan?.id}
                onClick={handsub}
                className={classes.selectPlanBtn}
                isLoading={isMakingPayment}
              >
                Proceed
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PlanSubscription;
