import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setSplashLoader } from "../../../../redux/feature/splashLoaderSlice";
import authApplication from "../../../../service/auth";
import { timeout } from "../../../../utils/constants";
import { setCurrentUser } from "../../../../redux/feature/authSlice";

function useLibraryLayout() {
  const history = useNavigate();
  const [showChildren, setShowChildren] = useState(false);
  const reduxDispatch = useDispatch();
  const email = localStorage.getItem("accessEmail");
  const location = useLocation();
  const [timeOutHandler, setTimeoutHandler] = useState(null);

  useEffect(() => {
    reduxDispatch(setSplashLoader(true));
    authApplication
      .getCurrentUser({ email })
      .then(({ data: userData }) => {
        reduxDispatch(setCurrentUser(userData));
        setShowChildren(true);
      })
      .catch(() => {
        reduxDispatch(setCurrentUser({}));
        setShowChildren(false);
        history("/login");
      })
      .finally(() => {
        reduxDispatch(setSplashLoader(false));
      });
  }, [email]); // eslint-disable-line

  const initSessionTimeout = () => {
    clearTimeout(timeOutHandler);

    const handler = setTimeout(() => {
      history("/login", {
        state: {
          reason: "unauthorized",
          from: location.pathname,
        },
      });
    }, timeout);

    setTimeoutHandler(handler);
  };

  const clearSessionTimeout = () => initSessionTimeout();

  return {
    showChildren,
    history,
    clearSessionTimeout,
    setShowChildren,
  };
}

export default useLibraryLayout;
