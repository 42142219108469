import { useEffect, useState } from "react";
import useCdReactQuery from "../../../utils/hooks/useCdReactQuery";
import authApplication from "../../../service/auth";

function useCategories() {
  const { data, queryEndpoint, isLoading } = useCdReactQuery();
  const [categories, setCategories] = useState([]);
  const [studentClasses, setStudentClasses] = useState({});

  useEffect(() => {
    (async () => {
      await queryEndpoint({
        endpointUrl: authApplication.categories,
      });
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data) {
      const extractedStudentClasses = {};
      // This logic helps to extract data for categories in this format [{name, id},{name,id}] and
      // studentClasses in this format {categoryName:[{name, id}], categoryClasses:[{name, id}]} for easy retrieval
      console.log("data ", data);
      const _extractedCategories = data?.category?.map((item) => {
        extractedStudentClasses[item.name] = [
          ...item?.subcategories?.map((cls) => ({
            name: cls.name,
            id: cls.id,
          })),
        ];
        return {
          name: item.name,
          id: item.id,
        };
      });
      setCategories(_extractedCategories);
      setStudentClasses(extractedStudentClasses);
    }
  }, [data]);

  return {
    isLoading,
    categories,
    studentClasses,
  };
}

export default useCategories;
