import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../Button";
import classes from "./SwitchUploads.module.css";

function SwitchUploads({  buttonData }) {
  const location = useLocation();
  const navigate = useNavigate();
  const isActive = (regexPath) => {
    return regexPath.test(location.pathname)
  }
  return (
    <div className={classes.switchWrapper}>
      <div className={classes.buttContainer}>
       

        {buttonData.map((button) => (
          <Button
            onClick={() => navigate(button.pathName)}
            className={isActive(button.regexPath)?`${classes.overlay} ${classes.button} `: classes.button}
            style={{ color: isActive(button.regexPath) ? "white" : "" }}
            variant={isActive(button.regexPath) ? "contained" : ""}
          >
            {button.title}
          </Button>
        ))}
      
      </div>
    </div>
  );
}

export default SwitchUploads;
