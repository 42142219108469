import React, { useContext } from "react";
import { LineChart, Line, XAxis, CartesianGrid } from "recharts";
import style from "./AssessmentDetails.module.css";
import ExamPrepStorage from "../../storage/ExamPrepStorage";
import { BsClockFill, BsLink45Deg } from "react-icons/bs";
import { useBucketDetail } from "../hooks/useBucketDetail";
import { toast } from "react-toastify";

export const AssessmentDetails = () => {
  const {
    updateStorage,
    assessmentModalToggle,
    duration,
    title,
    subject,
    _id,
    preview_data = [],
    submission,
  } = useContext(ExamPrepStorage);

  const {
    bucket: { questions = [] },
  } = useBucketDetail(_id);

  const emptyQuestion = () => {
    toast.error("No question associated to this bucket");
  };

  const performanceTrend = submission?.scores_list?.map((item) => ({
    // score: item,
    score: Math.round((parseInt(item, 10) / questions.length) * 100),
  }));

  return (
    <div className={style.rootPage}>
      <div className={style.topBar}>
        <h3>ctrLearn</h3>
        <span>
          <BsClockFill /> {duration} min
        </span>
      </div>
      <h2 className={style.title}>{title || ""}</h2>
      <p className={style.label}>{subject?.name}</p>
      <div className={style.content}>
        <LineChart
          width={500}
          height={200}
          data={performanceTrend}
          className="exam-graph"
        >
          <XAxis
            dataKey="score"
            axisLine={false}
            tickLine={false}
            interval="preserveStartEnd"
            tickFormatter={(tick) => `${tick}%`} // Format ticks as percentages
          />
          <CartesianGrid strokeDasharray="3 3" />
          <Line
            type="monotone"
            dataKey="score"
            stroke="#8884d8"
            strokeWidth={4}
          />
        </LineChart>
      </div>
      <p className={style.label}>Performance Trend (last 6 attempts)</p>
      {!preview_data.length && (
        <div className={style.aside}>
          <button
            onClick={() => updateStorage({ modalPage: "PerformanceByTopics" })}
          >
            <BsLink45Deg /> View performance by topics
          </button>
        </div>
      )}
      <div className={style.footer}>
        <button
          onClick={() =>
            updateStorage({ assessmentModalToggle: !assessmentModalToggle })
          }
        >
          CANCEL
        </button>
        <button
          onClick={() =>
            questions.length || preview_data.length
              ? updateStorage({ modalPage: "Assessment" })
              : emptyQuestion()
          }
        >
          START
        </button>
      </div>
    </div>
  );
};
