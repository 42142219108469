import classes from "./ManageCourse.module.css";
import useManageCourse from "./hooks/useManageCourse";
import InputField from "../../../components/InputField";
import Dropdown from "../../../components/Dropdown/Dropdown";
import Button from "../../../components/Button/Button";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { useNavigate } from "react-router-dom";

function ManageCourse() {
  const navigate = useNavigate();
  const {
    subject,
    fetchSubject,
    state,
    topics,
    uploadingData,
    dispatch,
    addNewLine,
    removeLine,
    onChangeInput,
    onSave,
  } = useManageCourse();
  const { selectedSubject, title, topicRow } = state;
  return (
    <div className={classes.pageRoot}>
      <div className={classes.mainPageBody}>
        <div>
          <div className={classes.inputRow}>
            <div className={classes.inputColumn}>
              <div className={classes.inputLabel}>Title</div>
              <InputField
                value={title}
                onChange={({ target }) => dispatch({ title: target.value })}
              />
            </div>
            <div className={classes.inputColumn}>
              <div className={classes.inputLabel}>Subject</div>
              <Dropdown
                options={subject}
                isLoading={fetchSubject}
                value={selectedSubject?.name}
                onChange={(value) => {
                  dispatch({ selectedSubject: value });
                }}
              />
            </div>

            <div className={classes.inputColumn}>
              <div className={classes.inputLabel}>Course Time</div>
              <InputField />
            </div>
          </div>
          <div className={classes.inputRow}>
            <div className={classes.inputColumn}>
              <div className={classes.inputLabel}>Course Description</div>
              <InputField />
            </div>
          </div>
        </div>
        <div style={{ marginTop: "2em" }}>
          {topicRow.map((item, index) => (
            <div className={classes.inputRow}>
              <div style={{ width: 300 }}>
                <div className={classes.inputLabel}>Topic</div>
                <Dropdown
                  options={topics}
                  value={item?.topic?.name}
                  onChange={(value) => onChangeInput(value, "topic", index)}
                />
              </div>
              <div className={classes.inputColumn}>
                <div className={classes.inputLabel}>Description</div>
                <InputField
                  value={item?.description}
                  onChange={({ target }) =>
                    onChangeInput(target.value, "description", index)
                  }
                />
              </div>
              <div className={classes.actionSection}>
                {topicRow?.length - 1 === index && (
                  <button onClick={addNewLine} className={classes.addButton}>
                    +
                  </button>
                )}
                {topicRow?.length !== 1 && (
                  <button
                    onClick={() => removeLine(index)}
                    className={classes.deleteRowButton}
                  >
                    x
                  </button>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={classes.footerActionWrap}>
        <button onClick={() => navigate(-1)} className={classes.backButton}>
          <MdOutlineKeyboardBackspace className={classes.backIcon} />
          <span>Back</span>
        </button>
        <Button
          className={classes.saveButton}
          isLoading={uploadingData}
          onClick={onSave}
        >
          Save Course
        </Button>
      </div>
    </div>
  );
}

export default ManageCourse;
