import { useDispatch } from "react-redux";
import subscriptionService, {
  URL,
} from "../../../service/subscription.service";
import { QUERY_KEY } from "../../../utils/constants";
import { showConfirmationPopup } from "../../../redux/feature/confrimationPopupSlice";
import useAppMutation from "../../../utils/hooks/useAppMutation";
import { setIsloading } from "../../../redux/feature/progressLoaderSlice";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import useCdReactQuery from "../../../utils/hooks/useCdReactQuery";
import { useSelector } from "react-redux";
import env from "../../../env";

function useSubscription() {
  const reduxDispatch = useDispatch();
  const [subHistory, setSubHistory] = useState([]);
  const [currentPlan, setCurrentPlan] = useState({});
  const { user } = useSelector(({ currentUser }) => currentUser);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paymentReference = queryParams.get("reference");
  const planId = queryParams.get("plan_id");
  const { queryEndpoint: validatePayment, isLoading: validatingPayment } =
    useCdReactQuery();
  const { queryEndpoint } = useCdReactQuery();
  const { queryEndpoint: handleSubHistory, isLoading: fetchingSubLog } =
    useCdReactQuery();

  async function fetchCurrentPlan() {
    try {
      const response = await queryEndpoint({
        endpointUrl: URL.getUserCurrentPlan,
        httpMethod: "GET",
      });
      setCurrentPlan(response?.data || {});
    } catch (error) {}
  }

  async function fetchSubscriptionHistory() {
    try {
      const response = await handleSubHistory({
        endpointUrl: URL.getSubscriptionHistory,
        httpMethod: "GET",
      });
      setSubHistory(response?.data || []);
    } catch (error) {}
  }

  useEffect(() => {
    fetchSubscriptionHistory();
    fetchCurrentPlan();
  }, []); // eslint-disable-line

  const { mutateAsync: asycCancelPlan } = useAppMutation(
    QUERY_KEY.cancelSubscription,
    subscriptionService.cancelSubscription
  );

  function onCancelSub() {
    if (!currentPlan?.current_plan_id) {
      toast.error("No Active Subscription");
      return;
    }
    reduxDispatch(
      showConfirmationPopup({
        open: true,
        headerText: "Cancel Subscription",
        bodyText: (
          <p>
            You are about to cancel <b>your current subscription</b>
          </p>
        ),
        onConfirm: () => {
          reduxDispatch(showConfirmationPopup({ open: false }));
          reduxDispatch(setIsloading(true));
          asycCancelPlan({})
            .then(() => {
              toast.success("subscription cancelled successfully");
              fetchCurrentPlan();
            })
            .catch(({ msg }) => {
              toast.error(msg);
            })
            .finally(() => {
              reduxDispatch(setIsloading(false));
            });
        },
        buttonText: "Proceed",
      })
    );
  }

  async function validatePlanPayment({
    plan_id,
    reference_number,
    revalidate = false,
  }) {
    try {
      const response = await validatePayment({
        endpointUrl: URL.verifySubscription,
        httpMethod: "POST",

        body: {
          reference_number,
          email: user?.email,
          plan_id,
          revalidate,
        },
      });
      if (response) {
        fetchSubscriptionHistory();
        fetchCurrentPlan();
      }
    } catch (error) {}
  }

  const { queryEndpoint: currentPlanTopup, isLoading } = useCdReactQuery();

  async function handleUnitTopup() {
    try {
      await currentPlanTopup({
        endpointUrl: URL.topupUnit,
        httpMethod: "PUT",
        body: {
          plan_id: currentPlan?.current_plan_id,
          callback_url: `${env.APP_URL}/subscription?plan_id=${currentPlan?.current_plan_id}`,
          currency: "NGN",
          email: user?.email,
        },
      });
    } catch (error) {
      if (error?.auth_url) {
        window.location.href = error?.auth_url;
        return;
      }
      toast.error(error?.detail);
    }
  }

  useEffect(() => {
    if (paymentReference && user?.email)
      validatePlanPayment({
        plan_id: planId,
        reference_number: paymentReference,
      });
  }, [paymentReference, user?.email]); // eslint-disable-line

  async function revalidatePayment({
    plan_id,
    reference_number,
    revalidate = false,
  }) {
    await validatePlanPayment({ plan_id, reference_number, revalidate });
  }

  return {
    subHistory,
    validatingPayment,
    isLoading,
    fetchingSubLog,
    handleUnitTopup,
    currentPlan,
    onCancelSub,
    revalidatePayment,
    fetchCurrentPlan,
  };
}

export default useSubscription;
