import EventEmitter from "events";
import { stripeLogo } from "../assets/image";

export const navPaths = [
  {
    name: "Home",
    path: "home",
  },
  {
    name: "About",
    path: "about",
  },
  {
    name: "Features",
    path: "features",
  },
  // {
  //   name: "Subscription",
  //   path: "subscriptions",
  // },
  {
    name: "Contacts",
    path: "contact",
  },
];

export const QUERY_KEY = {
  register: ["post", "account-registration"],
  login: ["post", "account-login"],
  getCurrentUserData: ["post", "current-user-data"],
  logout: ["post", "account-logout"],
  editProfile: ["put", "edit-profile"],
  getNotifications: ["get", "get-notifications"],
  getAllPlans: ["get", "get-All-Plans"],
  subscriptionHistory: ["get", "get-subscription-history"],
  subscribePlan: ["put", "subscribe-plan"],
  getUserSubscription: ["get", "user-subscription"],
  cancelSubscription: ["put", "cancel-subscribe"],
  deleteAccount: ["delete", "delete-account"],
  deleteBookmark: ["delete", "delete-bookmark"],
  markNotificationAsRead: ["put", "mark-notification-as-read"],
  newPassword: ["put", "New-Password"],
  publishBook: ["post", "publish-book"],
  getMyBooks: ["get", "get-my-books"],
  getBooksBorrowed: ["get", "get-books-borrowed"],
  getBookDetails: ["get", "get-book-details"],
  getTopRated: ["get", "get-top-rated"],
  getRecentUploads: ["get", "get-recent-uploads"],
  returnBorrowedBook: ["delete", "delete-return-book"],
  borrowBook: ["PUT", "borrow-book"],
  getCategories: ["get", "get-categories"],
  getSubcategory: ["get", "get-subcategory"],
  createBookmark: ["post", "create-bookmark"],
  getAllBookmarks: ["get", "get-all-bookmarks"],
  subscriptionPayment: ["post", "subscription-payment"],
  getBookBySubCategory: ["get", "get-book-by-subcategory"],
  deletUploadedBook: ["delete", "delete-uploaded-book"],
  updateCourse: ["put", "update-course"],
  createAccessment: ["post", "create-accessment"],
  createAssessmentQuestion: ["post", "create-accessment-question"],
  payForBook: ["post", "pay-for-book"],
  saveNote: ["post", "save-note"],
  getNotes: ["get", "get-notes"],
  updateNote: ["put", "update-notes"],
  deleteNote: ["delete", "delete-notes"],
  getNoteById: ["get", "get-note-by-id"],
  saveReview: ["post", "save-review"],
  updateReview: ["get", "update-review"],
  getAllReviews: ["get", "get-reviews"],
  getAssessmentQuestion: ["get", "get-assessment-question"],
  getBooksPaidFor: ["get", "get-books-paid-for"],
  uploadCourseMedia: ["put", "upload-course-media"],
  getQuestionByFilter: ["get", "get-question-by-filter"],
  getAllSubjectsBySubId: ["get", "get-all-subjects-subcategory-id"],
  getTopicBySubjectId: ["get", "get-topic-by-subject-id"],
  uploadTemplateContent: ["put", "upload-template-content"],
  courseFilesUpload: ["put", "course-files-upload"],
  getAllCourses: ["get", "get-all-courses"],
  getQuestionByTopic: ["get", "get-question-by-topic"],
  uploadQuestion: ["post", "upload-question"],
  getUserQuestion: ["get", "get-user-question"],
  deleteQuestion: ["delete", "delete-question"],
  filterMaterials: ["get", "filter-material"],
  createCourse: ["post", "create-course"],
  getCourseBySubjectId: ["get", "get-course-by-subject-id"],
  getCourseById: ["get", "course-by-id"],
  getUserCurriculum: ["get", "user-curriculum"],
  deletUploadedCurriculum: ["delete", "delet-uploaded-curriculum"],
  getAllExamSubject: ["get", "get-all-exam-subject"],
  getAllQuetionsByExam: ["get", "get-all-question"],
  getAllExamUploadByTutor: ["get", "get-all-exam-upload-by-tutor"],
  getAllUploadedQuestions: ["get", "get-all-upload-questions"],
  createExam: ["post", "create-exam"],
  deleteExam: ["delete", "delete-exam"],
  getAllQuestionBySubject: ["get", "get-all-question-by-subject"],
  addQuestionsToExam: ["post", "post-add-questions-to-exam"],
  submitExam: ["post", "post-exam"],
};

export const emitter = {
  sessionTimeOutEventEmitter: new EventEmitter(),
};
export const timeout = 3600000; // 60mins

export const paymentPlatforms = [
  {
    name: "",
    value: "STRIPE",
    logo: stripeLogo,
  },
];

export const colors = {
  0: "#ffedd6",
  1: "#ecd3bc",
  2: "#c7bfe7",
  3: "#fae7899d",
  4: "#c6d9ff",
  5: "#e7f5c6",
  6: "#ffddbd",
  7: "#ecd3bc",
  8: "#c6d9ff",
  9: "#e7f5c6",
};

export const questionLevel = [
  {
    name: "Easy",
    id: "easy",
  },
  {
    name: "Medium",
    id: "medium",
  },
  {
    name: "Hard",
    id: "hard",
  },
];

export const booleanOption = [
  { name: "False", id: false },
  { name: "True", id: true },
];

export const OPTION_LABEL = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

export const TERMS = {
  1: "first",
  2: "second",
  3: "third",
};

export const PAGE_SIZE = 10;

export const SCREEN_SIZE = {
  sm: 767.99,
};
