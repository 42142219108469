import useBookCatalog from "./hooks/useBookCatalog";
import CatalogLayout from "./Catalog";

const BookCatalog = () => {
  const { data, isLoading, searchBooks } = useBookCatalog();
  return (
    <CatalogLayout
      data={data}
      searchPlaceholder="Search Catalogue"
      navLink="/library"
      navText="Catalogue"
      isLoading={isLoading}
      // returnBook={returnBook}
      searchFn={searchBooks}
    />
  );
};

export default BookCatalog;
