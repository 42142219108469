import React from "react";
import { MDBSpinner } from "mdb-react-ui-kit";
import classes from "./PageLoader.module.css";
function PageLoader({ splashLoader }) {
  return (
    <div
      className={`${classes.loaderRoot} ${
        splashLoader ? classes.showloader : undefined
      }`}
    >
      <MDBSpinner grow className={classes.loader}>
        {/* <span className="visually-hidden">Loading...</span> */}
      </MDBSpinner>
    </div>
  );
}

export default PageLoader;
