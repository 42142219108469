import styles from "./Pagination.module.css";

const Pagination = ({ onChange, currentPage = 1, pages = 1 }) => {
  const lastPage = pages;
  return (
    <nav className={styles.nav}>
      <div className={styles.ul}>
        <button
          onClick={() => {
            if (currentPage <= 1) return;
            onChange?.(currentPage - 1);
          }}
          className={`${styles.button} ${styles.previous}`}
        >
          <span className="sr-only">Previous</span>
          <svg
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 6 10"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M5 1 1 5l4 4"
            />
          </svg>
        </button>
        {[...Array(pages)].map((_, index) => {
          if (index >= currentPage - 2 && index <= currentPage + 2) {
            return (
              <button
                key={index}
                className={`${styles.button} ${
                  currentPage === index + 1 ? styles.buttonActive : ""
                }`}
                onClick={() => onChange?.(index + 1)}
              >
                {index + 1}
              </button>
            );
          } else if (
            (index === 3 && currentPage > 5) ||
            (index === pages - 4 && currentPage < (lastPage || 0) - 4)
          ) {
            return (
              <span key={index} className={styles.ellipsis}>
                ...
              </span>
            );
          }
          return "";
        })}
        <button
          onClick={() => {
            if (currentPage === lastPage) return;
            onChange?.(currentPage + 1);
          }}
          className={`${styles.button} ${styles.next}`}
        >
          <span className="sr-only">Next</span>
          <svg
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 6 10"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m1 9 4-4-4-4"
            />
          </svg>
        </button>
      </div>
    </nav>
  );
};

export default Pagination;
