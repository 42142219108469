import { DeleteIcon, EditIcon } from "../../assets/icons";
import useCurriculum from "../../pages/Admin/Curriculum/hooks/useCurriculum";
import BookCard2 from "../BookCard2/BookCard2";
import { Loader } from "../Modal";
import Tooltip from "../Tooltip";
import style from "./Accordion.module.css";
import { useState } from "react";

const Accordion = ({curriculum, data, onDelete, onEdit, term}) => {
    const [activeSelection, setActiveSelection] = useState("")
    const { curriculumTopic, isLoading } =  useCurriculum();
    const [currentTopic, setCurrentTopic] = useState(null)

    const toggleAccordion = async (value) => {
        await getTopicDetail(value)
        setActiveSelection(value !== activeSelection ? value : "")
    }

    const getTopicDetail = async (topic_id) => {
        const detailParam = {term : term, curriculum_id : curriculum, topic_id}
        const topicData = await curriculumTopic(detailParam)
        setCurrentTopic(topicData)
        return topicData
    }

    const  handleDelete = async(topic_id) => {
        const topic = await getTopicDetail(topic_id)
        onDelete(topic?.topicDetails)
    }

    const  handleEdit = async(topic_id) => {
        const topic = await getTopicDetail(topic_id)
        onEdit(topic?.topicDetails)
    }

    const getInitials = name => {
        // Split the name into words
        const words = name.split(' ');
        // Extract the first letter of each word and concatenate
        const initials = words.map(word => word.charAt(0)).join('');
    
        return initials;
      }

    return (
        <>
            {data.length ? data.map((item, index) => (
                <div className={`${style.accordion_container}`} key={index}>
                    <div className={`${style.accordion_header} ${style.row}`}>
                        <h5 className={`${style.accordion_title} ${activeSelection === item?.id ? style.active : ""}`}  onClick={() => toggleAccordion(item?.id)}>{item?.name}</h5>
                        <div>
                            <Tooltip
                                data="Edit Topic"
                                classes={style.tooltip}
                                callbackFn={() => handleEdit(item?.id)}
                                >
                                <EditIcon size={22} />
                            </Tooltip>
                            <Tooltip
                                data="Remove Topic"
                                classes={style.tooltip}
                                callbackFn={() => handleDelete(item?.id)}
                                >
                                <DeleteIcon size={22} />
                            </Tooltip>
                        </div>
                    </div>
                    <div className={`${style.accordion_content} ${activeSelection === item?.id ? style.display_content : ""}`}>
                        <div className={style.content}>
                            <div className={style.contentHeader}>
                                <h4 className={style.title}>{currentTopic?.topicDetails?.topic?.name}</h4>
                                {<div>Week 1</div>}
                            </div>
                            <div className={style.row_beside}>
                                {currentTopic?.topicDetails?.author && (<div className={style.initials}>{getInitials(currentTopic?.topicDetails?.author)}</div>)}
                                <div>
                                <p>{currentTopic?.topicDetails?.author}</p>
                                <p className={style.mtN20}>{currentTopic?.topicDetails?.author_position}</p>
                                </div>
                            </div>
                            <div>
                                <h5 className={style.title}>Course Description</h5>
                                <pre>{currentTopic?.topicDetails?.course_description}</pre>
                            </div>
                            <div>
                                <h5 className={style.title}>Learning Objectives</h5>
                                <pre className={style.learningObj}>{currentTopic?.topicDetails?.learning_objectives}</pre>
                            </div>
                            {currentTopic?.resources?.length > 0 && (
                                <div>
                                <h5 className={style.title}>Resources</h5>
                                <div className={style.row}>
                                    {currentTopic?.resources.map((book) => (
                                    <BookCard2 
                                        img={book?.book_cover_url} book_type={book?.book_type}
                                        title={book?.title} rating={book?.rating}
                                    />
                                    ))}
                                </div>
                                </div>
                            )}
                        </div>
                        {/* <p className={`${style.accordion_text} ${activeSelection === item?.id ? style.active : ""}`}>
                            {currentTopic?.course_description}
                        </p> */}
                    </div>
                </div>
            )) : <p style={{marginTop : "20px"}}>Content not found</p>}

            {isLoading && <Loader />}
        </>
    )
}

export default Accordion;