import style from "./BookCatalog.module.css";
import { useNavigate } from "react-router-dom";
import SkeletonLoader from "../../../components/SkeletonLoader";
import BookCard2 from "../../../components/BookCard2/BookCard2";
import { SearchUI } from "../../../components/searchUI";

const CatalogLayout = ({
  data,
  isLoading,
  searchPlaceholder = "Search",
  returnBook = () => {},
  searchFn,
  navLink = "",
  navText = "",
}) => {
  const navigate = useNavigate();

  // Extract all the subjects and put them in a set to remove duplicate
  const subjectSet = data?.length ? data?.filter((obj) => obj.subject) : [];
  const subjects = [...new Set(subjectSet.map((obj) => obj.subject.name))];

  const filterBySubject = async (value) => {
    await searchFn(value);
  };

  const searchHandler = async (searchItem) => {
    await searchFn(searchItem);
  };

  return (
    <div className={style.shelfSection}>
      <SearchUI
        navText={navText}
        navLink={navLink}
        filterBySubject={filterBySubject}
        searchHandler={searchHandler}
        subjects={subjects}
        MaxSubjectView={3}
        placeholder={searchPlaceholder}
      />
      <div className={style.mainBody}>
        <div className={style.shelfBooksWrapper}>
          {isLoading ? (
            <div className={style.loaderWrapper}>
              {[...new Array(7).values()].map((_, index) => (
                <SkeletonLoader
                  className={style.skeletonLogLoader}
                  key={index}
                />
              ))}
            </div>
          ) : (
            <div>
              {!data?.length ? (
                <div className={style.emptyHistory}>
                  <p>No book found.</p>
                </div>
              ) : (
                <div className={style.bookSection}>
                  {(data || []).map((item, index) => (
                    <BookCard2
                      key={index}
                      img={item?.book_cover_url}
                      title={item?.title}
                      rating={item?.rating}
                      subject={item?.subject?.name}
                      price={item?.price}
                      handleClick={() =>
                        navigate(`/library/course/${item?._id}`)
                      }
                      handleReturnBook={() => returnBook(item?._id)}
                    />
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CatalogLayout;
