import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

function useScrollToLayout() {
  const location = useLocation();
  const myRef = useRef(null);
  useEffect(() => {
    const element = myRef?.current;
    element?.scrollIntoView({
      behavior: element ? "smooth" : "auto",
    });
  }, [location]);

  function getScrollPosition(layout) {
    return location?.state === layout ? myRef : null;
  }

  return {
    getScrollPosition,
    myRef,
  };
}

export default useScrollToLayout;
