import { useEffect, useState } from "react";
import Accordion from "../../../components/Accordion/Accordion";
import useCurriculum from "./hooks/useCurriculum";
import { toast } from "react-toastify";
import { TERMS } from "../../../utils/constants";
import { initCaps } from "../../../utils/helpers";
import style from "./Term.module.css"
import { AddIcon } from "../../../assets/icons";
import Tooltip from "../../../components/Tooltip";
import ConfirmDialog from "../../../components/ConfirmationDialogCD/ConfirmDialog";

const Term = ({curriculum, term, addtopic, editTopic}) => {
    const { curriculumSemesterTopics, deleteCurriculumTopic, isLoading, error } =  useCurriculum();
    const [topics, setTopics] = useState([])
    const [isDelete, setIsDelete] = useState(false);
    const [deleteId, setDeleteId] = useState(0);


    useEffect(() => {
        const fetchTopics = async () => {
            const params = {term, curriculum_id : curriculum}
            const data = await curriculumSemesterTopics(params)
            // const detail = []
            // data?.forEach(topic => {
            //     (async() => {
            //         const detailParam = {...params, topic_id : topic.id}
            //         const topicData = await curriculumTopic(detailParam)
            //         await detail.push({...topicData, topic_name : topic.name})
            //     })()
            // })
            setTopics(data)
        }

        fetchTopics()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (error) {
          const error_data = error.response.data;
          toast.error(`
            ${error_data.error || error_data.msg || error_data.detail}
          `);
        }
    }, [error]);

    const deleteHandler = (curriculumId) => {
        setDeleteId(curriculumId);
        setIsDelete(true);
    };


    const handleDelete = async (topic) => {
        const res = await deleteCurriculumTopic(topic?.id)
        if (res){
            setTopics(topics.filter(_ => _.id !== topic?.topic?.id))
            toast.success(`
              ${res}
            `);
        }
    }


    return (
        <div className={`${style.container}`}>
            <div className={`${style.row}`}>
                <h5 className={style.header_title}>{initCaps(TERMS[term])} term</h5>
                <Tooltip
                    data="Add Topic"
                    classes={style.tooltip}
                    callbackFn={() => addtopic({})}
                    >
                    <AddIcon size={22} />
                </Tooltip>
            </div>
            <Accordion 
                data={topics} isLoading={isLoading} 
                onDelete={deleteHandler} onEdit={addtopic}
                term={term}
                curriculum={curriculum}
            />
            {isDelete && (
                <ConfirmDialog
                confirm={async () => {
                    handleDelete(deleteId);
                    // call delete action here
                    setIsDelete(false);
                }}
                cancel={() => setIsDelete(false)}
                message={"Click continue to REMOVE this topic from curriculum"}
                />
            )}
        </div>
    )
}

export default Term;