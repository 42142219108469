import { useEffect, useState } from "react";
import useCdReactQuery from "../../../../utils/hooks/useCdReactQuery";
import assessmentService from "../../../../service/assessment.service";

function useBuckets() {
  const { data, queryEndpoint, isLoading } = useCdReactQuery();
  const [buckets, setBuckets] = useState([]);
  // const [subjectid, setSubjectId] = useState(null)

  useEffect(() => {
    (async () => {
      await queryEndpoint({
        endpointUrl: assessmentService.buckets,
      });
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data) {
      setBuckets(data.buckets);
    }
  }, [data]);

  const getBucketByID = (id) => {
    // setSubjectId(id)
    (async () => {
      await queryEndpoint({
        endpointUrl: assessmentService.bucketBySubjectId(id),
      });
    })();
  };

  const getBuckets = (params) => {
    (async () => {
      await queryEndpoint({
        endpointUrl: assessmentService.buckets,
        params : params
      });
    })()
  }

  return {
    isLoading,
    buckets,
    getBucketByID,
    getBuckets,
  };
}

export default useBuckets;
