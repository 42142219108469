/* eslint-disable */
import { useEffect, useState } from "react";
import {
  DownTrendIcon,
  RightCaretIcon,
  UpTrendIcon,
} from "../../../../assets/icons";
import { defaultImage } from "../../../../assets/image";
import {
  cashFormater,
  formatDate,
  shortenLargeNumber,
  trend_percentage,
} from "../../../../utils/helpers";
import { totalStudents, totalRevenue, retainer, courseStat } from "./DummyData";
import style from "./Widgets.module.css";
import {
  BarChart,
  Bar,
  Cell,
  AreaChart,
  Area,
  Tooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
} from "recharts";
import useCdReactQuery from "../../../../utils/hooks/useCdReactQuery";
import adminService from "../../../../service/admin.service";
import SkeletonLoader from "../../../../components/SkeletonLoader";
import { FullpageModal } from "../../../../components/Modal";
import Pagination from "../../../../components/Pagination/Pagination";
import { IoChevronBackOutline } from "react-icons/io5";
import {
  BookStatsList,
  MostViewedBooksList,
  RenewalListTable,
  TotalRevenueTable,
  TotalStudentsTable,
} from "../Tables/Tables";
import OverviewModal from "../Tables/OverviewModal";

export const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const dataPoint = payload[0];
    return (
      <div className={style.customTooltip}>
        <p>{`Month: ${dataPoint.payload.name}`}</p>
        <p>{`Value: ${cashFormater(dataPoint.value)}`}</p>
      </div>
    );
  }
  return null;
};
export const CustomTooltip2 = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const dataPoint = payload[0];
    return (
      <div className={style.customTooltip}>
        <p>{`Month: ${dataPoint.payload.name}`}</p>
        <p>{`Renewed: ${cashFormater(dataPoint.payload.renewed)}`}</p>
        <p>{`Exited: ${cashFormater(dataPoint.payload.exited)}`}</p>
      </div>
    );
  }
  return null;
};
export const CustomTooltip3 = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const dataPoint = payload[0];
    // perform formatting on number e.g 1.2k 2.5m for million and thousand respectively
    return (
      <div className={style.customTooltip}>
        <p>{`Courses ${dataPoint.payload.name}`}</p>
        <p>{dataPoint.payload.value}</p>
      </div>
    );
  }
  return null;
};

export const TotalStudents = ({ graphData = totalStudents }) => {
  const { activeIndex } = graphData;
  const [viewMore, setViewMore] = useState(false);

  const { data: res, queryEndpoint } = useCdReactQuery();

  const {
    all_time_total = 0,
    monthly_total = {},
    percentage_growth = 0,
    previous_year_total = 0,
  } = res || {};

  async function fetch() {
    await queryEndpoint({
      endpointUrl: adminService.getStudentStats,
    });
  }
  useEffect(() => {
    fetch();
  }, []);

  const monthlyCartData = Object?.entries?.(monthly_total || {})?.map(
    ([key, value]) => ({
      name: key,
      value: value,
    })
  );

  const trend = trend_percentage(previous_year_total, percentage_growth);

  function toggleViewMore() {
    setViewMore(!viewMore);
  }

  return (
    <>
      <OverviewModal
        Component={TotalStudentsTable}
        isOpen={viewMore}
        onClose={toggleViewMore}
      />
      <div className={`${style.row} ${style.p20}`}>
        <div className={style.heading} onClick={toggleViewMore}>
          <p>
            Total Students <RightCaretIcon />
          </p>
          <h3 className={style.count}>{shortenLargeNumber(all_time_total)}</h3>
        </div>
        <span className={`${trend >= 1 ? style.upTrend : style.downTrend}`}>
          {trend >= 1 ? <UpTrendIcon /> : <DownTrendIcon />}
          {` ${trend}%`}
        </span>
      </div>
      <div className={style.px20}>
        {monthlyCartData?.length ? (
          <ResponsiveContainer width="100%" height={120}>
            <BarChart width={150} height={40} data={monthlyCartData}>
              <Tooltip content={<CustomTooltip />} />
              <Bar dataKey="value" onClick={() => {}}>
                {monthlyCartData?.map((entry, index) => (
                  <Cell
                    cursor="pointer"
                    fill={index === activeIndex ? "#82ca9d" : "#8884d8"}
                    key={`cell-${index}`}
                  />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        ) : (
          <ResponsiveContainer width="100%" height={120}>
            <BarChart width={150} height={40} data={graphData?.data}>
              <Bar dataKey="value" onClick={() => {}}>
                {graphData?.data?.map((entry, index) => (
                  <Cell cursor="pointer" fill="#E0E0E0" key={`cell-${index}`} />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        )}
      </div>
    </>
  );
};

export const TotalRevenue = ({ graphData = totalRevenue }) => {
  const { data, queryEndpoint } = useCdReactQuery();
  const [viewMore, setViewMore] = useState(false);

  const {
    current_year_total = 0,
    percentage_growth = 0,
    monthly_total = {},
  } = data || {};

  async function fetch() {
    await queryEndpoint({
      endpointUrl: adminService.getRevenueStats,
    });
  }
  useEffect(() => {
    fetch();
  }, []);

  const revnueGraphData = Object?.entries?.(monthly_total || {})?.map(
    ([key, value]) => ({
      name: key,
      value: value,
    })
  );

  function toggleViewMore() {
    setViewMore(!viewMore);
  }

  return (
    <>
      <OverviewModal
        Component={TotalRevenueTable}
        isOpen={viewMore}
        onClose={toggleViewMore}
      />
      <div className={`${style.row} ${style.p20}`}>
        <div className={style.heading} onClick={toggleViewMore}>
          <p>
            Total Revenue <RightCaretIcon />
          </p>
          <h3 className={style.count}>
            {shortenLargeNumber(current_year_total)}
          </h3>
        </div>
        <span
          className={`${
            percentage_growth >= 1 ? style.upTrend : style.downTrend
          }`}
        >
          {percentage_growth >= 1 ? <UpTrendIcon /> : <DownTrendIcon />}
          {` ${percentage_growth}%`}
        </span>
      </div>
      <div>
        {revnueGraphData?.length ? (
          <ResponsiveContainer width="100%" height={120}>
            <AreaChart
              data={revnueGraphData}
              margin={{
                top: 10,
                right: 0,
                left: 0,
                bottom: 0,
              }}
            >
              {/* <CartesianGrid strokeDasharray="3 3" /> */}
              {/* <XAxis dataKey="name" /> */}
              {/* <YAxis /> */}
              <Tooltip content={<CustomTooltip />} />
              <Area
                type="monotone"
                dataKey="value"
                stroke="#63acff"
                fill="#63acff"
              />
            </AreaChart>
          </ResponsiveContainer>
        ) : (
          <ResponsiveContainer width="100%" height={120}>
            <AreaChart
              data={graphData}
              margin={{
                top: 10,
                right: 0,
                left: 0,
                bottom: 0,
              }}
            >
              <Area
                type="monotone"
                dataKey="value"
                stroke="#E0E0E0"
                fill="#E0E0E0"
              />
            </AreaChart>
          </ResponsiveContainer>
        )}
      </div>
    </>
  );
};

export const CourseStats = ({ graphData = courseStat }) => {
  const COLORS = ["#8884d8", "#00C49F", "#FFBB28", "#FF8042"];

  const { data, queryEndpoint } = useCdReactQuery();

  const [viewMore, setViewMore] = useState(false);

  const { books_bought = 0, books_read = 0 } = data || {};

  async function fetch() {
    await queryEndpoint({
      endpointUrl: adminService.getBookReadSoldStats,
    });
  }

  useEffect(() => {
    fetch();
  }, []);

  const courseStateData = Object?.entries?.({
    Read: books_read,
    Sold: books_bought,
  })?.map(([key, value]) => ({
    name: key,
    value: value,
  }));

  function toggleViewMore() {
    setViewMore(!viewMore);
  }

  return (
    <>
      <OverviewModal
        Component={BookStatsList}
        isOpen={viewMore}
        onClose={toggleViewMore}
      />
      <div className={`${style.row} ${style.p20}`}>
        <div className={style.heading} onClick={toggleViewMore}>
          <p>
            Book Stats <RightCaretIcon />
          </p>
        </div>
      </div>
      <div className={`${style.px20} ${style.courseStat}`}>
        {/* <ResponsiveContainer width="100%" height={120}> */}
        {courseStateData?.length ? (
          <PieChart width={160} height={155}>
            <Pie
              data={courseStateData}
              cx={60}
              cy={60}
              innerRadius={40}
              outerRadius={60}
              fill="#8884d8"
              paddingAngle={5}
              dataKey="value"
            >
              {courseStateData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip content={<CustomTooltip3 />} />
          </PieChart>
        ) : (
          <PieChart width={160} height={155}>
            <Pie
              data={graphData}
              cx={60}
              cy={60}
              innerRadius={40}
              outerRadius={60}
              fill="#E0E0E0"
              paddingAngle={5}
              dataKey="value"
            >
              {graphData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip content={<CustomTooltip3 />} />
          </PieChart>
        )}
        <div>
          <ul>
            {courseStateData.map((item, index) => (
              <li
                key={index}
                className={style.courseStatItem}
              >{`Books ${item.name} - ${item.value}`}</li>
            ))}
          </ul>
        </div>
        {/* </ResponsiveContainer> */}
      </div>
    </>
  );
};

export const Retainer = ({
  trend = "down", // up or down
  graphData = retainer,
  growthPercent = -8,
  totalCount = "4.5k", // total renewed for the period on the graph
}) => {
  return (
    <>
      <div className={`${style.row} ${style.p20}`}>
        <div
          className={style.heading}
          onClick={() => {
            console.log("load modal");
          }}
        >
          <p>
            Retainers <RightCaretIcon />
          </p>
          <h3 className={style.count}>{totalCount}</h3>
        </div>
        <span className={`${trend === "up" ? style.upTrend : style.downTrend}`}>
          {trend === "up" ? <UpTrendIcon /> : <DownTrendIcon />}
          {` ${growthPercent}%`}
        </span>
      </div>
      <div>
        <ResponsiveContainer width="100%" height={210}>
          <AreaChart
            width={500}
            height={400}
            data={graphData}
            margin={{
              top: 10,
              right: 0,
              left: 0,
              bottom: 0,
            }}
          >
            <Tooltip content={<CustomTooltip2 />} />

            <Area
              type="monotone"
              dataKey="renewed"
              stackId="1"
              stroke="#82ca9d"
              fill="#82ca9d"
            />
            <Area
              type="monotone"
              dataKey="exited"
              stackId="2"
              stroke="#ffc658"
              fill="#ffc658"
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};

export const ExpiryList = ({}) => {
  const { data, isLoading, queryEndpoint } = useCdReactQuery();
  const [viewMore, setViewMore] = useState(false);

  const expiryList = data?.data || [];
  const totalCount = data?.total_items || 0;

  async function fetch() {
    await queryEndpoint({
      endpointUrl: adminService.getRenewalList,
    });
  }

  useEffect(() => {
    fetch();
  }, []);

  function toggleViewMore() {
    setViewMore(!viewMore);
  }

  return (
    <>
      <OverviewModal
        Component={RenewalListTable}
        isOpen={viewMore}
        onClose={toggleViewMore}
      />
      <div className={`${style.expiryList} ${style.p30}`}>
        <div className={`${style.row} `}>
          <div
            className={style.heading}
            onClick={() => {
              if (!expiryList?.length) {
                return;
              }
              toggleViewMore();
            }}
          >
            <p>
              Renewal List <RightCaretIcon />
            </p>
            <h3 className={style.count}>{totalCount}</h3>
          </div>
        </div>
        <div className={style.listItemHeader}>
          <div className={style.name}>Name</div>
          <div className={style.class}>Phone Number</div>
          <div className={style.amount}>Amount</div>
          <div className={style.expiry_date}>Expiry Date</div>
          <div className={style.status}>Status</div>
          <div className={style.actions}>Action</div>
        </div>
        {isLoading ? (
          <div className={style.renewalListContainer}>
            {[...new Array(5).values()].map((_, index) => (
              <div key={index}>
                <SkeletonLoader className={style.skeletonLogLoader} />
              </div>
            ))}
          </div>
        ) : expiryList?.length ? (
          <div className={style.renewalListContainer}>
            {expiryList.map((item, index) => (
              <div className={style.listItem} key={index}>
                <div className={style.name}>{item.name}</div>
                <div className={style.class}>{item.phone_number}</div>
                <div className={style.amount}>
                  &#8358; {cashFormater(item.amount)}
                </div>
                <div className={style.expiry_date}>
                  {formatDate(item.expiry_date)}
                </div>
                <div
                  className={style.status}
                  style={{ textTransform: "capitalize" }}
                >
                  {item?.subscription_status}
                </div>
                <div className={style.actions}>
                  <span>Notify</span>
                  <span>Pay now</span>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p className={style.emptyState}>
            <h4>No Data Found</h4>
          </p>
        )}
      </div>
    </>
  );
};
export const MostViewedBooks = ({}) => {
  const { data, isLoading, queryEndpoint } = useCdReactQuery();
  const [viewMore, setViewMore] = useState(false);

  const mostViewedBooks = data?.data || [];

  async function fetch() {
    await queryEndpoint({
      endpointUrl: adminService.getMostViewBooks,
    });
  }

  useEffect(() => {
    fetch();
  }, []);

  function toggleViewMore() {
    setViewMore(!viewMore);
  }

  return (
    <>
      <OverviewModal
        Component={MostViewedBooksList}
        isOpen={viewMore}
        onClose={toggleViewMore}
      />
      <div className={style.p30}>
        <div
          className={style.heading}
          onClick={() => {
            if (!mostViewedBooks?.length) {
              return;
            }
            toggleViewMore();
          }}
        >
          Most Viewed Books <RightCaretIcon />
        </div>
        {isLoading ? (
          <div className={style.mostViewedContainer}>
            {[...new Array(3).values()].map((_, index) => (
              <div key={index}>
                <SkeletonLoader className={style.skeletonLogLoader} />
              </div>
            ))}
          </div>
        ) : mostViewedBooks?.length ? (
          <div className={style.mostViewedContainer}>
            {mostViewedBooks.slice(0, 4).map((item, index) => (
              <div className={style.mostViewed} key={index}>
                <img
                  src={item.image || defaultImage}
                  alt="book cover"
                  className={style.bookCover}
                />
                <div className={style.content}>
                  <p className={style.topic}>{item.topic}</p>
                  <p>{item.subject}</p>
                </div>
                <div className={style.views}>{item.views} views</div>
              </div>
            ))}
          </div>
        ) : (
          <p className={style.emptyState}>
            <h4>No Book Found</h4>
          </p>
        )}
      </div>
    </>
  );
};
