import { useEffect, useState } from "react";
import Dropdown from "../../../../components/Dropdown";
import InputField from "../../../../components/InputField";
import TextArea from "../../../../components/TextArea";
import useCurriculum from "../hooks/useCurriculum";
import style from "./AddCurriculum.module.css";

const AddTopic = ({addTopic, curriculum, subject_id, term, topic}) => {
  const { topicsBySubjectID } = useCurriculum();
  const [topics, setTopics ] = useState([])
  const [initForm, setInitForm] = useState({
        author : "",
        author_position : "",
        curriculum_id: curriculum,
        learning_objectives : "",
        term : term,
        topic_description : "",
        topic_id : "",
        week : 1
  })


  useEffect(() => {
    const fetch = async () => {
        const topics = await topicsBySubjectID(subject_id)
        setTopics(topics)
    }
    fetch()

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if(topic?.id){
      setInitForm({
        author : topic?.author,
        author_position : topic?.author_position,
        // curriculum_id: curriculum,
        learning_objectives : topic?.learning_objectives,
        term : term,
        topic_description : topic?.course_description,
        topic_id : topic?.topic?.id,
        week : topic?.week, 
        curriculum_details_id : topic?.id
      })
    }
    // eslint-disable-next-line
  }, [])



  const saveTopic = () => {
    addTopic(initForm)
  }

  const getTopicByID = (id) => {
    const topicData = topics?.find(item => item.id === id)
    return topicData?.name
  }

  return (
    <div className={style.form_container}>
      <h5 className={style.header_title}>{topic?.id ? "edit" : "create"} Topic</h5>
      <div className={style.fluid}>
        <div className={style.formGroup}>
          <div className={style.label}>Weeks</div>
          <Dropdown
            options={[...new Array(4).values()].map((_, index) => ({id : index+1, name : index+1}))}
            value={initForm.week}
            onChange={(e) => {
                setInitForm({ ...initForm, week: e.id });
              }}
            className={style.difficulty}
          />
        </div>
        <div className={style.formGroup}>
          <div className={style.label}>Topic</div>
          <Dropdown
            options={topics}
            value={getTopicByID(initForm.topic_id) || ""}
            onChange={(e) => {
              setInitForm({ ...initForm, topic_id: e.id });
            }}
            className={style.difficulty}
          />
        </div>
      </div>
      <div className={style.fluid}>
        <div className={style.formGroup}>
          <div className={style.label}>Author</div>
          <InputField 
            value={initForm.author}
            onChange={(e) => setInitForm({...initForm, author : e.target.value})}
          />
        </div>
        <div className={style.formGroup}>
          <div className={style.label}>Author Position</div>
          <InputField 
            value={initForm.author_position}
            onChange={(e) => setInitForm({...initForm, author_position : e.target.value})}
          />
        </div>
      </div>
      <div style={{marginBottom : "20px"}}>
        <div className={style.label}>Learning objectives</div>
        <TextArea
          onChange={(e) => setInitForm({...initForm, learning_objectives : e.target.value})}
          rows={6}
          name="learning_objectives"
          value={initForm.learning_objectives}
          className={style.question}
        />
      </div>
      <div style={{marginBottom : "20px"}}>
        <div className={style.label}>Topic Description</div>
        <TextArea
          onChange={(e) => setInitForm({...initForm, topic_description : e.target.value})}
          rows={6}
          name="topic_description"
          value={initForm.topic_description}
          className={style.question}
        />
      </div>
      <div className={`${style.fluid} ${style.actionGroup}`}>
        <div className={style.btn} onClick={saveTopic}>
          SAVE TOPIC
        </div>
      </div>
    </div>
  );
};

export default AddTopic;
