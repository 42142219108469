import { useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import libraryService from "../../../../service/library.service";

import useCdReactQuery from "../../../../utils/hooks/useCdReactQuery";
import { NoteContext } from "../../Notes/noteContext";
const useCourseDetailsNew = () => {
  const { courseId } = useParams();
  const { updatedNote } = useContext(NoteContext);

  const { queryEndpoint, error } = useCdReactQuery();
  const [isLoading, setIsloading] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    (async () => {
      await queryEndpoint({
        endpointUrl: libraryService.mostViewedBooks,
        httpMethod: "PATCH",
        params: { book_id: courseId },
      });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function fetchCourseDetails() {
    // create a self called asynch function (IFF) to handle the queryEndpoint.
    // This is needed because the useEffect cannot directly call an asynch function
    (async () => {
      setIsloading(true);
      const _data = await queryEndpoint({
        endpointUrl: libraryService.bookDetails(courseId),
      });
      setData(_data);
    })();
    setIsloading(false);
  }

  useEffect(() => {
    fetchCourseDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseId, updatedNote]);

  return {
    isLoading,
    data,
    error,
    fetchCourseDetails,
  };
};
export default useCourseDetailsNew;
