// import React, { useContext, useEffect, useState } from "react";
// import style from "./Review.module.css";
// import ExamPrepStorage from "../../storage/ExamPrepStorage";
// import { AiOutlineCloseCircle } from "react-icons/ai";
// import { FaLongArrowAltLeft, FaLongArrowAltRight } from "react-icons/fa";
// import FlipCard from "./FlipCard";
// import TextView from "../../ExamPrepBuckets/Assessment/QuestionTextView/TextView";
// import ImageView from "../../ExamPrepBuckets/Assessment/QuestionImageView/ImageView";
// import VideoPlayer from "../../../../components/VideoPlayer/VideoPlayer";

// export const Review = () => {
//   const [qstIndex, setQstIndex] = useState(0);
//   const [toggleFlipState, setToggleFlipState] = useState(false);
//   const { updateStorage, assessmentModalToggle, questions } =
//     useContext(ExamPrepStorage);

//   // This always ensures MathJax is loaded when a new page is generated
//   useEffect(() => {
//     if (typeof window?.MathJax !== "undefined") {
//       window.MathJax.typesetClear();
//       window.MathJax.typeset();
//     }
//   });

//   const totalQuestions = questions?.length || 0;

//   const renderQuestion = (index, source) => {
//     const data = questions[index];

//     return (
//       <div>
//         <TextView question={data[source]?.text} />
//         <ImageView
//           imageUrl={data[source].imageUrl}
//           question={data[source]?.imageUrl}
//         />
//         <VideoPlayer
//           src={data[source].videoUrl}
//           type={data[source]?.videoUrl}
//         />
//       </div>
//     );
//   };

//   console.log("toggleFlipState in Review:", toggleFlipState);
//   return (
//     <div className={style.rootPage}>
//       <div className={style.close}>
//         <button
//           onClick={() =>
//             updateStorage({
//               assessmentModalToggle: !assessmentModalToggle,
//               modalPage: "Review",
//             })
//           }
//         >
//           <AiOutlineCloseCircle />
//         </button>
//       </div>
//       {questions && questions.length && (
//         <div className={style.content}>
//           <div className={style.left}>
//             <h3>Question</h3>
//             <p className={style.pre} style={{ overflowY: "scroll", flex: 1 }}>
//               {renderQuestion(qstIndex, "question")}
//             </p>
//             <div className={style.footer}>
//               {qstIndex > 0 && (
//                 <button
//                   onClick={() => {
//                     setQstIndex((prev) => prev - 1);
//                     setToggleFlipState(false);
//                   }}
//                 >
//                   <FaLongArrowAltLeft />
//                 </button>
//               )}
//               {qstIndex < totalQuestions - 1 && (
//                 <button
//                   onClick={() => {
//                     setQstIndex((prev) => prev + 1);
//                     setToggleFlipState(false);
//                   }}
//                 >
//                   <FaLongArrowAltRight />
//                 </button>
//               )}
//               {
//                 <button
//                   onClick={() => {
//                     setToggleFlipState(true);
//                   }}
//                   className={style.showAnswerMobile}
//                 >
//                   SHOW ANSWER
//                 </button>
//               }
//             </div>
//           </div>
//           <div
//             className={`${style.right} ${!toggleFlipState ? style.hide : ""}`}
//           >
//             <FlipCard
//               answer={renderQuestion(qstIndex, "answer")}
//               toggleState={toggleFlipState}
//               callback={() => setToggleFlipState(false)}
//             />
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

import React, { useContext, useEffect, useState } from "react";
import style from "./Review.module.css";
import ExamPrepStorage from "../../storage/ExamPrepStorage";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { FaLongArrowAltLeft, FaLongArrowAltRight } from "react-icons/fa";
import FlipCard from "./FlipCard";
import TextView from "../../ExamPrepBuckets/Assessment/QuestionTextView/TextView";
import ImageView from "../../ExamPrepBuckets/Assessment/QuestionImageView/ImageView";
import VideoPlayer from "../../../../components/VideoPlayer/VideoPlayer";

export const Review = () => {
  const [qstIndex, setQstIndex] = useState(0);
  const [toggleFlipState, setToggleFlipState] = useState(false);
  const [toggleFlipStateMobile, setToggleFlipStateMobile] = useState(false);
  const { updateStorage, assessmentModalToggle, questions } =
    useContext(ExamPrepStorage);

  // This always ensures MathJax is loaded when a new page is generated
  useEffect(() => {
    if (typeof window?.MathJax !== "undefined") {
      window.MathJax.typesetClear();
      window.MathJax.typeset();
    }
  });

  const totalQuestions = questions?.length || 0;

  const renderQuestion = (index, source) => {
    const data = questions[index];

    return (
      <>
        <TextView question={data[source]?.text} />
        <ImageView
          imageUrl={data[source].imageUrl}
          question={data[source]?.imageUrl}
        />
        <VideoPlayer
          src={data[source].videoUrl}
          type={data[source]?.videoUrl}
        />
      </>
    );
  };

  return (
    <div className={style.rootPage}>
      <div className={style.close}>
        <button
          onClick={() =>
            updateStorage({
              assessmentModalToggle: !assessmentModalToggle,
              modalPage: "Review",
            })
          }
        >
          <AiOutlineCloseCircle />
        </button>
      </div>
      {questions && questions.length && (
        <div className={style.content}>
          <div className={style.left}>
            <h3>Question</h3>
            <p className={style.pre} style={{ overflowY: "scroll", flex: 1 }}>
              {renderQuestion(qstIndex, "question")}
            </p>
            <div className={style.footer}>
              {qstIndex > 0 && (
                <button
                  onClick={() => {
                    setQstIndex((prev) => prev - 1);
                    setToggleFlipState((prev) => !prev);
                    setToggleFlipStateMobile(false);
                  }}
                >
                  <FaLongArrowAltLeft />
                </button>
              )}
              {qstIndex < totalQuestions - 1 && (
                <button
                  onClick={() => {
                    setQstIndex((prev) => prev + 1);
                    setToggleFlipState((prev) => !prev);
                    setToggleFlipStateMobile(false);
                  }}
                >
                  <FaLongArrowAltRight />
                </button>
              )}
              {
                <button
                  onClick={() => {
                    setToggleFlipState((prev) => !prev);
                    setToggleFlipStateMobile(true);
                  }}
                  className={style.showAnswerMobile}
                >
                  SHOW ANSWER
                </button>
              }
            </div>
          </div>
          <div
            className={`${style.right} ${
              toggleFlipStateMobile ? "" : style.hide
            }`}
          >
            <FlipCard
              answer={renderQuestion(qstIndex, "answer")}
              toggleState={toggleFlipState}
              toggleStateMobile={toggleFlipStateMobile}
              callback={() => setToggleFlipStateMobile(false)}
            />
          </div>
        </div>
      )}
    </div>
  );
};
