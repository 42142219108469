import React, { useEffect } from "react";
import style from "./PageLayout.module.css";
import classNames from "classnames";

let componentCounter = 0;

const setBgColor = (color, elId) => {
  const el = document.getElementById(elId);
  if (el) el.style.backgroundColor = color;
};

export const RowLayout = (props) => {
  const componentId = `component-${componentCounter++}`;

  useEffect(() => {
    props.bg && setBgColor(props.bg, componentId);
  }, [props, componentId]);
  return (
    <div className={`${style.row} ${props?.classes}`} id={componentId}>
      {props.children}
    </div>
  );
};

export const ColumnLayout = ({ children, _positionRight, className }) => {
  return (
    <div className={classNames(style.column, className)}>
      <div className={_positionRight && style.positionRight}>{children}</div>
    </div>
  );
};

export const RowLayout2 = (props) => {
  // const componentId = `component-${componentCounter++}`;

  // useEffect(() => {
  //   props.bg && setBgColor(props.bg, componentId);
  // }, [props, componentId]);
  return <div className={style.row2}>{props.children}</div>;
};

export const ColumnLayout2 = ({ children, _positionRight }) => {
  return (
    <div className={style.column2}>
      <div className={_positionRight && style.positionRight}>{children}</div>
    </div>
  );
};
