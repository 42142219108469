import BookCatalogCard from "../../../components/BookCatalogCard";
// eslint-disable-next-line
import SearchField from "../../../components/SearchField";
import classes from "./ManageQuestionCategory.module.css";
import { catalogBgColors, catalogColors } from "./constants";
import useBookCatalog from "./hooks/useBookCatalog";
import SkeletonLoader from "../../../components/SkeletonLoader";
import CatalogBanner from "../../../components/CatalogBanner/CatalogBanner";
import { useNavigate } from "react-router-dom";

function ManageQuestionCategory() {
  // eslint-disable-next-line
  const { searchCategories, state, fetchingCategories, dispatch } =
    useBookCatalog();
  // eslint-disable-next-line
  const { searchText } = state;
  const navigate = useNavigate();
  return (
    <div className={classes.pageRoot}>
      <CatalogBanner
        onClick={() => navigate(-1)}
        className={classes.bannerBox}
        bannerTitle="Questions And Answers"
        bannerText="It’s time to roll up your sleeves—we learn best by doing. All of our
        courses are interactive, combining short videos with hands-on exercises.
        It’s the DataCamp way."
      />
      <div className={classes.pageBody}>
        {fetchingCategories ? (
          <div className={classes.catalogItems}>
            {[...new Array(6).values()].map((_, index) => (
              <SkeletonLoader className={classes.skeletonLogLoader} />
            ))}
          </div>
        ) : (
          <div className={classes.catalogItems}>
            {(searchCategories || []).map((category, i) => {
              const string = `${i}`;
              const firstVal = string?.charAt(0);
              return (
                <BookCatalogCard
                  name={category?.name}
                  subcategory={category?.subcategories}
                  colorCode={catalogColors[firstVal]}
                  bgColorCode={catalogBgColors[firstVal]}
                  onSelectSubcategory={(item) =>
                    navigate(`/library/create/questions/${item?._id?.$oid}`)
                  }
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

export default ManageQuestionCategory;
