import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import usePermission from "../utils/hooks/usePerission";

export default function PrivateRoute({ children }) {
  const { user } = useSelector(({ currentUser }) => currentUser);
  const { showChildren } = usePermission();

  if (!showChildren) return <div />;
  return user ? children : <Navigate to="/login" replace />;
}
