import { useState } from "react";
import { useParams } from "react-router-dom";
import libraryService from "../../../../service/library.service";
import { QUERY_KEY } from "../../../../utils/constants";
import useAppQuery from "../../../../utils/hooks/useAppQuery";

function useCourseList() {
  const [selectedSubject, setSelectedSubject] = useState({
    name: "All",
    value: "ALL",
  });
  const { subcategoryId } = useParams();
  const {
    data: { subcategory: { subject = [] } = {} } = {},
    isLoading: fetchSubject,
  } = useAppQuery(
    [QUERY_KEY.getAllSubjectsBySubId],
    libraryService.getAllSubjectsBySubId,
    { subcategoryId: subcategoryId }
  );

  const { data: { data: books = [] } = {}, isLoading: loadingBooks } =
    useAppQuery(
      QUERY_KEY.getBookBySubCategory,
      libraryService.getBookBySubCategory,
      {
        sub_category_id: subcategoryId,
        subject_id: selectedSubject?._id?.$oid,
      },
      {
        enabled: !!subcategoryId || selectedSubject?._id?.$oid,
      }
    );

  return {
    fetchSubject,
    subject,
    selectedSubject,
    loadingBooks,
    books,
    setSelectedSubject,
  };
}

export default useCourseList;
