import {
  DeleteIcon,
  DifficultyIcon,
  DurationIcon,
  EditIcon,
  PublishIcon,
  UnPublishedIcon,
} from "../../../../assets/icons";
import Tooltip from "../../../../components/Tooltip/Tooltip";
import style from "./BucketCard.module.css";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import ConfirmDialog from "../../../../components/ConfirmationDialogCD/ConfirmDialog";
import useCdReactQuery from "../../../../utils/hooks/useCdReactQuery";
import adminService from "../../../../service/admin.service";
import { useSelector } from "react-redux";
import { initCaps } from "../../../../utils/helpers";
import SkeletonLoader from "../../../../components/SkeletonLoader";
import { Loader } from "../../../../components/Modal";
import { toast } from "react-toastify";
// const data = [
//   {
//     _id: "6576a652952df7342faf7aa8",
//     date_created: "10-12-2023 21:21:35",
//     date_modified: "11-12-2023 06:04:02",
//     difficulty_level: "easy",
//     duration: 120,
//     sub_category: {
//       id: "6569a22436ec9881dcd1c95e",
//       name: "Senior Secondary",
//     },
//     subject: {
//       id: "656d22d06f586cd771bb81c3",
//       name: "Mathematics",
//     },
//     submission: {
//       max_score: 0,
//       no_attempts: 0,
//     },
//     title: "Mathematics",
//   },
// ];
const BucketCard = ({ data }) => {
  const navigate = useNavigate();
  const { error, queryEndpoint, isLoading } = useCdReactQuery();
  const [buckets, setBuckets] = useState([]);
  const [isDelete, setIsDelete] = useState(false);
  const { userClass, subject } = useSelector((store) => store.questionBank);
  const [deleteId, setDeleteId] = useState(0);
  const deleteHandler = (questionId) => {
    setDeleteId(questionId);
    setIsDelete(true);
  };

  const fetch = async () => {
    const res = await queryEndpoint({
      endpointUrl: adminService.getBuckets,
      params: {
        // subcategory_id: userClass.id,
        subject_id: subject.id,
        limit: 100,
      },
    });

    setBuckets(res);
  };

  useEffect(() => {
    setBuckets(data);
  }, [data]);

  useEffect(() => {
    fetch();

    // eslint-disable-next-line
  }, [userClass.id, subject.id]);

  useEffect(() => {
    if (error) {
      const error_data = error.response.data;
      toast.error(`
        ${error_data.error || error_data.msg || error_data.detail}
      `);
    }
  }, [error]);

  const deleteBucket = async (bucketId) => {
    const res = await queryEndpoint({
      endpointUrl: adminService.getBucketByID,
      httpMethod: "DELETE",
      params: {
        bucket_id: bucketId,
      },
    });
    if (res.msg) {
      toast.success(res.msg);
      fetch();
    }
  };

  const publishBucket = async (bucket) => {
    const payload = {
      bucket_id: bucket._id,
      is_published: !bucket.is_published,
    };
    const res = await queryEndpoint({
      endpointUrl: adminService.updateBucket,
      httpMethod: "PUT",
      body: payload,
    });

    if (res.msg) {
      const updatedBucket = buckets?.buckets.map((item) => {
        if (item._id === bucket._id) {
          return { ...item, is_published: !bucket.is_published };
        }
        return item;
      });
      setBuckets({ buckets: updatedBucket });
      toast.success(res.msg);
    }
  };

  return (
    <>
      <div className={style.row}>
        {isLoading ? (
          <div className={style.row}>
            {[...new Array(4).values()].map((_, index) => (
              <SkeletonLoader className={style.skeletonLogLoader} key={index} />
            ))}
          </div>
        ) : (
          (buckets?.buckets || []).map((item, index) => (
            <div className={style.questionCard} key={index}>
              <div className={style.actions}>
                {/* TODO: Update url with question id */}
                <span onClick={() => navigate(`/admin/examprep/${item?._id}`)}>
                  <Tooltip data="edit" classes={style.tooltip}>
                    <EditIcon />
                  </Tooltip>
                </span>
                {/* TODO: Update handler with question id */}
                <span onClick={() => deleteHandler(item?._id)}>
                  <Tooltip data="delete" classes={style.tooltip}>
                    <DeleteIcon />
                  </Tooltip>
                </span>
                <span onClick={() => publishBucket(item)}>
                  <Tooltip
                    data={item.is_published ? "Unpublish" : "Publish"}
                    classes={style.tooltip}
                  >
                    {item.is_published ? <UnPublishedIcon /> : <PublishIcon />}
                  </Tooltip>
                </span>
              </div>
              <div
                className={`${style.row} ${style.bucketcard_header_container}`}
              >
                <h5 className={style.topic}>{item?.title}</h5>
                {/* <p className={style.sub_category}>Seconday</p> */}
              </div>

              <div className={`${style.row} ${style.bucket_info}`}>
                <div className={`${style.row} ${style.bucket_content}`}>
                  <DurationIcon /> {item?.duration}mins
                </div>
                <div className={`${style.row} ${style.bucket_content}`}>
                  <DifficultyIcon /> {initCaps(item?.difficulty_level)}
                </div>
              </div>
            </div>
          ))
        )}
      </div>
      {isDelete && (
        <ConfirmDialog
          confirm={async () => {
            deleteBucket(deleteId);
            // call delete action here
            setIsDelete(false);
          }}
          cancel={() => setIsDelete(false)}
          message={"Click continue to DELETE this bucket"}
        />
      )}
      {isLoading && <Loader />}
    </>
  );
};

export default BucketCard;
