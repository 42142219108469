import { useDispatch } from "react-redux";
import questionService from "../../../../service/questions.service";
import { QUERY_KEY } from "../../../../utils/constants";
import useAppMutation from "../../../../utils/hooks/useAppMutation";
import useAppQuery from "../../../../utils/hooks/useAppQuery";
import { showConfirmationPopup } from "../../../../redux/feature/confrimationPopupSlice";
import { setIsloading } from "../../../../redux/feature/progressLoaderSlice";
import { useState } from "react";
import { toast } from "react-toastify";

function useTutorUploadedQuestions() {
  const reduxDispatch = useDispatch();
  const [questionToDelete, setQuestionToDelete] = useState({});
  const {
    data: userQuestions = [],
    refetch,
    isLoading: isFetching,
  } = useAppQuery(
    QUERY_KEY.getUserQuestion,
    questionService.getUserQuestion,
    {}
  );

  const { mutateAsync: asycDeleteQuestion } = useAppMutation(
    QUERY_KEY.deleteQuestion,
    questionService.deleteQuestion
  );
  function deletUploadedQuestion(question) {
    const questionId = question?._id?.$oid;
    reduxDispatch(
      showConfirmationPopup({
        open: true,
        headerText: "Delete Question",
        bodyText: (
          <p>
            You are about to delete a material titled:
            <b style={{ fontWeight: 600 }}>{question.title}</b>
          </p>
        ),
        onConfirm: () => {
          reduxDispatch(setIsloading(true));
          reduxDispatch(showConfirmationPopup({ open: false }));
          setQuestionToDelete({ ...questionToDelete, [questionId]: true });
          asycDeleteQuestion({
            questionId,
          })
            .then(() => {
              toast.success("Deleted successfully");
              refetch();
            })
            .catch(({ detail }) => {
              toast.error(detail);
            })
            .finally(() => {
              const id = questionId;
              const temp = { ...questionToDelete };
              delete temp[id];
              setQuestionToDelete(temp);
              reduxDispatch(setIsloading(false));
            });
        },
        buttonText: "Delete",
      })
    );
  }

  return {
    userQuestions,
    isFetching,
    deletUploadedQuestion,
  };
}

export default useTutorUploadedQuestions;
