import { createContext, useEffect, useMemo, useReducer, useState } from "react";
import useCdReactQuery from "../utils/hooks/useCdReactQuery";
import adminService from "../service/admin.service";

const reducerFn = (state, action) => {
  switch (action.type) {
    case "updateFormState":
      console.log("form state ", action.payload);
      return { ...state, ...action.payload };
    case "updatePages": {
      const updatedPages = state.pages.map((page) =>
        page.pageId === action.payload.pageId ? action.payload : page
      );
      return { ...state, pages: updatedPages };
    }

    default:
      return state;
  }
};

const AdminContext = createContext();

export const AdminContextProvider = ({ children }) => {
  const initState = {
    book_id: "",
    bookType: "",
    language: "",
    subject: "",
    subject_id: "",
    class: "",
    class_id: "",
    topic: "",
    topic_id: "",
    is_published: false,
    title: "",
    bookSummary: "",
    book_cover_url: "",
    pages: [],
    content_type: "",
    price: 0,
  };
  const { queryEndpoint } = useCdReactQuery(null);
  const [state, dispatch] = useReducer(reducerFn, initState);
  const [books, setBooks] = useState([]);
  const [classes, setClasses] = useState([]);
  const [subjects, setSubjects] = useState([]);

  // Initialize data
  useEffect(() => {
    getBooks();
    getClasses();
    getSubjects();
    // eslint-disable-next-line
  }, []);

  // Call save function when cover image is changed and pages is updated
  useEffect(() => {
    saveBook();
    // eslint-disable-next-line
  }, [state.book_cover_url, useMemo(() => state.pages, [state.pages])]);

  const updateFormState = (formData) => {
    dispatch({ type: "updateFormState", payload: formData });
  };

  const updatePages = (pageData) => {
    dispatch({ type: "updatePages", payload: pageData });
  };

  const saveBook = () => {
    // console.log("state ", state);
    const formData = {
      book_type: state.bookType.toLowerCase(),
      description: state.bookSummary,
      is_published: state.is_published,
      language: state.language,
      sub_category_id: state.class_id,
      subject_id: state.subject_id,
      pages: state.pages,
      title: state.title,
      topic_id: state.topic_id,
      book_cover_url: state.book_cover_url,
      content_type: state.content_type.toLowerCase(),
      price: parseInt(state.price),
    };

    // prevent save when state is cleared at the same prevent it from not updating the database due to strict check
    if (
      state.book_id &&
      (!!state.bookType === true ||
        !!state.bookSummary === true ||
        !!state.language === true ||
        !!state.class_id === true ||
        !!state.subject_id === true ||
        (!!state.pages && state.pages.length > 0) === true ||
        !!state.title === true ||
        !!state.topic_id === true ||
        !!state.book_cover_url === true)
    ) {
      (async () => {
        await queryEndpoint({
          endpointUrl: adminService.getBook(state.book_id),
          httpMethod: "PUT",
          body: formData,
        });
        getBooks();
      })();
    }
  };

  const updatePulishStatus = (status = false) => {
    (async () => {
      await queryEndpoint({
        endpointUrl: adminService.getBook(state.book_id),
        httpMethod: "PUT",
        body: { is_published: status },
      });
      getBooks();
    })();
  };

  // This will register the user id to a book without data in the book collections.
  // It also, returns the new book ID for possible update actions
  const createBook = async () => {
    const data = await queryEndpoint({
      endpointUrl: adminService.createBook,
      httpMethod: "POST",
      body: {},
    });
    updateFormState({ ...initState, book_id: data.data._id });
    getBooks();
    return data.data._id;
  };

  const deleteBook = (book_id) => {
    (async () => {
      await queryEndpoint({
        endpointUrl: adminService.getBook(book_id),
        httpMethod: "DELETE",
      });
      getBooks();
    })();
  };

  const getBooks = () => {
    (async () => {
      const data = await queryEndpoint({
        endpointUrl: adminService.getBooks,
        httpMethod: "GET",
      });
      setBooks(data);
    })();
  };

  const getClasses = () => {
    (async () => {
      const data = await queryEndpoint({
        endpointUrl: adminService.getClasses,
        httpMethod: "GET",
      });
      setClasses(data?.data);
    })();
  };

  const getSubjects = () => {
    (async () => {
      const data = await queryEndpoint({
        endpointUrl: adminService.getSubjects,
        httpMethod: "GET",
      });
      setSubjects(data?.data);
    })();
  };

  const context = {
    state,
    updateFormState,
    updatePages,
    saveBook,
    createBook,
    getBooks,
    books,
    classes,
    subjects,
    deleteBook,
    updatePulishStatus,
  };
  return (
    <AdminContext.Provider value={context}>{children}</AdminContext.Provider>
  );
};

export default AdminContext;
