import React from "react";
import style from "./ParagraphView.module.css";

const ParagraphView = ({ paragraph, question }) => {
  return (
    <>
      <div className={style.paragraphDiv}>
        <p>Paragraph</p>
        <div className={style.paragraph}>{paragraph}</div>
      </div>
      <div className={style.question}>
        {/* <h5>Question :</h5> */}
        <p>{question}</p>
      </div>
    </>
  );
};

export default ParagraphView;
