import libraryService from "../../../../service/library.service";
import useCdReactQuery from "../../../../utils/hooks/useCdReactQuery";


const useAdminCategory = () => {
    const { queryEndpoint } = useCdReactQuery();

    const deleteCategory = async (id) => {
        const resp = await queryEndpoint({
            endpointUrl : libraryService.categoryById(id),
            httpMethod : "DELETE",
        });

        return await resp
        
    }

    const createCategory = async (item) => {
        const resp = await queryEndpoint({
            endpointUrl : libraryService.categories,
            httpMethod : "POST",
            body : JSON.stringify(item),
        });

        return await resp
    }

    const editCategory = async (item) => {
        const resp = await queryEndpoint({
            endpointUrl : libraryService.categories,
            httpMethod : "PUT",
            body : JSON.stringify(item),
        });

        return await resp
    }

    return {
        createCategory,
        deleteCategory,
        editCategory,

    }

}

export default useAdminCategory;