import { useLocation, useNavigate } from "react-router-dom";
import classes from "./SideMenu.module.css";
import { navPaths, libraryPaths, examPrepPaths } from "./constants";
import { useEffect, useState } from "react";
import classNames from "classnames";
import { RiMenuFoldLine, RiMenuUnfoldLine } from "react-icons/ri";
import useResponsiveness from "../../utils/hooks/useResponsiveness";
import { SCREEN_SIZE } from "../../utils/constants";

function SideMenu() {
  const location = useLocation();
  const navigate = useNavigate();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { responsive } = useResponsiveness(SCREEN_SIZE.sm);

  // validate the path to know what menu to display
  let menu = [];

  // load library menu
  if (/\/library\//.test(location.pathname)) {
    menu = libraryPaths;
  } else if (/\/examprep\//.test(location.pathname)) {
    // load exam prep menu
    menu = examPrepPaths;
  } else {
    // load main menu
    menu = navPaths;
  }
  useEffect(() => {
    setIsCollapsed(responsive);
  }, [responsive]);

  return (
    <div
      className={classNames(
        classes.compRoot,
        isCollapsed ? classes.componentRootCollapse : undefined
      )}
    >
      <button
        className={classNames(
          classes.menuToggleButton,
          isCollapsed ? classes.menuToggleCollapsed : undefined
        )}
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        {isCollapsed ? <RiMenuUnfoldLine /> : <RiMenuFoldLine />}
      </button>
      <div
        className={classes.pathWrapper}
        style={{ display: isCollapsed ? "none" : undefined }}
      >
        {menu.map((path, index) => (
          <div
            key={index}
            onClick={() => navigate(path.path)}
            className={`${classes.navPath} ${
              path?.regex?.test(location.pathname)
                ? classes.activeLink
                : undefined
            }`}
          >
            <div className={classes.navName}>{path.name}</div>
            <path.icon className={classes.menuIcon} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default SideMenu;
