import InputField from "../../../components/InputField";
import TextArea from "../../../components/TextArea";
import style from "./ContactUI2.module.css";
import Dropdown from "../../../components/Dropdown";
import ReCAPTCHA from "react-google-recaptcha";
import useContactUI2 from "./useContactUI2";
import Button from "../../../components/Button";

const ContactUI2 = ({ forwardedRef }) => {
  const {
    loading,
    formData,
    setFormData,
    queryType,
    debouncedFn,
    dropdownHandler,
    sumbmitContact,
  } = useContactUI2();

  return (
    <div ref={forwardedRef} className={style.root}>
      <div className={style.leftContainer}>
        <h2>We'd love to help</h2>
        <p>
          Our customer care team are ready to answer your questions. Will get in
          touch within 24hrs.
        </p>
        <div className={style.form}>
          <div>
            <i>Name</i>
            <InputField
              placeholder="John Doe"
              value={formData.name}
              onChange={({ target: { value } }) => {
                debouncedFn({ name: value });
              }}
            />
          </div>
          <div>
            <i>Email</i>
            <InputField
              placeholder="you@company.com"
              value={formData.email}
              onChange={({ target: { value } }) => {
                debouncedFn({ email: value });
              }}
            />
          </div>
          <div>
            <i>Query type</i>
            <Dropdown
              options={[
                { name: "Sales Inquiry", id: 1 },
                { name: "Technical Support", id: 2 },
                { name: "Billing Issue", id: 3 },
                { name: "Product Information", id: 4 },
                { name: "Feedback", id: 5 },
              ]}
              value={queryType}
              onChange={dropdownHandler}
            />
          </div>
          <div>
            <i>Message</i>
            <TextArea
              placeholder="Leave us a message"
              value={formData.message}
              onChange={({ target: { value } }) => {
                debouncedFn({ message: value });
              }}
            />
          </div>
          <div>
            <ReCAPTCHA
              sitekey="6LdrjQoqAAAAAP0W-QljB2Uu-djbXSIlsdQWHnhP"
              onChange={(value) =>
                setFormData((prev) => ({
                  ...prev,
                  recaptcha_token: value,
                }))
              }
            />
          </div>
          <div className={style.submitAction}>
            <Button
              type="submit"
              className={style.submitBtn}
              isLoading={loading}
              onClick={sumbmitContact}
            >
              Send message
            </Button>
          </div>
        </div>
      </div>
      <div className={style.rightContainer}></div>
    </div>
  );
};

export default ContactUI2;
