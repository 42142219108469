import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  // facebookIcon,
  // googleIcon,
  hourGlassImage,
  topUpImage,
} from "../../assets/image";
import Button from "../../components/Button";
import InputField from "../../components/InputField";
import AuthLayout from "../../layouts/AuthLayout";
import useLogin from "./hooks/useLogin";
import style from "./LogIn.module.css";
import { IoCloseCircle } from "react-icons/io5";
import { MdOutlineKeyboardBackspace } from "react-icons/md";

function LogIn() {
  const {
    state,
    isLoading,
    handleLogin,
    dispatch,
    popup,
    togglePopup,
    handleTopUp,
    handleSubRenew,
    loading,
  } = useLogin();
  const { password, email } = state;
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };
  const history = useNavigate();
  return (
    <AuthLayout>
      <div className={style.root}>
        <div className={style.mainBody}>
          <button
            className={`${style.mobile} ${style.back}`}
            href="#"
            onClick={() => history("/")}
          >
            <MdOutlineKeyboardBackspace />
            <span style={{ fontSize: "1em", paddingLeft: "5px" }}>
              ctrLearn
            </span>
          </button>
          <div className={style.formRootWrapper}>
            <div className={style.formContainer}>
              <h2 className={style.header}>Login</h2>
              <p>Welcome back!!!</p>
              <form onSubmit={handleLogin}>
                <div className={style.formField}>
                  <div className={style.inputRow}>
                    <InputField
                      type="email"
                      name="email"
                      value={email}
                      onChange={({ target }) =>
                        dispatch({ email: target.value })
                      }
                      placeholder="E-mail"
                      className={style.inputField}
                      required
                    />
                  </div>

                  <div className={style.inputRow}>
                    <InputField
                      type="password"
                      name="password"
                      placeholder="Password"
                      onChange={({ target }) =>
                        dispatch({ password: target.value })
                      }
                      value={password}
                      className={style.inputField}
                      required
                    />
                  </div>
                </div>
                <div className={style.decision}>
                  <div className={style.checkboxField}>
                    <input type="checkbox" />
                    <label>Remember me</label>
                  </div>
                  <div className={style.forget}>
                    <Link to="/reset">
                      <p>Forgot Password?</p>
                    </Link>
                  </div>
                </div>
                <div className={style.actionsSection}>
                  <Button
                    type="submit"
                    className={style.loginSubmit}
                    isLoading={isLoading}
                  >
                    Login
                  </Button>
                </div>
              </form>
              <div className={style.routeLogin}>
                Don't have an account?{" "}
                <Link onClick={toggleModal}>
                  <span>Sign-Up</span>
                </Link>
              </div>
              {/* Social login implementation is pending */}
              {/* <div className={style.actionsSection}>
                <div className={style.socialSignUp}>
                  <p className={style.option}>
                    Login with social media account
                  </p>
                  <div className={style.mySocialIcon}>
                    <button className={style.socialSignUpButton}>
                      <img
                        className={style.socialIcon}
                        src={facebookIcon}
                        alt=""
                      />
                    </button>
                    <button className={style.socialSignUpButton}>
                      <img
                        className={style.socialIcon}
                        src={googleIcon}
                        alt=""
                      />
                    </button>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div
        onClick={toggleModal}
        className={`${style.auth__modal} ${
          isOpen ? style.showModal : undefined
        }`}
      >
        <div
          className={`${style.popup} ${style.centerAll}`}
          onClick={(event) => event.stopPropagation()}
        >
          <div className={`${style.popup__body} `}>
            <div className={`${style.heading}`}>
              <h4>REGISTRATION</h4>
              <span>Please select an account type to register</span>
            </div>
            <div className={style.regType}>
              <Link to="/register/student">
                <button
                  className={`${style.popupLinkBtn1} ${style.outlinePrimary} ${style.active}`}
                >
                  STUDENT
                </button>
              </Link>
              <Link to="/register/tutor">
                <button
                  className={`${style.popupLinkBtn2} ${style.outlinePrimary}`}
                >
                  TUTOR
                </button>
              </Link>
              <Link to="/register/organization">
                <button
                  className={`${style.popupLinkBtn3} ${style.outlinePrimary}`}
                >
                  ORGANIZATION
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div
        onClick={togglePopup}
        className={`${style.auth__modal} ${
          popup.open ? style.showModal : undefined
        }`}
      >
        <div
          className={`${style.popup} ${style.centerAll} ${style.subscription}`}
          onClick={(event) => event.stopPropagation()}
        >
          <div
            style={{
              textAlign: "right",
              position: "absolute",
              right: 20,
              top: 20,
            }}
          >
            <IoCloseCircle className={style.subIcon} onClick={togglePopup} />
          </div>
          {popup?.type !== "not_enough_units" ? (
            <>
              <img
                // className={style.socialIcon}
                src={hourGlassImage}
                alt=""
                style={{
                  maxWidth: 100,
                  marginBottom: "20px",
                  marginTop: "15px",
                }}
              />
              <h4>Your subscription has expired!</h4>
              <div className={`${style.sub_text_container}`}>
                <span className={`${style.sub_desc}`}>
                  To continue enjoying uninterrupted access to all our premium
                  content and services, please renew your subscription
                </span>
              </div>
              <div className={`${style.btnContainer}`}>
                <Button
                  type="submit"
                  className={style.topUpBtn}
                  isLoading={loading}
                  onClick={handleSubRenew}
                >
                  RENEW SUBSCRIPTION
                </Button>
              </div>
            </>
          ) : (
            <>
              <img
                // className={style.socialIcon}
                src={topUpImage}
                alt=""
                style={{
                  maxWidth: 100,
                  marginBottom: "20px",
                  marginTop: "15px",
                }}
              />
              <h4>Oops! You've reached your monthly limit!</h4>
              <div className={`${style.sub_text_container}`}>
                <span className={`${style.sub_desc}`}>
                  To keep enjoying unlimited access to all our premium content
                  and services, please top up your units now.
                </span>
              </div>

              <div className={`${style.btnContainer}`}>
                <Button
                  type="submit"
                  className={style.topUpBtn}
                  isLoading={loading}
                  onClick={handleTopUp}
                >
                  TOPUP UNITS
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </AuthLayout>
  );
}

export default LogIn;
