import React from "react";
import Navbar from "../../layouts/Navbar";
import Banner from "../../layouts/Banner";
import BriefAbout from "../../layouts/BriefAbout";
import { ContactUI2 } from "../../layouts/ContactInfo";
import ProductFeatures from "../../layouts/ProductFeatures";
// import Sponsors from "../../layouts/Sponsors";
// import Testimonial from "../../layouts/Testimonial";
// import Subscriptions from "../../layouts/Subscriptions";
import useScrollToLayout from "../../utils/hooks/useScrollToLayout";
import Footer from "../../layouts/Footer/Footer";
import classes from "./HomePage.module.css";

const HomePage = () => {
  const { getScrollPosition } = useScrollToLayout();
  return (
    <div style={{ position: "relative" }}>
      <div className={classes.pageBody}>
        <Navbar forwardedRef={getScrollPosition("home")} />
        <Banner forwardedRef={getScrollPosition("home")} />
        <BriefAbout forwardedRef={getScrollPosition("about")} />
        {/* <Sponsors /> */}
        <ProductFeatures forwardedRef={getScrollPosition("features")} />
        {/* <Testimonial /> */}
        {/* <Subscriptions forwardedRef={getScrollPosition("subscriptions")} /> */}
        {/* <ContactInfo forwardedRef={getScrollPosition("contact")} /> */}
        <ContactUI2 forwardedRef={getScrollPosition("contact")} />
      </div>
      <div className={classes.footerSection}>
        <Footer />
      </div>
    </div>
  );
};

export default HomePage;
