import env from "../../../../env";
import libraryService from "../../../../service/library.service";
import useCdReactQuery from "../../../../utils/hooks/useCdReactQuery";

const useAdminLibrary = () => {
  const { queryEndpoint, isLoading } = useCdReactQuery();

  const getSubjects = async () => {
    const response = await queryEndpoint({
      endpointUrl: libraryService.subjects,
    });
    return response?.data;
  };

  const getTopicsBySubject = async (id) => {
    const response = await queryEndpoint({
      endpointUrl: libraryService.getTopicsBySubject(id),
    });
    return response?.data;
  };

  const getTopics = async () => {
    const response = await queryEndpoint({
      endpointUrl: `${env.API_URL}/categories/subcategories`,
    });
    return response?.data;
  };

  const getClasses = async () => {
    const response = await queryEndpoint({
      endpointUrl: libraryService.getClasses,
    });
    return response?.data;
  };

  const getBookTypes = async () => {
    const response = await queryEndpoint({
      endpointUrl: libraryService.getBookTypes,
    });
    return response?.data["book.book_type"].map((type, index) => ({
      id: index + 1,
      name: type,
    }));
  };

  //categories
  const getCategories = async () => {
    const response = await queryEndpoint({
      endpointUrl: libraryService.categories,
    });
    return response.category;
  };
  return {
    getCategories,
    getClasses,
    getSubjects,
    getTopics,
    isLoading,
    getBookTypes,
    getTopicsBySubject,
  };
};
export default useAdminLibrary;
