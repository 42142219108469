import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { toast } from "react-toastify";
import { DeleteIcon, EditIcon, GridAddIcon, SaveIcon, SearchIcon } from "../../../../assets/icons"
import InputField from "../../../../components/InputField"
import { FullpageModal } from "../../../../components/Modal"
import useAdminCategory from "../hooks/useAdminCategory"
import useAdminLibrary from "../../Library/hooks/useAdminLibrary"
import style from "./Categories.module.css"
import TextArea from "../../../../components/TextArea"
import ConfirmDialog from "../../../../components/ConfirmationDialogCD/ConfirmDialog";

const INITIAL_DATA = {name : "", description : ""}

const Categories = () => {
    const { createCategory, deleteCategory } = useAdminCategory()
    const { getCategories, isLoading } = useAdminLibrary()
    const [category, setCategory] = useState(INITIAL_DATA)
    const [categories, setCategories] = useState([])
    const [modalOpen, setModalOpen] = useState(false);
    const [dialogModal, setDialogModal] = useState({ categoryId: "", modal: false });
    const [isEdit, setIsEdit] = useState(false)

    useEffect(() => {
      (async () => {
        setCategories(await getCategories())
      })();

    // eslint-disable-next-line
    }, [])

    const updateCategory = async() => {

    };

    const deleteCategoryHandler = (category_id) => {
      setDialogModal((prev) => ({ categoryId: category_id, modal: !prev.modal }));
    };

    const handleDelete =  async(id) => {
        const resp = await deleteCategory(id)
        if(resp.category){
            toast.success(resp.category);
            setCategories(curr => curr.filter(cat => cat.id !==  id))
        }else{
            toast.error(resp.error);
        }
    }

    const saveCategory = async () => {
        const resp = await createCategory(category)
        if(resp.category){
            toast.success("Category created successfully")
            setCategory(INITIAL_DATA)
            toggleModal()
            setCategories([...categories, resp.category])
        }
        else{
            toast.error("Error creating category")
        }
    }

    const toggleModal = () => {
      setModalOpen(!modalOpen)
    }

    return (
        <div className={style.root}>
      <div className={style.bookWrapper}>
        <div className={style.header}>
          <span><Link to="/admin/settings/">Back</Link></span>
          <div className={style.right}>
            <i className={style.searchIcon}>
              <SearchIcon />
            </i>
            <i>
              <GridAddIcon title="Add subject" onClick={() => {
                setIsEdit(false)
                setCategory(INITIAL_DATA)
                toggleModal()
              }}/>
            </i>
          </div>
        </div>
        <div className={style.scrollWrapper}>
          <table className={style.bookList}>
            <thead>
              <tr>
                <th>Name</th>
                <th className={style.center}>Description</th>
                <th className={style.center}>Action</th>
              </tr>
            </thead>
            <tbody className={style.items}>
              {isLoading ? (
                "Loading all categories"
              ) : categories && categories.length > 0 ? (
                categories.map((item, index) => (
                  <tr key={index}>
                    <td>
                      {item.name}
                    </td>
                    <td className={style.center}>
                      {item.description || "-"}
                    </td>
                    <td className={`${style.actions} ${style.center}`}>
                      <i onClick={() => {
                        setCategory(item)
                        setIsEdit(true)
                        toggleModal()
                      }}>
                        <EditIcon />
                      </i>{" "}
                      <i>
                        <DeleteIcon onClick={() => deleteCategoryHandler(item.id)}/>
                      </i>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colspan="6">No record found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {modalOpen && (
        <FullpageModal
            toggleModal={toggleModal}
            className={style.previewSize}
          >
            <h5 style={{fontWeight : 700, textAlign : "center"}}>{isEdit ? "Edit" : "Add"} Category</h5>
            <div className={style.scrollWrapper}>
              <div className={style.fluid}>
                  <div className={style.formGroup}>
                      <div className={style.label}>Name</div>
                      <InputField
                          className={style.title}
                          value={category.name}
                          onChange={e => setCategory({...category, name : e.target.value})}
                      />
                  </div>
                  <div className={style.formGroup}>
                        <div className={style.label}>Description</div>
                        <TextArea
                        onChange={(e) => setCategory({...category, description : e.target.value})}
                        rows={4}
                        name="description"
                        className={style.textarea}
                        value={category.description}
                        />
                  </div>
                  <div>
                    {isEdit ? (
                      <button className={style.btn} onClick={updateCategory}>
                        Edit <EditIcon />
                      </button>
                    ) : (
                      <button className={style.btn} onClick={saveCategory}>
                        Save <SaveIcon />
                      </button>
                    )}
                  </div>
              </div>
            </div>
        </FullpageModal>
      )}
      {dialogModal.modal && (
        <ConfirmDialog
          confirm={() => {
            handleDelete(dialogModal.categoryId);
            setDialogModal((prev) => ({ categoryId: "", modal: false }));
          }}
          cancel={() =>
            setDialogModal((prev) => ({ ...prev, modal: !prev.modal }))
          }
          message="Click continue to DELETE this category"
        />
      )}
    </div>
    )
}

export default Categories;