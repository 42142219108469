import { useContext, useEffect, useReducer } from "react";
import useCdReactQuery from "../../../../../utils/hooks/useCdReactQuery";
import adminService from "../../../../../service/admin.service";
import { toast } from "react-toastify";
import useFileStorage from "../../../../../utils/hooks/useFileStorage";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ExamPrepStorage from "../../../../ExamPrep/storage/ExamPrepStorage";

function useExamprepManageReview({ isEdit = false }) {
  const { queryEndpoint } = useCdReactQuery();
  const { updateStorage, assessmentModalToggle } = useContext(ExamPrepStorage);

  const { queryEndpoint: deleteFn, isLoading: isDeleting } = useCdReactQuery();
  const { addImage, deleteImage } = useFileStorage();
  const navigate = useNavigate();
  const { reviewId } = useParams();
  const { userClass, subject } = useSelector(
    ({ questionBank }) => questionBank
  );
  const initialState = {
    isUploadingImage: { question: false, answer: false },
    isSaving: false,
    selectedSubject: {},
    selectedClass: {},
    topic: {},
    reviews: [],
    isFetchingReview: false,
    isPublished: false,
    isChangeTopic: false,
  };
  const [state, dispatch] = useReducer(
    (stateVal, value) => ({ ...stateVal, ...value }),
    initialState
  );

  const {
    reviews,
    selectedSubject,
    isPublished,
    selectedClass,
    topic,
    isUploadingImage,
  } = state;

  useEffect(() => {
    dispatch({
      selectedSubject: subject,
      selectedClass: userClass,
    });
  }, [userClass, subject]);

  async function fetchReviewById() {
    if (!isEdit) return;
    dispatch({ isFetchingReview: true });
    const reviewResponse = await queryEndpoint({
      endpointUrl: adminService.getReviewById(reviewId),
      httpMethod: "GET",
      body: {},
    });

    if (reviewResponse?.data) {
      const { data = {} } = reviewResponse;
      dispatch({
        selectedSubject: data?.subject,
        selectedClass: data?.subcategory,
        topic: data?.topic,
        reviews: data?.questions,
        isPublished: data?.is_published,
      });
    }
    dispatch({ isFetchingReview: false });
  }

  useEffect(() => {
    fetchReviewById();
  }, [reviewId]); // eslint-disable-line

  function handleAddReview() {
    dispatch({
      reviews: [
        ...reviews,
        {
          question: {
            text: "",
            imageUrl: "",
            videoUrl: "",
          },
          answer: {
            text: "",
            imageUrl: "",
            videoUrl: "",
          },
        },
      ],
    });
  }

  function onDeleteReview(index, imageUrl) {
    const allReviews = [...reviews];
    delete allReviews[index];
    imageUrl && deleteImage(imageUrl);
    dispatch({ reviews: allReviews.filter(({ question }) => question) });
  }
  function onQuestionChange(e, index) {
    const { value } = e.target;
    const allReviews = [...reviews];
    allReviews[index].question.text = value;
    dispatch({ reviews: allReviews });
  }
  function onAnswerChange(e, index) {
    const { value } = e.target;
    const allReviews = [...reviews];
    const reviewToEdit = allReviews[index];
    reviewToEdit.answer.text = value;
    allReviews[index] = reviewToEdit;

    dispatch({ reviews: allReviews });
  }

  const submitHandler = async () => {
    dispatch({ isSaving: true });
    try {
      await queryEndpoint({
        endpointUrl:
          adminService[isEdit ? "reviewAddQuestion" : "courseReview"],
        httpMethod: isEdit ? "PATCH" : "POST",
        body: {
          [isEdit ? "subcategory_id" : "class_id"]: selectedClass?.id,
          subject_id: selectedSubject?.id,
          ...(isEdit ? { review_id: reviewId } : {}),
          is_published: isPublished,
          topic_id: topic?._id?.$oid,
          questions: reviews,
        },
      });
      toast.success("Review saved successfully");
      navigate(-1);
    } catch (error) {
      toast.error(error?.error);
    }
    dispatch({ isSaving: false });
  };

  function onUploadImage(event, index, imageUrl, inputSource) {
    const file = event.target.files[0];

    if (!file) return;
    const pathFolder = `review_${index}`;
    const form = new FormData();
    form.append("folder", pathFolder);
    form.append("file", file);
    imageUrl && deleteImage(imageUrl);
    dispatch({
      isUploadingImage: { ...isUploadingImage, [inputSource]: true },
    });
    addImage(form)
      .then((url) => {
        const allReviews = [...reviews];
        allReviews[index][inputSource].imageUrl = url;
        dispatch({ reviews: allReviews });
      })
      .catch((error) => {
        console.log(error);
        toast.error("error occurred");
      })
      .finally(() => {
        dispatch({
          isUploadingImage: { ...isUploadingImage, [inputSource]: false },
        });
      });
  }
  async function deleteReview() {
    dispatch({ dialogModal: false });
    const deleteResponse = await deleteFn({
      endpointUrl: adminService.deleteReview(reviewId),
      httpMethod: "DELETE",
    });
    if (deleteResponse) {
      toast.success("review deleted successfully");
      navigate(-1);
      fetch();
    }
  }

  const previewReview = () => {
    updateStorage({
      ...state,
      questions: reviews,
      assessmentModalToggle: !assessmentModalToggle,
      modalPage: "Review",
      preview_data: {},
      subject: subject,
    });
  };

  const { queryEndpoint: managePublisher, isLoading: isUpdatingPublish } =
    useCdReactQuery();

  async function onPublish() {
    try {
      await managePublisher({
        endpointUrl: adminService.publishReview,
        httpMethod: "PATCH",
        body: {
          is_published: !isPublished,
          review_id: reviewId,
        },
      });
      toast.success(
        isPublished
          ? "Review unpublished successfully"
          : "Review published successfully"
      );
      navigate(-1);
    } catch (error) {
      console.error(error);
    }
  }

  return {
    state,
    isDeleting,
    assessmentModalToggle,
    isUpdatingPublish,
    onPublish,
    dispatch,
    handleAddReview,
    onDeleteReview,
    onQuestionChange,
    onAnswerChange,
    submitHandler,
    onUploadImage,
    deleteReview,
    previewReview,
  };
}

export default useExamprepManageReview;
