import style from "./NotePreview.module.css";
import { AiOutlineExpand } from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";
import { GrEdit } from "react-icons/gr";
import NoteModal from "./NoteModal";
import NoteForm from "./form/NoteForm";
import { useContext, useState } from "react";
import NoteDetails from "./form/NoteDetails";
import ConfirmDialog from "../../../components/ConfirmationDialogCD";
import { NoteContext } from "./noteContext";
const NotePreview = ({ data }) => {
  const { title, content, note_style, dateCreated } = data;
  const [formView, setFormView] = useState("view");
  const { dispatch } = useContext(NoteContext);

  const [toggleModal, setToggleModal] = useState(false);

  const modalHandler = () => setToggleModal((prev) => !prev);

  const componentHolder = {
    edit: (
      <NoteForm
        data={data}
        formAction="UPDATE_NOTE"
        closeModal={modalHandler}
      />
    ),
    view: (
      <NoteDetails
        themeColor={note_style}
        data={data}
        formAction="UPDATE_NOTE"
        closeModal={modalHandler}
      />
    ),
    delete: (
      <ConfirmDialog
        message="Are you sure you want to delete this note?"
        cancel={() => modalHandler()}
        confirm={() => {
          dispatch({ type: "DELETE_NOTE", payload: data._id });
          modalHandler();
        }}
      />
    ),
  };
  const actionHandler = (action) => {
    setFormView((prev) => action);
    modalHandler();
  };

  return (
    <>
      <div className={style.note} style={{ ...note_style }}>
        <p className={style.title}>{title || "Untitled"}</p>
        <p className={style.dateCreated}>{dateCreated}</p>
        <pre className={style.content}>{content[0]}</pre>
        <div className={style.actions}>
          <i onClick={() => actionHandler("view")}>
            <AiOutlineExpand />
          </i>
          <i onClick={() => actionHandler("edit")}>
            <GrEdit />
          </i>
          <i onClick={() => actionHandler("delete")}>
            <RiDeleteBin6Line />
          </i>
        </div>
      </div>
      {toggleModal && <NoteModal>{componentHolder[formView]}</NoteModal>}
    </>
  );
};
export default NotePreview;
