import { useDispatch } from "react-redux";
import coursesService from "../../../../service/courses.service";
import { QUERY_KEY } from "../../../../utils/constants";
import useAppQuery from "../../../../utils/hooks/useAppQuery";
import { showConfirmationPopup } from "../../../../redux/feature/confrimationPopupSlice";
import useAppMutation from "../../../../utils/hooks/useAppMutation";
import { toast } from "react-toastify";
import { useState } from "react";

function useMyCourse() {
  const reduxDispatch = useDispatch();
  const [curriculumToDelete, setCurriculumToDelete] = useState({});
  const {
    data: curriculumUploaded = [],
    refetch,
    isLoading: isFetching,
  } = useAppQuery(
    [QUERY_KEY.getUserCurriculum],
    coursesService.getUserCurriculum,
    {}
  );

  const { mutateAsync: asycDelete } = useAppMutation(
    QUERY_KEY.deletUploadedCurriculum,
    coursesService.deletUploadedCurriculum
  );

  function deletUploadedCurriculum(curriculum) {
    const curriculumId = curriculum?._id?.$oid;
    reduxDispatch(
      showConfirmationPopup({
        open: true,
        headerText: "Delete Account",
        bodyText: (
          <p>
            You are about to delete a material titled:{" "}
            <b style={{ fontWeight: 600 }}>{curriculum.title}</b>
          </p>
        ),
        onConfirm: () => {
          reduxDispatch(showConfirmationPopup({ open: false }));
          setCurriculumToDelete({
            ...curriculumToDelete,
            [curriculumId]: true,
          });
          asycDelete({
            curriculumId,
            filenames: [curriculum?.book_cover_filename],
          })
            .then(() => {
              toast.success("Deleted successfully");
              refetch();
            })
            .catch(({ detail }) => {
              toast.error(detail);
            })
            .finally(() => {
              const id = curriculumId;
              const temp = { ...curriculumToDelete };
              delete temp[id];
              setCurriculumToDelete(temp);
            });
        },
        buttonText: "Delete",
      })
    );
  }

  return {
    curriculumUploaded,
    curriculumToDelete,
    isFetching,
    deletUploadedCurriculum,
  };
}

export default useMyCourse;
