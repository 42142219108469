import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import libraryService from "../../../../service/library.service";
import { QUERY_KEY } from "../../../../utils/constants";
import useAppMutation from "../../../../utils/hooks/useAppMutation";
import useAppQuery from "../../../../utils/hooks/useAppQuery";
import { showConfirmationPopup } from "../../../../redux/feature/confrimationPopupSlice";

function useManageCourses() {
  const [bookToDelete, setBookToDelete] = useState({});
  const reduxDispatch = useDispatch();
  const {
    data: courseUploaded = [],
    refetch,
    isLoading: isFetching,
  } = useAppQuery(QUERY_KEY.getMyBooks, libraryService.getMyBooks, {});

  const { mutateAsync: asycDelete } = useAppMutation(
    QUERY_KEY.deletUploadedBook,
    libraryService.deletUploadedBook
  );
  function deletUploadedBook(book) {
    const bookId = book?._id?.$oid;

    console.log(book);
    reduxDispatch(
      showConfirmationPopup({
        open: true,
        headerText: "Delete Account",
        bodyText: (
          <p>
            You are about to delete a material titled:{" "}
            <b style={{ fontWeight: 600 }}>{book.title}</b>
          </p>
        ),
        onConfirm: () => {
          reduxDispatch(showConfirmationPopup({ open: false }));
          setBookToDelete({ ...bookToDelete, [bookId]: true });
          asycDelete({
            bookId,
            filenames: [
              book?.book_cover_filename ? book?.book_cover_filename : "",
            ],
          })
            .then(() => {
              toast.success("Deleted successfully");
              refetch();
            })
            .catch(({ detail, error, msg }) => {
              toast.error(detail || error || msg);
            })
            .finally(() => {
              const id = bookId;
              const temp = { ...bookToDelete };
              delete temp[id];
              setBookToDelete(temp);
            });
        },
        buttonText: "Delete",
      })
    );
  }

  return {
    isFetching,
    courseUploaded,
    bookToDelete,
    deletUploadedBook,
  };
}

export default useManageCourses;
