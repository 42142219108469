import { useEffect, useReducer } from "react";
import { useSelector } from "react-redux";

function useRequestHelp(courseDetails) {
  const { user } = useSelector(({ currentUser }) => currentUser);

  const initialState = {
    courseTitle: "",
    helpText: "",
  };
  const [state, dispatch] = useReducer(
    (stateValue, value) => ({ ...stateValue, ...value }),
    initialState
  );
  useEffect(() => {
    dispatch({
      courseTitle: courseDetails?.title,
    });
  }, [courseDetails]);
  return {
    state,
    user,
    dispatch,
  };
}

export default useRequestHelp;
