import { defaultImage } from "../../assets/image";

export const DUMMY_DATA = {
  // index:0,
  // name
  // pageId:1, the index, name and pageId is managed from the code that renders the template
  title: "Title here",
  paragraph1: `** *** ******** **** ** ******* ** ******* ** ******* ******, 
  ******* ****** ******* ****** *** *******, **** ******** ** * *********`,
  paragraph2: `** *** ******** **** ** ******* ** ******* ** ******* ******, 
  ******* ****** ******* ****** *** *******, **** ******** ** * *********`,
  image: defaultImage,
};
