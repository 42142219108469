import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import classes from "./StarRating.module.css";
import { useEffect, useState } from "react";
import { FaStarHalfAlt } from "react-icons/fa";

function StarRating({ rating, className, isEditable, callbackFn }) {
  const [ratingScore, setRatingScore] = useState(rating);
  // remove selection if user pick same value twice
  const validateRating = (prev, curr) => (prev === curr ? 0 : curr);

  const clickHandler = (_rating) => {
    if (isEditable) {
      setRatingScore((prev) => {
        const validation = validateRating(prev, _rating);
        callbackFn(validation);
        return validation;
      });
    }
  };

  useEffect(() => {
    setRatingScore(rating);
  }, [rating]);

  return (
    <div className={classes.ratingStar}>
      {[...Array(5)].map((star, index) => {
        const value = index + 1;
        return value <= ratingScore ? (
          <AiFillStar
            key={index}
            className={`${classes.starIcon} ${className} ${
              isEditable ? classes.clickable : ""
            }`}
            onClick={() => clickHandler(index + 1)}
          />
        ) : value - 0.5 === ratingScore ? (
          <FaStarHalfAlt className={`${classes.starHalfIcon} ${className}`} />
        ) : (
          <AiOutlineStar
            key={index}
            className={`${classes.starIcon} ${className} ${
              isEditable ? classes.clickable : ""
            }`}
            onClick={() => clickHandler(index + 1)}
          />
        );
      })}{" "}
      {/* check if rating has fraction */}
      {/* {rating?.toString().includes(".") && (
        <FaStarHalfAlt className={`${classes.starIcon} ${className}`} />
      )} */}
      {/* display total rating for comment form only */}
      {isEditable && (
        <span className={classes.ratingScore}>[{ratingScore} star] </span>
      )}
    </div>
  );
}

export default StarRating;
