import { MdOutlineKeyboardBackspace } from "react-icons/md";
import classes from "./Banner2.module.css";

function BannerAndSearch({ onClick, label, text }) {
  return (
    <div className={classes.courseBanner}>
      {(!!label || !!onClick) && (
        <button onClick={onClick} className={classes.courseTypeText}>
          {!!onClick && (
            <MdOutlineKeyboardBackspace className={classes.backIcon} />
          )}
          <span>{label}</span>
        </button>
      )}
      <div className={classes.bannerTextWrap}>{text}</div>
    </div>
  );
}

export default BannerAndSearch;
