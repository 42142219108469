import { MdAdd } from "react-icons/md";
import NotePreview from "./NotePreview";
import style from "./Notes.module.css";
import NoteModal from "./NoteModal";
import NoteForm from "./form/NoteForm";
import { NoteContext } from "./noteContext";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaLongArrowAltLeft } from "react-icons/fa";
import SkeletonLoader from "../../../components/SkeletonLoader";

function Notes() {
  const { notes, isLoading } = useContext(NoteContext);

  const [toggleModal, setToggleModal] = useState(false);

  const navigate = useNavigate();
  const modalHandler = () => setToggleModal((prev) => !prev);

  return (
    <div className={style.pageWrapper}>
      <div className={style.header}>
        <h6 onClick={() => navigate("/library")}>
          <FaLongArrowAltLeft /> Notes
        </h6>
        <i className={style.addBtn} onClick={modalHandler}>
          <MdAdd />
        </i>
      </div>
      {isLoading ? (
        <div className={style.loaderWrapper}>
          {[...new Array(4).values()].map((_, index) => (
            <SkeletonLoader className={style.skeletonLogLoader} key={index} />
          ))}
        </div>
      ) : (
        <div className={style.notes}>
          {notes?.map((item, index) => (
            <NotePreview data={item} key={index} />
          ))}
        </div>
      )}

      {toggleModal && (
        <NoteModal>
          <NoteForm
            formAction="newNote"
            data={{
              title: "",
              book_id: "",
              content: [""],
              note_style: { backgroundColor: "#fec46d", color: "#000" },
            }}
            closeModal={modalHandler}
          />
        </NoteModal>
      )}
    </div>
  );
}

export default Notes;
