import { request } from "./api";
import env from "../env";

const API = env.API_URL;

const URL = {
  publishBook: `${API}/books/create`,
  updateCourse: (courseId) => `${API}/books/${courseId}`,
  getMyBooks: `${API}/books/user_books`,
  getBooksBorrowed: `${API}/books/user_borrowed`,
  getBooksPaidFor: `${API}/books/paid`,
  getBookDetails: (bookId) => `${API}/books/${bookId}`,
  getTopRated: `${API}/ratings/top_ratings/10`,
  getRecentUploads: `${API}/books/recent_uploads/10`,
  returnBorrowedBook: (bookId) => `${API}/books/${bookId}/borrowed`,
  borrowBook: (bookId) => `${API}/books/${bookId}/borrow`,
  getCategories: `${API}/categories`,
  getSubcategory: (categoryId) =>
    `${API}/categories/${categoryId}/subcategories/`,
  createBookmark: `${API}/bookmark/create`,
  getAllBookmarks: `${API}/bookmarks/get`,
  deleteBookmark: (bookId) => `${API}/bookmark/delete/${bookId}`,
  getBookBySubCategory: `${API}/books/filter`,
  deletUploadedBook: (bookId) => `${API}/books/${bookId}`,
  createAccessment: `${API}/assessments`,
  createAssessmentQuestion: `${API}/assessments/questions`,
  payForBook: `${API}/books/pay`,
  saveNote: `${API}/notes`,
  getNotes: `${API}/notes`,
  updateNote: (noteId) => `${API}/notes/${noteId}`,
  deleteNote: (noteId) => `${API}/notes/${noteId}`,
  getNoteById: (noteId) => `${API}/notes/${noteId}`,
  uploadCourseMedia: (courseId) => `${API}/books/${courseId}/upload`,
  getECourse: (courseId) => `${API}/books/template/${courseId}`,
  getAllSubjectsBySubId: (subcategoryId) =>
    `${API}/categories/subcategories/${subcategoryId}`,
  getTopicBySubjectId: (subjectId) => `${API}/subject/${subjectId}/topics`,
  uploadTemplateContent: (bookId) => `${API}/books/template/${bookId}`,
  courseFilesUpload: `${API}/books/files/upload`,
  saveReview: `${API}/reviews`,
  getAllReviews: (itemId) => `${API}/reviews?item_id=${itemId}`,
  filterMaterials: `${API}/books/filter`,
};

function publishBook(params) {
  return request(URL.publishBook, params, "POST", {});
}
function courseFilesUpload(params) {
  return request(
    URL.courseFilesUpload,
    params,
    "PUT",
    {},
    { isFormData: true }
  );
}
function getMyBooks(params) {
  return request(URL.getMyBooks, params, "GET", {});
}
function getBooksBorrowed(params) {
  return request(URL.getBooksBorrowed, params, "GET", {});
}
function getBookDetails({ bookId }) {
  return request(URL.getBookDetails(bookId), {}, "GET", {});
}
function getTopRated(params) {
  return request(URL.getTopRated, params, "GET", {});
}
function getRecentUploads(params) {
  return request(URL.getRecentUploads, params, "GET", {});
}

function returnBorrowedBook({ bookId }) {
  return request(URL.returnBorrowedBook(bookId), {}, "PUT", {});
}
// function borrowBook({ bookId }) {
//   return request(URL.borrowBook(bookId), {}, "PUT", {});
// }
function getCategories(params) {
  return request(URL.getCategories, params, "GET", {});
}
function getSubcategory({ categoryId }) {
  return request(URL.getSubcategory(categoryId), {}, "GET", {});
}

function createBookmark(params) {
  return request(URL.createBookmark, params, "POST", {});
}
function getAllBookmarks(params) {
  return request(URL.getAllBookmarks, params, "GET", {});
}
// function deleteBookmark({ bookId }) {
//   return request(URL.deleteBookmark(bookId), {}, "DELETE", {});
// }
function getBookBySubCategory(params) {
  return request(URL.getBookBySubCategory, params, "GET", {});
}
function deletUploadedBook({ bookId, filenames }) {
  return request(URL.deletUploadedBook(bookId), { filenames }, "DELETE", {});
}
function updateCourse({ courseId, params }) {
  return request(URL.updateCourse(courseId), params, "PUT", {});
}
function createAccessment(params) {
  return request(URL.createAccessment, params, "POST", {});
}
function createAssessmentQuestion(params) {
  return request(URL.createAssessmentQuestion, params, "POST", {});
}
function payForBook(params) {
  return request(URL.payForBook, params, "POST", {});
}
function saveNote(params) {
  return request(URL.saveNote, params, "POST", {});
}
// function getNotes(params) {
//   return request(URL.getNotes, params, "GET", {});
// }
function updateNote({ noteId, filename, content }) {
  return request(
    URL.updateNote(noteId),
    {
      content,
      filename,
    },
    "PUT",
    {}
  );
}
// function deleteNote({ noteId }) {
//   return request(URL.deleteNote(noteId), {}, "DELETE", {});
// }
function getNoteById({ noteId }) {
  return request(URL.getNoteById(noteId), {}, "GET", {});
}
function getBooksPaidFor(params) {
  return request(URL.getBooksPaidFor, params, "GET", {});
}
function uploadCourseMedia({ courseId, params }) {
  return request(
    URL.uploadCourseMedia(courseId),
    params,
    "PUT",
    {},
    { isFormData: true }
  );
}
function getECourse({ courseId }) {
  return request(URL.getECourse(courseId), {}, "GET", {});
}

function getAllSubjectsBySubId({ subcategoryId }) {
  return request(URL.getAllSubjectsBySubId(subcategoryId), {}, "GET", {});
}
function getTopicBySubjectId({ subjectId }) {
  return request(URL.getTopicBySubjectId(subjectId), {}, "GET", {});
}
function uploadTemplateContent({ bookId, template_data, template_name }) {
  return request(
    URL.uploadTemplateContent(bookId),
    { template_data, template_name },
    "PUT",
    {}
  );
}
function saveReview(params) {
  return request(URL.saveReview, params, "POST", {});
}
function getAllReviews({ itemId }) {
  return request(URL.getAllReviews(itemId), {}, "GET", {});
}
function filterMaterials(params) {
  return request(URL.filterMaterials, params, "GET", {});
}

const libraryService = {
  // CHIDI STARTS
  bookDetails: (bookId) => `${API}/books/${bookId}`,
  addBookmark: `${API}/bookmark/create`,
  getBookmarks: `${API}/bookmarks/user`,
  deleteBookmark: (bookId) => `${API}/bookmark/delete/${bookId}`,
  borrowBook: (bookId) => `${API}/books/${bookId}/borrow`,
  getBorrowedBooks: `${API}/books/user_borrowed`,
  uploadReview: `${API}/ratings`,
  returnBook: (bookId) => `${API}/books/${bookId}/borrowed`,
  getBooks: `${API}/books`,
  getNotes: `${API}/notes/user-notes`,
  deleteNote: `${API}/notes`,
  addNote: `${API}/notes`,
  getClasses: `${API}/categories/subcategories`,
  createClass: `${API}/categories/subcategory`,
  getClassById: (id) => `${API}/categories/subcategories/${id}`,
  subjects: `${API}/subjects`,
  subjectById: (id) => `${API}/subjects/${id}`,
  getTopicsBySubject: (id) => `${API}/subject/${id}/topics`,
  topics: `${API}/topics`,
  getBookTypes: `${API}/all-enums-options`,
  categories: URL.getCategories,
  categoryById: (id) => `${API}/categories/${id}`,
  uncompletedReading: `${API}/books/uncompleted`,
  monthlyRead: `${API}/books/monthly/read-books`,
  mostViewedBooks: `${API}/most-viewed-book`,
  searchBook: `${API}/search_books`,
  booksFilter: `${API}/books/filter`,
  buyBook: `${API}/buy_book`,
  validateBookPayment: `${API}/verify_payment`,

  // CHIDI ENDS
  publishBook,
  getMyBooks,
  getBooksBorrowed,
  getBookDetails,
  getTopRated,
  getRecentUploads,
  returnBorrowedBook,
  // borrowBook,
  getCategories,
  getSubcategory,
  createBookmark,
  getAllBookmarks,
  // deleteBookmark,
  getBookBySubCategory,
  deletUploadedBook,
  updateCourse,
  createAccessment,
  createAssessmentQuestion,
  payForBook,
  saveNote,
  // getNotes,
  updateNote,
  // deleteNote,
  getNoteById,
  getBooksPaidFor,
  uploadCourseMedia,
  getECourse,
  getAllSubjectsBySubId,
  getTopicBySubjectId,
  uploadTemplateContent,
  courseFilesUpload,
  saveReview,
  getAllReviews,
  filterMaterials,
};

export default libraryService;
