import moment from "moment";
import StarRating from "../StarRating/StarRating";
import style from "./BookReviews.module.css";
import { useRef, useState } from "react";
import useCdReactQuery from "../../utils/hooks/useCdReactQuery";
import libraryService from "../../service/library.service";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

export const UserReview = ({ arg, data }) => {
  return (
    <div className={`${style.review} ${style[arg]}`}>
      <div className={style.user}>{data?.name.charAt(0)}</div>
      <div className={style.content}>
        <p className={style.datecreated}>
          {moment(data?.dateCreated, "DD-MM-YYYY HH:mm:ss").format(
            "MMMM Do YYYY, h:mm:ss a"
          )}
        </p>
        <h6 className={style.name}>{data?.name}</h6>
        <p className={style.comment}>{data?.comment}</p>
        <StarRating rating={data?.score} className={style.rating} />
      </div>
    </div>
  );
};

const BookReviews = ({ reviews }) => {
  const { user } = useSelector(({ currentUser }) => currentUser);
  const { data, queryEndpoint } = useCdReactQuery();
  const [bookReviews, setBookReviews] = useState(reviews);
  const params = useParams();
  let commentRef = useRef();
  const ratingScore = useRef();

  const handleCallback = (_score) => {
    ratingScore.current = _score;
  };

  const submitHandler = async () => {
    if (commentRef.current.value && ratingScore.current) {
      setBookReviews((prev) => [
        {
          dateCreated: moment().format("YYYY-MM-DD"),
          name: `${user.lastName} ${user.firstName}`,
          comment: commentRef.current.value,
          score: ratingScore.current,
        },
        ...prev,
      ]);

      await queryEndpoint({
        endpointUrl: libraryService.uploadReview,
        httpMethod: "POST",
        body: {
          comment: commentRef.current.value,
          item_id: params.courseId,
          score: ratingScore.current,
        },
      });
      toast.info(data);
    } else {
      toast.info("Rating and Comment fields are required", {
        autoClose: 3000, // Auto-close the notification after 3 seconds
        position: toast.POSITION.TOP_RIGHT, // Position of the toast
        hideProgressBar: true, // Display a progress bar
        closeOnClick: true, // Close the toast when clicked
        pauseOnHover: false, // Pause auto-close on hover
        draggable: false, // Allow dragging to dismiss
      });
    }
  };

  return (
    <div className={style.root}>
      <div className={style.content}>
        <h5 className={style.heading}>Reviews</h5>
      </div>
      <div className={style.reviewForm}>
        <textarea rows={5} cols={50} placeholder="Comments" ref={commentRef} />
        <div className={style.ratingHolder}>
          Rate this course{" "}
          <StarRating
            rating={0}
            className={""}
            isEditable={true}
            callbackFn={handleCallback}
          />{" "}
        </div>
        <div className={style.action}>
          <button onClick={submitHandler}>SAVE REVIEW</button>
        </div>
      </div>
      <div className={style.content}>
        {/* <h5 className={style.heading}>Reviews</h5> */}
        <div className={style.reviews}>
          {bookReviews?.map((review, index) => (
            <UserReview
              key={index}
              arg={index % 2 === 0 ? "even" : "odd"}
              data={review}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default BookReviews;
