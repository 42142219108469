import { useParams } from "react-router-dom";
import coursesService from "../../../../service/courses.service";
import { QUERY_KEY } from "../../../../utils/constants";
import useAppQuery from "../../../../utils/hooks/useAppQuery";

function useCourseDetails() {
  const { courseId } = useParams();
  const { data: { curriculum = {} } = {}, isLoading: fetchingDetals } =
    useAppQuery(
      QUERY_KEY.getCourseById,
      coursesService.getCourseById,
      { courseId },
      { enabled: !!courseId }
    );
  return { curriculum, fetchingDetals };
}

export default useCourseDetails;
